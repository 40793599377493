import Header from "../../../include/Header";
import Footer from "../../../include/Footer";
import { authHeader } from "../../../../auth";
import Pagination from '../../paginationResource/Pagination.vue';
import MobilePagination from '../../paginationResource/MobilePagination.vue';
import $ from 'jquery';
export default {
  name: "User Profile",
  title: "User Profile",
  components: {
    Header,
    Footer,
    Pagination,
    MobilePagination
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: "bars",
      imagePath: this.$imageURL + "user/",
      teamsDetail: [],
      message: null,
      userId: null, 
      reveal: false,
      userInfoData: {},
      userProfile: {},
      prducts: [],
      totalPages: 0,
      limit: 9,
      page: 1
    };
  },
  beforeCreate: function () {
    document.body.className =
      "archive author author-emily-rees author-3 theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-6";

    let style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href = "/asset/wp-content/uploads/elementor/css/post-868.css";
    document.head.appendChild(style);
  },
  async created() {
    this.userId = this.$route.params.id
    await this.getUserInfo(this.userId);
  },
  methods: {
    revealPhone: function() {
        if(localStorage.getItem('token')) {
            this.reveal = !this.reveal;
        }
        else {
            this.$swal.fire({
                icon: "error",
                text: "To reveal number please log in or register!",
            });
        }
        
        
    },
    getMonthDifference(st, ed) {
      console.log("st: ", st);
      let endDate = new Date(ed);
      let startDate  = new Date(st);
      return (
        endDate.getMonth() -
        startDate.getMonth() +
        12 * (endDate.getFullYear() - startDate.getFullYear())
      );
  },
    getUserInfo: async function (id) {
      this.isLoading = true;
      let params = "?page=" + this.page + "&limit=" + this.limit;
      var config = {
        method: "GET",
        url: this.$serverURL + this.$api.profile.userProfileInfo + id + params,
      };
      await this.$axios(config)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.userInfoData = response.data.userInfoData;
            this.userProfile = response.data.userProfile;
            this.products = response.data.products;
            this.totalPages = response.data.pageCount;
            this.memberSince = this.getMonthDifference(this.$filters.userDate(this.userInfoData.updatedAt), this.$filters.userDate(new Date()));
            this.isLoading = false;
            document.title = "User Profile";
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            text: "Could not get user details!",
          });
        });
    },
    sendMessage: function() {
        
        let data = {
          teamMemberId: this.userId,
          message: this.message,
        };
        console.log("data....",data.message);
        if((data.message)==null) {
          console.log("data....s",data.message);
          this.$swal.fire({
              icon: "error",
              text: "Please enter a message!",
          });
          return false;
        }
        let config = {
            method: "POST",
            url: this.$serverURL + this.$api.teams.sendMessage,
            data: data,
            headers: authHeader()
        };
        if (localStorage.getItem('token')){
            this.$axios(config)
            .then((response) => {
                if (response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully send your message",
                    });
                    this.message = null;
                }
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!",
                });
            });
        }
        else {
            this.$swal.fire({
                icon: "error",
                text: "Please Log In or Register",
            });
        }
    },
    onPageChange(SourceDataCounterPage) {
        console.log(SourceDataCounterPage)
        this.page = SourceDataCounterPage;
        console.log(SourceDataCounterPage);
        this.getUserInfo(this.userId);
        
        
        $('.listivo-pagination-desktop__page').removeClass('listivo-pagination-desktop__page--active');
        $('.listivo-pagination-desktop__page')
        .each(function(index,row){
            if($(row).html()==SourceDataCounterPage)
            {
                $(row).addClass('listivo-pagination-desktop__page--active');
            }
        });
        $(".product-frame-loader").removeClass('listivo-loading');
    },
  },
};
