<template>
  <div data-elementor-type="wp-post" data-elementor-id="57" class="elementor elementor-57"
     data-elementor-settings="[]">
     <div class="elementor-section-wrap">
        <section
           class="elementor-section elementor-top-section elementor-element elementor-element-de45e04 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
           data-id="de45e04" data-element_type="section">
           <div class="elementor-container elementor-column-gap-default">
              <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70803c3"
                 data-id="70803c3" data-element_type="column">
                 <div class="elementor-widget-wrap elementor-element-populated">
                    <div class="elementor-element elementor-element-d6d46ed elementor-widget elementor-widget-lst_menu"
                       data-id="d6d46ed" data-element_type="widget" data-widget_type="lst_menu.default">
                       <div class="elementor-widget-container">
                          <Header/>
                       </div>
                    </div>
                    <div class="elementor-element elementor-element-7807579 elementor-widget elementor-widget-lst_template_loader"
                       data-id="7807579" data-element_type="widget"
                       data-widget_type="lst_template_loader.default">
                       <div class="elementor-widget-container">
                          <div data-elementor-type="wp-page" data-elementor-id="739"
                             class="elementor elementor-739" data-elementor-settings="[]">
                             <div class="elementor-section-wrap">
                                <section
                                   class="elementor-section elementor-top-section elementor-element elementor-element-36217e51 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                   :style="{backgroundImage: 'url(' + imagePath + teamPageCms.headerBackground + ')'}">
                                   <div class="elementor-background-overlay"></div>
                                   <div class="elementor-container elementor-column-gap-default">
                                      <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6fbc91e1"
                                         data-id="6fbc91e1" data-element_type="column">
                                         <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-499d693b elementor-widget elementor-widget-heading"
                                               data-id="499d693b" data-element_type="widget"
                                               data-widget_type="heading.default">
                                               <div class="elementor-widget-container">
                                                  <h1
                                                     class="elementor-heading-title elementor-size-xl">
                                                     {{ teamPageCms.headerTitle }}
                                                  </h1>
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                      <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-745ff803"
                                         data-id="745ff803" data-element_type="column">
                                         <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-717be4cf elementor-widget elementor-widget-lst_breadcrumbs"
                                               data-id="717be4cf" data-element_type="widget"
                                               data-widget_type="lst_breadcrumbs.default">
                                               <div class="elementor-widget-container">
                                                  <div class="listivo-app">
                                                        <div
                                                           class="listivo-breadcrumbs-wrapper">
                                                           <div
                                                              class="listivo-breadcrumbs">
                                                              <div
                                                                 class="listivo-breadcrumbs__single">
                                                                 <a class="listivo-breadcrumbs__link"
                                                                    href="#"
                                                                    title="Home">
                                                                 Home </a>
                                                                 <span
                                                                    class="listivo-breadcrumbs__separator">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                       width="11" height="11"
                                                                       viewBox="0 0 11 11">
                                                                       <g>
                                                                          <g>
                                                                             <path
                                                                                d="M4.974.635c-.119.12-.119.246 0 .38l3.987 3.973H.587c-.178 0-.267.09-.267.268v.223c0 .179.089.268.267.268H8.96L4.974 9.721c-.119.133-.119.26 0 .379l.156.178c.134.119.26.119.379 0l4.7-4.732c.133-.119.133-.238 0-.357L5.508.457c-.119-.12-.245-.12-.379 0z" />
                                                                          </g>
                                                                       </g>
                                                                    </svg>
                                                                 </span>
                                                              </div>
                                                              <div
                                                                 class="listivo-breadcrumbs__last">
                                                                 {{ teamPageCms.headerPageName }}
                                                              </div>
                                                           </div>



                                                        </div>
                                                  </div>
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </section>
                                <section
                                   class="elementor-section elementor-top-section elementor-element elementor-element-ed8b077 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                   data-id="ed8b077" data-element_type="section"
                                   data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                   <div class="elementor-container elementor-column-gap-default">
                                      <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-de54db3"
                                         data-id="de54db3" data-element_type="column">
                                         <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-8355d81 elementor-absolute elementor-widget elementor-widget-lst_shape"
                                               data-id="8355d81" data-element_type="widget"
                                               data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
                                               data-widget_type="lst_shape.default">
                                               <div class="elementor-widget-container">
                                                  <div class="listivo-shape">
                                                     <div class="listivo-shape-dots"></div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-9a63ee4 elementor-widget__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-lst_user_profile"
                                               data-id="9a63ee4" data-element_type="widget"
                                               data-widget_type="lst_user_profile.default">
                                               <div class="elementor-widget-container">
                                                  <div
                                                     class="listivo-profile listivo-profile--single">
                                                        <router-link
                                                         v-if="
                                                         typeof ceo.id !== 'undefined'
                                                         "
                                                         :to="{
                                                         name: 'UserPage',
                                                         params: { id: ceo.id},
                                                         }"
                                                         class="listivo-profile__link-profile"
                                                         >
                                                         </router-link>
                                                     <div class="listivo-profile__image-wrapper">
                                                        <img
                                                               v-bind:src="imagePath + ceo.photo"
                                                               v-bind:alt="ceo.name"
                                                               />
                                                     </div>
                                                     <div class="listivo-profile__details">
                                                        <div class="listivo-profile__hidden">
                                                           <div
                                                              class="listivo-profile__social-icon">
                                                              <div
                                                                 class="listivo-social-profiles">
                                                                 <a class="listivo-social-profiles__single"
                                                                    :href="ceo.facebookLink" target="_blank">
                                                                 <i
                                                                    class="fab fa-facebook-f"></i>
                                                                 </a>
                                                                 <a class="listivo-social-profiles__single"
                                                                    :href="ceo.twitterLink" target="_blank">
                                                                 <i
                                                                    class="fab fa-twitter"></i>
                                                                 </a>
                                                                 <a class="listivo-social-profiles__single"
                                                                   :href="ceo.linkedinLink" target="_blank">
                                                                 <i
                                                                    class="fab fa-linkedin"></i>
                                                                 </a>
                                                                 <a class="listivo-social-profiles__single"
                                                                    :href="ceo.instagramLink" target="_blank">
                                                                 <i
                                                                    class="fab fa-instagram"></i>
                                                                 </a>
                                                                 <a class="listivo-social-profiles__single"
                                                                    :href="ceo.youtubeLink" target="_blank">
                                                                 <i
                                                                    class="fab fa-youtube"></i>
                                                                 </a>
                                                              </div>
                                                           </div>
                                                        </div>
                                                     </div>
                                                  </div>
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                      <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7cee769"
                                         data-id="7cee769" data-element_type="column">
                                         <div class="elementor-widget-wrap elementor-element-populated" style="display: block;">
                                            <div class="elementor-element elementor-element-9425860 elementor-widget__width-auto elementor-widget elementor-widget-heading"
                                               data-id="9425860" data-element_type="widget"
                                               data-widget_type="heading.default">
                                               <div class="elementor-widget-container">
                                                  <h1
                                                     class="elementor-heading-title elementor-size-medium" style="color: #000;">
                                                     {{ ceo.name }}
                                                  </h1>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-8a490df elementor-widget__width-auto elementor-absolute elementor-widget elementor-widget-lst_shape"
                                               data-id="8a490df" data-element_type="widget"
                                               data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
                                               data-widget_type="lst_shape.default">
                                               <div class="elementor-widget-container">
                                                  <div class="listivo-shape">
                                                     <div class="listivo-shape-triangle">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                           viewBox="0 0 121.000000 121.000000"
                                                           preserveAspectRatio="xMidYMid meet">
                                                           <g transform="translate(0.000000,121.000000) scale(0.100000,-0.100000)"
                                                              stroke="none">
                                                              <path
                                                                 d="M1127 1196 c-3 -8 -23 -18 -44 -21 -21 -3 -67 -14 -103 -25 -124 -35 -185 -51 -240 -64 -30 -7 -71 -18 -90 -24 -40 -13 -128 -37 -210 -56 -30 -7 -77 -21 -103 -30 -26 -9 -57 -16 -67 -16 -11 0 -46 -9 -77 -19 -32 -11 -76 -22 -98 -26 -46 -8 -65 -21 -65 -44 0 -21 820 -841 841 -841 23 0 36 19 44 65 4 22 15 66 26 98 10 31 19 66 19 77 0 10 7 41 16 67 9 26 23 73 30 103 19 82 43 170 56 210 6 19 17 60 24 90 13 55 29 116 64 240 11 36 22 82 25 103 3 21 13 41 21 44 9 3 14 19 14 44 0 39 0 39 -39 39 -25 0 -41 -5 -44 -14z m-78 -149 c11 -14 -1 -90 -32 -192 -19 -65 -35 -126 -51 -195 -7 -30 -20 -77 -29 -105 -20 -63 -44 -151 -62 -234 -15 -64 -38 -111 -55 -111 -12 0 -610 598 -610 610 0 17 47 40 111 55 83 18 171 42 234 62 28 9 75 22 105 29 70 16 131 32 195 52 48 14 148 38 171 41 6 0 17 -5 23 -12z">
                                                              </path>
                                                           </g>
                                                        </svg>
                                                     </div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-544db88 elementor-widget elementor-widget-spacer"
                                               data-id="544db88" data-element_type="widget"
                                               data-widget_type="spacer.default">
                                               <div class="elementor-widget-container">
                                                  <div class="elementor-spacer">
                                                     <div class="elementor-spacer-inner"></div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-451aa39 elementor-widget elementor-widget-text-editor"
                                               data-id="451aa39" data-element_type="widget"
                                               data-widget_type="text-editor.default">
                                               <div class="elementor-widget-container">
                                                  <p>{{ ceo.designation  }}</p>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-e60bbea elementor-widget elementor-widget-spacer"
                                               data-id="e60bbea" data-element_type="widget"
                                               data-widget_type="spacer.default">
                                               <div class="elementor-widget-container">
                                                  <div class="elementor-spacer">
                                                     <div class="elementor-spacer-inner"></div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-8fdbea8 elementor-widget elementor-widget-lst_shape"
                                               data-id="8fdbea8" data-element_type="widget"
                                               data-widget_type="lst_shape.default">
                                               <div class="elementor-widget-container">
                                                  <div class="listivo-shape">
                                                     <div class="listivo-shape-line">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                           viewBox="0 0 913.000000 42.000000"
                                                           preserveAspectRatio="xMidYMid meet">
                                                           <g transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                                              stroke="none">
                                                              <path
                                                                 d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z">
                                                              </path>
                                                           </g>
                                                        </svg>
                                                     </div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-3e1d17c elementor-widget elementor-widget-spacer"
                                               data-id="3e1d17c" data-element_type="widget"
                                               data-widget_type="spacer.default">
                                               <div class="elementor-widget-container">
                                                  <div class="elementor-spacer">
                                                     <div class="elementor-spacer-inner"></div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-f9712e2 elementor-widget elementor-widget-text-editor"
                                               data-id="f9712e2" data-element_type="widget"
                                               data-widget_type="text-editor.default">
                                               <div class="elementor-widget-container">
                                                  <p v-html="ceo.shortDetail"></p>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-f8f4358 elementor-widget elementor-widget-spacer"
                                               data-id="f8f4358" data-element_type="widget"
                                               data-widget_type="spacer.default">
                                               <div class="elementor-widget-container">
                                                  <div class="elementor-spacer">
                                                     <div class="elementor-spacer-inner"></div>
                                                  </div>
                                               </div>
                                            </div>
                                            <!-- <section
                                               class="elementor-section elementor-inner-section elementor-element elementor-element-35fd429 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                               data-id="35fd429" data-element_type="section">
                                               <div
                                                  class="elementor-container elementor-column-gap-default">
                                                  <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-1774824"
                                                     data-id="1774824" data-element_type="column">
                                                     <div
                                                        class="elementor-widget-wrap elementor-element-populated">
                                                        <div class="elementor-element elementor-element-54e4b88 elementor-widget__width-auto elementor-absolute elementor-view-default elementor-widget elementor-widget-icon"
                                                           data-id="54e4b88"
                                                           data-element_type="widget"
                                                           data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
                                                           data-widget_type="icon.default">
                                                           <div class="elementor-widget-container">
                                                              <div class="elementor-icon-wrapper">
                                                                 <div class="elementor-icon">
                                                                    <i aria-hidden="true"
                                                                       class="fas fa-quote-left"></i>
                                                                 </div>
                                                              </div>
                                                           </div>
                                                        </div>
                                                        <div class="elementor-element elementor-element-00ea6a2 elementor-widget elementor-widget-text-editor"
                                                           data-id="00ea6a2"
                                                           data-element_type="widget"
                                                           data-widget_type="text-editor.default">
                                                           <div class="elementor-widget-container">
                                                              <p>Tempor incididunt laboret dolore
                                                                 sed magna aliqua duis veniam sed
                                                                 erciton. Lorem ipsum dolor sit
                                                                 amet adipisic dolor elit tempor
                                                                 incididunt labore.
                                                              </p>
                                                           </div>
                                                        </div>
                                                        <div class="elementor-element elementor-element-cb629fb elementor-widget elementor-widget-spacer"
                                                           data-id="cb629fb"
                                                           data-element_type="widget"
                                                           data-widget_type="spacer.default">
                                                           <div class="elementor-widget-container">
                                                              <div class="elementor-spacer">
                                                                 <div
                                                                    class="elementor-spacer-inner">
                                                                 </div>
                                                              </div>
                                                           </div>
                                                        </div>
                                                        <div class="elementor-element elementor-element-7096a53 elementor-widget elementor-widget-heading"
                                                           data-id="7096a53"
                                                           data-element_type="widget"
                                                           data-widget_type="heading.default">
                                                           <div class="elementor-widget-container">
                                                              <h2
                                                                 class="elementor-heading-title elementor-size-default">
                                                                 Jacob Austin
                                                              </h2>
                                                           </div>
                                                        </div>
                                                        <div class="elementor-element elementor-element-2f0cb06 elementor-widget__width-auto elementor-absolute elementor-hidden-mobile elementor-widget elementor-widget-lst_shape"
                                                           data-id="2f0cb06"
                                                           data-element_type="widget"
                                                           data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
                                                           data-widget_type="lst_shape.default">
                                                           <div class="elementor-widget-container">
                                                              <div class="listivo-shape">
                                                                 <div class="listivo-shape-ox">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                       viewBox="0 0 36 39">
                                                                       <g>
                                                                          <g>
                                                                             <path
                                                                                d="M35.386 13.26a1.437 1.437 0 0 1-2.027 0l-4.415-4.4-4.415 4.4a1.437 1.437 0 0 1-2.027 0 1.425 1.425 0 0 1 0-2.02l4.415-4.4-4.415-4.399a1.425 1.425 0 0 1 0-2.02 1.437 1.437 0 0 1 2.027 0l4.415 4.4 4.415-4.4a1.437 1.437 0 0 1 2.027 0c.56.558.56 1.462 0 2.02L30.97 6.84l4.415 4.4c.56.557.56 1.461 0 2.02zM11.58 33.58c0 2.932-2.384 5.307-5.326 5.307-2.94 0-5.325-2.375-5.325-5.307 0-2.93 2.384-5.306 5.325-5.306 2.942 0 5.326 2.376 5.326 5.306z" />
                                                                          </g>
                                                                       </g>
                                                                    </svg>
                                                                 </div>
                                                              </div>
                                                           </div>
                                                        </div>
                                                     </div>
                                                  </div>
                                               </div>
                                            </section> -->
                                            <div class="elementor-element elementor-element-c3f66f2 elementor-widget elementor-widget-spacer"
                                               data-id="c3f66f2" data-element_type="widget"
                                               data-widget_type="spacer.default">
                                               <div class="elementor-widget-container">
                                                  <div class="elementor-spacer">
                                                     <div class="elementor-spacer-inner"></div>
                                                  </div>
                                               </div>
                                            </div>
                                            <section
                                               class="elementor-section elementor-inner-section elementor-element elementor-element-6d1c1c0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                               data-id="6d1c1c0" data-element_type="section">
                                               <div
                                                  class="elementor-container elementor-column-gap-default">
                                                  <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-28353f4"
                                                     data-id="28353f4" data-element_type="column">
                                                     <div
                                                        class="elementor-widget-wrap elementor-element-populated">
                                                        <div class="elementor-element elementor-element-45336ea elementor-view-stacked elementor-widget__width-auto elementor-shape-circle elementor-widget elementor-widget-icon"
                                                           data-id="45336ea"
                                                           data-element_type="widget"
                                                           data-widget_type="icon.default">
                                                           <div class="elementor-widget-container">
                                                              <div class="elementor-icon-wrapper">
                                                                 <div class="elementor-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                       width="200" height="200"
                                                                       viewBox="0 0 200 200">

                                                                       <path id="icon"
                                                                          class="cls-1"
                                                                          d="M251.663,813.505a11.209,11.209,0,0,0-5.078-1.172,11.819,11.819,0,0,0-9.375,4.688l-17.188,21.094a134.625,134.625,0,0,1-62.5-62.5l21.094-17.188a11.819,11.819,0,0,0,4.688-9.375,11.212,11.212,0,0,0-1.172-5.078l-17.969-42.187q-3.131-7.416-11.328-7.422a11.669,11.669,0,0,0-2.734.39l-39.454,8.985a15.546,15.546,0,0,0-4.882,2.343,10.322,10.322,0,0,0-3.321,4.1,12.48,12.48,0,0,0-1.172,5.273v0.391a172.954,172.954,0,0,0,14.258,69.336,183.067,183.067,0,0,0,38.086,57.031q23.823,24.024,57.031,38.086a175.522,175.522,0,0,0,69.141,14.063h0.391a11.673,11.673,0,0,0,7.617-2.735,11.369,11.369,0,0,0,4.1-6.64l8.985-39.453a8.783,8.783,0,0,0,.39-2.735q0-8.2-7.421-11.328Zm-24.219,59.961a155.568,155.568,0,0,1-45.7-23.828A185.593,185.593,0,0,1,146,813.9a155.568,155.568,0,0,1-23.828-45.7,165.489,165.489,0,0,1-8.4-52.344l39.063-8.984L170.8,749.052,142.288,772.1q14.063,30.47,32.422,48.828t48.828,32.422l23.047-28.516L288.772,842.8l-8.984,39.063A165.659,165.659,0,0,1,227.444,873.466Z"
                                                                          transform="translate(-101.281 -694.375)">
                                                                       </path>
                                                                    </svg>
                                                                 </div>
                                                              </div>
                                                           </div>
                                                        </div>
                                                        <div class="elementor-element elementor-element-47b21f0 elementor-widget__width-auto elementor-widget elementor-widget-lst_phone"
                                                           data-id="47b21f0"
                                                           data-element_type="widget"
                                                           data-widget_type="lst_phone.default">
                                                           <div class="elementor-widget-container">
                                                              <div class="listivo-phone-wrapper">
                                                                 <a :href="'tel:' + ceo.phone"
                                                                    class="listivo-phone">
                                                                 {{ ceo.phone }} </a>
                                                              </div>
                                                           </div>
                                                        </div>
                                                     </div>
                                                  </div>
                                                  <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a022d1c"
                                                     data-id="a022d1c" data-element_type="column">
                                                     <div
                                                        class="elementor-widget-wrap elementor-element-populated">
                                                        <div class="elementor-element elementor-element-20d1730 elementor-view-stacked elementor-widget__width-auto elementor-shape-circle elementor-widget elementor-widget-icon"
                                                           data-id="20d1730"
                                                           data-element_type="widget"
                                                           data-widget_type="icon.default">
                                                           <div class="elementor-widget-container">
                                                              <div class="elementor-icon-wrapper">
                                                                 <div class="elementor-icon">
                                                                    <i aria-hidden="true"
                                                                       class="far fa-envelope-open"></i>
                                                                 </div>
                                                              </div>
                                                           </div>
                                                        </div>
                                                        <div class="elementor-element elementor-element-ee4d3ea elementor-widget__width-auto elementor-widget elementor-widget-lst_email"
                                                           data-id="ee4d3ea"
                                                           data-element_type="widget"
                                                           data-widget_type="lst_email.default">
                                                           <div class="elementor-widget-container">
                                                              <div class="listivo-email-wrapper">
                                                                 <a :href="'mailto:'+ ceo.email"
                                                                    class="listivo-email">
                                                                 <span class="__cf_email__"
                                                                    :data-cfemail="ceo.email">{{ ceo.email }}</span>
                                                                 </a>
                                                              </div>
                                                           </div>
                                                        </div>
                                                     </div>
                                                  </div>
                                               </div>
                                            </section>
                                         </div>
                                      </div>
                                   </div>
                                </section>
                                <section
                                   class="elementor-section elementor-top-section elementor-element elementor-element-a136842 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                   data-id="a136842" data-element_type="section">
                                   <div class="elementor-container elementor-column-gap-default">
                                      <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ef1b8c0"
                                         data-id="ef1b8c0" data-element_type="column">
                                         <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-e1ce75f elementor-widget elementor-widget-spacer"
                                               data-id="e1ce75f" data-element_type="widget"
                                               data-widget_type="spacer.default">
                                               <div class="elementor-widget-container">
                                                  <div class="elementor-spacer">
                                                     <div class="elementor-spacer-inner"></div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-7a1a32e elementor-widget elementor-widget-lst_heading"
                                               data-id="7a1a32e" data-element_type="widget"
                                               data-widget_type="lst_heading.default">
                                               <div class="elementor-widget-container">
                                                  <div
                                                     class="listivo-heading listivo-heading--center listivo-heading--triangle_left">
                                                     <div class="listivo-heading__small-text">
                                                        <div
                                                           class="listivo-heading__small-text__svg-1">
                                                           <svg xmlns="http://www.w3.org/2000/svg"
                                                              width="25" height="1"
                                                              viewBox="0 0 25 1">
                                                              <g>
                                                                 <g>
                                                                    <path d="M0 0h15v1H0z" />
                                                                 </g>
                                                                 <g>
                                                                    <path d="M20 0h5v1h-5z" />
                                                                 </g>
                                                              </g>
                                                           </svg>
                                                        </div>
                                                        <div
                                                           class="listivo-heading__small-text-content">
                                                           {{ teamPageCms.teamSubTitle }}
                                                        </div>
                                                        <div
                                                           class="listivo-heading__small-text__svg-2">
                                                           <svg xmlns="http://www.w3.org/2000/svg"
                                                              width="25" height="1"
                                                              viewBox="0 0 25 1">
                                                              <g>
                                                                 <g>
                                                                    <path d="M10 0h15v1H10z" />
                                                                 </g>
                                                                 <g>
                                                                    <path d="M0 0h5v1H0z" />
                                                                 </g>
                                                              </g>
                                                           </svg>
                                                        </div>
                                                     </div>
                                                     <div class="listivo-heading__main">
                                                        <div class="listivo-heading__text">
                                                           <h2>{{ teamPageCms.teamTitle }}</h2>
                                                           <div class="listivo-heading__triangle">
                                                              <svg xmlns='http://www.w3.org/2000/svg'
                                                                 viewBox='0 0 121.000000 121.000000'
                                                                 preserveAspectRatio='xMidYMid meet'>
                                                                 <g transform='translate(0.000000,121.000000) scale(0.100000,-0.100000)'
                                                                    stroke='none'>
                                                                    <path d='M1127 1196 c-3 -8 -23 -18 -44 -21 -21 -3 -67 -14 -103 -25 -124 -35
                                                                       -185 -51 -240 -64 -30 -7 -71 -18 -90 -24 -40 -13 -128 -37 -210 -56 -30 -7
                                                                       -77 -21 -103 -30 -26 -9 -57 -16 -67 -16 -11 0 -46 -9 -77 -19 -32 -11 -76
                                                                       -22 -98 -26 -46 -8 -65 -21 -65 -44 0 -21 820 -841 841 -841 23 0 36 19 44 65
                                                                       4 22 15 66 26 98 10 31 19 66 19 77 0 10 7 41 16 67 9 26 23 73 30 103 19 82
                                                                       43 170 56 210 6 19 17 60 24 90 13 55 29 116 64 240 11 36 22 82 25 103 3 21
                                                                       13 41 21 44 9 3 14 19 14 44 0 39 0 39 -39 39 -25 0 -41 -5 -44 -14z m-78
                                                                       -149 c11 -14 -1 -90 -32 -192 -19 -65 -35 -126 -51 -195 -7 -30 -20 -77 -29
                                                                       -105 -20 -63 -44 -151 -62 -234 -15 -64 -38 -111 -55 -111 -12 0 -610 598
                                                                       -610 610 0 17 47 40 111 55 83 18 171 42 234 62 28 9 75 22 105 29 70 16 131
                                                                       32 195 52 48 14 148 38 171 41 6 0 17 -5 23 -12z' />
                                                                 </g>
                                                              </svg>
                                                           </div>
                                                        </div>
                                                     </div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-9d8d8c0 elementor-widget elementor-widget-spacer"
                                               data-id="9d8d8c0" data-element_type="widget"
                                               data-widget_type="spacer.default">
                                               <div class="elementor-widget-container">
                                                  <div class="elementor-spacer">
                                                     <div class="elementor-spacer-inner"></div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-471b2cf elementor-widget elementor-widget-lst_user_profiles"
                                               data-id="471b2cf" data-element_type="widget"
                                               data-widget_type="lst_user_profiles.default">
                                               <div class="elementor-widget-container">
                                                  <div class="listivo-profiles">
                                                     <div 
                                                      v-for="(row, index) in teamMembers" 
                                                      :key="index" 
                                                      class="listivo-profiles__col">
                                                         <div class="listivo-profile">
                                                              <router-link
                                                         v-if="
                                                         typeof row.id !== 'undefined'
                                                         " 
                                                         :to="{
                                                         name: 'UserPage',
                                                         params: { id: row.id},
                                                         }"
                                                         class="listivo-profile__link-profile"
                                                         >
                                                         </router-link>
                                                           <div
                                                              class="listivo-profile__image-wrapper">
                                                              <img
                                                               v-bind:src="imagePath + row.photo"
                                                               v-bind:alt="row.name"
                                                               />
                                                           </div>
                                                           <div class="listivo-profile__details">
                                                              <div
                                                                 class="listivo-profile__visible">
                                                                 <div
                                                                    class="listivo-profile__title">
                                                                    {{ row['name'] }}
                                                                 </div>
                                                                 <div
                                                                    class="listivo-profile__subtitle">
                                                                    {{ row['designation'] }}
                                                                 </div>
                                                              </div>
                                                              <div
                                                                 class="listivo-profile__hidden">
                                                                 <a class="listivo-profile__phone"
                                                                    :href="'tel:'+ row['phone'] ">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                       viewBox="0 0 200 200">
                                                                       <path class="cls-1"
                                                                          d="M251.663,813.505a11.209,11.209,0,0,0-5.078-1.172,11.819,11.819,0,0,0-9.375,4.688l-17.188,21.094a134.625,134.625,0,0,1-62.5-62.5l21.094-17.188a11.819,11.819,0,0,0,4.688-9.375,11.212,11.212,0,0,0-1.172-5.078l-17.969-42.187q-3.131-7.416-11.328-7.422a11.669,11.669,0,0,0-2.734.39l-39.454,8.985a15.546,15.546,0,0,0-4.882,2.343,10.322,10.322,0,0,0-3.321,4.1,12.48,12.48,0,0,0-1.172,5.273v0.391a172.954,172.954,0,0,0,14.258,69.336,183.067,183.067,0,0,0,38.086,57.031q23.823,24.024,57.031,38.086a175.522,175.522,0,0,0,69.141,14.063h0.391a11.673,11.673,0,0,0,7.617-2.735,11.369,11.369,0,0,0,4.1-6.64l8.985-39.453a8.783,8.783,0,0,0,.39-2.735q0-8.2-7.421-11.328Zm-24.219,59.961a155.568,155.568,0,0,1-45.7-23.828A185.593,185.593,0,0,1,146,813.9a155.568,155.568,0,0,1-23.828-45.7,165.489,165.489,0,0,1-8.4-52.344l39.063-8.984L170.8,749.052,142.288,772.1q14.063,30.47,32.422,48.828t48.828,32.422l23.047-28.516L288.772,842.8l-8.984,39.063A165.659,165.659,0,0,1,227.444,873.466Z"
                                                                          transform="translate(-101.281 -694.375)" />
                                                                    </svg>
                                                                    {{ row['phone'] }}
                                                                 </a>
                                                                 <div
                                                                    class="listivo-profile__social-icon">
                                                                    <div
                                                                       class="listivo-social-profiles">
                                                                       <a class="listivo-social-profiles__single"
                                                                          :href="row['facebookLink'] "
                                                                          target="_blank">
                                                                       <i
                                                                          class="fab fa-facebook-f"></i>
                                                                       </a>
                                                                       <a class="listivo-social-profiles__single"
                                                                          :href="row['twitterLink'] "
                                                                          target="_blank">
                                                                       <i
                                                                          class="fab fa-twitter"></i>
                                                                       </a>
                                                                       <a class="listivo-social-profiles__single"
                                                                          :href="row['instagramLink'] "
                                                                          target="_blank">
                                                                       <i
                                                                          class="fab fa-instagram"></i>
                                                                       </a>
                                                                       <a class="listivo-social-profiles__single"
                                                                          :href="row['youtubeLink'] "
                                                                          target="_blank">
                                                                       <i
                                                                          class="fab fa-youtube"></i>
                                                                       </a>
                                                                    </div>
                                                                 </div>
                                                              </div>
                                                           </div>
                                                        </div>
                                                     </div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-be0cc8e elementor-widget elementor-widget-spacer"
                                               data-id="be0cc8e" data-element_type="widget"
                                               data-widget_type="spacer.default">
                                               <div class="elementor-widget-container">
                                                  <div class="elementor-spacer">
                                                     <div class="elementor-spacer-inner"></div>
                                                  </div>
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </section>
                                <Subscribe></Subscribe>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </section>
        <Footer/>
     </div>
  </div>
</template>

<style>
   .cls-1 {
   fill-rule: evenodd;
   }
</style>
<script src="../js/team.js"></script>