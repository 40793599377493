import Header from '../../../include/Header'
import Footer from '../../../include/Footer';
import { authHeader } from "../../../../auth";
import $ from 'jquery';

export default {
  name: 'Single post',
  title: 'Single post',
  components:{
    Header,
    Footer
  },
  data() {
    return {
        isLoading: false,
        fullPage: true,
        loader: "bars",
        blogList: [],
        blogCategories: [],
        blogTags: [],
        blogPageCms: [],
        blogDetailPageCmsData: [],
        imagePath: this.$imageURL + "blog/",
        newsDetail: null,
        blogDetail : [],
        blogDetails: [],
        blogComments: [],
        name: '',
        email: '',
        website: '',
        comment: '',
        popularBlogs: [],
        search: null,
        blogRelatedPosts: [],
        blogDetailsId: null
    };
  },
  mounted() {
    this.getPopularPosts();
  },
  beforeCreate: function() {
        document.body.className = 'post-template-default single single-post postid-454 single-format-standard theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-5228';

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = 'asset/asset/wp-content/uploads/elementor/css/post-871.css';
        document.head.appendChild(style);
    },
    created() {
        $('html,body').animate({ scrollTop: 0 }, 'slow');
        this.blogDetailsId = this.$route.params.id;
        this.getBlogDetails(this.$route.params.id);
   },
    methods: {
        postComment: async function() {
            try {
                this.isLoading = true;
                if(this.name == "" || this.email == "" || this.comment == "") {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Please fillup all required field!"
                    });
                }
                else {
                    let data = {
                        postId: this.$route.params.id,
                        name: this.name,
                        email: this.email,
                        website: this.website,
                        comment: this.comment
                    };
                    let config = {
                        method: "POST",
                        data: data,
                        url: this.$serverURL + this.$api.blog.postComment
                    };
                    await this.$axios(config).then((response) => {
                        if (response.status == 201) {
                            this.isLoading = false;
                            this.$swal.fire({
                                icon: "success",
                                text: "Thanks for your feedback!"
                            });
                            this.name = "";
                            this.email = "";
                            this.website = "";
                            this.comment = "";
                            this.getBlogDetails(this.$route.params.id);
                        }
                    }).catch(error => {
                        this.isLoading = false;
                        console.log(error);
                    });
                }  
            } catch (error) {
                this.isLoading = false;
                console.log(error);
            }
        },
        getBlogDetails: async function(id) {
            try {
                this.isLoading = true;
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.blog.blogDetailsURL + id
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.isLoading = false;
                        this.blogCategories = response.data.blogCategories;
                        this.blogTags = response.data.blogTags;
                        this.blogPageCms = response.data.blogPageCms;
                        this.blogDetailPageCmsData = response.data.blogDetailPageCmsData;
                        this.blogComments = response.data.blogComments;
                        this.blogDetail = response.data.blogDetailPageDataIdWise;
                        this.blogDetails = response.data;
                        document.title = response.data.blogDetailPageDataIdWise.title;
                        this.blogRelatedPosts = response.data.blogRelatedPosts;
                        console.log("Blog Related Post: " + this.blogRelatedPosts);

                        $('html,body').animate({ scrollTop: 0 }, 'slow');
                    }
                }).catch(error => {
                    this.isLoading = false;
                    console.log(error);
                });
            } catch (error) {
                this.isLoading = false;
                console.log(error)
            }
        },
        getPopularPosts: function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.popularBlog,
                headers: authHeader()
            };
            this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.popularBlogs = response.data.posts;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
        categorySearch: function(catId){
            this.$router.push({
                name: 'Blog',
                query: {categoryId: catId}
            });
        },
        tagSearch: function(tagName){
            this.$router.push({
                name: 'Blog',
                query: {tag: tagName}
            });
        },
        searchBlog: function(){
            this.$router.push({
                name: 'Blog',
                query: {keyword: this.search}
            });
        },
        deleteComment: async function(id) {
            let config = {
                method: "DELETE",
                url: this.$serverURL + this.$api.blog.deleteCommentAPI + id,
                headers: authHeader()
            };
            await this.$axios(config)
                .then(async (response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Your Comment has been deleted!",
                        });
                        await this.getBlogDetails(this.blogDetailsId);
                    }
                    else if (response.status == 200){
                        this.$swal.fire({
                            icon: "error",
                            text: "You can not delete this comment",
                        });
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
    },

    
}