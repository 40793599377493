<template>
  <div data-elementor-type="wp-post" data-elementor-id="57" class="elementor elementor-57"
     data-elementor-settings="[]">
     <div class="elementor-section-wrap">
        <section
           class="elementor-section elementor-top-section elementor-element elementor-element-de45e04 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
           data-id="de45e04" data-element_type="section">
           <div class="elementor-container elementor-column-gap-default">
              <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70803c3"
                 data-id="70803c3" data-element_type="column">
                 <div class="elementor-widget-wrap elementor-element-populated">
                    <div class="elementor-element elementor-element-d6d46ed elementor-widget elementor-widget-lst_menu"
                       data-id="d6d46ed" data-element_type="widget" data-widget_type="lst_menu.default">
                       <div class="elementor-widget-container">
                          <Header/>
                       </div>
                    </div>
                    <div class="elementor-element elementor-element-7807579 elementor-widget elementor-widget-lst_template_loader"
                       data-id="7807579" data-element_type="widget"
                       data-widget_type="lst_template_loader.default">
                       <div class="elementor-widget-container">
                          <div data-elementor-type="wp-page" data-elementor-id="1324"
                             class="elementor elementor-1324" data-elementor-settings="[]">
                             <div class="elementor-section-wrap">
                                <section
                                   class="elementor-section elementor-top-section elementor-element elementor-element-1c5d3f1 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                   data-id="1c5d3f1" data-element_type="section">
                                   <div class="elementor-container elementor-column-gap-default">
                                      <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c036486"
                                         data-id="c036486" data-element_type="column">
                                         <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-36c33f7 elementor-widget elementor-widget-spacer"
                                               data-id="36c33f7" data-element_type="widget"
                                               data-widget_type="spacer.default">
                                               <div class="elementor-widget-container">
                                                  <div class="elementor-spacer">
                                                     <div class="elementor-spacer-inner"></div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-0781d1b elementor-widget-mobile__width-initial elementor-widget elementor-widget-image"
                                               data-id="0781d1b" data-element_type="widget"
                                               data-widget_type="image.default">
                                               <div class="elementor-widget-container">
                                                  <img width="500" height="330"
                                                     src="/asset/wp-content/uploads/2021/09/plug-off.png"
                                                     class="attachment-full size-full" alt=""
                                                     loading="lazy"
                                                     srcset="/asset/wp-content/uploads/2021/09/plug-off.png 500w, /asset/wp-content/uploads/2021/09/plug-off-300x198.png 300w"
                                                     sizes="(max-width: 500px) 100vw, 500px" />
                                               </div>
                                            </div>
                                            <section
                                               class="elementor-section elementor-inner-section elementor-element elementor-element-331864e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                               data-id="331864e" data-element_type="section">
                                               <div
                                                  class="elementor-container elementor-column-gap-default">
                                                  <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-d1ede51"
                                                     data-id="d1ede51" data-element_type="column">
                                                     <div
                                                        class="elementor-widget-wrap elementor-element-populated">
                                                        <div class="elementor-element elementor-element-01d9550 elementor-widget elementor-widget-heading"
                                                           data-id="01d9550"
                                                           data-element_type="widget"
                                                           data-widget_type="heading.default">
                                                           <div class="elementor-widget-container">
                                                              <h1
                                                                 class="elementor-heading-title elementor-size-xxl">
                                                                 404
                                                              </h1>
                                                           </div>
                                                        </div>
                                                     </div>
                                                  </div>
                                               </div>
                                            </section>
                                            <div class="elementor-element elementor-element-4957475 elementor-widget elementor-widget-lst_shape"
                                               data-id="4957475" data-element_type="widget"
                                               data-widget_type="lst_shape.default">
                                               <div class="elementor-widget-container">
                                                  <div class="listivo-shape">
                                                     <div class="listivo-shape-line">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                           viewBox="0 0 913.000000 42.000000"
                                                           preserveAspectRatio="xMidYMid meet">
                                                           <g transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                                              stroke="none">
                                                              <path
                                                                 d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z">
                                                              </path>
                                                           </g>
                                                        </svg>
                                                     </div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-a226923 elementor-widget elementor-widget-spacer"
                                               data-id="a226923" data-element_type="widget"
                                               data-widget_type="spacer.default">
                                               <div class="elementor-widget-container">
                                                  <div class="elementor-spacer">
                                                     <div class="elementor-spacer-inner"></div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-bba8477 elementor-widget elementor-widget-heading"
                                               data-id="bba8477" data-element_type="widget"
                                               data-widget_type="heading.default">
                                               <div class="elementor-widget-container">
                                                  <h2
                                                     class="elementor-heading-title elementor-size-small">
                                                     Oops! That page can’t be found.
                                                  </h2>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-12f9511 elementor-widget elementor-widget-spacer"
                                               data-id="12f9511" data-element_type="widget"
                                               data-widget_type="spacer.default">
                                               <div class="elementor-widget-container">
                                                  <div class="elementor-spacer">
                                                     <div class="elementor-spacer-inner"></div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-006644b elementor-widget elementor-widget-lst_button"
                                               data-id="006644b" data-element_type="widget"
                                               data-widget_type="lst_button.default">
                                               <div class="elementor-widget-container">
                                                  <div class="listivo-alignment-center">
                                                     <a class="listivo-primary-button listivo-primary-button--icon"
                                                        href="/home"
                                                        title="Back to Homepage">
                                                     <span class="listivo-primary-button__text">
                                                     Back to Homepage </span>
                                                     <span class="listivo-primary-button__icon">
                                                     <i class="fas fa-home"></i>
                                                     </span>
                                                     </a>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-1618f6b elementor-widget elementor-widget-spacer"
                                               data-id="1618f6b" data-element_type="widget"
                                               data-widget_type="spacer.default">
                                               <div class="elementor-widget-container">
                                                  <div class="elementor-spacer">
                                                     <div class="elementor-spacer-inner"></div>
                                                  </div>
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </section>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </section>
        <Footer/>
     </div>
  </div>
</template>
<script>
import Header from '../../../include/Header';
import Footer from '../../../include/Footer';
export default {
  name: '404',
  title: '404',
  components:{
    Header,
    Footer
  },
  beforeCreate: function() {
        document.body.className = 'page-template-default page page-id-1324 theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-6 elementor-page elementor-page-1324';

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/asset/wp-content/uploads/elementor/css/post-1324.css';
        document.head.appendChild(style);



    }
}
</script>
