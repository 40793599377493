<template>
<div data-elementor-type="wp-post" data-elementor-id="57" class="elementor elementor-57" data-elementor-settings="[]">
   <div class="elementor-section-wrap">
      <section class="elementor-section elementor-top-section elementor-element elementor-element-de45e04 elementor-section-full_width elementor-section-height-default elementor-section-height-default">
         <div class="elementor-container elementor-column-gap-default">
            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70803c3">
               <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-d6d46ed elementor-widget elementor-widget-lst_menu"
                     data-id="d6d46ed" data-element_type="widget" data-widget_type="lst_menu.default">
                     <div class="elementor-widget-container">
                        <Header/>
                     </div>
                  </div>
                  <div class="elementor-element elementor-element-7807579 elementor-widget elementor-widget-lst_template_loader"
                     data-id="7807579" data-element_type="widget"
                     data-widget_type="lst_template_loader.default">
                     <div class="elementor-widget-container">
                        <div data-elementor-type="wp-page" data-elementor-id="2437"
                           class="elementor elementor-2437" data-elementor-settings="[]">
                           <div class="elementor-section-wrap">
                              <section class="elementor-section elementor-top-section elementor-element elementor-element-29acfe6 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                 data-id="29acfe6" data-element_type="section">
                                 <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-57f0af7"
                                       data-id="57f0af7" data-element_type="column">
                                       <div class="elementor-widget-wrap elementor-element-populated">
                                          <div class="elementor-element elementor-element-3a14264 elementor-widget elementor-widget-lst_search_map"
                                             data-id="3a14264" data-element_type="widget"
                                             data-widget_type="lst_search_map.default">
                                             <div class="elementor-widget-container">
                                                <div class="listivo-app">
                                                   <div
                                                      class="listivo-map-search-wrapper">
                                                      <div
                                                         class="listivo-map-search">
                                                         <div
                                                            class="listivo-map-search__left">
                                                            <div class="listivo-fields">
                                                               <div
                                                                  class="listivo-fields-primary">
                                                                  <div
                                                                     class="listivo-fields-primary__inner">
                                                                     <div>
                                                                        <div
                                                                           class="listivo-relative">
                                                                           <div
                                                                              class="listivo-keyword-icon">
                                                                              <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 class="h-6 w-6"
                                                                                 fill="none"
                                                                                 viewBox="0 0 24 24"
                                                                                 stroke="currentColor">
                                                                                 <path
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-width="1"
                                                                                    d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                                                                              </svg>
                                                                           </div>
                                                                           <div
                                                                              class="listivo-select__placeholder">
                                                                           </div>
                                                                           <input
                                                                              type="text"
                                                                              placeholder="Keyword"
                                                                              >
                                                                           <div
                                                                              class="listivo-field__icon listivo-field__icon--clear">
                                                                           </div>
                                                                           <div
                                                                              class="listivo-select__dropdown">
                                                                              <div
                                                                                 class="listivo-select__options">
                                                                                 <div class="listivo-select__option">
                                                                                    <div>
                                                                                       <span>
                                                                                       -
                                                                                       in
                                                                                       </span>
                                                                                    </div>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div>
                                                                        <div
                                                                           class="listivo-field__location">
                                                                           <div
                                                                              class="listivo-field__location-input listivo-field__location-input--my-location">
                                                                              <input
                                                                                 class="lst-location-153"
                                                                                 type="text"
                                                                                 placeholder="Location">
                                                                              <div
                                                                                 class="listivo-field__set-my-location">
                                                                                 <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1"
                                                                                       d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1"
                                                                                       d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                                 </svg>
                                                                                 <div
                                                                                    class="listivo-field__set-my-location-label">
                                                                                    Use
                                                                                    my
                                                                                    location
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-fields-secondary">
                                                                  <div
                                                                     class="listivo-search-mobile-more-button">
                                                                     <button>
                                                                     More
                                                                     Filters
                                                                     </button>
                                                                  </div>
                                                                  <div
                                                                     class="listivo-fields-secondary__inner">
                                                                     <div class="listivo-field">
                                                                        <div
                                                                           class="listivo-field">
                                                                           <div
                                                                              tabindex="0"
                                                                              class="listivo-select">
                                                                              <div
                                                                                 class="listivo-select__field">
                                                                                 Category
                                                                              </div>
                                                                              <div
                                                                                 class="listivo-select__field">
                                                                                 <div>
                                                                                    Selected
                                                                                 </div>
                                                                              </div>
                                                                              <div
                                                                                 class="listivo-select__dropdown">
                                                                                 <div
                                                                                    class="listivo-select__no-options">
                                                                                    No
                                                                                    options
                                                                                 </div>
                                                                                 <div
                                                                                    class="listivo-select__options">
                                                                                    <div
                                                                                       class="listivo-select__option"
                                                                                       >
                                                                                       <div class="listivo-select__value">
                                                                                       </div>
                                                                                    </div>
                                                                                 </div>
                                                                              </div>
                                                                              <div
                                                                                 class="listivo-field__icon listivo-field__icon--arrow">
                                                                              </div>
                                                                              <div
                                                                                 class="listivo-field__icon listivo-field__icon--clear">
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-field">
                                                                           <div
                                                                              tabindex="0"
                                                                              class="listivo-select"
                                                                              >
                                                                              <div
                                                                                 class="listivo-select__field">
                                                                              </div>
                                                                              <div
                                                                                 class="listivo-select__field">
                                                                                 <div>
                                                                                 </div>
                                                                                 <div>Selected
                                                                                 </div>
                                                                              </div>
                                                                              <div
                                                                                 class="listivo-select__dropdown">
                                                                                 <div
                                                                                    class="listivo-select__no-options">
                                                                                    No
                                                                                    options
                                                                                 </div>
                                                                              </div>
                                                                              <div
                                                                                 class="listivo-field__icon listivo-field__icon--arrow">
                                                                              </div>
                                                                              <div
                                                                                 class="listivo-field__icon listivo-field__icon--clear">
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div >
                                                                        <div
                                                                           class="listivo-field__fields">
                                                                           <div
                                                                              class="listivo-field"
                                                                              >
                                                                              <div
                                                                                 class="listivo-relative">
                                                                                 <input
                                                                                    type="text"
                                                                                    placeholder="Min Price">
                                                                                 <div
                                                                                    class="listivo-field__icon listivo-field__icon--clear">
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           <div class="listivo-field">
                                                                              <div
                                                                                 class="listivo-relative">
                                                                                 <input
                                                                                    type="text"
                                                                                    placeholder="Max Price">
                                                                                 <div
                                                                                    class="listivo-field__icon listivo-field__icon--clear">
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-field"
                                                                        >
                                                                        <div
                                                                           class="listivo-field">
                                                                           <div
                                                                              tabindex="0"
                                                                              class="listivo-select listivo-select--searchable"
                                                                              >
                                                                              <div
                                                                                 class="listivo-select__field">
                                                                                 Make
                                                                              </div>
                                                                              <div
                                                                                 class="listivo-select__field">
                                                                                 <div>
                                                                                 </div>
                                                                                 <div>Selected
                                                                                 </div>
                                                                              </div>
                                                                              <div
                                                                                 class="listivo-select__dropdown">
                                                                                 <div
                                                                                    class="listivo-select__search">
                                                                                    <div
                                                                                       class="listivo-select__search__inner">
                                                                                       <input
                                                                                          type="text"
                                                                                          placeholder="Search...">
                                                                                    </div>
                                                                                 </div>
                                                                                 <div
                                                                                    class="listivo-select__no-options">
                                                                                    No
                                                                                    options
                                                                                 </div>
                                                                                 <div
                                                                                    class="listivo-select__options">
                                                                                    <div
                                                                                       class="listivo-select__option"
                                                                                       >
                                                                                       <div class="listivo-select__value">
                                                                                       </div>
                                                                                       <div
                                                                                          class="listivo-select__count">
                                                                                       </div>
                                                                                    </div>
                                                                                 </div>
                                                                              </div>
                                                                              <div
                                                                                 class="listivo-field__icon listivo-field__icon--arrow">
                                                                              </div>
                                                                              <div
                                                                                 class="listivo-field__icon listivo-field__icon--clear">
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-field">
                                                                        <div
                                                                           class="listivo-field">
                                                                           <div
                                                                              tabindex="0"
                                                                              class="listivo-select listivo-select--searchable"
                                                                              >
                                                                              <div
                                                                                 class="listivo-select__field">
                                                                                 Model
                                                                              </div>
                                                                              <div
                                                                                 class="listivo-select__field">
                                                                                 <div>
                                                                                 </div>
                                                                                 <div> Selected </div>
                                                                              </div>
                                                                              <div
                                                                                 class="listivo-select__dropdown">
                                                                                 <div
                                                                                    class="listivo-select__search">
                                                                                    <div
                                                                                       class="listivo-select__search__inner">
                                                                                       <input
                                                                                          type="text"
                                                                                          placeholder="Search...">
                                                                                    </div>
                                                                                 </div>
                                                                                 <div
                                                                                    class="listivo-select__no-options">
                                                                                    No
                                                                                    options
                                                                                 </div>
                                                                                 <div
                                                                                    class="listivo-select__options">
                                                                                    <div
                                                                                       class="listivo-select__option"
                                                                                       >
                                                                                       <div class="listivo-select__value">
                                                                                       </div>
                                                                                    </div>
                                                                                    <div
                                                                                       class="listivo-select__count">
                                                                                    </div>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           <div
                                                                              class="listivo-field__icon listivo-field__icon--arrow">
                                                                           </div>
                                                                           <div
                                                                              class="listivo-field__icon listivo-field__icon--clear">
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div
                                                            class="listivo-clearfix">
                                                         </div>
                                                         <div
                                                            class="listivo-search-mobile">
                                                            <div
                                                               class="listivo-search-mobile__inner">
                                                               <div>
                                                                  <h3
                                                                     class="listivo-search-mobile__heading">
                                                                     Filters
                                                                  </h3>
                                                                  <div
                                                                     class="listivo-search-mobile__close">
                                                                     <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="21"
                                                                        height="19"
                                                                        viewBox="0 0 21 19">
                                                                        <g>
                                                                           <g>
                                                                              <path
                                                                                 fill="#111"
                                                                                 d="M.602 18.781h2.443c.335 0 .574-.106.766-.284l6.178-6.615a.216.216 0 0 1 .336 0l6.13 6.615c.192.178.431.284.766.284h2.347c.48 0 .67-.284.383-.569L12.05 9.89a.176.176 0 0 1 0-.213l7.902-8.322c.288-.284.096-.569-.383-.569H17.03c-.336 0-.575.107-.767.285l-6.13 6.614a.215.215 0 0 1-.335 0l-6.13-6.614C3.475.893 3.235.786 2.9.786H.6c-.478 0-.67.285-.382.57l7.855 8.321a.177.177 0 0 1 0 .213L.219 18.212c-.288.285-.096.57.383.57z" />
                                                                           </g>
                                                                        </g>
                                                                     </svg>
                                                                  </div>
                                                               </div>
                                                               <div
                                                                  class="listivo-search-mobile__results-bar">
                                                                  <div
                                                                     class="listivo-results-bar__count">
                                                                     <div>
                                                                        Results
                                                                     </div>
                                                                     <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 913.000000 42.000000"
                                                                        preserveAspectRatio="xMidYMid meet">
                                                                        <g transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                                                           stroke="none">
                                                                           <path
                                                                              d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z">
                                                                           </path>
                                                                        </g>
                                                                     </svg>
                                                                  </div>
                                                                  <div>
                                                                     <button
                                                                        class="listivo-search-mobile__clear-all">
                                                                     Clear
                                                                     All
                                                                     </button>
                                                                  </div>
                                                               </div>
                                                               <div>
                                                                  <div
                                                                     class="listivo-relative">
                                                                     <div
                                                                        class="listivo-keyword-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                           class="h-6 w-6"
                                                                           fill="none"
                                                                           viewBox="0 0 24 24"
                                                                           stroke="currentColor">
                                                                           <path
                                                                              stroke-linecap="round"
                                                                              stroke-linejoin="round"
                                                                              stroke-width="1"
                                                                              d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                                                                        </svg>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-select__placeholder">
                                                                     </div>
                                                                     <input
                                                                        type="text"
                                                                        placeholder="Keyword">
                                                                     <div
                                                                        class="listivo-field__icon listivo-field__icon--clear">
                                                                     </div>
                                                                     <div
                                                                        class="listivo-select__dropdown">
                                                                        <div
                                                                           class="listivo-select__options">
                                                                           <div class="listivo-select__option" >
                                                                              <div>
                                                                                 <span
                                                                                    class="listivo-select__in-category">
                                                                                 -
                                                                                 in
                                                                                 <span></span>
                                                                                 </span>
                                                                              </div>
                                                                              <div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div>
                                                                  <div
                                                                     class="listivo-field__location">
                                                                     <div
                                                                        class="listivo-field__location-input listivo-field__location-input--my-location">
                                                                        <input
                                                                           class="lst-location-153"
                                                                           type="text"
                                                                           placeholder="Location">
                                                                        <div
                                                                           class="listivo-field__set-my-location">
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path
                                                                                 stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1"
                                                                                 d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                                                              <path
                                                                                 stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1"
                                                                                 d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                           </svg>
                                                                           <div
                                                                              class="listivo-field__set-my-location-label">
                                                                              Use
                                                                              my
                                                                              location
                                                                           </div>
                                                                        </div>
                                                                        <div class="listivo-field__icon listivo-field__icon--clear">
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div
                                                                  class="listivo-field"
                                                                  >
                                                                  <div
                                                                     class="listivo-field">
                                                                     <div
                                                                        tabindex="0"
                                                                        class="listivo-select"
                                                                        >
                                                                        <div
                                                                           class="listivo-select__field">
                                                                           Category
                                                                        </div>
                                                                        <div
                                                                           class="listivo-select__field">
                                                                           <div>
                                                                           </div>
                                                                           <div>
                                                                              Selected
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-select__dropdown">
                                                                           <div
                                                                              class="listivo-select__no-options">
                                                                              No
                                                                              options
                                                                           </div>
                                                                           <div
                                                                              class="listivo-select__options">
                                                                              <div
                                                                                 class="listivo-select__option"
                                                                                 >
                                                                                 <div class="listivo-select__value"
                                                                                    >
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-field__icon listivo-field__icon--arrow">
                                                                        </div>
                                                                        <div
                                                                           class="listivo-field__icon listivo-field__icon--clear">
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div
                                                                     class="listivo-field">
                                                                     <div
                                                                        tabindex="0"
                                                                        class="listivo-select"
                                                                        >
                                                                        <div
                                                                           class="listivo-select__field">
                                                                        </div>
                                                                        <div
                                                                           class="listivo-select__field">
                                                                           <div>
                                                                           </div>
                                                                           <div>
                                                                              Selected
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-select__dropdown">
                                                                           <div
                                                                              class="listivo-select__no-options">
                                                                              No
                                                                              options
                                                                           </div>
                                                                           <div
                                                                              class="listivo-select__options">
                                                                              <div
                                                                                 class="listivo-select__option"
                                                                                 >
                                                                                 <div class="listivo-select__value"
                                                                                    >
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-field__icon listivo-field__icon--arrow">
                                                                        </div>
                                                                        <div
                                                                           class="listivo-field__icon listivo-field__icon--clear">
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div>
                                                                  <div
                                                                     class="listivo-field__fields">
                                                                     <div
                                                                        class="listivo-field"
                                                                        >
                                                                        <div
                                                                           class="listivo-relative">
                                                                           <input
                                                                              type="text"
                                                                              placeholder="Min Price">
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-field"
                                                                        >
                                                                        <div
                                                                           class="listivo-relative">
                                                                           <input
                                                                              type="text"
                                                                              placeholder="Max Price">
                                                                           <div
                                                                              class="listivo-field__icon listivo-field__icon--clear">
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div
                                                                  class="listivo-field"
                                                                  >
                                                                  <div
                                                                     class="listivo-field">
                                                                     <div
                                                                        tabindex="0"
                                                                        class="listivo-select listivo-select--searchable"
                                                                        >
                                                                        <div
                                                                           class="listivo-select__field">
                                                                           Make
                                                                        </div>
                                                                        <div
                                                                           class="listivo-select__field">
                                                                           <div>
                                                                           </div>
                                                                           <div
                                                                              >
                                                                              Selected
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-select__dropdown">
                                                                           <div
                                                                              class="listivo-select__search">
                                                                              <div
                                                                                 class="listivo-select__search__inner">
                                                                                 <input
                                                                                    type="text"
                                                                                    placeholder="Search..."
                                                                                    >
                                                                              </div>
                                                                           </div>
                                                                           <div
                                                                              class="listivo-select__no-options">
                                                                              No
                                                                              options
                                                                           </div>
                                                                           <div
                                                                              class="listivo-select__options">
                                                                              <div
                                                                                 class="listivo-select__option"
                                                                                 >
                                                                                 <div class="listivo-select__value"
                                                                                    >
                                                                                 </div>
                                                                                 <div
                                                                                    class="listivo-select__count">
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-field__icon listivo-field__icon--arrow">
                                                                        </div>
                                                                        <div
                                                                           class="listivo-field__icon listivo-field__icon--clear">
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div
                                                                  class="listivo-field"
                                                                  >
                                                                  <div
                                                                     class="listivo-field">
                                                                     <div
                                                                        tabindex="0"
                                                                        class="listivo-select listivo-select--searchable"
                                                                        >
                                                                        <div
                                                                           class="listivo-select__field">
                                                                           Model
                                                                        </div>
                                                                        <div
                                                                           class="listivo-select__field">
                                                                           <div >
                                                                           </div>
                                                                           <div
                                                                              >
                                                                              Selected
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-select__dropdown">
                                                                           <div
                                                                              class="listivo-select__search">
                                                                              <div
                                                                                 class="listivo-select__search__inner">
                                                                                 <input
                                                                                    type="text"
                                                                                    placeholder="Search..."
                                                                                    >
                                                                              </div>
                                                                           </div>
                                                                           <div
                                                                              class="listivo-select__no-options">
                                                                              No
                                                                              options
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-field__icon listivo-field__icon--arrow">
                                                                        </div>
                                                                        <div
                                                                           class="listivo-field__icon listivo-field__icon--clear">
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div
                                                                  class="listivo-search-mobile__apply">
                                                                  <button
                                                                     class="listivo-primary-button listivo-primary-button--full-width listivo-primary-button--icon"
                                                                     >
                                                                  <span
                                                                     class="listivo-primary-button__text">
                                                                  Apply
                                                                  </span>
                                                                  <span
                                                                     class="listivo-primary-button__icon">
                                                                  <i
                                                                     class="fas fa-check"></i>
                                                                  </span>
                                                                  </button>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div
                                                            class="listivo-results-bar">
                                                            <div
                                                               class="listivo-results-bar__count">
                                                               <div
                                                                  class="listivo-results-bar__count-inner">
                                                                  <div
                                                                     class="listivo-results-bar__count-big">
                                                                     Results
                                                                     <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 913.000000 42.000000"
                                                                        preserveAspectRatio="xMidYMid meet">
                                                                        <g transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                                                           stroke="none">
                                                                           <path
                                                                              d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z">
                                                                           </path>
                                                                        </g>
                                                                     </svg>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                            <div
                                                               class="listivo-results-bar__right">
                                                               <div
                                                                  class="listivo-results-bar__sort">
                                                                  <div
                                                                     class="listivo-results-bar__sort-label">
                                                                     Sort by:
                                                                  </div>
                                                                  <div
                                                                     tabindex="0"
                                                                     class="listivo-select">
                                                                     <div class="listivo-select__field"
                                                                        >
                                                                        <div
                                                                           >
                                                                        </div>
                                                                        <div
                                                                           class="listivo-field__icon listivo-field__icon--arrow">
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-select__dropdown">
                                                                        <div
                                                                           class="listivo-select__options">
                                                                           <div
                                                                              class="listivo-select__option"
                                                                              >
                                                                              <div class="listivo-select__value"
                                                                                 >
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div
                                                                  class="listivo-results-bar__grids">
                                                                  <div
                                                                     class="listivo-results-bar__grid"
                                                                     >
                                                                     <svg xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor">
                                                                        <path
                                                                           stroke-linecap="round"
                                                                           stroke-linejoin="round"
                                                                           stroke-width="1.4"
                                                                           d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                                                                     </svg>
                                                                  </div>
                                                                  <div
                                                                     class="listivo-results-bar__grid"
                                                                     >
                                                                     <svg xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor">
                                                                        <path
                                                                           stroke-linecap="round"
                                                                           stroke-linejoin="round"
                                                                           stroke-width="1.4"
                                                                           d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                                                                     </svg>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div
                                                            class="listivo-listings">
                                                            <div
                                                               class="listivo-cards-list-v2">
                                                               <div class="listivo-listing-card listivo-listing-no-user listivo-listing-featured"
                                                                  data-id="3804">
                                                                  <div
                                                                     class="listivo-listing-card__inner">
                                                                     <a class="listivo-listing-card__link"
                                                                        href="#"></a>
                                                                     <div>
                                                                        <div
                                                                           class="listivo-listing-card__top">
                                                                           <img class="lazyload listivo-listing-card__preview"
                                                                              data-srcset="asset/wp-content/uploads/2021/11/car-new-1-720x540.jpg 720w, asset/wp-content/uploads/2021/11/car-new-1-360x270.jpg 360w"
                                                                              data-sizes="auto"
                                                                              alt="Mercedes-Benz S-Class">
                                                                           <div
                                                                              class="listivo-listing-card__labels">
                                                                              <div
                                                                                 class="listivo-listing-card__label">
                                                                                 Featured
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-listing-card__main">
                                                                           <div
                                                                              class="listivo-listing-card__main-head">
                                                                              <h3
                                                                                 class="listivo-listing-card__name">
                                                                                 Mercedes-Benz
                                                                                 S-Class
                                                                              </h3>
                                                                              <div
                                                                                 class="listivo-listing-card__location">
                                                                                 <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                    </path>
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                    </path>
                                                                                 </svg>
                                                                                 Washington,
                                                                                 DC,
                                                                                 USA
                                                                              </div>
                                                                              <b
                                                                                 class="listivo-listing-card__price">
                                                                              $150,000
                                                                              </b>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-listing-card__footer">
                                                                        <div
                                                                           class="listivo-listing-card__features">
                                                                           <ul>
                                                                              <li><span>Vehicles</span>
                                                                              </li>
                                                                              <li><span>Cars</span>
                                                                              </li>
                                                                              <li><span>Year:
                                                                                 2021</span>
                                                                              </li>
                                                                           </ul>
                                                                        </div>
                                                                        <button
                                                                           class="listivo-listing-card__favorite">
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path
                                                                                 stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1.4"
                                                                                 d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                           </svg>
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              viewBox="0 0 20 20"
                                                                              fill="currentColor">
                                                                              <path
                                                                                 fill-rule="evenodd"
                                                                                 d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                                                 clip-rule="evenodd" />
                                                                           </svg>
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-listing-card listivo-listing-no-user listivo-listing-featured"
                                                                  data-id="3803">
                                                                  <div
                                                                     class="listivo-listing-card__inner">
                                                                     <a class="listivo-listing-card__link"
                                                                        href="#"></a>
                                                                     <div>
                                                                        <div
                                                                           class="listivo-listing-card__top">
                                                                           <img class="listivo-listing-card__preview lazyload"
                                                                              data-src="asset/wp-content/uploads/2021/09/promo-resort.jpg"
                                                                              alt="Apartment With Pool">
                                                                           <div
                                                                              class="listivo-listing-card__labels">
                                                                              <div
                                                                                 class="listivo-listing-card__label">
                                                                                 Featured
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-listing-card__main">
                                                                           <div
                                                                              class="listivo-listing-card__main-head">
                                                                              <h3
                                                                                 class="listivo-listing-card__name">
                                                                                 Apartment
                                                                                 With
                                                                                 Pool
                                                                              </h3>
                                                                              <div
                                                                                 class="listivo-listing-card__location">
                                                                                 <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                    </path>
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                    </path>
                                                                                 </svg>
                                                                                 Miami,
                                                                                 FL,
                                                                                 USA
                                                                              </div>
                                                                              <b
                                                                                 class="listivo-listing-card__price">
                                                                              $80,000
                                                                              </b>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-listing-card__footer">
                                                                        <div
                                                                           class="listivo-listing-card__features">
                                                                           <ul>
                                                                              <li><span>Real
                                                                                 Estate</span>
                                                                              </li>
                                                                              <li><span>Apartments</span>
                                                                              </li>
                                                                              <li><span>Bedrooms:
                                                                                 2</span>
                                                                              </li>
                                                                           </ul>
                                                                        </div>
                                                                        <button
                                                                           class="listivo-listing-card__favorite">
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path
                                                                                 stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1.4"
                                                                                 d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                           </svg>
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              viewBox="0 0 20 20"
                                                                              fill="currentColor">
                                                                              <path
                                                                                 fill-rule="evenodd"
                                                                                 d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                                                 clip-rule="evenodd" />
                                                                           </svg>
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-listing-card listivo-listing-no-user"
                                                                  data-id="3802">
                                                                  <div
                                                                     class="listivo-listing-card__inner">
                                                                     <a class="listivo-listing-card__link"
                                                                        href="#"></a>
                                                                     <div>
                                                                        <div
                                                                           class="listivo-listing-card__top">
                                                                           <img class="listivo-listing-card__preview lazyload"
                                                                              data-src="asset/wp-content/uploads/2021/09/promo-pc.jpg"
                                                                              alt="Home Office Desktop">
                                                                           <div
                                                                              class="listivo-listing-card__labels">
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-listing-card__main">
                                                                           <div
                                                                              class="listivo-listing-card__main-head">
                                                                              <h3
                                                                                 class="listivo-listing-card__name">
                                                                                 Home
                                                                                 Office
                                                                                 Desktop
                                                                              </h3>
                                                                              <div
                                                                                 class="listivo-listing-card__location">
                                                                                 <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                    </path>
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                    </path>
                                                                                 </svg>
                                                                                 Washington,
                                                                                 DC,
                                                                                 USA
                                                                              </div>
                                                                              <b
                                                                                 class="listivo-listing-card__price">
                                                                              $800
                                                                              </b>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-listing-card__footer">
                                                                        <div
                                                                           class="listivo-listing-card__features">
                                                                           <ul>
                                                                              <li><span>Electronics</span>
                                                                              </li>
                                                                              <li><span>Computers</span>
                                                                              </li>
                                                                           </ul>
                                                                        </div>
                                                                        <button
                                                                           class="listivo-listing-card__favorite">
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path
                                                                                 stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1.4"
                                                                                 d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                           </svg>
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              viewBox="0 0 20 20"
                                                                              fill="currentColor">
                                                                              <path
                                                                                 fill-rule="evenodd"
                                                                                 d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                                                 clip-rule="evenodd" />
                                                                           </svg>
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-listing-card listivo-listing-no-user"
                                                                  data-id="3797">
                                                                  <div
                                                                     class="listivo-listing-card__inner">
                                                                     <a class="listivo-listing-card__link"
                                                                        href="#"></a>
                                                                     <div>
                                                                        <div
                                                                           class="listivo-listing-card__top">
                                                                           <img class="listivo-listing-card__preview lazyload"
                                                                              data-src="asset/wp-content/uploads/2021/09/promo-vr.jpg"
                                                                              alt="VR Set Plus Two Games">
                                                                           <div
                                                                              class="listivo-listing-card__labels">
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-listing-card__main">
                                                                           <div
                                                                              class="listivo-listing-card__main-head">
                                                                              <h3
                                                                                 class="listivo-listing-card__name">
                                                                                 VR
                                                                                 Set
                                                                                 Plus
                                                                                 Two
                                                                                 Games
                                                                              </h3>
                                                                              <div
                                                                                 class="listivo-listing-card__location">
                                                                                 <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                    </path>
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                    </path>
                                                                                 </svg>
                                                                                 New
                                                                                 York,
                                                                                 NY,
                                                                                 USA
                                                                              </div>
                                                                              <b
                                                                                 class="listivo-listing-card__price">
                                                                              $200
                                                                              </b>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-listing-card__footer">
                                                                        <div
                                                                           class="listivo-listing-card__features">
                                                                           <ul>
                                                                              <li><span>Electronics</span>
                                                                              </li>
                                                                              <li><span>Computers</span>
                                                                              </li>
                                                                           </ul>
                                                                        </div>
                                                                        <button
                                                                           class="listivo-listing-card__favorite">
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path
                                                                                 stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1.4"
                                                                                 d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                           </svg>
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              viewBox="0 0 20 20"
                                                                              fill="currentColor">
                                                                              <path
                                                                                 fill-rule="evenodd"
                                                                                 d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                                                 clip-rule="evenodd" />
                                                                           </svg>
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-listing-card listivo-listing-no-user"
                                                                  data-id="1480">
                                                                  <div
                                                                     class="listivo-listing-card__inner">
                                                                     <a class="listivo-listing-card__link"
                                                                        href="#"></a>
                                                                     <div>
                                                                        <div
                                                                           class="listivo-listing-card__top">
                                                                           <img class="lazyload listivo-listing-card__preview"
                                                                              data-srcset="asset/wp-content/uploads/2021/05/baby-009-720x540.jpg 720w, asset/wp-content/uploads/2021/05/baby-009-360x270.jpg 360w, asset/wp-content/uploads/2021/05/baby-009-180x135.jpg 180w"
                                                                              data-sizes="auto"
                                                                              alt="New Teddy Bear">
                                                                           <div
                                                                              class="listivo-listing-card__labels">
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-listing-card__main">
                                                                           <div
                                                                              class="listivo-listing-card__main-head">
                                                                              <h3
                                                                                 class="listivo-listing-card__name">
                                                                                 New
                                                                                 Teddy
                                                                                 Bear
                                                                              </h3>
                                                                              <div
                                                                                 class="listivo-listing-card__location">
                                                                                 <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                    </path>
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                    </path>
                                                                                 </svg>
                                                                                 Arlington,
                                                                                 TX,
                                                                                 USA
                                                                              </div>
                                                                              <b
                                                                                 class="listivo-listing-card__price">
                                                                              $15
                                                                              </b>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-listing-card__footer">
                                                                        <div
                                                                           class="listivo-listing-card__features">
                                                                           <ul>
                                                                              <li><span>For
                                                                                 Kids</span>
                                                                              </li>
                                                                              <li><span>Toys</span>
                                                                              </li>
                                                                           </ul>
                                                                        </div>
                                                                        <button
                                                                           class="listivo-listing-card__favorite">
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path
                                                                                 stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1.4"
                                                                                 d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                           </svg>
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              viewBox="0 0 20 20"
                                                                              fill="currentColor">
                                                                              <path
                                                                                 fill-rule="evenodd"
                                                                                 d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                                                 clip-rule="evenodd" />
                                                                           </svg>
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-listing-card listivo-listing-no-user"
                                                                  data-id="1478">
                                                                  <div
                                                                     class="listivo-listing-card__inner">
                                                                     <a class="listivo-listing-card__link"
                                                                        href="#"></a>
                                                                     <div>
                                                                        <div
                                                                           class="listivo-listing-card__top">
                                                                           <img class="lazyload listivo-listing-card__preview"
                                                                              data-srcset="asset/wp-content/uploads/2021/05/baby-008-720x540.jpg 720w, asset/wp-content/uploads/2021/05/baby-008-360x270.jpg 360w, asset/wp-content/uploads/2021/05/baby-008-180x135.jpg 180w"
                                                                              data-sizes="auto"
                                                                              alt="Wooden Train">
                                                                           <div
                                                                              class="listivo-listing-card__labels">
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-listing-card__main">
                                                                           <div
                                                                              class="listivo-listing-card__main-head">
                                                                              <h3
                                                                                 class="listivo-listing-card__name">
                                                                                 Wooden
                                                                                 Train
                                                                              </h3>
                                                                              <div
                                                                                 class="listivo-listing-card__location">
                                                                                 <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                    </path>
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                    </path>
                                                                                 </svg>
                                                                                 Wichita,
                                                                                 KS,
                                                                                 USA
                                                                              </div>
                                                                              <b
                                                                                 class="listivo-listing-card__price">
                                                                              $199
                                                                              </b>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-listing-card__footer">
                                                                        <div
                                                                           class="listivo-listing-card__features">
                                                                           <ul>
                                                                              <li><span>For
                                                                                 Kids</span>
                                                                              </li>
                                                                              <li><span>Toys</span>
                                                                              </li>
                                                                           </ul>
                                                                        </div>
                                                                        <button
                                                                           class="listivo-listing-card__favorite">
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path
                                                                                 stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1.4"
                                                                                 d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                           </svg>
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              viewBox="0 0 20 20"
                                                                              fill="currentColor">
                                                                              <path
                                                                                 fill-rule="evenodd"
                                                                                 d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                                                 clip-rule="evenodd" />
                                                                           </svg>
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-listing-card listivo-listing-no-user listivo-listing-featured"
                                                                  data-id="1479">
                                                                  <div
                                                                     class="listivo-listing-card__inner">
                                                                     <a class="listivo-listing-card__link"
                                                                        href="#"></a>
                                                                     <div>
                                                                        <div
                                                                           class="listivo-listing-card__top">
                                                                           <img class="lazyload listivo-listing-card__preview"
                                                                              data-srcset="asset/wp-content/uploads/2021/05/baby-010-720x540.jpg 720w, asset/wp-content/uploads/2021/05/baby-010-360x270.jpg 360w, asset/wp-content/uploads/2021/05/baby-010-180x135.jpg 180w"
                                                                              data-sizes="auto"
                                                                              alt="Gray Baby Stroller">
                                                                           <div
                                                                              class="listivo-listing-card__labels">
                                                                              <div
                                                                                 class="listivo-listing-card__label">
                                                                                 Featured
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-listing-card__main">
                                                                           <div
                                                                              class="listivo-listing-card__main-head">
                                                                              <h3
                                                                                 class="listivo-listing-card__name">
                                                                                 Gray
                                                                                 Baby
                                                                                 Stroller
                                                                              </h3>
                                                                              <div
                                                                                 class="listivo-listing-card__location">
                                                                                 <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                    </path>
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                    </path>
                                                                                 </svg>
                                                                                 New
                                                                                 Orleans,
                                                                                 LA,
                                                                                 USA
                                                                              </div>
                                                                              <b
                                                                                 class="listivo-listing-card__price">
                                                                              $700
                                                                              </b>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-listing-card__footer">
                                                                        <div
                                                                           class="listivo-listing-card__features">
                                                                           <ul>
                                                                              <li><span>For
                                                                                 Kids</span>
                                                                              </li>
                                                                              <li><span>Prams
                                                                                 &amp;
                                                                                 Strollers</span>
                                                                              </li>
                                                                           </ul>
                                                                        </div>
                                                                        <button
                                                                           class="listivo-listing-card__favorite">
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path
                                                                                 stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1.4"
                                                                                 d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                           </svg>
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              viewBox="0 0 20 20"
                                                                              fill="currentColor">
                                                                              <path
                                                                                 fill-rule="evenodd"
                                                                                 d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                                                 clip-rule="evenodd" />
                                                                           </svg>
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-listing-card listivo-listing-no-user"
                                                                  data-id="4587">
                                                                  <div
                                                                     class="listivo-listing-card__inner">
                                                                     <a class="listivo-listing-card__link"
                                                                        href="#"></a>
                                                                     <div>
                                                                        <div
                                                                           class="listivo-listing-card__top">
                                                                           <img class="lazyload listivo-listing-card__preview"
                                                                              data-srcset="asset/wp-content/uploads/2021/10/truck-7-720x540.jpg 720w, asset/wp-content/uploads/2021/10/truck-7-360x270.jpg 360w"
                                                                              data-sizes="auto"
                                                                              alt="White Truck">
                                                                           <div
                                                                              class="listivo-listing-card__labels">
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-listing-card__main">
                                                                           <div
                                                                              class="listivo-listing-card__main-head">
                                                                              <h3
                                                                                 class="listivo-listing-card__name">
                                                                                 White
                                                                                 Truck
                                                                              </h3>
                                                                              <div
                                                                                 class="listivo-listing-card__location">
                                                                                 <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                    </path>
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                    </path>
                                                                                 </svg>
                                                                                 Luanda,
                                                                                 Angola
                                                                              </div>
                                                                              <b
                                                                                 class="listivo-listing-card__price">
                                                                              $50,000
                                                                              </b>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-listing-card__footer">
                                                                        <div
                                                                           class="listivo-listing-card__features">
                                                                           <ul>
                                                                              <li><span>Vehicles</span>
                                                                              </li>
                                                                              <li><span>Trucks</span>
                                                                              </li>
                                                                              <li><span>Year:
                                                                                 2005</span>
                                                                              </li>
                                                                           </ul>
                                                                        </div>
                                                                        <button class="listivo-listing-card__favorite">
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path
                                                                                 stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1.4"
                                                                                 d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                           </svg>
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              viewBox="0 0 20 20"
                                                                              fill="currentColor">
                                                                              <path
                                                                                 fill-rule="evenodd"
                                                                                 d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                                                 clip-rule="evenodd" />
                                                                           </svg>
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-listing-card listivo-listing-no-user"
                                                                  data-id="4715">
                                                                  <div
                                                                     class="listivo-listing-card__inner">
                                                                     <a class="listivo-listing-card__link"
                                                                        href="#"></a>
                                                                     <div>
                                                                        <div
                                                                           class="listivo-listing-card__top">
                                                                           <img class="lazyload listivo-listing-card__preview"
                                                                              data-srcset="asset/wp-content/uploads/2021/10/cleaning-3-720x540.jpg 720w, asset/wp-content/uploads/2021/10/cleaning-3-360x270.jpg 360w"
                                                                              data-sizes="auto"
                                                                              alt="Experienced housekeeper">
                                                                           <div
                                                                              class="listivo-listing-card__labels">
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-listing-card__main">
                                                                           <div
                                                                              class="listivo-listing-card__main-head">
                                                                              <h3
                                                                                 class="listivo-listing-card__name">
                                                                                 Experienced
                                                                                 housekeeper
                                                                              </h3>
                                                                              <div
                                                                                 class="listivo-listing-card__location">
                                                                                 <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                    </path>
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                    </path>
                                                                                 </svg>
                                                                                 Shreveport,
                                                                                 LA,
                                                                                 USA
                                                                              </div>
                                                                              <b
                                                                                 class="listivo-listing-card__price">
                                                                              $3,000
                                                                              /month
                                                                              </b>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-listing-card__footer">
                                                                        <div
                                                                           class="listivo-listing-card__features">
                                                                           <ul>
                                                                              <li><span>Jobs</span>
                                                                              </li>
                                                                              <li><span>Cleaning
                                                                                 Jobs</span>
                                                                              </li>
                                                                              <li><span>Permanent</span>
                                                                              </li>
                                                                           </ul>
                                                                        </div>
                                                                        <button
                                                                           class="listivo-listing-card__favorite">
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path
                                                                                 stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1.4"
                                                                                 d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                           </svg>
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              viewBox="0 0 20 20"
                                                                              fill="currentColor">
                                                                              <path
                                                                                 fill-rule="evenodd"
                                                                                 d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                                                 clip-rule="evenodd" />
                                                                           </svg>
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-listing-card listivo-listing-no-user"
                                                                  data-id="4768">
                                                                  <div
                                                                     class="listivo-listing-card__inner">
                                                                     <a class="listivo-listing-card__link"
                                                                        href="#"></a>
                                                                     <div>
                                                                        <div
                                                                           class="listivo-listing-card__top">
                                                                           <img class="lazyload listivo-listing-card__preview"
                                                                              data-srcset="asset/wp-content/uploads/2021/11/car-5-720x540.jpg 720w, asset/wp-content/uploads/2021/11/car-5-360x270.jpg 360w"
                                                                              data-sizes="auto"
                                                                              alt="Ferrari LaFerrari red">
                                                                           <div
                                                                              class="listivo-listing-card__labels">
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-listing-card__main">
                                                                           <div
                                                                              class="listivo-listing-card__main-head">
                                                                              <h3
                                                                                 class="listivo-listing-card__name">
                                                                                 Ferrari
                                                                                 LaFerrari
                                                                                 red
                                                                              </h3>
                                                                              <div
                                                                                 class="listivo-listing-card__location">
                                                                                 <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                    </path>
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                    </path>
                                                                                 </svg>
                                                                                 Evanston,
                                                                                 IL,
                                                                                 USA
                                                                              </div>
                                                                              <b
                                                                                 class="listivo-listing-card__price">
                                                                              $1,100,000
                                                                              </b>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-listing-card__footer">
                                                                        <div
                                                                           class="listivo-listing-card__features">
                                                                           <ul>
                                                                              <li><span>Vehicles</span>
                                                                              </li>
                                                                              <li><span>Cars</span>
                                                                              </li>
                                                                              <li><span>Year:
                                                                                 2021</span>
                                                                              </li>
                                                                           </ul>
                                                                        </div>
                                                                        <button
                                                                           class="listivo-listing-card__favorite">
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path
                                                                                 stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1.4"
                                                                                 d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                           </svg>
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              viewBox="0 0 20 20"
                                                                              fill="currentColor">
                                                                              <path
                                                                                 fill-rule="evenodd"
                                                                                 d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                                                 clip-rule="evenodd" />
                                                                           </svg>
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-listing-card listivo-listing-no-user"
                                                                  data-id="4605">
                                                                  <div
                                                                     class="listivo-listing-card__inner">
                                                                     <a class="listivo-listing-card__link"
                                                                        href="#"></a>
                                                                     <div>
                                                                        <div
                                                                           class="listivo-listing-card__top">
                                                                           <img class="lazyload listivo-listing-card__preview"
                                                                              data-srcset="asset/wp-content/uploads/2021/10/motorcycle-14-720x540.jpg 720w, asset/wp-content/uploads/2021/10/motorcycle-14-360x270.jpg 360w"
                                                                              data-sizes="auto"
                                                                              alt="Red and Black Motorcycle">
                                                                           <div
                                                                              class="listivo-listing-card__labels">
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-listing-card__main">
                                                                           <div
                                                                              class="listivo-listing-card__main-head">
                                                                              <h3
                                                                                 class="listivo-listing-card__name">
                                                                                 Red
                                                                                 and
                                                                                 Black
                                                                                 Motorcycle
                                                                              </h3>
                                                                              <div
                                                                                 class="listivo-listing-card__location">
                                                                                 <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                    </path>
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                    </path>
                                                                                 </svg>
                                                                                 Mumbai,
                                                                                 Maharashtra,
                                                                                 India
                                                                              </div>
                                                                              <b
                                                                                 class="listivo-listing-card__price">
                                                                              $62,000
                                                                              </b>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-listing-card__footer">
                                                                        <div
                                                                           class="listivo-listing-card__features">
                                                                           <ul>
                                                                              <li><span>Vehicles</span>
                                                                              </li>
                                                                              <li><span>Motorcycles</span>
                                                                              </li>
                                                                              <li><span>Year:
                                                                                 2016</span>
                                                                              </li>
                                                                           </ul>
                                                                        </div>
                                                                        <button
                                                                           class="listivo-listing-card__favorite">
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path
                                                                                 stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1.4"
                                                                                 d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                           </svg>
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              viewBox="0 0 20 20"
                                                                              fill="currentColor">
                                                                              <path
                                                                                 fill-rule="evenodd"
                                                                                 d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                                                 clip-rule="evenodd" />
                                                                           </svg>
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-listing-card listivo-listing-no-user"
                                                                  data-id="1522">
                                                                  <div
                                                                     class="listivo-listing-card__inner">
                                                                     <a class="listivo-listing-card__link"
                                                                        href="#"></a>
                                                                     <div>
                                                                        <div
                                                                           class="listivo-listing-card__top">
                                                                           <img class="lazyload listivo-listing-card__preview"
                                                                              data-srcset="asset/wp-content/uploads/2021/05/animals-014-720x540.jpg 720w, asset/wp-content/uploads/2021/05/animals-014-360x270.jpg 360w, asset/wp-content/uploads/2021/05/animals-014-180x135.jpg 180w"
                                                                              data-sizes="auto"
                                                                              alt="Joyful dog">
                                                                           <div
                                                                              class="listivo-listing-card__labels">
                                                                           </div>
                                                                        </div>
                                                                        <div
                                                                           class="listivo-listing-card__main">
                                                                           <div
                                                                              class="listivo-listing-card__main-head">
                                                                              <h3
                                                                                 class="listivo-listing-card__name">
                                                                                 Joyful
                                                                                 dog
                                                                              </h3>
                                                                              <div
                                                                                 class="listivo-listing-card__location">
                                                                                 <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                    </path>
                                                                                    <path
                                                                                       stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                    </path>
                                                                                 </svg>
                                                                                 State
                                                                                 of
                                                                                 São
                                                                                 Paulo,
                                                                                 Brazil
                                                                              </div>
                                                                              <b
                                                                                 class="listivo-listing-card__price">
                                                                              $50
                                                                              </b>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="listivo-listing-card__footer">
                                                                        <div
                                                                           class="listivo-listing-card__features">
                                                                           <ul>
                                                                              <li><span>Pets</span>
                                                                              </li>
                                                                              <li><span>Dogs</span>
                                                                              </li>
                                                                              <li><span>Age:
                                                                                 2
                                                                                 years</span>
                                                                              </li>
                                                                              <li><span>Ready
                                                                                 to
                                                                                 Leave:
                                                                                 Now</span>
                                                                              </li>
                                                                           </ul>
                                                                        </div>
                                                                        <button
                                                                           class="listivo-listing-card__favorite">
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path
                                                                                 stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1.4"
                                                                                 d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                           </svg>
                                                                           <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              viewBox="0 0 20 20"
                                                                              fill="currentColor">
                                                                              <path
                                                                                 fill-rule="evenodd"
                                                                                 d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                                                 clip-rule="evenodd" />
                                                                           </svg>
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div class="listivo-pagination listivo-pagination--listings"
                                                            >
                                                            <div
                                                               class="listivo-pagination-mobile">
                                                               <button
                                                                  class="listivo-pagination-mobile__arrow listivo-pagination-mobile__arrow--left"
                                                                  >
                                                               <i
                                                                  class="fa fa-chevron-left"></i>
                                                               </button>
                                                               <span
                                                                  class="listivo-pagination-mobile__start">1</span>
                                                               <span
                                                                  class="listivo-pagination-mobile__middle">/</span>
                                                               <span
                                                                  class="listivo-pagination-mobile__end">10</span>
                                                               <button
                                                                  class="listivo-pagination-mobile__arrow listivo-pagination-mobile__arrow--right"
                                                                  >
                                                               <i
                                                                  class="fa fa-chevron-right"></i>
                                                               </button>
                                                            </div>
                                                            <div
                                                               class="listivo-pagination-desktop">
                                                               <div
                                                                  class="listivo-pagination-desktop__inner">
                                                                  <div
                                                                     class="listivo-pagination-desktop__arrow listivo-pagination-desktop__arrow--left"
                                                                     >
                                                                     <i
                                                                        class="fa fa-chevron-left"></i>
                                                                  </div>
                                                                  <div
                                                                     class="listivo-pagination-desktop__page">
                                                                     1
                                                                  </div>
                                                                  <div
                                                                     class="listivo-pagination-desktop__page listivo-pagination-desktop__page--dots">
                                                                     ...
                                                                  </div>
                                                                  <div
                                                                     class="listivo-pagination-desktop__page"
                                                                     >
                                                                     page
                                                                  </div>
                                                                  <div
                                                                     class="listivo-pagination-desktop__arrow listivo-pagination-desktop__arrow--right">
                                                                     <i
                                                                        class="fa fa-chevron-right"></i>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div
                                                         class="listivo-map-search__right">
                                                         <div
                                                            class="listivo-map-container">
                                                            <div
                                                               class="listivo-map-calculate-height">
                                                               <div class="listivo-map__search-as-i-move-wrapper"
                                                                  >
                                                                  <div
                                                                     class="listivo-map__search-as-i-move">
                                                                     <div
                                                                        class="listivo-checkbox">
                                                                        <input
                                                                           id="listivo-search-as-i-move-map-3a14264"
                                                                           type="checkbox">
                                                                        <label
                                                                           for="listivo-search-as-i-move-map-3a14264">
                                                                        Search
                                                                        as
                                                                        I
                                                                        move
                                                                        the
                                                                        map
                                                                        </label>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div
                                                                  class="listivo-map-results">
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div
                                                      class="listivo-map-search__mobile-buttons">
                                                      <div
                                                         class="listivo-primary-button listivo-primary-button--icon listivo-button--map-view">
                                                         <div
                                                            class="listivo-primary-button__text">
                                                            Map view
                                                         </div>
                                                         <div
                                                            class="listivo-primary-button__icon">
                                                            <i class="far fa-map"></i>
                                                         </div>
                                                      </div>
                                                      <div
                                                         class="listivo-primary-button listivo-primary-button--icon listivo-button--listings-view">
                                                         <div
                                                            class="listivo-primary-button__text">
                                                            List view
                                                         </div>
                                                         <div
                                                            class="listivo-primary-button__icon">
                                                            <i
                                                               class="fas fa-list-ul"></i>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </section>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
   <Footer/>
</div>
</template>
<script>
import Header from '../../../include/Header';
import Footer from '../../../include/Footer';
export default {
  name: 'Map search',
  title: 'Map search',
  components:{
    Header,
    Footer,
  },
  beforeCreate: function() {
        document.body.className = 'page-template-default page page-id-2437 theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-5228 elementor-page elementor-page-2437';

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/asset/wp-content/uploads/elementor/css/post-5228.css';
        document.head.appendChild(style);



    }
}
</script>
