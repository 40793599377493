<template v-if="productDetail.length > 0">
  <div
    data-elementor-type="wp-post"
    data-elementor-id="57"
    class="elementor elementor-57"
    data-elementor-settings="[]"
  >
    <div class="elementor-section-wrap">
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-de45e04 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="de45e04"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70803c3"
            data-id="70803c3"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-d6d46ed elementor-widget elementor-widget-lst_menu"
                data-id="d6d46ed"
                data-element_type="widget"
                data-widget_type="lst_menu.default"
              >
                <div class="elementor-widget-container">
                  <Header />
                </div>
              </div>
              <div
                class="elementor-element elementor-element-7807579 elementor-widget elementor-widget-lst_template_loader"
                data-id="7807579"
                data-element_type="widget"
                data-widget_type="lst_template_loader.default"
              >
                <div class="elementor-widget-container">
                  <div
                    data-elementor-type="wp-post"
                    data-elementor-id="374"
                    class="elementor elementor-374"
                    data-elementor-settings="[]"
                  >
                    <div class="elementor-section-wrap">
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-4014d233 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="4014d233"
                        data-element_type="section"
                      >
                        <div
                          class="elementor-container elementor-column-gap-default"
                        >
                          <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5cc8225 elementor-hidden-tablet elementor-hidden-mobile"
                            data-id="5cc8225"
                            data-element_type="column"
                          >
                            <div
                              class="elementor-widget-wrap elementor-element-populated"
                            >
                              <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-6504df8c elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="6504df8c"
                                data-element_type="section"
                              >
                                <div
                                  class="elementor-container elementor-column-gap-default"
                                >
                                  <div
                                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4a1beaca"
                                    data-id="4a1beaca"
                                    data-element_type="column"
                                  >
                                    <div
                                      class="elementor-widget-wrap elementor-element-populated"
                                    >
                                      <div
                                        class="elementor-element elementor-element-766cc225 elementor-widget__width-auto elementor-widget-tablet__width-inherit elementor-widget elementor-widget-lst_breadcrumbs"
                                        data-id="766cc225"
                                        data-element_type="widget"
                                        data-widget_type="lst_breadcrumbs.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="listivo-app">
                                            <div
                                              class="listivo-breadcrumbs-wrapper"
                                            >
                                              <div class="listivo-breadcrumbs">
                                                <div
                                                  class="listivo-breadcrumbs__single"
                                                >
                                                  <a
                                                    class="listivo-breadcrumbs__link"
                                                    href="."
                                                    title="Home"
                                                  >
                                                    Home
                                                  </a>
                                                  <span
                                                    class="listivo-breadcrumbs__separator"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="11"
                                                      height="11"
                                                      viewBox="0 0 11 11"
                                                    >
                                                      <g>
                                                        <g>
                                                          <path
                                                            d="M4.974.635c-.119.12-.119.246 0 .38l3.987 3.973H.587c-.178 0-.267.09-.267.268v.223c0 .179.089.268.267.268H8.96L4.974 9.721c-.119.133-.119.26 0 .379l.156.178c.134.119.26.119.379 0l4.7-4.732c.133-.119.133-.238 0-.357L5.508.457c-.119-.12-.245-.12-.379 0z"
                                                          />
                                                        </g>
                                                      </g>
                                                    </svg>
                                                  </span>
                                                </div>

                                                <div
                                                  class="listivo-breadcrumbs__last"
                                                >
                                                  {{ productDetail.name }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-51f92a25 listivo-listing-social-column"
                                    data-id="51f92a25"
                                    data-element_type="column"
                                  >
                                    <div
                                      class="elementor-widget-wrap elementor-element-populated"
                                    >
                                      <div
                                        class="elementor-element elementor-element-4db8b704 elementor-widget__width-auto elementor-widget-tablet__width-inherit elementor-widget elementor-widget-lst_social_share"
                                        data-id="4db8b704"
                                        data-element_type="widget"
                                        data-widget_type="lst_social_share.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="listivo-social-share">
                                            <a
                                              href="htttp://facebook.com"
                                              target="_blank"
                                              class="listivo-social-share__single listivo-social-share__single--facebook"
                                            >
                                              <i class="fab fa-facebook-f"></i>
                                            </a>
                                            <a
                                              href="htttp://twitter.com"
                                              target="_blank"
                                              class="listivo-social-share__single listivo-social-share__single--twitter"
                                            >
                                              <i class="fab fa-twitter"></i>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-1543d1d0 elementor-widget__width-auto elementor-widget elementor-widget-lst_listing_print"
                                        data-id="1543d1d0"
                                        data-element_type="widget"
                                        data-widget_type="lst_listing_print.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div
                                            class="listivo-app listivo-listing-print"
                                          >
                                            <div>
                                              <button
                                                title="Print listing page"
                                                onclick="window.print()"
                                                class="listivo-print-button"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  stroke="currentColor"
                                                >
                                                  <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="1.7"
                                                    d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                                                  />
                                                </svg>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-e752c66 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="e752c66"
                        data-element_type="section"
                      >
                        <div
                          class="elementor-container elementor-column-gap-default"
                        >
                          <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-37aef4b8"
                            data-id="37aef4b8"
                            data-element_type="column"
                          >
                            <div
                              class="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                class="elementor-element elementor-element-45279315 elementor-widget elementor-widget-spacer"
                                data-id="45279315"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-f53140 elementor-widget elementor-widget-lst_listing_top"
                                data-id="f53140"
                                data-element_type="widget"
                                data-widget_type="lst_listing_top.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="listivo-listing-top">
                                    <div class="listivo-listing-top__left">
                                      <h1
                                        id="targetDiv"
                                        class="listivo-listing-top__name"
                                      >
                                        {{ productDetail.name }}
                                      </h1>
                                      <div class="listivo-listing-top__address">
                                        <div
                                          class="listivo-app listivo-address listivo-address--with-icon"
                                        >
                                          <div class="listivo-address__inner">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="currentColor"
                                            >
                                              <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                              />
                                              <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                              />
                                            </svg>
                                            <span>{{
                                              productDetail.contact_address
                                            }}</span>
                                            <a
                                              @click="movetomap"
                                              class="listivo-see-map"
                                              href="#"
                                            >
                                              See map
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="listivo-listing-top__right">
                                      <div
                                        id="targetDiv"
                                        class="listivo-listing-top__price"
                                      >
                                        ${{ productDetail.price }}
                                      </div>
                                      <div
                                        class="listivo-app listivo-listing-top__finance"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-326b51a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="326b51a8"
                        data-element_type="section"
                      >
                        <div
                          class="elementor-container elementor-column-gap-default"
                        >
                          <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-43e2522"
                            data-id="43e2522"
                            data-element_type="column"
                          >
                            <div
                              class="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                class="elementor-element elementor-element-27cecf44 elementor-widget elementor-widget-spacer"
                                data-id="27cecf44"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-3b8ca69f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="3b8ca69f"
                        data-element_type="section"
                      >
                        <div
                          class="elementor-container elementor-column-gap-default"
                        >
                          <div
                            class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-12bff271"
                            data-id="12bff271"
                            data-element_type="column"
                          >
                            <div
                              class="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                class="elementor-element elementor-element-65ef2a13 elementor-widget elementor-widget-lst_listing_gallery"
                                data-id="65ef2a13"
                                data-element_type="widget"
                                data-widget_type="lst_listing_gallery.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="listivo-app">
                                    <div class="listivo-listing-gallery">
                                      <div class="listivo-swiper-container">
                                        <div
                                          id="targetDiv"
                                          class="listivo-swiper-wrapper"
                                        >
                                          <carousel
                                            v-if="images.length > 0"
                                            :items-to-show="1"
                                            :snapAlign="center"
                                            :wrap-around="true"
                                            :centerMode="true"
                                            :adjustableHeight="false"
                                            v-model="currentSlide"
                                          >
                                          <template #addons>
                                              <Pagination />
                                              <Navigation />
                                            </template>
                                            <slide 
                                              v-for="(row, index) in images"
                                              :key="index"
                                            >
                                              <div 
                                                class="carousel__item"
                                                v-if="row.itemType == 'Gallery'"
                                              >
                                                <img
                                                  :src="imagePath + row.image"
                                                />
                                              </div>
                                            </slide>

                                            
                                          </carousel>
                                        </div>
                                      </div>
                                      <div
                                        class="listivo-listing-gallery__count"
                                      >
                                        <i class="far fa-image"></i>

                                       {{currentSlide+1}} /{{ images.length }}
                                      </div>
                                    </div>
                                    <portal to="footer">
                                      <div
                                        class="pswp"
                                        tabindex="-1"
                                        role="dialog"
                                        aria-hidden="true"
                                      >
                                        <div class="pswp__bg"></div>
                                        <div class="pswp__scroll-wrap">
                                          <div class="pswp__container">
                                            <div class="pswp__item"></div>
                                            <div class="pswp__item"></div>
                                            <div class="pswp__item"></div>
                                          </div>
                                          <div
                                            class="pswp__ui pswp__ui--hidden"
                                          >
                                            <div class="pswp__top-bar">
                                              <div class="pswp__counter"></div>
                                              <button
                                                class="pswp__button pswp__button--close"
                                                title="Close (Esc)"
                                              ></button>
                                              <button
                                                class="pswp__button pswp__button--share"
                                                title="Share"
                                              ></button>
                                              <button
                                                class="pswp__button pswp__button--fs"
                                                title="Toggle fullscreen"
                                              ></button>
                                              <button
                                                class="pswp__button pswp__button--zoom"
                                                title="Zoom in/out"
                                              ></button>
                                              <div class="pswp__preloader">
                                                <div
                                                  class="pswp__preloader__icn"
                                                >
                                                  <div
                                                    class="pswp__preloader__cut"
                                                  >
                                                    <div
                                                      class="pswp__preloader__donut"
                                                    ></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"
                                            >
                                              <div
                                                class="pswp__share-tooltip"
                                              ></div>
                                            </div>
                                            <button
                                              class="pswp__button pswp__button--arrow--left"
                                              title="Previous (arrow left)"
                                            ></button>
                                            <button
                                              class="pswp__button pswp__button--arrow--right"
                                              title="Next (arrow right)"
                                            ></button>
                                            <div class="pswp__caption">
                                              <div
                                                class="pswp__caption__center"
                                              ></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </portal>
                                  </div>
                                </div>
                              </div>
                              <!-- <div
                                                class="elementor-element elementor-element-61da7d62 elementor-widget elementor-widget-lst_listing_attributes"
                                                data-id="61da7d62" data-element_type="widget"
                                                data-widget_type="lst_listing_attributes.default">
                                                <div class="elementor-widget-container">
                                                   <div class="listivo-attributes">
                                                      <div class="listivo-attribute">
                                                         <div class="listivo-attribute__value">
                                                            Free shipping
                                                         </div>
                                                      </div>
                                                      <div class="listivo-attribute">
                                                         <div class="listivo-attribute__value">
                                                            On the guarantee
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div> -->
                              <div
                                class="elementor-element elementor-element-724e587f elementor-widget elementor-widget-lst_listing_description"
                                data-id="724e587f"
                                data-element_type="widget"
                                data-widget_type="lst_listing_description.default"
                              >
                                <div class="elementor-widget-container">
                                  <div
                                    id="targetDiv"
                                    class="listivo-listing-description"
                                    v-html="productDetail.description"
                                  ></div>
                                </div>
                              </div>
                              <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-662aa70b elementor-section-content-middle elementor-hidden-desktop elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="662aa70b"
                                data-element_type="section"
                              >
                                <div
                                  class="elementor-container elementor-column-gap-default"
                                >
                                  <div
                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-7fd385d6"
                                    data-id="7fd385d6"
                                    data-element_type="column"
                                  >
                                    <div
                                      class="elementor-widget-wrap elementor-element-populated"
                                    >
                                      <div
                                        class="elementor-element elementor-element-162216d4 elementor-widget__width-auto elementor-widget elementor-widget-heading"
                                        data-id="162216d4"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <h4
                                            class="elementor-heading-title elementor-size-default"
                                          >
                                            Share:
                                          </h4>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-faa1d1e elementor-widget__width-auto elementor-widget elementor-widget-lst_social_share"
                                        data-id="faa1d1e"
                                        data-element_type="widget"
                                        data-widget_type="lst_social_share.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="listivo-social-share">
                                            <a
                                              href="#"
                                              target="_blank"
                                              class="listivo-social-share__single listivo-social-share__single--facebook"
                                            >
                                              <i class="fab fa-facebook-f"></i>
                                            </a>
                                            <a
                                              href="#"
                                              target="_blank"
                                              class="listivo-social-share__single listivo-social-share__single--twitter"
                                            >
                                              <i class="fab fa-twitter"></i>
                                            </a>
                                            <a
                                              href="#"
                                              target="_blank"
                                              class="listivo-social-share__single listivo-social-share__single--whatsapp"
                                            >
                                              <i class="fab fa-whatsapp"></i>
                                            </a>
                                            <a
                                              href="#"
                                              target="_blank"
                                              class="listivo-social-share__single listivo-social-share__single--messenger"
                                            >
                                              <i
                                                class="fab fa-facebook-messenger"
                                              ></i>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <div
                                class="elementor-element elementor-element-7e8cc404 elementor-widget elementor-widget-lst_listing_embed_field"
                                data-id="7e8cc404"
                                data-element_type="widget"
                                data-widget_type="lst_listing_embed_field.default"
                                v-if="productDetail_video_link.length > 0"
                              >
                                <div class="elementor-widget-container">
                                  <h3 class="listivo-heading-listing-embed">
                                    Video
                                  </h3>
                                  <div class="listivo-listing-embed">
                                    <div class="listivo-listing-embed__inner" >
                                      <YouTube
                                        :src="productDetail_video_link"
                                        :width="'100%'"
                                        @ready="onReady"
                                        ref="youtube"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-769fc6c2 elementor-widget elementor-widget-lst_listing_attachments"
                                data-id="769fc6c2"
                                data-element_type="widget"
                                data-widget_type="lst_listing_attachments.default"
                                v-if="attachment.length > 0"
                              >
                                <div class="elementor-widget-container">
                                  <h3
                                    class="listivo-heading-listing-attachments"
                                  >
                                    Attachments
                                  </h3>
                                  <div class="listivo-attachments">
                                    <div
                                      class="listivo-attachment-single-wrapper"
                                      v-for="(row, index) in attachment"
                                      :key="index"
                                    >
                                      <a
                                        class="listivo-attachment"
                                        v-if="row.itemType != 'Gallery'"
                                        :href="this.imagePath + row.image"
                                        target="_blank"
                                      >
                                        <div class="listivo-attachment__icon">
                                          <img
                                            src="../../../../../public/asset/wp-content/themes/listivo/assets/img/pdf.svg"
                                            alt="Notes"
                                          />
                                        </div>
                                        <div class="listivo-attachment__name">
                                          Attachment
                                        </div>
                                      </a>
                                    </div>
                                    <!-- <div class="listivo-attachment-single-wrapper">
                                                         <a class="listivo-attachment"
                                                            href="asset/wp-content/uploads/2021/05/Sample-PDF-File.pdf"
                                                            target="_blank">
                                                            <div class="listivo-attachment__icon">
                                                               <img
                                                                  src="asset/wp-content/themes/listivo/assets/img/pdf.svg"
                                                                  alt="Sample PDF File">
                                                            </div>
                                                            <div class="listivo-attachment__name">
                                                               Sample PDF File
                                                            </div>
                                                         </a>
                                                      </div> -->
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-7cbe113f elementor-widget elementor-widget-lst_listing_map"
                                data-id="7cbe113f"
                                data-element_type="widget"
                                id="map"
                                data-widget_type="lst_listing_map.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="listivo-listing-map-anchor"></div>
                                  <h3 class="listivo-heading-listing-location">
                                    Location
                                  </h3>
                                  <div
                                    id="listivo-model-form__map--153"
                                    class="listivo-add-listing-section__map"
                                    style="position: relative; overflow: hidden"
                                  >
                                    <div
                                      style="
                                        height: 100%;
                                        width: 100%;
                                        position: absolute;
                                        top: 0px;
                                        left: 0px;
                                        background-color: rgb(229, 227, 223);
                                      "
                                    >
                                      <div class="gm-style">
                                        <div class="google-map" id="address-map" style="height: 509px; width: 100%;" ref="mapDivRef"></div>
                                        <!-- <GoogleMap
                                          :api-key="this.$mapAPI"
                                          style="width: 100%; height: 500px"
                                          :center="center"
                                          :zoom="15"
                                        >
                                          <Marker
                                            :draggable="true"
                                            :options="{ position: center }"
                                          />
                                        </GoogleMap> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-103c397c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="103c397c"
                                data-element_type="section"
                              >
                                <div
                                  class="elementor-container elementor-column-gap-default"
                                >
                                  <div
                                    class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-5763336c"
                                    data-id="5763336c"
                                    data-element_type="column"
                                  >
                                    <div
                                      class="elementor-widget-wrap elementor-element-populated"
                                    >
                                      <div
                                        class="elementor-element elementor-element-3349b1ec elementor-widget elementor-widget-lst_listing_id"
                                        data-id="3349b1ec"
                                        data-element_type="widget"
                                        data-widget_type="lst_listing_id.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="listivo-listing-meta">
                                            <span
                                              class="listivo-listing-meta__label"
                                              >ID:</span
                                            >
                                            <span
                                              class="listivo-listing-meta__value"
                                              >{{ productDetail.pid }}</span
                                            >
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-703cc27"
                                    data-id="703cc27"
                                    data-element_type="column"
                                  >
                                    <div
                                      class="elementor-widget-wrap elementor-element-populated"
                                    >
                                      <div
                                        class="elementor-element elementor-element-2adf4d6d elementor-widget elementor-widget-lst_listing_publish_date"
                                        data-id="2adf4d6d"
                                        data-element_type="widget"
                                        data-widget_type="lst_listing_publish_date.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="listivo-listing__date">
                                            <span
                                              class="listivo-listing__date-label"
                                              >Published:</span
                                            >
                                            <span
                                              class="listivo-listing__date-value"
                                              >{{
                                                this.$filters.formatDate(
                                                  productDetail.createdAt
                                                )
                                              }}</span
                                            >
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1d77a70a"
                                    data-id="1d77a70a"
                                    data-element_type="column"
                                  >
                                    <div
                                      class="elementor-widget-wrap elementor-element-populated"
                                    >
                                      <div
                                        class="elementor-element elementor-element-6005676f elementor-widget elementor-widget-lst_listing_views"
                                        data-id="6005676f"
                                        data-element_type="widget"
                                        data-widget_type="lst_listing_views.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="listivo-listing-meta">
                                            <span
                                              class="listivo-listing-meta__label"
                                              >Views:</span
                                            >
                                            <span
                                              class="listivo-listing-meta__value"
                                              >{{
                                                productDetail.product_view
                                              }}</span
                                            >
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <div
                                class="elementor-element elementor-element-679c77e5 elementor-widget elementor-widget-spacer"
                                data-id="679c77e5"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-201281bf listivo-sticky"
                            data-id="201281bf"
                            data-element_type="column"
                          >
                            <div
                              class="elementor-widget-wrap elementor-element-populated"
                              style="display: block"
                            >
                              <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-413f232d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="413f232d"
                                data-element_type="section"
                                data-settings='{"background_background":"classic"}'
                              >
                                <div
                                  class="elementor-container elementor-column-gap-default"
                                >
                                  <div
                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-6fd9f313"
                                    data-id="6fd9f313"
                                    data-element_type="column"
                                  >
                                    <div
                                      class="elementor-widget-wrap elementor-element-populated"
                                    >
                                      <div
                                        class="elementor-element elementor-element-4d434652 elementor-widget__width-initial elementor-absolute listivo-padding-none elementor-widget elementor-widget-lst_user_image"
                                        data-id="4d434652"
                                        data-element_type="widget"
                                        data-settings='{"_position":"absolute"}'
                                        data-widget_type="lst_user_image.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <a
                                            class="listivo-user-image"
                                            href="#"
                                            v-if="userInfo.profileImage"
                                          >
                                            <img
                                              :src="
                                                $imageURL +
                                                'user/' +
                                                userInfo.profileImage
                                              "
                                              class="listivo-user-image-control-size"
                                            />
                                          </a>
                                          <a
                                            class="listivo-user-image"
                                            href="#"
                                            v-else
                                          >
                                            <img
                                              src="https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png"
                                              class="listivo-user-image-control-size"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-41b4068b listivo-font-xs elementor-widget elementor-widget-lst_user_name"
                                        data-id="41b4068b"
                                        data-element_type="widget"
                                        data-widget_type="lst_user_name.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <a
                                            href="#"
                                            class="listivo-user__name"
                                          >
                                            {{ userInfo.displayName }}
                                          </a>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-644bb594 elementor-widget elementor-widget-spacer"
                                        data-id="644bb594"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="elementor-spacer">
                                            <div
                                              class="elementor-spacer-inner"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-21bb639c elementor-widget elementor-widget-lst_user_registered_since"
                                        data-id="21bb639c"
                                        data-element_type="widget"
                                        data-widget_type="lst_user_registered_since.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="listivo-user-date">
                                            Member since
                                            {{ memberSince }} months
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-66fb0897 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                        data-id="66fb0897"
                                        data-element_type="widget"
                                        data-widget_type="divider.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="elementor-divider">
                                            <span
                                              class="elementor-divider-separator"
                                            >
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-320f7ff5 elementor-widget elementor-widget-lst_user_address"
                                        data-id="320f7ff5"
                                        data-element_type="widget"
                                        data-widget_type="lst_user_address.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div
                                            class="listivo-address listivo-address--with-icon"
                                          >
                                            <div
                                              v-if="productDetail.contact_address"
                                              class="listivo-address__inner"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                              >
                                                <path
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  stroke-width="2"
                                                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                                />
                                                <path
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  stroke-width="2"
                                                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                                />
                                              </svg>
                                              <span>{{
                                                productDetail.contact_address
                                              }}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-845ea7f elementor-widget elementor-widget-spacer"
                                        data-id="845ea7f"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="elementor-spacer">
                                            <div
                                              class="elementor-spacer-inner"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-6320d62a elementor-widget elementor-widget-lst_user_profile_button"
                                        data-id="6320d62a"
                                        data-element_type="widget"
                                        data-widget_type="lst_user_profile_button.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div
                                            class="listivo-alignment-justify"
                                          >
                                            <a
                                              class="listivo-primary-outline-button listivo-primary-outline-button--full-width"
                                              href="javascript:void(0)"
                                              @click="
                                                $router.push({
                                                  name: 'UserProfile',
                                                  params: { id: userInfo.userID },
                                                })
                                              "
                                            >
                                              <span
                                                class="listivo-primary-outline-button__text"
                                              >
                                                View profile
                                              </span>
                                              <span
                                                class="listivo-primary-outline-button__icon"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  class="h-5 w-5"
                                                  viewBox="0 0 20 20"
                                                  fill="currentColor"
                                                >
                                                  <path
                                                    fill-rule="evenodd"
                                                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                                    clip-rule="evenodd"
                                                  />
                                                </svg>
                                              </span>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <div
                                class="elementor-element elementor-element-42efdcda elementor-widget elementor-widget-lst_user_hidden_phone"
                                data-id="42efdcda"
                                data-element_type="widget"
                                data-widget_type="lst_user_hidden_phone.default"
                              >
                                <div class="elementor-widget-container">
                                  <div
                                    class="listivo-app listivo-user__hidden-phone"
                                  >
                                    <div>
                                      <button class="listivo-phone-hidden">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="22"
                                          height="35"
                                          viewBox="0 0 22 35"
                                        >
                                          <g>
                                            <g>
                                              <path
                                                fill="#0b4355"
                                                d="M18.972-.002c.397 0 .782.081 1.157.24.374.16.694.376.958.65.265.273.485.605.661.991.176.388.242.786.199 1.197l.066 28.849a5.54 5.54 0 0 1-.265 1.196 2.31 2.31 0 0 1-.66.992 4.256 4.256 0 0 1-.96.65c-.33.159-.738.238-1.222.238H3.107c-.485 0-.892-.08-1.223-.239a4.23 4.23 0 0 1-.958-.65 2.298 2.298 0 0 1-.661-.99A5.466 5.466 0 0 1 0 31.924l.066-28.85c-.044-.41.022-.808.199-1.196.175-.386.396-.718.66-.991.265-.274.584-.49.96-.65.373-.159.76-.24 1.156-.24zM3.041 2.05a1.23 1.23 0 0 0-.397.068.535.535 0 0 0-.297.24 3.035 3.035 0 0 1-.231.341.386.386 0 0 0-.066.377l-.067 3.076H20.03l-.066-3.076a.39.39 0 0 0-.066-.377 2.904 2.904 0 0 1-.232-.342.534.534 0 0 0-.297-.24 1.231 1.231 0 0 0-.397-.067zM20.03 8.203H1.983l.067 16.475h17.914zM18.906 32.95c.22 0 .374-.023.463-.068.088-.046.187-.125.297-.239a1.05 1.05 0 0 0 .232-.342c.043-.113.087-.24.132-.376l-.066-5.127H2.05l-.067 5.127c.044.137.088.263.133.376.043.114.12.228.23.342.11.114.21.193.298.24.088.044.242.067.463.067zm-6.875-3.076a3.074 3.074 0 0 0-.099.376.534.534 0 0 1-.198.307c-.11.093-.221.171-.33.24a.81.81 0 0 1-.43.102.974.974 0 0 1-.364-.102.784.784 0 0 1-.33-.24 3.489 3.489 0 0 1-.199-.307.444.444 0 0 1-.033-.376.533.533 0 0 1 .033-.41c.066-.137.132-.25.198-.342.067-.09.176-.159.33-.205.155-.045.276-.068.365-.068.175 0 .319.023.43.068.109.046.22.114.33.205.11.092.175.205.198.342.022.137.055.273.1.41z"
                                              />
                                            </g>
                                          </g>
                                        </svg>
                                        <span
                                          v-if="!reveal"
                                          class="listivo-phone-hidden__placeholder"
                                          >{{ productDetail.contact_number?productDetail.contact_number.slice(0, 4):'******'}}
                                          *** ***</span
                                        >
                                        <span
                                          v-else
                                          class="listivo-phone-hidden__placeholder"
                                          >{{productDetail.contact_number }}</span
                                        >
                                        <span
                                          class="listivo-phone-hidden__reveal"
                                          @click="revealPhone"
                                          ><span>(</span><span>Reveal</span
                                          ><span>)</span></span
                                        >
                                      </button>
                                      <!-- <a
                                                               class="listivo-phone-hidden listivo-phone-hidden--clicked">
                                                               <svg xmlns="http://www.w3.org/2000/svg"
                                                                  width="22" height="35"
                                                                  viewBox="0 0 22 35">
                                                                  <g>
                                                                     <g>
                                                                        <path fill="#0b4355"
                                                                           d="M18.972-.002c.397 0 .782.081 1.157.24.374.16.694.376.958.65.265.273.485.605.661.991.176.388.242.786.199 1.197l.066 28.849a5.54 5.54 0 0 1-.265 1.196 2.31 2.31 0 0 1-.66.992 4.256 4.256 0 0 1-.96.65c-.33.159-.738.238-1.222.238H3.107c-.485 0-.892-.08-1.223-.239a4.23 4.23 0 0 1-.958-.65 2.298 2.298 0 0 1-.661-.99A5.466 5.466 0 0 1 0 31.924l.066-28.85c-.044-.41.022-.808.199-1.196.175-.386.396-.718.66-.991.265-.274.584-.49.96-.65.373-.159.76-.24 1.156-.24zM3.041 2.05a1.23 1.23 0 0 0-.397.068.535.535 0 0 0-.297.24 3.035 3.035 0 0 1-.231.341.386.386 0 0 0-.066.377l-.067 3.076H20.03l-.066-3.076a.39.39 0 0 0-.066-.377 2.904 2.904 0 0 1-.232-.342.534.534 0 0 0-.297-.24 1.231 1.231 0 0 0-.397-.067zM20.03 8.203H1.983l.067 16.475h17.914zM18.906 32.95c.22 0 .374-.023.463-.068.088-.046.187-.125.297-.239a1.05 1.05 0 0 0 .232-.342c.043-.113.087-.24.132-.376l-.066-5.127H2.05l-.067 5.127c.044.137.088.263.133.376.043.114.12.228.23.342.11.114.21.193.298.24.088.044.242.067.463.067zm-6.875-3.076a3.074 3.074 0 0 0-.099.376.534.534 0 0 1-.198.307c-.11.093-.221.171-.33.24a.81.81 0 0 1-.43.102.974.974 0 0 1-.364-.102.784.784 0 0 1-.33-.24 3.489 3.489 0 0 1-.199-.307.444.444 0 0 1-.033-.376.533.533 0 0 1 .033-.41c.066-.137.132-.25.198-.342.067-.09.176-.159.33-.205.155-.045.276-.068.365-.068.175 0 .319.023.43.068.109.046.22.114.33.205.11.092.175.205.198.342.022.137.055.273.1.41z" />
                                                                     </g>
                                                                  </g>
                                                               </svg>
                                                               <span
                                                                  class="listivo-phone-hidden__number"></span>
                                                            </a> -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-1e41321b elementor-widget elementor-widget-lst_user_whats_app"
                                data-id="1e41321b"
                                data-element_type="widget"
                                data-widget_type="lst_user_whats_app.default"
                              >
                                <div class="elementor-widget-container">
                                  <div
                                    class="listivo-whatsapp"
                                    v-if="isLoggedin"
                                  >
                                    <a
                                      :href="
                                        'https://api.whatsapp.com/send?phone=' +
                                        productDetail.contact_number?productDetail.contact_number:''
                                      "
                                    >
                                      <i class="fab fa-whatsapp"></i>
                                      Chat via
                                      <strong>WhatsApp</strong>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-4aa9149b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="4aa9149b"
                                data-element_type="section"
                              >
                                <div
                                  class="elementor-container elementor-column-gap-default"
                                >
                                  <div
                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-1611fa85"
                                    data-id="1611fa85"
                                    data-element_type="column"
                                  >
                                    <div
                                      class="elementor-widget-wrap elementor-element-populated"
                                    >
                                      <div
                                        class="elementor-element elementor-element-13127d3f elementor-widget elementor-widget-heading"
                                        data-id="13127d3f"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <h2
                                            class="elementor-heading-title elementor-size-default"
                                            style="color: #000000"
                                          >
                                            Send Message
                                          </h2>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-4b078286 elementor-widget elementor-widget-spacer"
                                        data-id="4b078286"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="elementor-spacer">
                                            <div
                                              class="elementor-spacer-inner"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-49c82e8d elementor-widget elementor-widget-lst_shape"
                                        data-id="49c82e8d"
                                        data-element_type="widget"
                                        data-widget_type="lst_shape.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="listivo-shape">
                                            <div class="listivo-shape-line">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 913.000000 42.000000"
                                                preserveAspectRatio="xMidYMid meet"
                                              >
                                                <g
                                                  transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                                  stroke="none"
                                                >
                                                  <path
                                                    d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z"
                                                  ></path>
                                                </g>
                                              </svg>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-7d4d0b91 elementor-widget elementor-widget-spacer"
                                        data-id="7d4d0b91"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="elementor-spacer">
                                            <div
                                              class="elementor-spacer-inner"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-4aee321b elementor-widget elementor-widget-lst_contact_user"
                                        data-id="4aee321b"
                                        data-element_type="widget"
                                        data-widget_type="lst_contact_user.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="listivo-app">
                                            <form @submit.prevent="sendMessage">
                                              <div
                                                class="listivo-chat-send-form"
                                              >
                                                <textarea
                                                  v-model="message"
                                                ></textarea>
                                                <button
                                                  class="listivo-primary-button listivo-primary-button--full-width listivo-primary-button--icon listivo-primary-button--height-61"
                                                >
                                                  <span
                                                    class="listivo-primary-button__text"
                                                  >
                                                    Send
                                                  </span>
                                                  <span
                                                    class="listivo-primary-button__icon"
                                                  >
                                                    <i
                                                      class="fas fa-paper-plane"
                                                    ></i>
                                                  </span>
                                                </button>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <div
                                class="elementor-element elementor-element-a942f68 elementor-widget elementor-widget-lst_blog_post_comments"
                                data-id="a942f68"
                                data-element_type="widget"
                                data-widget_type="lst_blog_post_comments.default"
                              >
                                <div class="elementor-widget-container">
                                  <div
                                    class="listivo-comments"
                                    style="display: block"
                                    v-if="messages.length > 0"
                                  >
                                    <h4
                                      id="listivo-comments"
                                      class="listivo-comments__heading-top-count"
                                      style="font-size: 24px"
                                    >
                                      <span
                                        class="listivo-comments__heading-top-count__title"
                                        v-if="messages"
                                      >
                                        Messages
                                      </span>
                                    </h4>
                                    <div
                                      v-for="(row, index) in messages"
                                      :key="index"
                                      style="display: block"
                                    >
                                      <div
                                        class="comment byuser comment-author-emily-rees bypostauthor even thread-even depth-1 listivo-comment parent"
                                        id="comment-15"
                                        v-if="row.requesterID"
                                      >
                                        <div
                                          class="listivo-comment__content"
                                          style="
                                            padding-left: 0px;
                                            padding-top: 20px;
                                          "
                                        >
                                          <div
                                            class="listivo-comment__bg"
                                            style="padding: 15px"
                                          >
                                            <div
                                              class="listivo-comment__top"
                                              style="
                                                margin-bottom: 0px;
                                                display: block;
                                              "
                                            >
                                              <h4
                                                class="listivo-comment__user-name"
                                                style="
                                                  font-size: 18px;
                                                  margin-bottom: 10px;
                                                  display: block;
                                                "
                                              >
                                                <a href="#">{{
                                                  row.requesterName
                                                }}</a>
                                              </h4>
                                              <div
                                                class="listivo-comment__date"
                                                style="
                                                  top: -5px;
                                                  display: block;
                                                "
                                              >
                                                {{
                                                  $filters.messageDate(
                                                    row.createdAt
                                                  )
                                                }}
                                              </div>
                                            </div>
                                            <div
                                              class="listivo-comment__text"
                                              style="
                                                font-size: 14px;
                                                text-align: justify;
                                              "
                                            >
                                              <p>{{ row.productMessage }}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="comment byuser comment-author-emily-rees bypostauthor even thread-even depth-1 listivo-comment parent"
                                        id="comment-15"
                                        v-if="row.productOwnerID"
                                      >
                                        <div
                                          class="listivo-comment__content"
                                          style="
                                            padding-left: 0px;
                                            padding-top: 20px;
                                          "
                                        >
                                          <div
                                            class="listivo-comment__bg"
                                            style="padding: 15px"
                                          >
                                            <div
                                              class="listivo-comment__top"
                                              style="
                                                margin-bottom: 0px;
                                                padding-right: 0px;
                                              "
                                            >
                                              <h4
                                                class="listivo-comment__user-name"
                                                style="
                                                  font-size: 18px;
                                                  margin-bottom: 10px;
                                                  display: block;
                                                  width: 100%; /*! background: rebeccapurple; */
                                                  text-align: right;
                                                "
                                              >
                                                <a href="#">{{
                                                  row.productOwnerName
                                                }}</a>
                                              </h4>
                                              <br />
                                              <div
                                                class="listivo-comment__date"
                                                style="
                                                  top: -5px;
                                                  display: block;
                                                  width: 100%; /*! background: rebeccapurple; */
                                                  text-align: right;
                                                "
                                              >
                                                {{
                                                  $filters.messageDate(
                                                    row.createdAt
                                                  )
                                                }}
                                              </div>
                                            </div>
                                            <div
                                              class="listivo-comment__text"
                                              style="font-size: 14px"
                                            >
                                              <p>{{ row.productMessage }}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-3192538e elementor-widget elementor-widget-spacer"
                                data-id="3192538e"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-104cbda7 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                        data-id="104cbda7"
                        data-element_type="section"
                      >
                        <div
                          class="elementor-container elementor-column-gap-default"
                        >
                          <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-21ccb601"
                            data-id="21ccb601"
                            data-element_type="column"
                          >
                            <div
                              class="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                class="elementor-element elementor-element-502f119a elementor-widget elementor-widget-lst_listing_user_listings"
                                data-id="502f119a"
                                data-element_type="widget"
                                data-widget_type="lst_listing_user_listings.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="listivo-app">
                                    <div
                                      class="listivo-listing-carousel listivo-listing-carousel--simple"
                                    >
                                      <div
                                        class="listivo-listing-carousel__header"
                                      >
                                        <h3
                                          class="listivo-listing-carousel__heading"
                                        >
                                          More from this user
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 913.000000 42.000000"
                                            preserveAspectRatio="xMidYMid meet"
                                          >
                                            <g
                                              transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                              stroke="none"
                                            >
                                              <path
                                                d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z"
                                              ></path>
                                            </g>
                                          </svg>
                                        </h3>
                                        <!-- <div
                                                               class="listivo-listing-carousel__nav listivo-listing-carousel__nav--desktop">
                                                               <a href="#"
                                                                  class="listivo-button-pill">
                                                               Display all from {{userInfo.displayName}}
                                                               </a>

                                                                  <button
                                                                     class="listivo-arrow">
                                                                  <i
                                                                     class="fas fa-chevron-left"></i>
                                                                  </button>
                                                                  <button
                                                                     class="listivo-arrow">
                                                                  <i
                                                                     class="fas fa-chevron-right"></i>
                                                                  </button>
                                                            </div> -->
                                      </div>
                                      <div
                                        v-if="ownerProducts.length > 0"
                                        class="listivo-listing-carousel__listings"
                                      >
                                        <div class="listivo-swiper-container">
                                          <div class="listivo-swiper-wrapper">
                                            <div
                                              class="listivo-swiper-slide"
                                              v-for="(
                                                row, index
                                              ) in ownerProducts"
                                              :key="index"
                                            >
                                              <a
                                                href="javascript:void(0)"
                                                @click="
                                                  $router.push({
                                                    name: 'SingleProduct',
                                                    params: { id: row['id'] },
                                                  })
                                                "
                                                class="listivo-listing-card listivo-listing-no-user listivo-listing-featured"
                                                data-id="3804"
                                              >
                                                <div
                                                  class="listivo-listing-card__inner"
                                                >
                                                  <div>
                                                    <div
                                                      class="listivo-listing-card__top"
                                                      v-if="
                                                        row.photos.length > 0
                                                      "
                                                    >
                                                      <img
                                                        :data-srcset="
                                                          imagePath +
                                                          row.photos[0].image +
                                                          ' 720w, ' +
                                                          imagePath +
                                                          row.photos[0].image +
                                                          ' 360w'
                                                        "
                                                        data-sizes="auto"
                                                        :alt="row.name"
                                                        class="listivo-listing-card__preview lazyautosizes lazyloaded"
                                                        sizes="358px"
                                                        :srcset="
                                                          imagePath +
                                                          row.photos[0].image +
                                                          ' 720w, ' +
                                                          imagePath +
                                                          row.photos[0].image +
                                                          ' 360w'
                                                        "
                                                      />
                                                      <div
                                                        class="listivo-listing-card__labels"
                                                      >
                                                        <div
                                                          class="listivo-listing-card__label"
                                                          v-if="row.isFeatured"
                                                        >
                                                          > Featured
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      class="listivo-listing-card__main"
                                                    >
                                                      <div
                                                        class="listivo-listing-card__main-head"
                                                      >
                                                        <h3
                                                          class="listivo-listing-card__name"
                                                        >
                                                          {{ row.name }}
                                                        </h3>
                                                        <div
                                                          class="listivo-listing-card__location"
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                          >
                                                            <path
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                              stroke-width="1.4"
                                                              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                                            ></path>
                                                            <path
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                              stroke-width="1.4"
                                                              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                                            ></path>
                                                          </svg>
                                                          {{ row.location }}
                                                        </div>
                                                        <b
                                                          class="listivo-listing-card__price"
                                                        >
                                                          ${{ row.price }}
                                                        </b>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="listivo-listing-card__footer"
                                                  >
                                                    <div
                                                      class="listivo-listing-card__features"
                                                    >
                                                      <ul>
                                                        <li>
                                                          <span>{{
                                                            row.category.name
                                                          }}</span>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                    <!-- <button
                                                                                    class="listivo-listing-card__favorite">
                                                                                       <svg
                                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                                          fill="none"
                                                                                          viewBox="0 0 24 24"
                                                                                          stroke="currentColor">
                                                                                          <path
                                                                                             stroke-linecap="round"
                                                                                             stroke-linejoin="round"
                                                                                             stroke-width="1.4"
                                                                                             d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                                       </svg>
                                                                                       <svg
                                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                                          viewBox="0 0 20 20"
                                                                                          fill="currentColor">
                                                                                          <path
                                                                                             fill-rule="evenodd"
                                                                                             d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                                                             clip-rule="evenodd" />
                                                                                       </svg>
                                                                                 </button> -->
                                                  </div>
                                                </div>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="listivo-listing-carousel__nav listivo-listing-carousel__nav--mobile"
                                      >
                                        <button class="listivo-arrow">
                                          <i class="fas fa-chevron-left"></i>
                                        </button>
                                        <button class="listivo-arrow">
                                          <i class="fas fa-chevron-right"></i>
                                        </button>
                                      </div>
                                      <div
                                        class="listivo-listing-carousel__more-button-mobile"
                                      >
                                        <a
                                          href="#"
                                          class="listivo-primary-button"
                                        >
                                          <span
                                            class="listivo-primary-button__text"
                                          >
                                            All user listings
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-78941eaa elementor-widget elementor-widget-lst_related_listings"
                                data-id="78941eaa"
                                data-element_type="widget"
                                data-widget_type="lst_related_listings.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="listivo-app">
                                    <div
                                      class="listivo-listing-carousel listivo-listing-carousel--simple"
                                    >
                                      <div
                                        gi
                                        class="listivo-listing-carousel__header"
                                      >
                                        <h3
                                          class="listivo-listing-carousel__heading"
                                        >
                                          You may also like...
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 913.000000 42.000000"
                                            preserveAspectRatio="xMidYMid meet"
                                          >
                                            <g
                                              transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                              stroke="none"
                                            >
                                              <path
                                                d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z"
                                              ></path>
                                            </g>
                                          </svg>
                                        </h3>
                                        <!-- <div
                                                               class="listivo-listing-carousel__nav listivo-listing-carousel__nav--desktop">
                                                               <a class="listivo-button-pill"
                                                                  href="./listings/">
                                                               Start a new search </a>

                                                                  <button

                                                                     class="listivo-arrow">
                                                                  <i
                                                                     class="fas fa-chevron-left"></i>
                                                                  </button>
                                                                  <button
                                                                     class="listivo-arrow">
                                                                  <i
                                                                     class="fas fa-chevron-right"></i>
                                                                  </button>

                                                            </div> -->
                                      </div>
                                      <div
                                        v-if="relatedProducts.length > 0"
                                        class="listivo-listing-carousel__listings"
                                      >
                                        <div class="listivo-swiper-container">
                                          <div class="listivo-swiper-wrapper">
                                            <div
                                              class="listivo-swiper-slide"
                                              v-for="(
                                                row, index
                                              ) in relatedProducts"
                                              :key="index"
                                            >
                                              <a
                                                href="javascript:void(0)"
                                                @click="
                                                  $router.push({
                                                    name: 'SingleProduct',
                                                    params: { id: row['id'] },
                                                  })
                                                "
                                                class="listivo-listing-card listivo-listing-no-user"
                                                data-id="3802"
                                              >
                                                <div
                                                  class="listivo-listing-card__inner"
                                                >
                                                  <div>
                                                    <div
                                                      class="listivo-listing-card__top"
                                                    >
                                                      <img
                                                        :data-srcset="
                                                          imagePath +
                                                          row.photos[0].image +
                                                          ' 720w, ' +
                                                          imagePath +
                                                          row.photos[0].image +
                                                          ' 360w'
                                                        "
                                                        data-sizes="auto"
                                                        :alt="row.name"
                                                        class="listivo-listing-card__preview lazyautosizes lazyloaded"
                                                        sizes="358px"
                                                        :srcset="
                                                          imagePath +
                                                          row.photos[0].image +
                                                          ' 720w, ' +
                                                          imagePath +
                                                          row.photos[0].image +
                                                          ' 360w'
                                                        "
                                                      />
                                                      <div
                                                        class="listivo-listing-card__labels"
                                                      ></div>
                                                    </div>
                                                    <div
                                                      class="listivo-listing-card__main"
                                                    >
                                                      <div
                                                        class="listivo-listing-card__main-head"
                                                      >
                                                        <h3
                                                          class="listivo-listing-card__name"
                                                        >
                                                          {{ row.name }}
                                                        </h3>
                                                        <div
                                                          class="listivo-listing-card__location"
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                          >
                                                            <path
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                              stroke-width="1.4"
                                                              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                                            ></path>
                                                            <path
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                              stroke-width="1.4"
                                                              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                                            ></path>
                                                          </svg>
                                                          {{ row.location }}
                                                        </div>
                                                        <b
                                                          class="listivo-listing-card__price"
                                                        >
                                                          ${{ row.price }}
                                                        </b>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="listivo-listing-card__footer"
                                                  >
                                                    <div
                                                      class="listivo-listing-card__features"
                                                    >
                                                      <ul>
                                                        <li>
                                                          <span>{{
                                                            row.category.name
                                                          }}</span>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                    <!-- <button
                                                                                    class="listivo-listing-card__favorite">
                                                                                       <svg
                                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                                          fill="none"
                                                                                          viewBox="0 0 24 24"
                                                                                          stroke="currentColor">
                                                                                          <path
                                                                                             stroke-linecap="round"
                                                                                             stroke-linejoin="round"
                                                                                             stroke-width="1.4"
                                                                                             d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                                                       </svg>
                                                                                       <svg
                                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                                          viewBox="0 0 20 20"
                                                                                          fill="currentColor">
                                                                                          <path
                                                                                             fill-rule="evenodd"
                                                                                             d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                                                             clip-rule="evenodd" />
                                                                                       </svg>
                                                                                 </button> -->
                                                  </div>
                                                </div>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="listivo-listing-carousel__nav listivo-listing-carousel__nav--mobile"
                                      >
                                        <a
                                          href="./listings/"
                                          class="listivo-button-pill"
                                        >
                                          Start a new search
                                        </a>

                                        <button class="listivo-arrow">
                                          <i class="fas fa-chevron-left"></i>
                                        </button>
                                        <button class="listivo-arrow">
                                          <i class="fas fa-chevron-right"></i>
                                        </button>
                                      </div>
                                      <div
                                        class="listivo-listing-carousel__more-button-mobile"
                                      >
                                        <a
                                          href="./listings/"
                                          class="listivo-primary-button"
                                        >
                                          <span
                                            class="listivo-primary-button__text"
                                          >
                                            Start a new search
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  </div>
</template>

<script src="../js/product_details.js"></script>
<style scoped>
.carousel__next {
    top: 50%;
    margin-top: -100px !important;
    right: 0;
    transform: translate(50%, -50%);
}
.carousel__prev {
    top: 50%;
    margin-top: -100px !important;
    left: 0;
    transform: translate(50%, -50%);
}
@media print {
  html,
  body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  header,
  footer,
  aside,
  form,
  … {
    display: none;
  }
  article {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  a:after {
    content: "('attr(href)')";
  }
  body * {
    visibility: hidden;
  }
  #targetDiv,
  #targetDiv * {
    visibility: visible;
    border: none;
  }
}
@page {
  margin: 2cm;
}
@page: first {
  margin: 0cm;
}
@page: last {
  margin: 5cm;
}
@page: left {
  margin: 2cm 1.5cm 2cm 2cm;
}
@page: right {
  margin: 2cm 2cm 2cm 1.5cm;
}
body.printing {
  display: block;
}
.print:last-child {
  page-break-after: avoid;
  page-break-inside: avoid;
  margin-bottom: 0px;
}
</style>

