<template v-if="pageCMS">
    <header class="listivo-header listivo-header--with-submit-button listivo-header--with-dashboard-link">
        <div class="listivo-header__container">
            <div class="listivo-header__inner">
                <div class="listivo-hide-mobile listivo-hide-tablet">
                <div class="listivo-menu__desktop">
                    <div class="listivo-menu__wrapper">
                        <div class="listivo-menu__limit-width" v-if="pageCMS">
                            <div class="listivo-menu__left">
                            <div class="listivo-logo">
                                <router-link to="/home" :title="pageCMS.siteName">
                                <img :src="$imageURL + 'site/'+ pageCMS.headerLogo"  :alt="pageCMS.siteName">
                                </router-link>
                            </div>
                            <div class="listivo-logo listivo-logo--sticky">
                                <router-link to="/home"
                                    :title="pageCMS.siteName">
                                <img :src="$imageURL + 'site/'+ pageCMS.headerLogo"
                                    :alt="pageCMS.siteName">
                                </router-link>
                            </div>
                            <div class="listivo-menu__container">
                                <div class="listivo-menu-hover"></div>
                                <div id="listivo-menu" class="listivo-menu">
                                    <div id="listivo-menu-element-menu-item-1-5293"
                                        class="listivo-home-menu-link menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5039 current_page_item menu-item-5293 listivo-menu-item listivo-menu__item listivo-menu-item-depth-0 listivo-menu__item--depth-0">
                                        <router-link to="/home" title="Home"
                                        class="listivo-menu__link">
                                        Home </router-link>
                                    </div>
                                    <div id="listivo-menu-element-menu-item-1-5293"
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-851 listivo-menu-item listivo-menu__item listivo-menu-item-depth-0 listivo-menu__item--depth-0">
                                        <router-link to="/home" title="Home"
                                        class="listivo-menu__link">
                                        Home </router-link>
                                    </div>
                                    <div id="listivo-menu-element-menu-item-1-851"
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-851 listivo-menu-item listivo-menu__item listivo-menu-item-depth-0 listivo-menu__item--depth-0">
                                        <router-link to="/search" title="Search"
                                        class="listivo-menu__link">
                                        Search </router-link>
                                    </div>
                                    <div id="listivo-menu-element-menu-item-1-485" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-485 listivo-menu-item listivo-menu__item listivo-menu-item-depth-0 listivo-menu__item--depth-0">
                                        <a href="#" title="Category" class="listivo-menu__link">
                                        Category </a>
                                        <div class="listivo-submenu listivo-submenu--level-0 listivo-menu__submenu listivo-menu__submenu--0" v-if="categoryList">
                                            <div id="listivo-menu-element-menu-item-1-1742" v-for="(cat, index) in categoryList" :key="index" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1742 listivo-menu-item listivo-menu__item listivo-menu-item-depth-1 listivo-menu__item--depth-1">
                                                <router-link :to="{
                                                    name: 'ProductList',
                                                    params: {id: cat.id}
                                                }" :title="cat.name" class="listivo-menu__link"> {{cat.name}} </router-link>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div id="listivo-menu-element-menu-item-1-5293"
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-851 listivo-menu-item listivo-menu__item listivo-menu-item-depth-0 listivo-menu__item--depth-0">
                                        <router-link to="/about" title="About Us"
                                        class="listivo-menu__link">
                                        About Us</router-link>
                                    </div>
                                    <div id="listivo-menu-element-menu-item-1-1737"
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-851 listivo-menu-item listivo-menu__item listivo-menu-item-depth-0 listivo-menu__item--depth-0">
                                        <router-link to="/blog" title="Blog"
                                        class="listivo-menu__link">
                                        Blog </router-link>
                                    </div>
                                    <!-- <div id="listivo-menu-element-menu-item-1-898"
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-851 listivo-menu-item listivo-menu__item listivo-menu-item-depth-0 listivo-menu__item--depth-0">
                                        <router-link to="/our-team" title="Our Team"
                                        class="listivo-menu__link">
                                        Our Team</router-link>
                                    </div> -->
                                    
                                </div>
                            </div>
                            </div>
                            <div class="listivo-menu__more">
                            <div v-if="isLoggedIn == null"
                                class="listivo-menu-desktop-login-register-link">
                                <svg class="listivo-menu-user-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24">
                                    <path stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="1"
                                        d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <div class="listivo-menu-item-depth-0">
                                    <router-link to="/login">
                                    <span class="listivo-menu-desktop-login-register-link__login-text listivo-menu-item-depth-0">
                                    Log In </span>
                                    </router-link>
                                </div>
                                <span
                                    class="listivo-menu-desktop-login-register-link__separator"></span>
                                <div class="listivo-menu-item-depth-0">
                                    <router-link to="/register">
                                    <span
                                        class="listivo-menu-desktop-login-register-link__register-text listivo-menu-item-depth-0">
                                    Register </span>
                                    </router-link>
                                </div>
                            </div>
                            <div v-else class="listivo-menu-desktop-login-register-link">
                                <div class="listivo-menu-item-depth-0">
                                    <router-link class="listivo-menu-desktop-dashboard-link" to="#">
                                        <svg class="listivo-menu-user-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                        </svg>
                                        Dashboard                                
                                    </router-link>
                                </div>
                                <div class="listivo-desktop-user-menu listivo-submenu listivo-submenu--level-0">
                                    <div class="listivo-desktop-user-menu__inner">
                                        <div class="listivo-desktop-user-menu__top">
                                            <div class="listivo-desktop-user-menu__top-inner">
                                            <a class="listivo-avatar-link-wrapper" href="#">
                                                <div class="listivo-avatar listivo-user-image-control-size">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                                                    </svg>
                                                </div>
                                            </a>
                                            <div class="listivo-desktop-user-menu__top-info">
                                                <div class="listivo-desktop-user-menu__name">
                                                    <a href="#">
                                                    {{fullname}}                                                    </a>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="listivo-desktop-user-menu__menu-links">
                                            <router-link class="listivo-desktop-user-menu__menu-link--my-listings" :to="{name: 'Listing'}" >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
                                            </svg>
                                            Dashboard
                                            </router-link>
                                            <router-link class="listivo-desktop-user-menu__menu-link--add-listing" :to="{name: 'Listing'}" >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            My Listing                                        
                                            </router-link>
                                            <router-link class="listivo-desktop-user-menu__menu-link--settings" :to="{name: 'Profile'}">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                                            </svg>
                                            Profile                                        
                                            </router-link>
                                            <hr>
                                            <a class="listivo-desktop-user-menu__menu-link--logout" href="#" 
                                            @click="logout()"
                                            >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                                            </svg>
                                            Log Out                                        
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <router-link class="listivo-primary-button listivo-primary-button--icon listivo-button--menu-submit"
                                to="/add-listing">
                                <span class="listivo-primary-button__text">
                                Add Listing </span>
                                <span class="listivo-primary-button__icon">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 4v16m8-8H4" />
                                    </svg>
                                </span>
                            </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="listivo-hide-desktop">
                <div class="listivo-mobile-menu__wrapper listivo-hide-desktop"  v-if="pageCMS">
                    <div class="listivo-app listivo-mobile-menu__hamburger">
                            <div>
                            <div class="listivo-menu-icon-wrapper" @click="listivoMenuIconWrapper"
                                >
                                <svg fill="#222"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25" height="16" viewBox="0 0 25 16">
                                    <g>
                                        <g>
                                        <path
                                            d="M1.125 6.875H20.75a1.125 1.125 0 1 1 0 2.25H1.125a1.125 1.125 0 1 1 0-2.25zm.012 6.844h22.726c.628 0 1.137.509 1.137 1.137v.007C25 15.49 24.49 16 23.863 16H1.137C.51 16 0 15.49 0 14.863v-.007c0-.628.51-1.137 1.137-1.137zM1.137 0h16.476c.628 0 1.137.51 1.137 1.137v.007c0 .628-.51 1.137-1.137 1.137H1.137C.51 2.281 0 1.772 0 1.144v-.007C0 .51.51 0 1.137 0z" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                                <div
                                    class="listivo-mobile-menu__open" id="menuOpen">
                                    <div
                                        class="listivo-mobile-menu__open__content">
                                        <div
                                        class="listivo-mobile-menu__open__top">
                                        <div
                                            class="listivo-mobile-menu__open__top__submit-button">
                                            <a href="/add-listing"
                                                class="listivo-primary-button listivo-primary-button--icon">
                                                <span
                                                    class="listivo-primary-button__text">
                                                Add Listing </span>
                                                <span
                                                    class="listivo-primary-button__icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor">
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M12 4v16m8-8H4" />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div
                                            class="listivo-mobile-menu__open__top__x" @click="listivoMenuIconWrapperClose">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="21" height="19"
                                                viewBox="0 0 21 19">
                                                <g>
                                                    <g>
                                                    <path fill="#fff"
                                                        d="M.602 18.781h2.443c.335 0 .574-.106.766-.284l6.178-6.615a.216.216 0 0 1 .336 0l6.13 6.615c.192.178.431.284.766.284h2.347c.48 0 .67-.284.383-.569L12.05 9.89a.176.176 0 0 1 0-.213l7.902-8.322c.288-.284.096-.569-.383-.569H17.03c-.336 0-.575.107-.767.285l-6.13 6.614a.215.215 0 0 1-.335 0l-6.13-6.614C3.475.893 3.235.786 2.9.786H.6c-.478 0-.67.285-.382.57l7.855 8.321a.177.177 0 0 1 0 .213L.219 18.212c-.288.285-.096.57.383.57z" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        </div>
                                        <div class="listivo-mobile-menu__nav">
                                        <div id="listivo-menu-mobile"
                                            class="listivo-menu">
                                            <div id="listivo-menu-element-menu-item-2-5293"
                                                class="listivo-home-menu-link menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5039 current_page_item menu-item-5293 listivo-menu-item listivo-menu__item listivo-menu-item-depth-0 listivo-menu__item--depth-0">
                                               
                                                <router-link to="/home" title="Product"
                                        class="listivo-menu__link">
                                        Home </router-link>
                                            </div>
                                            <div id="listivo-menu-element-menu-item-2-851"
                                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-851 listivo-menu-item listivo-menu__item listivo-menu-item-depth-0 listivo-menu__item--depth-0">
                                                <router-link to="/search" title="Search"
                                                    class="listivo-menu__link"> Search 
                                                </router-link>
                                            </div>
                                            <div v-if="categoryList" id="listivo-menu-element-menu-item-2-485" 
                                            @click="showHide()" 
                                            :class="[isHidden ? `${isHiddens}` : '']"
                                            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-485 listivo-menu-item listivo-menu__item listivo-menu-item-depth-0 listivo-menu__item--depth-0">
                                                <a href="#" title="Category" class="listivo-menu__link">
                                                Category </a>
                                                <div class="listivo-submenu listivo-submenu--level-0 listivo-menu__submenu listivo-menu__submenu--0" v-if="categoryList.length > 0">
                                                    <div id="listivo-menu-element-menu-item-2-1742" v-for="(cat, index) in categoryList" :key="index" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1742 listivo-menu-item listivo-menu__item listivo-menu-item-depth-1 listivo-menu__item--depth-1">
                                                        <router-link :to="{
                                                            name: 'ProductList',
                                                            params: {id: cat.id}
                                                        }" :title="cat.name" class="listivo-menu__link"> {{cat.name}} </router-link>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div id="listivo-menu-element-menu-item-2-5293"
                                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-851 listivo-menu-item listivo-menu__item listivo-menu-item-depth-0 listivo-menu__item--depth-0">
                                                <router-link to="/about" title="About Us"
                                                class="listivo-menu__link">
                                                About Us</router-link>
                                            </div>
                                            <div id="listivo-menu-element-menu-item-2-1737"
                                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-851 listivo-menu-item listivo-menu__item listivo-menu-item-depth-0 listivo-menu__item--depth-0">
                                                <router-link to="/blog" title="Blog"
                                                class="listivo-menu__link">
                                                Blog </router-link>
                                            </div>
                                            <div id="listivo-menu-element-menu-item-2-898"
                                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-851 listivo-menu-item listivo-menu__item listivo-menu-item-depth-0 listivo-menu__item--depth-0">
                                                <router-link to="/our-team" title="Our Team"
                                                class="listivo-menu__link">
                                                Our Team</router-link>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="listivo-mobile-menu__info">
                                        <!-- <div
                                            class="listivo-mobile-menu__info-phone">
                                            <span
                                                class="listivo-mobile-menu__info-phone-label">Call
                                            Support</span><span>:</span>
                                            <a :href="'tel:' + pageCMS.callSupport" class="listivo-phone">
                                    {{pageCMS.callSupport}} </a>
                                        </div> -->
                                        <div
                                            class="listivo-mobile-menu__info-email">
                                            <span
                                                class="listivo-mobile-menu__info-email-label">Email
                                            Address</span><span>:</span>
                                            <a :href="'mailTo:' + pageCMS.supportEmail" class="listivo-email">
                                                <span class="__cf_email__"
                                                    :data-cfemail="pageCMS.supportEmail">{{pageCMS.supportEmail}}</span>
                                            </a>
                                        </div>
                                        <div
                                            class="listivo-mobile-menu__info-social">
                                            <a href="#"
                                                class="listivo-social-profiles__single">
                                            <i
                                                class="fab fa-facebook-f"></i>
                                            </a>
                                            <a href="#"
                                                class="listivo-social-profiles__single">
                                            <i
                                                class="fab fa-twitter"></i>
                                            </a>
                                            <a href="#"
                                                class="listivo-social-profiles__single">
                                            <i
                                                class="fab fa-linkedin"></i>
                                            </a>
                                            <a href="#"
                                                class="listivo-social-profiles__single">
                                            <i
                                                class="fab fa-instagram"></i>
                                            </a>
                                            <a href="#"
                                                class="listivo-social-profiles__single">
                                            <i
                                                class="fab fa-youtube"></i>
                                            </a>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="listivo-mobile-menu-mask"></div>

                            </div>
                    </div>
                    <div class="listivo-mobile-menu__logo">
                        <div class="listivo-logo">
                            <router-link to="/home" :title="pageCMS.siteName">
                                <img :src="$imageURL + 'site/'+ pageCMS.headerLogo"  :alt="pageCMS.siteName">
                                </router-link>
                        </div>
                    </div>
                    <div class="listivo-mobile-menu__login">
                            <router-link to="/login" 
                            class="listivo-user-icon-wrapper">
<svg viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469-112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-325.914062 354.316406c8.453125-72.734375 70.988281-128.890625 144.894531-128.890625 38.960938 0 75.597656 15.179688 103.15625 42.734375 23.28125 23.285156 37.964844 53.6875 41.742188 86.152344-39.257813 32.878906-89.804688 52.707031-144.898438 52.707031s-105.636719-19.824219-144.894531-52.703125zm144.894531-159.789063c-42.871094 0-77.753906-34.882812-77.753906-77.753906 0-42.875 34.882812-77.753906 77.753906-77.753906s77.753906 34.878906 77.753906 77.753906c0 42.871094-34.882812 77.753906-77.753906 77.753906zm170.71875 134.425782c-7.644531-30.820313-23.585938-59.238282-46.351562-82.003906-18.4375-18.4375-40.25-32.269532-64.039063-40.9375 28.597656-19.394532 47.425781-52.160157 47.425781-89.238282 0-59.414062-48.339844-107.753906-107.753906-107.753906s-107.753906 48.339844-107.753906 107.753906c0 37.097656 18.84375 69.875 47.464844 89.265625-21.886719 7.976563-42.140626 20.308594-59.566407 36.542969-25.234375 23.5-42.757812 53.464844-50.882812 86.347656-34.410157-39.667968-55.261719-91.398437-55.261719-147.910156 0-124.617188 101.382812-226 226-226s226 101.382812 226 226c0 56.523438-20.859375 108.265625-55.28125 147.933594zm0 0" />
                            </svg>
                            </router-link>
                            
                    </div>
                </div>
                </div>
            </div>
        </div>
    </header>
</template>
<script src="./js/header.js"></script>
<style scoped>
.isHidden {
  background-color: grey;
}
</style>