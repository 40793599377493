<template>
    <div id="live-chat">

        <header class="clearfix">

            <a href="javascript:void(0);" @click="closeChatMessage()" class="chat-close">x</a>

            <h4>{{requesterName}}</h4>

            <!-- <span class="chat-message-counter">3</span> -->

        </header>

        <div class="chat">

            <div class="chat-history" >

                <div class="chat-message clearfix" v-for="(row, index) in conversation" :key="index">

                    <!-- <img src="http://lorempixum.com/32/32/people" alt="" width="32" height="32"> -->

                    <div class="chat-message-content clearfix" v-if="row.requesterID">

                        <span class="chat-time">{{$filters.messageDate(row.createdAt)}}</span>

                        <h5>{{row.requesterName}}</h5>

                        <p class="p-chat-m-0">{{row.productMessage}}.</p>
                    </div> 
                    
                    <div class="chat-message-content clearfix" v-if="row.productOwnerID">

                        <span class="chat-time">{{$filters.messageDate(row.createdAt)}}</span>

                        <h5>{{row.productOwnerName}}</h5>

                        <p class="p-chat-m-0">{{row.productMessage}}</p>
                    </div>

                </div> 

            </div> 

            <form >

                <fieldset class="chat-fieldset">

                    <input type="text" v-model="text" @keyup.enter="replyMessage" placeholder="Enter Your Message..." autofocus>
                    <input type="hidden">

                </fieldset>

            </form>

        </div> <!-- end chat -->

    </div> <!-- end live-chat -->
</template>
<style src="../css/chat.css"></style>
<script src="../js/chat.js"></script>