<template>

   <div data-elementor-type="wp-post" data-elementor-id="57" class="elementor elementor-57"
      data-elementor-settings="[]">
      <div class="elementor-section-wrap">
         <section
            class="elementor-section elementor-top-section elementor-element elementor-element-de45e04 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="de45e04" data-element_type="section">
            <div class="elementor-container elementor-column-gap-default">
               <div
                  class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70803c3"
                  data-id="70803c3" data-element_type="column">
                  <div class="elementor-widget-wrap elementor-element-populated">
                     <div class="elementor-element elementor-element-d6d46ed elementor-widget elementor-widget-lst_menu"
                        data-id="d6d46ed" data-element_type="widget" data-widget_type="lst_menu.default">
                        <div class="elementor-widget-container">
                           <Header />
                        </div>
                     </div>
                     <div
                        class="elementor-element elementor-element-7807579 elementor-widget elementor-widget-lst_template_loader"
                        data-id="7807579" data-element_type="widget" data-widget_type="lst_template_loader.default">
                        <div class="elementor-widget-container">
                           <div data-elementor-type="wp-page" data-elementor-id="1719" class="elementor elementor-1719"
                              data-elementor-settings="[]">
                              <div class="elementor-section-wrap">
                                 <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-167a15d elementor-section-full_width elementor-section-height-default elementor-section-height-default">
                                    <div class="elementor-container elementor-column-gap-default">
                                       <div
                                          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-34829c6"
                                          data-id="34829c6" data-element_type="column">
                                          <div class="elementor-widget-wrap elementor-element-populated">
                                             <div
                                                class="elementor-element elementor-element-a932591 elementor-widget elementor-widget-lst_panel"
                                                data-id="a932591" data-element_type="widget"
                                                data-widget_type="lst_panel.default">
                                                <div class="elementor-widget-container">
                                                   <div class="listivo-app">
                                                      <div>
                                                         <div class="listivo-panel-plain-header">
                                                            <div class="listivo-panel-plain-header__inner">
                                                               <h1 v-if="!preview_confirm_show">Add Listing</h1>
                                                               <h1 v-if="preview_confirm_show">Please review &amp; confirm your listing information.</h1>
                                                            </div>
                                                         </div>
                                                         <div class="listivo-add-listing-wrapper">
                                                            <div class="listivo-add-listing">
                                                               <div v-if="!isLoggedin"
                                                                  class="listivo-add-listing-notice">
                                                                  You can also <router-link to="/login">Log
                                                                     In</router-link>
                                                                  or <router-link to="/register">Register</router-link>
                                                                  first.
                                                               </div>
                                                               <div class="listivo-add-listing-section listivo-add-listing-section--top">
                                                                  <div class="listivo-submit-fields-wrapper">
                                                                     <div class="listivo-submit-fields">
                                                                        <div
                                                                           class="listivo-submit-field listivo-submit-field--name listing-name">
                                                                           <div class="listivo-submit-field-name-inner">
                                                                              <label
                                                                                 class="listivo-submit-field-label-small"
                                                                                 for="name">
                                                                                 Listing Name
                                                                                 <span
                                                                                    class="listivo-required-asterisk">*</span>
                                                                              </label>
                                                                              <div  class="listivo-relative">
                                                                                 <input id="name" v-model="name" name="name"
                                                                                    placeholder="Enter Your Listing Name"
                                                                                    type="text" :readonly="preview_confirm_show"
                                                                                    @keyup="fieldDecorator('listing-name')">
                                                                                 <div v-if="!preview_confirm_show" style="display:none;" @click="fieldDecoratorClear('listing-name')" class="listivo-field__icon listivo-field__icon--clear"> </div>
                                                                                 <div v-if="preview_confirm_show" class="listivo-field__icon listivo-field__icon--clear"> </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                        <div class="listivo-submit-field-multi">
                                                                           <div class="listivo-submit-field">
                                                                              <label for="listivo_14"
                                                                                 class="listivo-submit-field-label-small listivo-submit-field-small-label--required">
                                                                                 Category
                                                                                 <span
                                                                                    class="listivo-required-asterisk">*</span>
                                                                              </label>
                                                                              <div tabindex="0"
                                                                                 class="listivo-select catSelect">
                                                                                 <div v-if="!preview_confirm_show" class="listivo-select__field"
                                                                                    @click="manageCategoryDropdown()">
                                                                                    {{categoryListing}}
                                                                                 </div>
                                                                                 <div v-if="preview_confirm_show" class="listivo-select__field">
                                                                                    {{categoryListing}}
                                                                                 </div>
                                                                                 <div class="listivo-select__dropdown"
                                                                                    style="display:none;">
                                                                                    <!---->
                                                                                    <div
                                                                                       class="listivo-select__options">
                                                                                       <div
                                                                                          class="listivo-select__option" v-for="(cat, index) in categoryList" :key="index">
                                                                                          <div
                                                                                             class="listivo-select__value"
                                                                                             @click="setCategoryChoosen(cat.name,cat.id)">
                                                                                             {{cat.name}}</div>
                                                                                       </div>
                                                                                       
                                                                                    </div>
                                                                                 </div>
                                                                                 <div v-if="!preview_confirm_show" class="listivo-field__icon listivo-field__icon--arrow" @click="manageCategoryDropdown()">  </div>
                                                                                 <div v-if="preview_confirm_show" class="listivo-field__icon listivo-field__icon--arrow">  </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                        
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-add-listing-section listivo-add-listing-section--basic">
                                                                  <div class="listivo-submit-fields-wrapper">
                                                                     <div class="listivo-submit-fields listivo-submit-fields--basic">
                                                                        <div class="listivo-submit-field listivo-submit-field--price listing-price">
                                                                           <label for="listivo_130_listivo_13" class="listivo-submit-field-label-small">
                                                                              Price (USD)
                                                                           </label>
                                                                           <div class="listivo-relative">
                                                                              <input id="listivo_130_listivo_13" :readonly="preview_confirm_show" v-model="price" name="listivo_130_listivo_13" type="text" @keyup="fieldDecorator('listing-price')">
                                                                              <div class="listivo-submit-field-sign"> $ </div >
                                                                              <div v-if="!preview_confirm_show" style="display:none;" class="listivo-field__icon listivo-field__icon--clear" @click="fieldDecoratorClear('listing-price')"></div>
                                                                              <div v-if="preview_confirm_show"  class="listivo-field__icon listivo-field__icon--clear"></div>
                                                                           </div>
                                                                        </div>

                                                                     </div>
                                                                  </div>
                                                               </div>

                                                               <div class="listivo-add-listing-section listivo-add-listing-section--basic">
                                                                  <div class="listivo-submit-fields-wrapper">
                                                                     <div class="listivo-submit-fields">
                                                                        
                                                                        <div class="listivo-submit-field-multi">
                                                                           <div class="listivo-submit-field">
                                                                              <label for="listivo_14"
                                                                                 class="listivo-submit-field-label-small listivo-submit-field-small-label--required">
                                                                                 Featured Pricing
                                                                                 <span
                                                                                    class="listivo-required-asterisk">*</span>
                                                                              </label>
                                                                              <div tabindex="0"
                                                                                 class="listivo-select featSelect">
                                                                                 <div v-if="!preview_confirm_show" class="listivo-select__field"
                                                                                    @click="manageFeaturedDropdown()">
                                                                                    {{featuredListing}}
                                                                                 </div>
                                                                                 <div v-if="preview_confirm_show" class="listivo-select__field">
                                                                                    {{featuredListing}}
                                                                                 </div>
                                                                                 <div class="listivo-select__dropdown"
                                                                                    style="display:none;">
                                                                                    <!---->
                                                                                    <div
                                                                                       class="listivo-select__options">
                                                                                       <div
                                                                                          class="listivo-select__option" v-for="(row, index) in featuredPricingList" :key="index">
                                                                                          <div
                                                                                             class="listivo-select__value"
                                                                                             @click="setFeaturedChoosen(row.featuredName,row.id)">
                                                                                             {{row.featuredName}} (${{row.featuredPrice}})</div>
                                                                                       </div>
                                                                                       
                                                                                    </div>
                                                                                 </div>
                                                                                 <div v-if="!preview_confirm_show" class="listivo-field__icon listivo-field__icon--arrow" @click="manageFeaturedDropdown()"></div>
                                                                                 <div v-if="preview_confirm_show" class="listivo-field__icon listivo-field__icon--arrow"></div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>


                                                               <div
                                                                  class="listivo-add-listing-section listivo-add-listing-section--description">
                                                                  <h3 for="description"
                                                                     class="listivo-submit-field-label-big">
                                                                     Description
                                                                  </h3>
                                                                  <div>
                                                                     <div id="wp-listivo_description-wrap"
                                                                        class="wp-core-ui wp-editor-wrap tmce-active">
                                                                        
                                                                        <div
                                                                           id="wp-listivo_description-editor-container"
                                                                           class="wp-editor-container">
                                                                           <Editor :disabled="preview_confirm_show" api-key="44lamukdf4uvndgqk5nzsrlp6sx2r5yy35eokwtslcnqpmwl"  v-model="description" />
                                                                           <!-- <textarea class="wp-editor-area" rows="20"
                                                                              autocomplete="off" cols="40"
                                                                              name="listivo_description"
                                                                              id="listivo_description"></textarea> -->
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>

                                                               <div class="listivo-add-listing-section listing-video">
                                                                  <div class="listivo-add-video">
                                                                     <h3 for="listivo_345" class="listivo-submit-field-label-big">
                                                                        Video
                                                                     </h3>
                                                                     <div class="listivo-add-video__description">
                                                                        Copy any online video link e.g. YouTube, Facebook, Instagram or .mp4
                                                                     </div>
                                                                     <div class="listivo-add-video__input">
                                                                        <div class="listivo-relative">
                                                                           <input id="listivo_345" :readonly="preview_confirm_show" name="listivo_345" type="text" v-model="videoLink" placeholder="Video Link" @keyup="fieldDecorator('listing-video')">
                                                                           <div v-if="!preview_confirm_show" style="display:none;" class="listivo-field__icon listivo-field__icon--clear" @click="fieldDecoratorClear('listing-video')"> </div>
                                                                           <div v-if="preview_confirm_show" class="listivo-field__icon listivo-field__icon--clear"> </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>


                                                               <div class="listivo-add-listing-section">
                                                                  <div>
                                                                     <h3 class="listivo-submit-field-label-big">
                                                                        Gallery <br>
                                                                        <code style="color: red; font-size: 13px;">Image receomended size is width 1200 and height 600 (1200 X 600)</code>
                                                                     </h3>
                                                                  </div>
                                                                  <div
                                                                     class="listivo-form__section listivo-form__section--dropzone">
                                                                     <div id="listivo_145" style="cursor:pointer;" class="vue-dropzone dropzone dz-clickable" >
                                                                        <div v-if="!preview_confirm_show" @click="openGalleryFileExporer()" 
                                                                           class="dz-default dz-message dz-listing-first">
                                                                           <span ><i class="far fa-images"></i> Add Images</span>
                                                                        </div>
                                                                        
                                                                        <template v-for="(img, index) in uploadedImages" :key="index" >
                                                                           <div class="dz-preview dz-processing dz-image-preview dz-success dz-complete">  
                                                                                 <div class="dz-image">
                                                                                    <img data-dz-thumbnail="" :alt="img.imgData" :src="img.dataUrl+''+img.imgData">
                                                                                 </div>  
                                                                                 <div v-if="!preview_confirm_show" @click="removeUploadedGalleryItem(index,img.gid)" class="listivo-thumble-close-button" style="">X</div>
                                                                           </div>
                                                                        </template>

                                                                        <div v-if="!preview_confirm_show" @click="openGalleryFileExporer()" class="fotter-listing-addmore" style="" >
                                                                           <i class="far fa-images"></i> Add More Images              
                                                                        </div>
                                                                        
                                                                     </div>
                                                                     <div class="listivo-form__gallery__bottom">
                                                                        
                                                                        <div class="listivo-form__gallery__counter">
                                                                           {{uploadedImages.length}} / 20 
                                                                        </div>
                                                                     </div>
                                                                     <input @change="listingGalleryUpload" id="images-upload" ref="file" type="file" accept="image/*" multiple hidden>
                                                                  </div>
                                                               </div>

                                                               <div class="listivo-add-listing-section">
                                                                  <div>
                                                                     <h3 class="listivo-submit-field-label-big">
                                                                        Attachments
                                                                     </h3>
                                                                  </div>
                                                                  <div
                                                                     class="listivo-form__section listivo-form__section--dropzone listivo-form__section--attachments">
                                                                     <div id="listivo_344" class="vue-dropzone dropzone dz-clickable">
                                                                        <!---->
                                                                        <div v-if="!preview_confirm_show"  class="dz-default dz-message dz-listing-attachment-first"  @click="openAttachmentFileExporer()" >
                                                                              <span><i class="fas fa-paperclip"></i> Add Attachments</span> 
                                                                        </div>

                                                                        <template v-for="(img, index) in uploadedAttachment" :key="index" >
                                                                           <div class="dz-preview dz-processing dz-image-preview dz-success dz-complete">  
                                                                                 <div class="dz-image">
                                                                                    <img data-dz-thumbnail="" :alt="img.imgData" :src="img.dataUrl+''+img.imgData">
                                                                                 </div>  
                                                                                 <div v-if="!preview_confirm_show" @click="removeUploadedAttachmentItem(index,img.gid)" class="listivo-thumble-close-button" style="">X</div>
                                                                           </div>
                                                                        </template>

                                                                        <div v-if="!preview_confirm_show" @click="openAttachmentFileExporer()" class="fotter-listing-attachment-addmore" style="" >
                                                                           <i class="far fa-images"></i> Add More Attachment              
                                                                        </div>
                                                                     </div>
                                                                     <div class="listivo-form__gallery__bottom">
                                                                        <!---->
                                                                        <div class="listivo-form__gallery__counter">
                                                                           {{uploadedAttachment.length}} / 10 </div>
                                                                     </div>
                                                                     <input @change="listingAttachmentUpload" id="attachment-upload"  type="file" multiple hidden>
                                                                  </div>
                                                               </div>

                                                               <div class="listivo-add-listing-section listivo-add-listing-section--top">
                                                                  <div class="listivo-submit-fields-wrapper">
                                                                     <div class="listivo-submit-fields">
                                                                        <div
                                                                           class="listivo-submit-field listivo-submit-field--name listing-name">
                                                                           <div class="listivo-submit-field-name-inner">
                                                                              <label
                                                                                 class="listivo-submit-field-label-small"
                                                                                 for="name">
                                                                                 Contact Information for listing
                                                                              </label>
                                                                           </div>
                                                                        </div>
                                                                        <div class="listivo-submit-field-multi">
                                                                           <div class="listivo-submit-field listing-contact-name">
                                                                              <label for="neutrix_contact_person_name" class="listivo-submit-field-label-small">
                                                                                 Full Name
                                                                              </label>
                                                                              <div class="listivo-relative ">
                                                                                 
                                                                                 <input id="neutrix_contact_person_name" :readonly="preview_confirm_show" v-model="contact_name" name="neutrix_contact_person_name" type="text" @keyup="fieldDecorator('listing-contact-name')">
                                                                                 
                                                                                 <div v-if="!preview_confirm_show" style="display:none;" class="listivo-field__icon listivo-field__icon--clear" @click="fieldDecoratorClear('listing-contact-name')"></div>
                                                                                 <div v-if="preview_confirm_show"  class="listivo-field__icon listivo-field__icon--clear"></div>
                                                                              </div>
                                                                           </div>
                                                                           <div class="listivo-submit-fields">
                                                                        
                                                                                 <div class="listivo-submit-field-multi">
                                                                                    <div class="listivo-submit-field listing-contact-number">
                                                                                       <label for="neutrix_contact_person_phone" class="listivo-submit-field-label-small">
                                                                                          Contact Number
                                                                                       </label>
                                                                                       <div class="listivo-relative ">
                                                                                          
                                                                                          <input id="neutrix_contact_person_phone" :readonly="preview_confirm_show" v-model="contact_number" name="neutrix_contact_person_phone" type="text" @keyup="fieldDecorator('listing-contact-number')">
                                                                                          
                                                                                          <div v-if="!preview_confirm_show" style="display:none;" class="listivo-field__icon listivo-field__icon--clear" @click="fieldDecoratorClear('listing-contact-number')"></div>
                                                                                          <div v-if="preview_confirm_show"  class="listivo-field__icon listivo-field__icon--clear"></div>
                                                                                       </div>
                                                                                    </div>
                                                                                 </div>
                                                                           </div>
                                                                        </div>
                                                                        
                                                                        <div class="listivo-submit-field-multi" style="display:block; clear:both;">
                                                                           <div class="listivo-submit-field listing-contact-address" style="width:100%;">
                                                                              <label for="neutrix_contact_person_name" class="listivo-submit-field-label-small">
                                                                                 Contact Listing Address
                                                                              </label>
                                                                              <div class="listivo-relative ">
                                                                                 
                                                                                 <input id="neutrix_contact_person_name" :readonly="preview_confirm_show" v-model="contact_address" name="neutrix_contact_person_name" type="text" @keyup="fieldDecorator('listing-contact-address')">
                                                                                 
                                                                                 <div v-if="!preview_confirm_show" style="display:none;" class="listivo-field__icon listivo-field__icon--clear" @click="fieldDecoratorClear('listing-contact-address')"></div>
                                                                                 <div v-if="preview_confirm_show"  class="listivo-field__icon listivo-field__icon--clear"></div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                        
                                                                        
                                                                        
                                                                     </div>
                                                                  </div>
                                                               </div>

                                                               <div class="listivo-add-listing-section">
                                                                  <h3 class="listivo-submit-field-label-big">
                                                                     Location for Listing
                                                                  </h3>
                                                                  
                                                                  <div class="">
                                                                     <div class="listivo-relative">
                                                                        <div
                                                                           class="listivo-model-form__location__address-field">
                                                                           <input 
                                                                              @input="updateAddressModel"
                                                                              @blur="updateAddressModel"
                                                                              v-model="address"
                                                                              type="text" name="address" :readonly="preview_confirm_show" ref="autocomplete" id="address" placeholder="Search Listing Location on map"
                                                                              class="pac-target-input map-input">
                                                                           <!---->
                                                                        </div>
                                                                     </div>
                                                                     <div class="listivo-checkbox"><input
                                                                           type="checkbox"
                                                                           @click="pickMyLocation()"
                                                                           v-if="!preview_confirm_show"
                                                                           id="listivo-locaton-sync-153"> 
                                                                           <label 
                                                                           v-if="!preview_confirm_show"
                                                                           for="listivo-locaton-sync-153">
                                                                           Pick My Location </label></div>
                                                                           <h5 v-if="!preview_confirm_show">Note: Autocomplete address when marker position is
                                                                           changed</h5>
               
                                                                     <div id="listivo-model-form__map--153"
                                                                        class="listivo-add-listing-section__map"
                                                                        style="position: relative; overflow: hidden;">
                                                                        <input type="hidden" name="latitude" id="address-latitude" :value="center.lat" />
                                                                        <input type="hidden" name="longitude" id="address-longitude" :value="center.lng" />
                                                                        <div
                                                                           style="height: 100%; width: 100%; position: absolute; top: 0px; left: 0px; background-color: rgb(229, 227, 223);">
                                                                           <div class="gm-style">
                                                                              <!-- <GoogleMap :api-key="this.$mapAPI" style="width: 100%; height: 500px" :center="center" :zoom="15" @click="updateCoordinates($event)">
                                                                                 <Marker :draggable="true" :options="{ position: center }" />
                                                                              </GoogleMap> -->
                                                                              <div class="google-map" id="address-map" style="height: 509px; width: 100%;" ref="mapDivRef"></div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>

                                                               <div class="listivo-add-listing-submit-button">
                                                                  <button v-if="preview_confirm_show" @click="editListingAdd" style="margin-right:10px; background: #195781; padding-left: 30px;"
                                                                     class="listivo-primary-button listivo-info-button--icon listivo-primary-button--height-61">
                                                                        <span  class="listivo-info-button__text">  Continue Edit </span> 
                                                                        <span style="background: #143a54;"
                                                                        class="listivo-primary-button__icon"><svg
                                                                           xmlns="http://www.w3.org/2000/svg"
                                                                           fill="none" viewBox="0 0 24 24"
                                                                           stroke="currentColor">
                                                                           <path stroke-linecap="round"
                                                                              stroke-linejoin="round" stroke-width="2"
                                                                              d="M9 5l7 7-7 7"></path>
                                                                        </svg></span>
                                                                     </button>
                                                                     <button @click="saveAddListing"
                                                                     class="listivo-primary-button listivo-primary-button--icon listivo-primary-button--height-61">
                                                                        <span v-if="!preview_confirm_show" class="listivo-primary-button__text">  Add Listing </span> 
                                                                        <span v-if="preview_confirm_show" class="listivo-primary-button__text">  Confirm Save Listing </span> 
                                                                        <span
                                                                        class="listivo-primary-button__icon"><svg
                                                                           xmlns="http://www.w3.org/2000/svg"
                                                                           fill="none" viewBox="0 0 24 24"
                                                                           stroke="currentColor">
                                                                           <path stroke-linecap="round"
                                                                              stroke-linejoin="round" stroke-width="2"
                                                                              d="M9 5l7 7-7 7"></path>
                                                                        </svg></span>
                                                                     </button>
                                                                     
                                                                  </div>



                                                            </div>
                                                         </div>
                                                      </div>










                                                      
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </section>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <Footer />
      </div>
   </div>
</template>
<script src="./js/add-listing.js"></script>
<style scoped>
   .fotter-listing-addmore, .fotter-listing-attachment-addmore{
      display: none;
      clear: both;
      height: 18px;
      position: absolute;
      bottom: 34px;     left: 38px;
   }

   .listivo-thumble-close-button
   {
      background: #fff;
      position: absolute;
      top: 14px;
      right: 14px;
      padding: 10px 14px;
      font-size: 20px;
      border-radius: 4px;
      color: #eb787c;
      font-weight: 900; 
      -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
      box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
   }
   .listivo-thumble-close-button:hover
   {
      background: rgb(220, 218, 218);
      position: absolute;
      top: 14px;
      right: 14px;
      padding: 10px 14px;
      font-size: 20px;
      border-radius: 4px;
      color: #9e1f23;
      font-weight: 900; 
      -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
      box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
   }
</style>