<template>
    <div id="main-message">
        <div id="main-message-nano-wrapper" class="nano">
            <div class="nano-content">
                <ul class="message-list">
                    <li class="unread blue-dot" @click="openChatMessage(row.product_query_messages_messageSequenceID, row.product_query_messages_productId, row.product_query_messages_requesterName)" v-for="(row, index) in messageList" :key="index" :class="index!=0 ? 'mt-15-message':''">
                        <div class="col col-1">
                            <span class="dot"></span>
                            <p class="title">{{row.product_query_messages_requesterName}} 
                                ({{row.product_query_messages_productName?row.product_query_messages_productName:'No Product Name'}})</p>
                            <span class="star-toggle glyphicon glyphicon-star-empty"></span>
                        </div>
                        <div class="col col-2">
                            <div class="subject"> <span class="teaser">{{row.product_query_messages_productMessage}}</span></div>
                            <div class="date">{{$filters.messageDate(row.product_query_messages_createdAt)}}</div>
                        </div>
                    </li>
                    

                </ul>
            </div>
            
        </div>
    </div>
    <ChatMessage :conversation="conversation" :requesterName="requesterName" :seqID="seqID" :prodID="prodID" @conversationChanged="updateConversation" />
</template>
<style src="../css/message.css"></style>
<script src="../js/message.js"></script>