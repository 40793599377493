import testimonials from "../include/Testimonials";
import Header from "../../../include/Header";
import Footer from "../../../include/Footer";
import { authHeader } from "../../../../auth";
import Subscribe from "../../../include/Subscribe";
import $ from 'jquery';
import YouTube from 'vue3-youtube';

export default {
    name: "Home",
    title: "Home",
    components: {
        Header,
        Footer,
        testimonials,
        Subscribe,
        YouTube
    },
    data() {
        return {
            homeDataList: [],
            homePageCmData: [],
            footerSubscriber: {},
            sliderData: {},
            sliderProductCategoryData: {},
            homeBlogData: [],
            homeTestimonyData: [],
            imagePath: this.$imageURL + "site/",
            blogImagePath: this.$imageURL + "blog/",
            sliderImagePath: this.$imageURL + "home/",
            featuredImagePath: this.$imageURL + "product/",
            address: null,
            keyword: null,
            featureCategories: [],
            featureProductData: [],
            aboutTeamCms: {videoLink:''},
            aboutTeamCms_videoLink:""
        }
    },
    async created() {
        await this.getHomePageData();
    },

    mounted() {
        // this.getSiteSettings();
    },
    beforeCreate: function() {
        let style = document.createElement("link");
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = "/asset/wp-content/uploads/elementor/css/custom.css";
        document.head.appendChild(style);

        let style2 = document.createElement("link");
        style2.type = "text/css";
        style2.rel = "stylesheet";
        style2.href = "/asset/wp-content/uploads/elementor/css/post-5039.css";
        document.head.appendChild(style2);

        let style3 = document.createElement('link');
        style3.type = "text/css";
        style3.rel = "stylesheet";
        style3.href = '/asset/wp-content/uploads/elementor/css/post-471.css';
        document.head.appendChild(style3);
    },
    methods: {
        getSiteSettings: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.siteSettings.siteSettings,

            };
            await this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.footerSubscriber = response.data.footerSubscriber;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
        getHomePageData: async function() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.homePage.getHomePage,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.homeDataList = response.data;
                        this.homePageCmData = response.data.homePageCmData;
                        this.homeBlogData = response.data.homeBlogData;
                        this.sliderData = response.data.sliderData[0];
                        this.sliderProductCategoryData = response.data.sliderProductCategoryData;
                        this.featureCategories = response.data.featureCategory;
                        this.featureProductData = response.data.homeProductData;

                        this.aboutTeamCms = response.data.videoCMS;

                        // var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                        // var match = this.aboutTeamCms.videoLink.match(regExp);
                        // const urlIDYoutube = (match&&match[7].length==11)? match[7] : false;
                        const urlIDYoutube = this.youtube_parser(this.aboutTeamCms.videoLink);
                        this.aboutTeamCms_videoLink=urlIDYoutube;
                        console.log('this.aboutContentCms.videoLink =',this.aboutTeamCms_videoLink);
                        $('html,body').animate({ scrollTop: 0 }, 'slow');

                        console.log("homeDataList",this.homePageCmData);

                        localStorage.setItem("homePageCmData",JSON.stringify(this.homePageCmData))
                    }

                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error)
            }
        },
        pickMyLocation: function() {
            const success = (position) => {
                const latitude  = position.coords.latitude;
                const longitude = position.coords.longitude;
                if(latitude && longitude){
                    this.center = {
                        lat: latitude,
                        lng: longitude
                    }
                    
                }
                this.getStreetAddressFrom(this.center.lat, this.center.lng);
    
                // Do something with the position
            };
    
            const error = (error) => {
                console.log(error);
            };
    
            // This will open permission popup
            navigator.geolocation.getCurrentPosition(success, error);
            
        },
        async getStreetAddressFrom(lat, long) {
            try {
               var {
                  data
               } = await this.$axios.get(
                  "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                  lat +
                  "," +
                  long +
                  "&key=AIzaSyBhk7osZH33ThTArtnwC2Yg4UAU7qFlWfA"
               );
               if (data.error_message) {
                  console.log(data.error_message)
               } else {
                  this.address = data.results[0].formatted_address;
                  console.log("Address is: ", this.address);
               }
            } catch (error) {
               console.log(error.message);
            }
         },
        searchProduct: function() {
            this.$router.push({
                name: 'Product',
                query: {
                    search: this.keyword,
                    location: this.address
                }
            })
        },
        youtube_parser: function(url){
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            var match = url.match(regExp);
            return (match&&match[7].length==11)? match[7] : false;
        },
    }
};