<template>
    <div data-elementor-type="wp-post" data-elementor-id="57" class="elementor elementor-57"
        data-elementor-settings="[]">
        <div class="elementor-section-wrap">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-de45e04 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                data-id="de45e04" data-element_type="section">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70803c3"
                        data-id="70803c3" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-d6d46ed elementor-widget elementor-widget-lst_menu"
                                data-id="d6d46ed" data-element_type="widget" data-widget_type="lst_menu.default">
                                <div class="elementor-widget-container">
                                    <Header />
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-7807579 elementor-widget elementor-widget-lst_template_loader"
                                data-id="7807579" data-element_type="widget"
                                data-widget_type="lst_template_loader.default">
                                <div class="elementor-widget-container">
                                    <div data-elementor-type="wp-post" data-elementor-id="868"
                                        class="elementor elementor-868" data-elementor-settings="[]">
                                        <div class="elementor-section-wrap">
                                            <section
                                                class="elementor-section elementor-top-section elementor-element elementor-element-2890862 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                data-id="2890862" data-element_type="section"
                                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-fe037f7"
                                                        data-id="fe037f7" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <section
                                                                class="elementor-section elementor-inner-section elementor-element elementor-element-f8debaa elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                                data-id="f8debaa" data-element_type="section">
                                                                <div
                                                                    class="elementor-container elementor-column-gap-default">
                                                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-590866b listivo-col-180px-width-desktop"
                                                                        data-id="590866b" data-element_type="column">
                                                                        <div
                                                                            class="elementor-widget-wrap elementor-element-populated">
                                                                            <div class="elementor-element elementor-element-8014961 elementor-widget__width-auto elementor-widget elementor-widget-lst_user_image"
                                                                                data-id="8014961"
                                                                                data-element_type="widget"
                                                                                data-widget_type="lst_user_image.default">
                                                                                <div class="elementor-widget-container">
                                                                                    <div
                                                                                        class="listivo-user-image listivo-user-image-control-size">
                                                                                        <img v-if="userProfile.profileImage"
                                                                                            v-bind:src="imagePath + userProfile.profileImage" />
                                                                                        <img v-else
                                                                                            src="../../../../assets/default-user.png" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d6cad4e"
                                                                        data-id="d6cad4e" data-element_type="column">
                                                                        <div
                                                                            class="elementor-widget-wrap elementor-element-populated">
                                                                            <div class="elementor-element elementor-element-b5c95ee listivo-font-m elementor-widget elementor-widget-lst_user_name"
                                                                                data-id="b5c95ee"
                                                                                data-element_type="widget"
                                                                                data-widget_type="lst_user_name.default">
                                                                                <div class="elementor-widget-container">
                                                                                    <div class="listivo-user__name">
                                                                                        {{ userInfoData.name }}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="elementor-element elementor-element-7cc70a6 elementor-widget elementor-widget-lst_user_registered_since"
                                                                                data-id="7cc70a6"
                                                                                data-element_type="widget"
                                                                                data-widget_type="lst_user_registered_since.default">
                                                                                <div class="elementor-widget-container">
                                                                                    <div class="listivo-user-date">
                                                                                        Member since {{ memberSince}} months
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="elementor-element elementor-element-11c7e80 elementor-widget elementor-widget-lst_user_address"
                                                                                data-id="11c7e80"
                                                                                data-element_type="widget"
                                                                                data-widget_type="lst_user_address.default">
                                                                                <div class="elementor-widget-container" v-if="userProfile.address">
                                                                                    <div
                                                                                        class="listivo-address listivo-address--with-icon">
                                                                                        <div
                                                                                            class="listivo-address__inner">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                fill="none"
                                                                                                viewBox="0 0 24 24"
                                                                                                stroke="currentColor">
                                                                                                <path
                                                                                                    stroke-linecap="round"
                                                                                                    stroke-linejoin="round"
                                                                                                    stroke-width="2"
                                                                                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                                                                                <path
                                                                                                    stroke-linecap="round"
                                                                                                    stroke-linejoin="round"
                                                                                                    stroke-width="2"
                                                                                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                                            </svg>
                                                                                            <span>{{userProfile.address}}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="elementor-element elementor-element-44d4ca7 elementor-widget elementor-widget-lst_user_socials"
                                                                                data-id="44d4ca7"
                                                                                data-element_type="widget"
                                                                                data-widget_type="lst_user_socials.default">
                                                                                <div class="elementor-widget-container">
                                                                                    <div
                                                                                        class="listivo-social-profiles listivo-social-profiles--user">
                                                                                        <a :href="userProfile.facebookLink" v-if="userProfile.facebookLink"
                                                                                            class="listivo-social-profiles__single">
                                                                                            <i
                                                                                                class="fab fa-facebook-f"></i>
                                                                                        </a>
                                                                                        <a :href="userProfile.twitterLink" v-if="userProfile.twitterLink"
                                                                                            class="listivo-social-profiles__single">
                                                                                            <i
                                                                                                class="fab fa-twitter"></i>
                                                                                        </a>
                                                                                        <a :href="userProfile.instagramLink" v-if="userProfile.instagramLink"
                                                                                            class="listivo-social-profiles__single">
                                                                                            <i
                                                                                                class="fab fa-instagram"></i>
                                                                                        </a>
                                                                                        <a :href="userProfile.youtubeLink" v-if="userProfile.youtubeLink"
                                                                                            class="listivo-social-profiles__single">
                                                                                            <i
                                                                                                class="fab fa-youtube"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                            <section
                                                                class="elementor-section elementor-inner-section elementor-element elementor-element-16cb534 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                                data-id="16cb534" data-element_type="section">
                                                                <div
                                                                    class="elementor-container elementor-column-gap-default">
                                                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-406cad3"
                                                                        data-id="406cad3" data-element_type="column">
                                                                        <div
                                                                            class="elementor-widget-wrap elementor-element-populated">
                                                                            <div class="elementor-element elementor-element-c8c7a98 elementor-widget__width-inherit listivo-user-phone-whatsapp-buttons elementor-widget-mobile__width-inherit elementor-widget elementor-widget-lst_user_hidden_phone"
                                                                                data-id="c8c7a98"
                                                                                data-element_type="widget"
                                                                                data-widget_type="lst_user_hidden_phone.default">
                                                                                <div class="elementor-widget-container">
                                                                                    <div
                                                                                        class="listivo-app listivo-user__hidden-phone listivo-user__hidden-phone--v2">

                                                                                        <div>
                                                                                            <button
                                                                                                class="listivo-phone-hidden">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                    width="22"
                                                                                                    height="35"
                                                                                                    viewBox="0 0 22 35">
                                                                                                    <g>
                                                                                                        <g>
                                                                                                            <path
                                                                                                                fill="#0b4355"
                                                                                                                d="M18.972-.002c.397 0 .782.081 1.157.24.374.16.694.376.958.65.265.273.485.605.661.991.176.388.242.786.199 1.197l.066 28.849a5.54 5.54 0 0 1-.265 1.196 2.31 2.31 0 0 1-.66.992 4.256 4.256 0 0 1-.96.65c-.33.159-.738.238-1.222.238H3.107c-.485 0-.892-.08-1.223-.239a4.23 4.23 0 0 1-.958-.65 2.298 2.298 0 0 1-.661-.99A5.466 5.466 0 0 1 0 31.924l.066-28.85c-.044-.41.022-.808.199-1.196.175-.386.396-.718.66-.991.265-.274.584-.49.96-.65.373-.159.76-.24 1.156-.24zM3.041 2.05a1.23 1.23 0 0 0-.397.068.535.535 0 0 0-.297.24 3.035 3.035 0 0 1-.231.341.386.386 0 0 0-.066.377l-.067 3.076H20.03l-.066-3.076a.39.39 0 0 0-.066-.377 2.904 2.904 0 0 1-.232-.342.534.534 0 0 0-.297-.24 1.231 1.231 0 0 0-.397-.067zM20.03 8.203H1.983l.067 16.475h17.914zM18.906 32.95c.22 0 .374-.023.463-.068.088-.046.187-.125.297-.239a1.05 1.05 0 0 0 .232-.342c.043-.113.087-.24.132-.376l-.066-5.127H2.05l-.067 5.127c.044.137.088.263.133.376.043.114.12.228.23.342.11.114.21.193.298.24.088.044.242.067.463.067zm-6.875-3.076a3.074 3.074 0 0 0-.099.376.534.534 0 0 1-.198.307c-.11.093-.221.171-.33.24a.81.81 0 0 1-.43.102.974.974 0 0 1-.364-.102.784.784 0 0 1-.33-.24 3.489 3.489 0 0 1-.199-.307.444.444 0 0 1-.033-.376.533.533 0 0 1 .033-.41c.066-.137.132-.25.198-.342.067-.09.176-.159.33-.205.155-.045.276-.068.365-.068.175 0 .319.023.43.068.109.046.22.114.33.205.11.092.175.205.198.342.022.137.055.273.1.41z" />
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </svg>
                                                                                                <span
                                                                                                    class="listivo-phone-hidden__col">
                                                                                                    <span v-if="!reveal"
                                                                                                        class="listivo-phone-hidden__placeholder">{{userInfoData.phone.slice(0, 4)}}
                                                                                                        *** ***</span>
                                                                                                    <span v-else
                                                                                                        class="listivo-phone-hidden__placeholder">{{userInfoData.phone}}</span>
                                                                                                    <span
                                                                                                        class="listivo-phone-hidden__reveal"
                                                                                                        @click="revealPhone"><span>(</span><span>Reveal</span><span>)</span></span>
                                                                                                </span>
                                                                                            </button>

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-bde2a7d"
                                                                        data-id="bde2a7d" data-element_type="column">
                                                                        <div
                                                                            class="elementor-widget-wrap elementor-element-populated">
                                                                            <div class="elementor-element elementor-element-36d40c9 elementor-widget__width-inherit elementor-widget-mobile__width-inherit elementor-widget elementor-widget-lst_user_whats_app"
                                                                                data-id="36d40c9"
                                                                                data-element_type="widget"
                                                                                data-widget_type="lst_user_whats_app.default">
                                                                                <div class="elementor-widget-container">
                                                                                    <div
                                                                                        class="listivo-whatsapp listivo-whatsapp--v2">
                                                                                        <a target="_blank"
                                                                                            :href="'https://api.whatsapp.com/send?phone=' + userInfoData.phone">
                                                                                            <i
                                                                                                class="fab fa-whatsapp"></i>
                                                                                            <span
                                                                                                class="listivo-whatsapp__texts">
                                                                                                <span
                                                                                                    class="listivo-whatsapp__text-chat-via">
                                                                                                    Chat via </span>
                                                                                                <span
                                                                                                    class="listivo-whatsapp__text-whatsapp">
                                                                                                    <strong>WhatsApp</strong>
                                                                                                </span>
                                                                                            </span>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                            <div class="elementor-element elementor-element-bb54b63 elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-lst_user_description"
                                                                data-id="bb54b63" data-element_type="widget"
                                                                data-widget_type="lst_user_description.default">
                                                                <div class="elementor-widget-container">
                                                                    <div class="listivo-user-description"
                                                                        v-html="userProfile.profileDescription">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3852c3d"
                                         data-id="3852c3d" data-element_type="column">
                                         <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-0451205 elementor-widget elementor-widget-heading"
                                               data-id="0451205" data-element_type="widget"
                                               data-widget_type="heading.default">
                                               <div class="elementor-widget-container">
                                                  <h2 class="elementor-heading-title elementor-size-small" style="color:#000">
                                                     Send a Message
                                                  </h2>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-1b7170d elementor-widget elementor-widget-lst_shape"
                                               data-id="1b7170d" data-element_type="widget"
                                               data-widget_type="lst_shape.default">
                                               <div class="elementor-widget-container">
                                                  <div class="listivo-shape">
                                                     <div class="listivo-shape-line">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                           viewBox="0 0 913.000000 42.000000"
                                                           preserveAspectRatio="xMidYMid meet">
                                                           <g transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                                              stroke="none">
                                                              <path
                                                                 d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z">
                                                              </path>
                                                           </g>
                                                        </svg>
                                                     </div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-d1447a9 elementor-widget-tablet__width-inherit elementor-widget elementor-widget-spacer"
                                               data-id="d1447a9" data-element_type="widget"
                                               data-widget_type="spacer.default">
                                               <div class="elementor-widget-container">
                                                  <div class="elementor-spacer">
                                                     <div class="elementor-spacer-inner"></div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div class="elementor-element elementor-element-ec4d4d3 elementor-widget elementor-widget-lst_contact_user"
                                               data-id="ec4d4d3" data-element_type="widget"
                                               data-widget_type="lst_contact_user.default">
                                               <div class="elementor-widget-container">
                                                  <div class="listivo-app">

                                                        <form @submit.prevent="sendMessage">
                                                           <div class="listivo-chat-send-form">
                                                              <textarea v-model="message" placeholder="Write your message here"></textarea>
                                                              <button
                                                                 class="listivo-primary-button listivo-primary-button--full-width listivo-primary-button--icon listivo-primary-button--height-61"
                                                                 >
                                                              <span
                                                                 class="listivo-primary-button__text">
                                                              Send </span>
                                                              <span
                                                                 class="listivo-primary-button__icon">
                                                              <i
                                                                 class="fas fa-paper-plane"></i>
                                                              </span>
                                                              </button>
                                                           </div>
                                                        </form>
                                                  </div>
                                               </div>
                                            </div>
                                         </div>
                                      </div> -->
                                                </div>
                                            </section>
                                            <section
                                                class="elementor-section elementor-top-section elementor-element elementor-element-4d68fad elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                data-id="4d68fad" data-element_type="section">
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1443e2b"
                                                        data-id="1443e2b" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-eca13b7 elementor-widget elementor-widget-lst_user_listings"
                                                                data-id="eca13b7" data-element_type="widget"
                                                                data-widget_type="lst_user_listings.default">
                                                                <div class="elementor-widget-container">
                                                                    <div class="listivo-app listivo-results-user">
                                                                        <div class="listivo-results-bar">
                                                                            <div class="listivo-results-bar__count">
                                                                                <div
                                                                                    class="listivo-results-bar__count-inner">
                                                                                    <div
                                                                                        class="listivo-results-bar__count-big">
                                                                                        User Listings
                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                            viewBox="0 0 913.000000 42.000000"
                                                                                            preserveAspectRatio="xMidYMid meet">
                                                                                            <g transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                                                                                stroke="none">
                                                                                                <path d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4
                                                                          -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124
                                                                          -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22
                                                                          -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640
                                                                          -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9
                                                                          -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10
                                                                          17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330
                                                                          16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931
                                                                          14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271
                                                                          1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14
                                                                          -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106
                                                                          15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10
                                                                          -162 10 -133 -1 -165 -4 -176 -16z" />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="listivo-cards-list" v-if="products.length > 0">
                                                                            <div class="listivo-cards-list__inner">
                                                                                <div class="listivo-listing-card listivo-listing-no-user listivo-listing-featured" v-for="(row, index) in products" :key="index"
                                                                                    data-id="3804">
                                                                                    <div
                                                                                        class="listivo-listing-card__inner">
                                                                                        <a class="listivo-listing-card__link"
                                                                        href="javascript:void(0)" @click="$router.push({
                                                                     name: 'SingleProduct',
                                                                     params: {id: row.id}})"></a>
                                                                     <!-- <a :href="'product/details/' + row['id']" class="listivo-listing-card__link"></a>  -->
                                                                     <div>
                                                                        <div class="listivo-listing-card__top"
                                                                           v-if="row.photos.length > 0">
                                                                           <img
                                                                              :data-srcset="$imageURL + 'product/'+ row.photos[0].image + ' 720w, ' + $imageURL + 'product/' + row.photos[0].image +  ' 360w'"
                                                                              data-sizes="auto"
                                                                              class="listivo-listing-card__preview lazyautosizes lazyloaded"
                                                                              sizes="358px"
                                                                              :srcset="$imageURL + 'product/' + row.photos[0].image +  ' 720w, ' + $imageURL + 'product/' + row.photos[0].image +  ' 360w'">
                                                                           <div class="listivo-listing-card__labels">
                                                                              <div class="listivo-listing-card__label" v-if="row.isFeatured">
                                                                                 Featured
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                        <div class="listivo-listing-card__top" v-else>
                                                                           <img data-srcset="../../../../assets/default.jpg 720w, ../../../../assets/default.jpg 360w"
                                                                           data-sizes="auto"
                                                                           class="listivo-listing-card__preview lazyautosizes lazyloaded"
                                                                           sizes="358px"
                                                                           srcset="../../../../assets/default.jpg 720w, ../../../../assets/default.jpg 360w"
                                                                           >

                                                                        </div>
                                                                        <div class="listivo-listing-card__main">
                                                                           <div class="listivo-listing-card__main-head">
                                                                              <h3 class="listivo-listing-card__name">
                                                                                 {{row.name}}
                                                                              </h3>
                                                                              <div
                                                                                 class="listivo-listing-card__location">
                                                                                 <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none" viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                    </path>
                                                                                    <path stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                    </path>
                                                                                 </svg>
                                                                                 {{row.location}}
                                                                              </div>
                                                                              <b class="listivo-listing-card__price">
                                                                                 ${{row.price}} </b>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="listivo-listing-card__footer">
                                                                        <div class="listivo-listing-card__features">
                                                                           <ul>
                                                                              <li><span>{{row.category.name}}</span>
                                                                              </li>
                                                                           </ul>
                                                                        </div>
                                                                        <button class="listivo-listing-card__favorite">
                                                                           <svg xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none" viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1.4"
                                                                                 d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z">
                                                                              </path>
                                                                           </svg>
                                                                           <!---->
                                                                        </button>
                                                                     </div>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="listivo-pagination listivo-pagination--listings"
                                                                        v-if="1 > 0">
                                                                        <div class="listivo-pagination-mobile">
                                                                            <MobilePagination :totalPages="totalPages"
                                                                                :perPage="limit" :maxVisibleButtons="5"
                                                                                :currentPage="page"
                                                                                @pagechanged="onPageChange" />
                                                                        </div>
                                                                        <div class="listivo-pagination-desktop">
                                                                            <pagination :totalPages="totalPages"
                                                                                :perPage="limit" :maxVisibleButtons="5"
                                                                                :currentPage="page"
                                                                                @pagechanged="onPageChange" />
                                                                            <!-- </div> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    </div>
</template>
<script src="../js/user-profile.js"></script>
<style>
    .cls-1 {
        fill-rule: evenodd;
    }
    .listivo-pagination-mobile .listivo-pagination-mobile__arrow--left {
    margin-right: 17px;
}
.listivo-pagination-mobile .listivo-pagination-mobile__arrow {
    display: inline-block;
    min-width: 50px;
    line-height: 48px;
    border-radius: var(--radius-rounded);
    height: 50px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid var(--border-color);
}
</style>