import Header from "../../../include/Header";
import Footer from "../../../include/Footer";
export default {
  name: "Reset Password",
  title: "Reset Password ",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      email: null,
      current_password: "",
      password: "",
      confirm_password: "",
      isActive: 0,
      resetFlag: false,
      passToken: null,
    };
  },
  async mounted() {

      this.passToken = this.$route.params.passToken
      let resetFlagStatus = await this.verifyPassToken(this.$route.params.passToken);
      console.log('resetFlagStatus = ', resetFlagStatus);
      // if(!resetFlagStatus) {
      //   this.$swal.fire({
      //       icon: "error",
      //       text: "Invalid reset password link.",
      //   });
      //   this.$router.push('/login');
      // }
  },
  beforeCreate: function () {
    document.body.className =
      "page-template-default page page-id-1587 theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-5228 elementor-page elementor-page-1587";

    let style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href = "asset/wp-content/uploads/elementor/css/post-1587.css";
    document.head.appendChild(style);
  },
  methods: {
    async verifyPassToken(hashPass) {
      let data = {
        hashPass: hashPass,
      };
      try {
        let config = {
          method: "POST",
          data: data,
          url: this.$serverURL + this.$api.auth.verifyForgotPasswordToken,
        };
        await this.$axios(config)
          .then((response) => {
            console.log(response);
            console.log(response.data.data);
            if (response.status == 201 && response.data.data) {
              this.resetFlag = true;
            }
            else
            {
                this.$swal.fire({
                    icon: "error",
                    text: "Invalid reset password link.",
                });
                this.$router.push('/login');
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal.fire({
                icon: "error",
                text: "Something went wrong, please try agian later.",
            });
            this.$router.push('/login');
          });
      } catch (error) {
        console.log(error);
      }
    },
    updatePassword: async function() {
      this.isLoading = false;
      if (this.password == "") {
        this.$swal.fire({
          icon: "error",
          text: "Please enter your new password!",
        });
      } else if (this.confirm_password == "") {
        this.isLoading = false;
        this.$swal.fire({
          icon: "error",
          text: "Please enter your confirm new password!",
        });
      } else if (this.password != this.confirm_password) {
        this.isLoading = false;
        this.$swal.fire({
          icon: "error",
          text: "New Password & Confirm New Password does not match!",
        });
      } else {
        if (this.password.length < 6) {
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            text: "You have to enter at least 6 digit!",
          });
        } else {
          this.isLoading = false;
          let data = {
            passToken: this.passToken,
            password: this.password,
          };
          try {
            let config = {
              method: "POST",
              data: data,
              url: this.$serverURL + this.$api.auth.updatePasswordByVerifiedToken,
            };
            this.$axios(config)
              .then((response) => {
                console.log(response);
                if (response.status == 201 && response.data.data) {

                  this.$swal.fire({
                      icon: "success",
                      text: "Your password updated successfully",
                  });
                  this.$router.push('/login');
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
  },
};
