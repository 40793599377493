import Header from '../../../include/Header';
import Footer from '../../../include/Footer';
import Pagination from '../../paginationResource/Pagination.vue';
import ListingMessage from '../template/message.vue';
import { authHeader } from "../../../../auth";
import ChatMessage from '../template/ChatMessage.vue';
import $ from 'jquery';
export default {
  name: 'My Listing',
  title: 'My Listing',
  components:{
    Header,
    Footer,
    Pagination,
    ListingMessage,
    ChatMessage
  },
  data (){
     return {
            
            messageList: [],
            status: null,
            imagePath: this.$imageURL + 'product/',
            chatStatus: false,
            seqID: null,
            prodID: null,
            conversation: [],
            requesterName: ''
     }
  },
  mounted() {
    $('#live-chat').hide();
    this.getMessageList();
  },
  beforeCreate: function() {
        document.body.className = 'page-template-default page page-id-1719 logged-in theme-listivo listivo-1.0.19 listivo_user woocommerce-js elementor-default elementor-kit-5228 elementor-page elementor-page-1719 e--ua-blink e--ua-chrome e--ua-webkit';

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/asset/wp-content/uploads/elementor/css/post-1719.css';
        document.head.appendChild(style);
    },
    methods: {
     
        getMessageList: async function() {
            
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.message.getListingMessage,
                headers: authHeader()
            }
            await this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.messageList = response.data.cms;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
      },
      getMessageDetails: async function() {
        let config = {
          method: "GET",
          url: this.$serverURL + this.$api.message.getConversation + this.prodID + '/' + this.seqID,
          headers: authHeader()
      }
      await this.$axios(config)
          .then((response) => {
              if (response.status == 200) {
                  this.conversation = response.data.cms;
              }
              this.isLoading = false;
          })
          .catch((error) => {
              console.log(error);
              this.isLoading = false;
              this.$swal.fire({
                  icon: "error",
                  text: error.response.data.message
              });
          });
          console.log("Conversation: ", this.conversation);
      },
      updateConversation: function(conv) {
        console.log("Conversations Are: ", conv);
        this.conversation = conv;
      },
      openChatMessage:function(seqId, productId , requesterName){
        this.seqID = seqId;
        this.prodID = productId;
        this.requesterName = requesterName;
        this.getMessageDetails();
        $('#live-chat').fadeIn(300);
      },
      closeChatMessage:function(){
        $('#live-chat').fadeOut(300);
      },
    }
}