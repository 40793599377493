import Header from "../../../include/Header";
import Footer from "../../../include/Footer";
export default {
    name: "Register",
    title: "Register ",
    components: {
        Header,
        Footer
    },
    data() {
        return {
            name: null,
            email: null,
            password: null,
            phone: null,
        }
    },
    beforeCreate: function() {
        document.body.className =
            "page-template-default page page-id-1587 theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-5228 elementor-page elementor-page-1587";

        let style = document.createElement("link");
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = "asset/wp-content/uploads/elementor/css/post-1587.css";
        document.head.appendChild(style);
    },
    methods: {
        async handleClickSignIn(){
            try {
              const googleUser = await this.$gAuth.signIn();
              if (googleUser) {
                this.isLogin = this.$gAuth.isAuthorized;
                const idToken = googleUser.getId();
                await this.processGoogleLogin(idToken, googleUser);
              }
            //   console.log("googleUser", googleUser);
            //   this.user = googleUser.getBasicProfile().getEmail();
            //   console.log("getId", this.user);
            //   console.log("getBasicProfile", googleUser.getBasicProfile());
            //   console.log("getAuthResponse", googleUser.getAuthResponse());
            //   console.log(
            //     "getAuthResponse",
            //     this.$gAuth.instance.currentUser.get().getAuthResponse()
            //   );
            } catch (error) {
              //on fail do something
              console.error(error);
              return null;
            }
          },
          processGoogleLogin: async function(idToken, googleUser){
                let loggedData = googleUser.getBasicProfile();
                // console.log('ID: ' + loggedData.getId());
                // console.log('Full Name: ' + loggedData.getName());
                // console.log('Given Name: ' + loggedData.getGivenName());
                // console.log('Family Name: ' + loggedData.getFamilyName());
                // console.log('Image URL: ' + loggedData.getImageUrl());
                // console.log('Email: ' + loggedData.getEmail());
                console.log(loggedData);
                let email = loggedData.getEmail();
                let fullName = loggedData.getName();
                let data = { 
                    token: idToken,
                    email: email,
                    name: fullName 
                };
                //return false;
                let config = {
                    method: "POST",
                    data: data,
                    url: this.$serverURL + this.$api.auth.googleLogin,
                };
                this.isLoading = true;
                // this.$api + "api/geek-buyback-social-login"
                await this.$axios(config).then((response) => 
                {
                    if(response.status == 201) {
                        localStorage.setItem(this.$token, response.data.token)
                        localStorage.setItem(this.$id, response.data.user.id)
                        localStorage.setItem(this.$isActive, response.data.user.isActive)
                        localStorage.setItem(this.$name, response.data.user.name)
                        localStorage.setItem(this.$phone, response.data.user.phone)
                        this.$swal.fire({
                            icon: "Success",
                            text: "Successfuly logged in!!",
                        });
                        this.$router.push('/listing')
                    }
                    else {
                        this.isLoading = false
                        this.$swal.fire({
                            icon: "error",
                            text: "Google Login Failed!"
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong! Please try again."
                    });
                });
        },
        navigate: function(path) {
            this.$router.push('/' + path);
        },
        checkValidation: function() {
            if(this.name == null || this.name == '') {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter Your Name",
                });
                return false;
            }
            if(this.email == null || this.email == '') {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter Your Email",
                });
                return false;
            }
            if(this.phone == null || this.phone == '') {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter Your Phone Number",
                });
                return false;
            }
            if(this.password == null || this.password == '') {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter Password",
                });
                return false;
            }
            return true;
        },
        signUp: async function() {
            console.log("ok working in signup");
            let data = {
                name: this.name,
                email: this.email,
                password: this.password,
                phone: this.phone
            }
            try {
                let config = {
                    method: "POST",
                    data: data,
                    url: this.$serverURL + this.$api.auth.signUp,
                };
                if(this.checkValidation()){
                    await this.$axios(config).then((response) => {
                        if (response.status == 201) {
                            this.$swal.fire({
                                icon: "success",
                                text: "Successfully Registered in Gun Buy and Sell!",
                            });
                            this.$router.push('/login');
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                }
                
            } catch (error) {
                console.log(error)
            }
        }
    }
};