<template>
  <div
    data-elementor-type="wp-post"
    data-elementor-id="57"
    class="elementor elementor-57"
    data-elementor-settings="[]"
  >
    <div class="elementor-section-wrap">
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-de45e04 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="de45e04"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70803c3"
            data-id="70803c3"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-d6d46ed elementor-widget elementor-widget-lst_menu"
                data-id="d6d46ed"
                data-element_type="widget"
                data-widget_type="lst_menu.default"
              >
                <div class="elementor-widget-container">
                  <Header />
                </div>
              </div>
              <div
                class="elementor-element elementor-element-7807579 elementor-widget elementor-widget-lst_template_loader"
                data-id="7807579"
                data-element_type="widget"
                data-widget_type="lst_template_loader.default"
              >
                <div class="elementor-widget-container">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="1587"
                    class="elementor elementor-1587"
                    data-elementor-settings="[]"
                  >
                    <div class="elementor-section-wrap">
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-714310b listivo-bg-100-auto elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="714310b"
                        data-element_type="section"
                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}"
                      >
                        <div class="elementor-container elementor-column-gap-default">
                          <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-35aead8"
                            data-id="35aead8"
                            data-element_type="column"
                          >
                            <div class="elementor-widget-wrap elementor-element-populated">
                              <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-48222fe elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="48222fe"
                                data-element_type="section"
                              >
                                <div class="elementor-container elementor-column-gap-default">
                                  <div
                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-160e398"
                                    data-id="160e398"
                                    data-element_type="column"
                                  >
                                    <div class="elementor-widget-wrap elementor-element-populated">
                                      <div
                                        class="elementor-element elementor-element-8743712 elementor-widget elementor-widget-spacer"
                                        data-id="8743712"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="elementor-spacer">
                                            <div class="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-2eec4ad elementor-widget elementor-widget-lst_login_and_register"
                                        data-id="2eec4ad"
                                        data-element_type="widget"
                                        data-widget_type="lst_login_and_register.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="listivo-app">
                                            <div class="listivo-access">
                                              <div>
                                                <div class="listivo-access__tabs">
                                                  <button
                                                    class="listivo-access__tab"
                                                    v-on:click="navigate('login')"
                                                  >Login</button>
                                                  <button
                                                    class="listivo-access__tab  listivo-access__tab--active"
                                                    v-on:click="navigate('register')"
                                                  >Register</button>
                                                </div>

                                                <div>
                                                  <div class="listivo-access__socials">
                                                    <!-- <a
                                                      class="listivo-access__social-button listivo-access__social-button--f"
                                                      href="wp-admin/admin-post.php?action=listivo/socialAuth/facebook"
                                                    >
                                                      <i class="fab fa-facebook"></i>
                                                      <span>
                                                        Continue
                                                        with
                                                        Facebook
                                                      </span>
                                                    </a> -->
                                                    <a
                                                      @click="handleClickSignIn"
                                                      class="listivo-access__social-button listivo-access__social-button--g"
                                                      href="javascript:void(0);"
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        width="24"
                                                        height="24"
                                                      >
                                                        <g
                                                          transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)"
                                                        >
                                                          <path
                                                            fill="#4285F4"
                                                            d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                                                          />
                                                          <path
                                                            fill="#34A853"
                                                            d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                                                          />
                                                          <path
                                                            fill="#FBBC05"
                                                            d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                                                          />
                                                          <path
                                                            fill="#EA4335"
                                                            d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                                                          />
                                                        </g>
                                                      </svg>
                                                      <span>
                                                        Continue
                                                        with
                                                        Google
                                                      </span>
                                                    </a>
                                                  </div>
                                                  <div class="listivo-access__or">
                                                    <div class="listivo-access__or-inner"></div>
                                                  </div>

                                                  <div>
                                                    <form class="listivo-access__form" @submit.prevent="signUp">
                                                      <div class="listivo-access__field">
                                                        <input
                                                          v-model="name"
                                                          type="text"
                                                          class="listivo-access__field__input"
                                                          placeholder="Username*"
                                                        />
                                                      </div>
                                                      <div class="listivo-access__field">
                                                        <input
                                                          v-model="email"
                                                          type="text"
                                                          class="listivo-access__field__input"
                                                          placeholder="Email*"
                                                        />
                                                      </div>
                                                      <div class="listivo-access__field">
                                                        <input
                                                          v-model="phone"
                                                          type="tel"
                                                          class="listivo-access__field__input"
                                                          placeholder="Phone*"
                                                        />
                                                      </div>
                                                      <div class="listivo-access__field">
                                                        <input
                                                          v-model="password"
                                                          class="listivo-access__field__input"
                                                          type="password"
                                                          placeholder="Password*"
                                                        />
                                                      </div>
                                                      <div class="listivo-access__main-button">
                                                        <button
                                                          type="submit"
                                                          class="listivo-primary-button listivo-primary-button--icon"
                                                        >
                                                          <span
                                                            class="listivo-primary-button__text"
                                                          >Register</span>
                                                          <span
                                                            class="listivo-primary-button__icon"
                                                          >
                                                            <i class="fas fa-sign-in-alt"></i>
                                                          </span>
                                                        </button>
                                                      </div>
                                                    </form>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-d688b63 elementor-widget elementor-widget-spacer"
                                        data-id="d688b63"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="elementor-spacer">
                                            <div class="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  </div>
</template>
<style scoped>
  button.listivo-access__tab:nth-of-type(2) {
      background: #0c4355;
      color: #fff;
      border-top-right-radius: 10px;
  }
  button.listivo-access__tab:nth-of-type(2):hover {
      background: #2a5666;
      color: #fff;
      border-top-right-radius: 10px;
  }
  button.listivo-access__tab:nth-of-type(1):hover {
      background: #2a5666;
      color: #fff;
      border-top-left-radius: 10px;
  }
</style>
<script src="../js/Register.js"></script>