import Header from '../../../../include/Header';
import Footer from '../../../../include/Footer';
import $ from 'jquery';
import Editor from '@tinymce/tinymce-vue';
import { v4 as uuidv4 } from "uuid";
// import { GoogleMap, Marker } from "vue3-google-map";
import { authHeader } from "../../../../../auth";
import { onMounted } from "vue";

export default {
    name: 'Add Listing',
    title: 'Add Listing',
    components: {
        Header,
        Footer,
        Editor,
        
    },
    data() {
        return {
            isLoggedin: false,
            categoryListing: "Category",
            media:[],
            loading:false,
            server:"core/upload-product-image",
            guiid: "",
            guiidAttachment: "",
            uploadedImages:[],
            uploadedAttachment:[],
            imgData:{},
            imgNameAxios:"",
            imgPath: this.$imageURL + 'product/',
            address: "",
            myLocationStatus: false,
            name: null,
            categoryId: null,
            price: null,
            description: null,
            videoLink: null,
            creatorID: null,
            requestStatus: null,
            categoryList: [],
            image_file_length: 0,
            attachment_file_length: 0,
            featuredListing: "Not Required",
            featuredPricingList: [],
            featuredPricingId: '',
            center: {
                lat: 42.6264712,
                lng: -83.0609145
            },
            map: null, 
            marker: null, 
            infoWindow: null,
            f_lat:0,
            f_lng:0,
            preview_confirm_show:false,
            contact_name:'',
            contact_number:'',
            contact_address:'',
        }
    },
    props: {
    center: { lat: Number, lng: Number },
    zoom: Number,
    mapType: String,
    disableUI: Boolean
  },
  setup() {

    onMounted(() => {

      const googleMapScript = document.createElement("SCRIPT");
      googleMapScript.setAttribute(
        "src",
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyBAQTQIGNCLnH93PMerongNRnj9S84C1kA&libraries=places&callback=initMap`
      );
      googleMapScript.setAttribute("defer", "");
      googleMapScript.setAttribute("async", "");
      document.head.appendChild(googleMapScript);


    });
    window.initMap = () => {
        $('form').on('keyup keypress', function(e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                e.preventDefault();
                return false;
            }
        });
        const locationInputs = document.getElementsByClassName("map-input");
    
        const autocompletes = [];
        const geocoder = new window.google.maps.Geocoder;
        for (let i = 0; i < locationInputs.length; i++) {
    
            const input = locationInputs[i];
            const fieldKey = input.id.replace("-input", "");
            const isEdit = document.getElementById(fieldKey + "-latitude").value != '' && document.getElementById(fieldKey + "-longitude").value != '';
    
            const latitude = parseFloat(document.getElementById(fieldKey + "-latitude").value) || 42.6264712;
            const longitude = parseFloat(document.getElementById(fieldKey + "-longitude").value) || -83.0609145;
    
            const map = new window.google.maps.Map(document.getElementById(fieldKey + '-map'), {
                center: {lat: latitude, lng: longitude},
                zoom: 13,
                draggable: true,
                styles: [{
                    elementType: 'geometry',
                    stylers: [{
                        color: '#242f3e'
                    }]
                }, {
                    elementType: 'labels.text.stroke',
                    stylers: [{
                        color: '#242f3e'
                    }]
                }, {
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#746855'
                    }]
                }, {
                    featureType: 'administrative.locality',
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#d59563'
                    }]
                }, {
                    featureType: 'poi',
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#d59563'
                    }]
                }, {
                    featureType: 'poi.park',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#263c3f'
                    }]
                }, {
                    featureType: 'poi.park',
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#6b9a76'
                    }]
                }, {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#38414e'
                    }]
                }, {
                    featureType: 'road',
                    elementType: 'geometry.stroke',
                    stylers: [{
                        color: '#212a37'
                    }]
                }, {
                    featureType: 'road',
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#9ca5b3'
                    }]
                }, {
                    featureType: 'road.highway',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#746855'
                    }]
                }, {
                    featureType: 'road.highway',
                    elementType: 'geometry.stroke',
                    stylers: [{
                        color: '#1f2835'
                    }]
                }, {
                    featureType: 'road.highway',
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#f3d19c'
                    }]
                }, {
                    featureType: 'transit',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#2f3948'
                    }]
                }, {
                    featureType: 'transit.station',
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#d59563'
                    }]
                }, {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#17263c'
                    }]
                }, {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#515c6d'
                    }]
                }, {
                    featureType: 'water',
                    elementType: 'labels.text.stroke',
                    stylers: [{
                        color: '#17263c'
                    }]
                }]
            });
            const marker = new window.google.maps.Marker({
                map: map,
                position: {lat: latitude, lng: longitude},
                draggable: true,
            });
    
            marker.setVisible(isEdit);
    
            const autocomplete = new window.google.maps.places.Autocomplete(input);
            autocomplete.key = fieldKey;
            autocompletes.push({input: input, map: map, marker: marker, autocomplete: autocomplete});
        }
    
        for (let i = 0; i < autocompletes.length; i++) {
            const input = autocompletes[i].input;
            const autocomplete = autocompletes[i].autocomplete;
            const map = autocompletes[i].map;
            const marker = autocompletes[i].marker;

            console.log('autocomplete =', autocomplete);
            console.log('input =', input.value);
    
            new window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
                marker.setVisible(false);
                const place = autocomplete.getPlace();
                console.log('place ', place);
                geocoder.geocode({'placeId': place.place_id}, function (results, status) {
                    if (status === window.google.maps.GeocoderStatus.OK) {
                        const lat = results[0].geometry.location.lat();
                        const lng = results[0].geometry.location.lng();
                        console.log('results[0] = ', results[0]);
                        window.setLocationCoordinates(autocomplete.key, lat, lng);
                    }
                });
    
                if (!place.geometry) {
                    window.alert("No details available for input: '" + place.name + "'");
                    input.value = "";
                    return;
                }
    
                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(17);
                }
                marker.setPosition(place.geometry.location);
                marker.setVisible(true);
    
            });
    
            new window.google.maps.event.addListener(marker, 'position_changed', function() {
    
                var lat = marker.getPosition().lat();
            
                var lng = marker.getPosition().lng();
                window.setLocationCoordinates(autocomplete.key, lat, lng);
            
            });
        }
    };
    window.setLocationCoordinates = (key, lat, lng) => {
        const latitudeField = document.getElementById(key + "-" + "latitude");
        const longitudeField = document.getElementById(key + "-" + "longitude");
        latitudeField.value = lat;
        longitudeField.value = lng;
        console.log(lat, lng);
    };

  },
    async mounted() {
        this.isLoggedin = localStorage.getItem('token');
        this.guiid = uuidv4();
        this.guiidAttachment = uuidv4();

        await this.getProfileData()

        //await this.getStreetAddressFrom(this.center.lat, this.center.lng);
        await this.getProductCategory();
        await this.getFeaturedPricing();


    },
    beforeCreate: function () {
        document.body.className =
        'page-template-default page page-id-1719 theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-6 elementor-page elementor-page-1719';

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/asset/wp-content/uploads/elementor/css/post-1719.css';
        document.head.appendChild(style);

        

        
    },
    methods:{
        getProfileData: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.profile.getProfileData,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    
                    console.log(response.data)
                    if(response.data.cms.phoneNumber==null)
                    {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please update your profile.",
                        });

                        this.$router.push("/user/profile");
                    }
                    if(response.data.cms.profileImage==null)
                    {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please update your profile photo.",
                        });

                        this.$router.push("/user/profile");
                    }
                }
                this.isLoading = false;
            }).catch((error) => {
                console.log(error);
                this.isLoading = false;
            }); 
        },
        getProductCategory: function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.products.productCategory,

            };
            this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.categoryList = response.data;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
        getFeaturedPricing: function() {
            let arr = 
                {
                    featuredName:"Not Required",
                    featuredPrice:0,
                };

            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.listing.featuredPricing,

            };
            this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.featuredPricingList = response.data;
                        this.featuredPricingList.push(arr);

                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
        checkValidation: function(){
            if(this.name == null || this.name == '') {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter listing Name",
                });
                return false;
            }
            if(this.categoryId == null) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Select listing Category",
                });
                return false;
            }
            if(this.price == null || this.price == '') {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter listing Price",
                });
                return false;
            }
            
            if(this.description == null || this.description == '') {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter listing Description",
                });
                return false;
            }

            if(this.image_file_length == 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Upload listing images",
                });
                return false;
            }

            if(this.contact_name == 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter listing contact name",
                });
                return false;
            }

            if(this.contact_number == 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter listing contact number",
                });
                return false;
            }
            if(this.contact_address == 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter listing contact address",
                });
                return false;
            }
            return true;

        },
        updateAddressModel: function(){
            console.log($("#address").val())
            this.address = $("#address").val();
        },
        editListingAdd: function(){
            if(this.preview_confirm_show === true)
            {
                this.preview_confirm_show = false;
                $('html,body').animate({ scrollTop: 0 }, 'slow');
                return false;
            }
        },
        saveAddListing: function() {

            if(this.address.length == 0)
            {
                let rawAddress= $("#address").val();
                this.address = rawAddress;
            }

            const fid_let = $("#address-latitude").val();
            const fid_lng = $("#address-longitude").val();

            this.requestStatus = "Pending";
            let data = {
                name: this.name,
                categoryId: this.categoryId,
                featuredPricingId: this.featuredPricingId ? this.featuredPricingId : '',
                price: this.price,
                description: this.description,
                videoLink: this.videoLink,
                location: this.address,
                guiid: this.guiid,
                guiidAttachment: this.guiidAttachment,
                requestStatus: this.requestStatus,
                lat: fid_let,
                lng: fid_lng,
                contact_name: this.contact_name,
                contact_number: this.contact_number,
                contact_address: this.contact_address,
            }
            console.log("data",data);
            let config = {
                method: "POST",
                data: data,
                url: this.$serverURL + this.$api.listing.createSellProductRequest,
                headers: authHeader()

            };

            if(this.preview_confirm_show === false)
            {
                // this.$swal.fire({
                //     icon: "warning",
                //     text: "Please review carefully all the field and submit",
                // });
                this.preview_confirm_show = true;
                $('html,body').animate({ scrollTop: 0 }, 'slow');
                
                return false;


            }



            if (this.checkValidation()){
                this.$axios(config)
                .then((response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully Saved your Info",
                        });
                        this.guiid = uuidv4();
                        this.guiidAttachment = uuidv4();
                        this.$router.push("/listing");
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
            }
        
        },
        openGalleryFileExporer: function(){
            $("#images-upload").trigger('click');
        },
        openAttachmentFileExporer: function(){
            $("#attachment-upload").trigger('click');
        },
        listingGalleryUpload: async function(event){
            let files = event.target.files;
            this.image_file_length = files.length;
            for(var i=0; i < files.length; i++){
                let formData = new FormData
                formData.append('product-image', files[i])
                formData.append('imageGuiid', this.guiid)
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.listing.listingImageUpload,
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                };

                this.$swal.showLoading();
                await this.$axios(config)
                    .then((response) => {
                        if (response.status == 201) {
                            this.imgData={imgData: response.data.image, dataUrl: this.imgPath, gid:response.data.id  }; 
                            this.uploadedImages.push(this.imgData);
                            if(this.uploadedImages.length > 0)
                            {
                                $(".dz-listing-first").hide();
                                $(".fotter-listing-addmore").show();
                            }
                            else
                            {
                                $(".dz-listing-first").show();
                                $(".fotter-listing-addmore").hide();
                            }
                            this.$swal.close();
                            this.$swal.close();
                        }

                        this.$swal.close();
                    })
                    .catch((error) => {
                        console.log('error = ',error);
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, Please try again."
                        });
                    });
                    
                    
            }
        },
        listingAttachmentUpload: async function(event){
            let files = event.target.files;
            this.attachment_file_length = files.length;
            for(var i=0; i < files.length; i++){
                let formData = new FormData
                formData.append('product-image', files[i])
                formData.append('imageGuiid', this.guiidAttachment)
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.listing.listingImageUpload,
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                };

                this.$swal.showLoading();
                await this.$axios(config)
                    .then((response) => {
                        if (response.status == 201) {
                            this.imgData={imgData: response.data.image, dataUrl: this.imgPath, gid:response.data.id  }; 
                            this.uploadedAttachment.push(this.imgData);
                            if(this.uploadedAttachment.length > 0)
                            {
                                $(".dz-listing-attachment-first").hide();
                                $(".fotter-listing-attachment-addmore").show();
                            }
                            else
                            {
                                $(".dz-listing-attachment-first").show();
                                $(".fotter-listing-attachment-addmore").hide();
                            }
                            this.$swal.close();
                            this.$swal.close();
                        }

                        this.$swal.close();
                    })
                    .catch((error) => {
                        console.log('error = ',error);
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, Please try again."
                        });
                    });
                    
                    
            }
        },
        removeUploadedGalleryItem: async function(itemIndex,gid){
                var removeConfirm = window.confirm("Are you sure to delete this image?");
                if(removeConfirm)
                {
                    let config = {
                        method: "DELETE",
                        url: this.$serverURL + this.$api.listing.listingImageDeleteUploaded+gid,
                    };
    
                    this.$swal.showLoading();
                    await this.$axios(config)
                        .then((response) => {
                            if (response.status == 200) {
                                this.uploadedImages.splice(itemIndex, 1);

                                if(this.uploadedImages.length > 0)
                                {
                                    $(".dz-listing-first").hide();
                                    $(".fotter-listing-addmore").show();
                                }
                                else
                                {
                                    $(".dz-listing-first").show();
                                    $(".fotter-listing-addmore").hide();
                                }

                                this.$swal.close();
                                this.$swal.close();
                            }
    
                            this.$swal.close();
                        })
                        .catch((error) => {
                            console.log('error = ',error);
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong, Please try again."
                            });
                        });
                }
                
        },
        removeUploadedAttachmentItem: async function(itemIndex,gid){
                var removeConfirm = window.confirm("Are you sure to delete this image?");
                if(removeConfirm)
                {
                    let config = {
                        method: "DELETE",
                        url: this.$serverURL + this.$api.listing.listingImageDeleteUploaded+gid,
                    };
    
                    this.$swal.showLoading();
                    await this.$axios(config)
                        .then((response) => {
                            if (response.status == 200) {
                                this.uploadedAttachment.splice(itemIndex, 1);

                                if(this.uploadedAttachment.length > 0)
                                {
                                    $(".dz-listing-attachment-first").hide();
                                    $(".fotter-listing-attachment-addmore").show();
                                }
                                else
                                {
                                    $(".dz-listing-attachment-first").show();
                                    $(".fotter-listing-attachment-addmore").hide();
                                }

                                this.$swal.close();
                                this.$swal.close();
                            }
    
                            this.$swal.close();
                        })
                        .catch((error) => {
                            console.log('error = ',error);
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong, Please try again."
                            });
                        });
                }
                
        },
        fieldDecorator:function(fidName)
        {
            if(fidName=="listing-name")
            {
                if($('.'+fidName).children('div').children('div').children('input').val().length > 0)
                {
                    $('.'+fidName).addClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('div').children('div').fadeIn();
                }
                else
                {
                    $('.'+fidName).removeClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('div').children('.listivo-field__icon').hide();
                }
            }
            else if(fidName=="listing-video")
            {
                if($('.'+fidName).children('div').children('div:eq(1)').children('div').children('input').val().length > 0)
                {
                    $('.'+fidName).addClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('div:eq(1)').children('div').children('div').fadeIn();
                }
                else
                {
                    $('.'+fidName).removeClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('div:eq(1)').children('div').children('div').fadeOut();
                }
            }
            else if(fidName=="listing-price")
            {
                if($('.'+fidName).children('div').children('input').val().length > 0)
                {
                    $('.'+fidName).addClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('div:eq(0)').fadeOut();
                    $('.'+fidName).children('div').children('div:eq(1)').fadeIn();
                }
                else
                {
                    $('.'+fidName).removeClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('div:eq(1)').children('.listivo-field__icon').hide();
                    $('.'+fidName).children('div').children('div:eq(0)').fadeIn();
                }
            }
            else if(fidName=="listing-contact-name")
            {
                if($('.'+fidName).children('div').children('input').val().length > 0)
                {
                    $('.'+fidName).addClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('div').fadeIn();
                }
                else
                {
                    $('.'+fidName).removeClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('.listivo-field__icon').hide();
                }
            }
            else if(fidName=="listing-contact-number")
            {
                if($('.'+fidName).children('div').children('input').val().length > 0)
                {
                    $('.'+fidName).addClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('div').fadeIn();
                }
                else
                {
                    $('.'+fidName).removeClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('.listivo-field__icon').hide();
                }
            }
            else if(fidName=="listing-contact-address")
            {
                if($('.'+fidName).children('div').children('input').val().length > 0)
                {
                    $('.'+fidName).addClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('div').fadeIn();
                }
                else
                {
                    $('.'+fidName).removeClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('.listivo-field__icon').hide();
                }
            }
            else
            {
                //listivo-submit-field-active
                // if($('.'+fidName).children('div:eq(0)').children('input').val().length > 0)
                // {
                //     $('.'+fidName).addClass('listivo-field--active');
                //     $('.'+fidName).children('div:eq(0)').children('div').fadeIn();
                // }
                // else
                // {
                //     $('.'+fidName).removeClass('listivo-field--active');
                //     $('.'+fidName).children('div:eq(0)').children('div').fadeOut();
                // }
            }
                
            
        },
        fieldDecoratorClear:function(fidName)
        {
            if(fidName=="listing-name")
            {
                $('.'+fidName).children('div').children('div').children('input').val("");
                $('.'+fidName).removeClass('listivo-submit-field-active');
                $('.'+fidName).children('div').children('div').children('div').fadeOut();
            }
            else if(fidName=="listing-video")
            {
                $('.'+fidName).children('div').children('div:eq(1)').children('div').children('input').val("");
                $('.'+fidName).removeClass('listivo-submit-field-active');
                $('.'+fidName).children('div').children('div:eq(1)').children('div').children('div').fadeOut();
            }
            else if(fidName=="listing-price")
            {
                $('.'+fidName).children('div').children('input').val("");
                $('.'+fidName).removeClass('listivo-submit-field-active');
                $('.'+fidName).children('div').children('div:eq(1)').fadeOut();
            }
            else if(fidName=="listing-contact-name")
            {
                $('.'+fidName).children('div').children('input').val("");
                $('.'+fidName).removeClass('listivo-submit-field-active');
                $('.'+fidName).children('div').children('div').fadeOut();
            }
            else if(fidName=="listing-contact-number")
            {
                $('.'+fidName).children('div').children('input').val("");
                $('.'+fidName).removeClass('listivo-submit-field-active');
                $('.'+fidName).children('div').children('div').fadeOut();
            }
            else if(fidName=="listing-contact-address")
            {
                $('.'+fidName).children('div').children('input').val("");
                $('.'+fidName).removeClass('listivo-submit-field-active');
                $('.'+fidName).children('div').children('div').fadeOut();
            }
            else
            {
                $('.'+fidName).children('div:eq(0)').children('input').val("");
                $('.'+fidName).removeClass('listivo-field--active');
                $('.'+fidName).children('div:eq(0)').children('div').fadeOut();
            }
                
                
            
        },
        manageCategoryDropdown:function(){
            $('.catSelect').toggleClass('listivo-select__field--active');
            $('.catSelect').children('.listivo-select__dropdown').fadeToggle();
            $('.catSelect').children('.listivo-field__icon').removeClass('listivo-field__icon--clear');
            $('.catSelect').children('.listivo-field__icon').addClass('listivo-field__icon--arrow');
        },
        manageFeaturedDropdown:function(){
            $('.featSelect').toggleClass('listivo-select__field--active');
            $('.featSelect').children('.listivo-select__dropdown').fadeToggle();
            $('.featSelect').children('.listivo-field__icon').removeClass('listivo-field__icon--clear');
            $('.featSelect').children('.listivo-field__icon').addClass('listivo-field__icon--arrow');
        },
        setCategoryChoosen:function(searchCat,catID){
            this.categoryListing = searchCat;
            this.categoryId = catID;
            $('.catSelect').addClass('listivo-select__field--active');
            $('.catSelect').children('.listivo-select__dropdown').hide();
            
            if($('.catSelect').children('.listivo-field__icon').hasClass('listivo-field__icon--arrow'))
            {
                $('.catSelect').children('.listivo-field__icon').removeClass('listivo-field__icon--arrow');
            }
            $('.catSelect').children('.listivo-field__icon').addClass('listivo-field__icon--clear');
            $('.catSelect').children('.listivo-select__dropdown').children('.listivo-select__options').children('.listivo-select__option').removeClass('listivo-select__option--active');
        },
        setFeaturedChoosen:function(searchFeat, featId){
            this.featuredListing = searchFeat;
            this.featuredPricingId = featId;
            $('.featSelect').addClass('listivo-select__field--active');
            $('.featSelect').children('.listivo-select__dropdown').hide();
            
            if($('.featSelect').children('.listivo-field__icon').hasClass('listivo-field__icon--arrow'))
            {
                $('.featSelect').children('.listivo-field__icon').removeClass('listivo-field__icon--arrow');
            }
            $('.featSelect').children('.listivo-field__icon').addClass('listivo-field__icon--clear');
            $('.featSelect').children('.listivo-select__dropdown').children('.listivo-select__options').children('.listivo-select__option').removeClass('listivo-select__option--active');
        },
        pickMyLocation: function() {

            this.map = new window.google.maps.Map(document.getElementById('address-map'), {
                center: this.center,
                zoom: 16,
                draggable: true,
                styles: [{
                    elementType: 'geometry',
                    stylers: [{
                        color: '#242f3e'
                    }]
                }, {
                    elementType: 'labels.text.stroke',
                    stylers: [{
                        color: '#242f3e'
                    }]
                }, {
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#746855'
                    }]
                }, {
                    featureType: 'administrative.locality',
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#d59563'
                    }]
                }, {
                    featureType: 'poi',
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#d59563'
                    }]
                }, {
                    featureType: 'poi.park',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#263c3f'
                    }]
                }, {
                    featureType: 'poi.park',
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#6b9a76'
                    }]
                }, {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#38414e'
                    }]
                }, {
                    featureType: 'road',
                    elementType: 'geometry.stroke',
                    stylers: [{
                        color: '#212a37'
                    }]
                }, {
                    featureType: 'road',
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#9ca5b3'
                    }]
                }, {
                    featureType: 'road.highway',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#746855'
                    }]
                }, {
                    featureType: 'road.highway',
                    elementType: 'geometry.stroke',
                    stylers: [{
                        color: '#1f2835'
                    }]
                }, {
                    featureType: 'road.highway',
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#f3d19c'
                    }]
                }, {
                    featureType: 'transit',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#2f3948'
                    }]
                }, {
                    featureType: 'transit.station',
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#d59563'
                    }]
                }, {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [{
                        color: '#17263c'
                    }]
                }, {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [{
                        color: '#515c6d'
                    }]
                }, {
                    featureType: 'water',
                    elementType: 'labels.text.stroke',
                    stylers: [{
                        color: '#17263c'
                    }]
                }]
            });

            this.marker = new window.google.maps.Marker({
                map: this.map,
                position: this.center,
                draggable: true,
            });

            this.marker.setVisible(true);

            this.infoWindow = new window.google.maps.InfoWindow();
        
            // Try HTML5 geolocation.
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };

                    const latitudeField = document.getElementById("address-latitude");
                    const longitudeField = document.getElementById("address-longitude");
                    latitudeField.value = pos.lat;
                    longitudeField.value = pos.lng;

                    
                    this.infoWindow.setPosition(pos);
                    
                    this.map.setCenter(pos);
                    
                    this.marker.setMap(this.map);
                    this.marker.setPosition(pos);

                    var geocoder = new window.google.maps.Geocoder();
                    var latLng = new window.google.maps.LatLng(pos);
                    geocoder.geocode({       
                                        latLng: latLng     
                                    }, 
                                    async function(responses) 
                                    {     
                                        if (responses && responses.length > 0) 
                                        {     
                                            console.log(responses[0].formatted_address);       
                                           $("#address").val(responses[0].formatted_address);

                                        }   
                                    }
                    );

                },
                () => {
                    this.handleLocationError(true, this.infoWindow, this.map.getCenter());
                }
                );

                
            } else {
                // Browser doesn't support Geolocation
                this.handleLocationError(false, this.infoWindow, this.map.getCenter());
            }
        },
        handleLocationError: function(browserHasGeolocation, infoWindow, pos) {
            infoWindow.setPosition(pos);
            infoWindow.setContent(
            browserHasGeolocation
                ? "Error: The Geolocation service failed."
                : "Error: Your browser doesn't support geolocation."
            );
            infoWindow.open(this.map);
        }


    }
}