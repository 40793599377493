import Header from "../../../include/Header";
import Footer from "../../../include/Footer";
import { authHeader } from "../../../../auth";
import $ from 'jquery';

export default {
    name: 'Profile',
    title: 'Profile',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            accountDetailsFlag: true,
            profileImageFlag: false,
            socialLinksFlag: false,
            changePasswordFlag: false,
            profileData: {},
            name: "",
            phone: "",
            description: "",
            address: "",
            facebookLink: "",
            twitterLink: "",
            youtubeLink: "",
            insagramLink: "",
            linkedinLink: "",
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            profileImageDisplay: "",
            photo: "",
            imageShow: null,
            flag: true,
            preview: null
        }
    },
    created() {
        this.getProfileData();
    },
    beforeCreate: function() {
        document.body.className = 'page-template-default page page-id-1719 logged-in theme-listivo listivo-1.0.19 listivo_user woocommerce-js elementor-default elementor-kit-5228 elementor-page elementor-page-1719 e--ua-blink e--ua-chrome e--ua-webkit';
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/asset/wp-content/uploads/elementor/css/post-1719.css';
        document.head.appendChild(style);
    },
    methods: {
        accountDetails: function() {
            this.accountDetailsFlag = true;
            this.profileImageFlag = false;
            this.socialLinksFlag = false;
            this.changePasswordFlag = false;
            $('.listivo-panel__menu').children('.listivo-panel__menu-pill').removeClass('listivo-panel__menu-pill--active');
            $('.listivo-panel__menu').children('#accountDetails').addClass('listivo-panel__menu-pill--active');
        },

        socialLinks: function() {
            this.socialLinksFlag = true;
            this.accountDetailsFlag = false;
            this.profileImageFlag = false;
            this.changePasswordFlag = false;
            $('.listivo-panel__menu').children('.listivo-panel__menu-pill').removeClass('listivo-panel__menu-pill--active');
            $('.listivo-panel__menu').children('#socialLinks').addClass('listivo-panel__menu-pill--active');
        },

        profileImage: function() {
            this.socialLinksFlag = false;
            this.accountDetailsFlag = false;
            this.profileImageFlag = true;
            this.changePasswordFlag = false;
            $('.listivo-panel__menu').children('.listivo-panel__menu-pill').removeClass('listivo-panel__menu-pill--active');
            $('.listivo-panel__menu').children('#profileImage').addClass('listivo-panel__menu-pill--active');
        },

        changePassword: function() {
            this.socialLinksFlag = false;
            this.accountDetailsFlag = false;
            this.profileImageFlag = false;
            this.changePasswordFlag = true;
            $('.listivo-panel__menu').children('.listivo-panel__menu-pill').removeClass('listivo-panel__menu-pill--active');
            $('.listivo-panel__menu').children('#changePassword').addClass('listivo-panel__menu-pill--active');
        },
        getProfileData: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.profile.getProfileData,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.profileData = response.data.cms;
                    this.name = this.profileData.displayName;
                    this.phone = this.profileData.phoneNumber;
                    this.description = this.profileData.profileDescription;
                    this.address = this.profileData.address;
                    this.facebookLink = this.profileData.facebookLink;
                    this.twitterLink = this.profileData.twitterLink;
                    this.insagramLink = this.profileData.insagramLink;
                    this.youtubeLink = this.profileData.youtubeLink;
                    this.linkedinLink = this.profileData.linkedinLink;
                    this.profileImageDisplay = this.profileData.profileImage;
                }
                this.isLoading = false;
            }).catch((error) => {
                console.log(error);
                this.isLoading = false;
            }); 
        },
        onProfileImageChange(event) {
            this.photo = event.target.files[0];
            const file = event.target.files[0];
            this.imageShow = URL.createObjectURL(file);
            this.flag = true;

            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = function(e) {
                    console.log("e.target.result",e.target.result);
                    $('#imagePreview').css('background-image', 'url('+e.target.result +')');
                }
                reader.readAsDataURL(input.files[0]);
            }
        },
        updateProfile: function() {

            if(this.phone.length == 0)
            {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter your phone number",
                });
                return false;
            }

            if(this.address.length == 0)
            {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter your address",
                });
                return false;
            }

            let token = localStorage.getItem('token');
            let formData = new FormData;
            formData.append('displayName', this.name);
            formData.append('phoneNumber', this.phone);
            formData.append('profileDescription', this.description);
            formData.append('address', this.address);
            formData.append('facebookLink', this.facebookLink);
            formData.append('twitterLink', this.twitterLink);
            formData.append('insagramLink', this.insagramLink);
            formData.append('youtubeLink', this.youtubeLink);
            formData.append('linkedinLink', this.linkedinLink);
            if(this.photo) {
                formData.append('profileImage', this.photo);
            }
            let config = {
                method: "POST",
                data: formData,
                url: this.$serverURL + this.$api.profile.updateProfile,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + token
                }
            };
            this.$axios(config).then((response) => {
                if (response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated profile data!",
                    });
                    //this.$refs.profileImageFile.value = null;
                    this.getProfileData();
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!",
                });
            }); 
        },
        updatePassword: function() {
            let data = {
                currentPassword: this.currentPassword,
                newPassword: this.newPassword,
                confirmPassword: this.confirmPassword
            }
            let config = {
                method: "POST",
                data: data,
                url: this.$serverURL + this.$api.profile.changePassword,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if (response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated password!",
                    });
                    this.currentPassword = "";
                    this.newPassword = "";
                    this.confirmPassword = "";
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!",
                });
            }); 
        },

    }
}