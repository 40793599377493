<template>
  <div data-elementor-type="wp-post" data-elementor-id="57" class="elementor elementor-57" data-elementor-settings="[]">
    <div class="elementor-section-wrap">
      <section class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-de45e04
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        " data-id="de45e04" data-element_type="section">
        <div class="elementor-container elementor-column-gap-default">
          <div class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-70803c3
            " data-id="70803c3" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <div class="
                  elementor-element elementor-element-d6d46ed elementor-widget
                  elementor-widget-lst_menu
                " data-id="d6d46ed" data-element_type="widget" data-widget_type="lst_menu.default">
                <div class="elementor-widget-container">
                  <Header />
                </div>
              </div>
              <div class="
                  elementor-element elementor-element-7807579 elementor-widget
                  elementor-widget-lst_template_loader
                " data-id="7807579" data-element_type="widget" data-widget_type="lst_template_loader.default">
                <div class="elementor-widget-container">
                  <div data-elementor-type="wp-page" data-elementor-id="1719" class="elementor elementor-1719"
                    data-elementor-settings="[]">
                    <div class="elementor-section-wrap">
                      <section class="
                          elementor-section
                          elementor-top-section
                          elementor-element
                          elementor-element-167a15d
                          elementor-section-full_width
                          elementor-section-height-default
                          elementor-section-height-default
                        " data-id="167a15d" data-element_type="section">
                        <div class="
                            elementor-container elementor-column-gap-default
                          ">
                          <div class="
                              elementor-column
                              elementor-col-100
                              elementor-top-column
                              elementor-element
                              elementor-element-34829c6
                            " data-id="34829c6" data-element_type="column">
                            <div class="
                                elementor-widget-wrap
                                elementor-element-populated
                              ">
                              <div class="
                                  elementor-element
                                  elementor-element-a932591
                                  elementor-widget
                                  elementor-widget-lst_panel
                                " data-id="a932591" data-element_type="widget" data-widget_type="lst_panel.default">
                                <div class="elementor-widget-container">

                                  <div class="listivo-panel__header">
                                    <div class="listivo-panel__header-inner">
                                      <h1>Listings</h1>
                                      <div class="listivo-panel__header-buttons">
                                        <router-link :to="{ name: 'Listing' }" class="
                                              listivo-button-outline
                                              listivo-button-outline--v2
                                            ">
                                          Dashboard
                                          <span class="
                                                listivo-button-outline__icon
                                              ">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14"
                                              viewBox="0 0 9 14">
                                              <g>
                                                <g>
                                                  <path fill="#fff"
                                                    d="M8.32 6.57L2.023.245c-.284-.327-.587-.327-.907 0L.742.627c-.284.29-.284.6 0 .927l5.443 5.452-5.443 5.452c-.32.327-.32.637 0 .927l.374.381c.32.328.623.328.907 0L8.32 7.442c.285-.29.285-.58 0-.872z">
                                                  </path>
                                                </g>
                                              </g>
                                            </svg>
                                          </span>
                                        </router-link>
                                        <router-link :to="{ name: 'Listing' }" class="listivo-button-outline">
                                          Listing
                                          <span class="
                                                listivo-button-outline__icon
                                              ">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14"
                                              viewBox="0 0 9 14">
                                              <g>
                                                <g>
                                                  <path fill="#fff"
                                                    d="M8.32 6.57L2.023.245c-.284-.327-.587-.327-.907 0L.742.627c-.284.29-.284.6 0 .927l5.443 5.452-5.443 5.452c-.32.327-.32.637 0 .927l.374.381c.32.328.623.328.907 0L8.32 7.442c.285-.29.285-.58 0-.872z">
                                                  </path>
                                                </g>
                                              </g>
                                            </svg>
                                          </span>
                                        </router-link>
                                        <router-link :to="{ name: 'Profile' }" class="
                                              listivo-button-outline
                                              listivo-button-outline--v2
                                            ">
                                          Profile
                                          <span class="
                                                listivo-button-outline__icon
                                              ">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14"
                                              viewBox="0 0 9 14">
                                              <g>
                                                <g>
                                                  <path fill="#fff"
                                                    d="M8.32 6.57L2.023.245c-.284-.327-.587-.327-.907 0L.742.627c-.284.29-.284.6 0 .927l5.443 5.452-5.443 5.452c-.32.327-.32.637 0 .927l.374.381c.32.328.623.328.907 0L8.32 7.442c.285-.29.285-.58 0-.872z">
                                                  </path>
                                                </g>
                                              </g>
                                            </svg>
                                          </span>
                                        </router-link>
                                      </div>
                                      <div class="listivo-profile-mobile">
                                        <div class="listivo-panel__menu" style="padding-top:10px">
                                          <router-link :to="{ name: 'Listing' }" class="
                                                listivo-button-outline
                                                listivo-button-outline--v2 listivo-profile-mobile-active
                                              " style="margin-right:10px">
                                            Dashboard
                                          </router-link>
                                          <router-link :to="{ name: 'Profile' }" class="
                                                listivo-button-outline
                                                listivo-button-outline--v2
                                              ">
                                            Profile
                                          </router-link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="listivo-panel__menu-wrapper">
                                    <div class="listivo-panel__menu">

                                      <div id="myListing" @click="getListingData()" class="
                                            listivo-panel__menu-pill
                                            listivo-panel__menu-pill--listings
                                          ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                          stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01">
                                          </path>
                                        </svg>
                                        My Listings
                                      </div>
                                      <div id="myFeaturedListing" @click="getFeaturedList()" class="
                                            listivo-panel__menu-pill
                                            listivo-panel__menu-pill--favorites
                                          ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                          stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z">
                                          </path>
                                        </svg>
                                        My Featured Listings
                                      </div>
                                      <div id="myApprovedListing" @click="getApprovedList()" class="
                                            listivo-panel__menu-pill
                                            listivo-panel__menu-pill--favorites
                                          ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                          stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z">
                                          </path>
                                        </svg>
                                        Approved
                                      </div>
                                      <div id="myPendingListing" @click="getPendingList()" class="
                                            listivo-panel__menu-pill
                                            listivo-panel__menu-pill--favorites
                                          ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                          stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z">
                                          </path>
                                        </svg>
                                        Pending
                                      </div>
                                      <div id="myMessageListing" @click="getMessageList()" class="
                                            listivo-panel__menu-pill
                                            listivo-panel__menu-pill--favorites
                                          ">
                                        <i class="fab fa-whatsapp"></i>
                                        Messages
                                      </div>
                                    </div>
                                  </div>

                                  <div class="listivo-panel-listings-bar" v-if="!messageFlag">

                                    <div class=" listivo-panel-listings-bar__right " v-if="productList.length > 0">
                                      <div class="listivo-results-bar__sort">
                                        <div class="
                                                listivo-results-bar__sort-label
                                              ">
                                          Sort by:
                                        </div>
                                        <div tabindex="0" class="listivo-select sort-select">
                                          <div class="listivo-select__field" @click="manageSortDropdown()">
                                            <div>{{ selectFieldSort }}</div>
                                          </div>
                                          <div class="listivo-select__dropdown" style="display: none">
                                            <div class="
                                                    listivo-select__options
                                                  ">
                                              <div class="
                                                      listivo-select__option
                                                    ">
                                                <div class="
                                                        listivo-select__value
                                                      " @click="
                                                        setSortChoosen('Newest')
                                                      ">
                                                  Newest
                                                </div>
                                              </div>
                                              <div class="
                                                      listivo-select__option
                                                    ">
                                                <div class="
                                                        listivo-select__value
                                                      " @click="
                                                        setSortChoosen('Oldest')
                                                      ">
                                                  Oldest
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="
                                                  listivo-field__icon
                                                  listivo-field__icon--arrow
                                                " @click="manageSortDropdown()"></div>
                                        </div>
                                      </div>
                                      <div class="listivo-simplest-search">
                                        <input v-model="keyword" placeholder="Search" type="text" />
                                        <div @click="getSearchedList()" class="
                                                listivo-simplest-search__button
                                              ">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18"
                                            viewBox="0 0 17 18">
                                            <g>
                                              <g>
                                                <path
                                                  d="M7.636-.004c4.227 0 7.666 3.639 7.666 8.112a8.409 8.409 0 0 1-1.178 4.316l2.346 2.483a1.876 1.876 0 0 1 0 2.549 1.638 1.638 0 0 1-2.41 0l-2.345-2.482a7.322 7.322 0 0 1-4.08 1.247c-4.227 0-7.666-3.64-7.666-8.113 0-4.473 3.439-8.112 7.667-8.112zm0 14.422c3.288 0 5.963-2.83 5.963-6.31 0-3.478-2.675-6.31-5.963-6.31-3.289 0-5.964 2.832-5.964 6.31 0 3.48 2.675 6.31 5.964 6.31z">
                                                </path>
                                              </g>
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!---->
                                  <!---->

                                  <div class="listivo-panel-my-listings" v-if="!messageFlag">
                                    <div class="listivo-listing-list-wrapper">
                                      <div class="listivo-cards-list-v2" v-if="productList.length > 0">
                                        <div v-for="(row, index) in productList" :key="index" data-id="3804" class="
                                                listivo-listing-no-user
                                                listivo-listing-featured
                                                listivo-listing-card
                                              ">
                                          <div class="
                                                  listivo-listing-card__inner
                                                ">
                                            <router-link :to="{ name: 'SingleProduct', params: {id: row['id']}}" class="
                                                    listivo-listing-card__link
                                                  "></router-link>

                                            <div>
                                              <div class="
                                                      listivo-listing-card__top
                                                    ">
                                                <img v-if="row.photos[0]"
                                                  :data-srcset="imagePath + row.photos[0].image + ' 720w, ' + imagePath + row.photos[0].image +  ' 360w'"
                                                  data-sizes="auto" alt="Test2" class="
                                                        listivo-listing-card__preview
                                                        lazyautosizes
                                                        lazyloaded
                                                      " sizes="358px" :srcset="
                                                        imagePath + row.photos[0].image + ' 720w, ' + imagePath + row.photos[0].image +  ' 360w'
                                                      " />
                                                <div class="
                                                        listivo-listing-card__labels
                                                      ">
                                                  <div v-if="row.isFeature==1" class="
                                                          listivo-listing-card__label
                                                        ">
                                                    Featured
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="
                                                      listivo-listing-card__main
                                                    ">
                                                <div class="
                                                        listivo-listing-card__main-head
                                                      ">
                                                  <h3 class="
                                                          listivo-listing-card__name
                                                        ">
                                                    {{row.name}}
                                                  </h3>
                                                  <div class="
                                                          listivo-listing-card__location
                                                        ">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                      viewBox="0 0 24 24" stroke="currentColor">
                                                      <path stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="1.4"
                                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                      </path>
                                                      <path stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="1.4" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                      </path>
                                                    </svg>
                                                    {{row.location}}
                                                  </div>
                                                  <b class="
                                                          listivo-listing-card__price
                                                        ">
                                                    ${{row.price}}</b>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="
                                                    listivo-listing-card__footer
                                                  ">
                                              <div class="
                                                      listivo-listing-card__features
                                                    ">
                                                <ul>
                                                  <li>
                                                    <span>{{row.category.name}}</span>
                                                  </li>
                                                </ul>
                                              </div>
                                              <button class="
                                                      listivo-listing-card__favorite
                                                    ">

                                                {{row.publishStatus}}
                                              </button>
                                            </div>
                                          </div>
                                          <!--v-if-->
                                        </div>

                                      </div>
                                      <div class=" listivo-pagination listivo-pagination--listings "
                                        v-if="productList.length > 0">
                                        <div class=" listivo-pagination-mobile ">
                                          <MobilePagination :totalPages="totalPage" :perPage="limit" :maxVisibleButtons="5"
                                            :currentPage="page" @pagechanged="onPageChange" />
                                        </div>
                                        <div class="listivo-pagination-desktop">


                                          <pagination :totalPages="totalPage" :perPage="limit" :maxVisibleButtons="5"
                                            :currentPage="page" @pagechanged="onPageChange" />

                                        </div>
                                      </div>

                                      <NoListing v-if="productList.length == 0" />

                                    </div>
                                  </div>

                                  <div class="listivo-panel-my-listings" v-if="messageFlag">
                                    <div class="listivo-listing-list-wrapper">
                                      <ListingMessage />

                                    </div>
                                  </div>



                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  </div>
</template>
<script src="../js/listing.js"></script>
<style scoped>

.listivo-pagination-mobile .listivo-pagination-mobile__arrow--left {
    margin-right: 17px;
}
.listivo-pagination-mobile .listivo-pagination-mobile__arrow {
    display: inline-block;
    min-width: 50px;
    line-height: 48px;
    border-radius: var(--radius-rounded);
    height: 50px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid var(--border-color);
}
</style>