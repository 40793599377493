import Header from '../../../../include/Header';
import Footer from '../../../../include/Footer';
import $ from 'jquery';
import Editor from '@tinymce/tinymce-vue';
import { v4 as uuidv4 } from "uuid";
import { GoogleMap, Marker } from "vue3-google-map";
import { authHeader } from "../../../../../auth";
//import axios from 'axios';
//import { UploadMedia, UpdateMedia } from 'vue-media-upload';
export default {
    name: 'Add Listing',
    title: 'Add Listing',
    components: {
        Header,
        Footer,
        Editor,
        GoogleMap,
        Marker
        
    },
    // setup() {
    //     const center = { lat: 40.689247, lng: -74.044502 };
    
    //     return { center };
    //   },
    data() {
        return {
            isLoggedin: false,
            categoryListing: "Category",
            media:[],
            loading:false,
            server:"core/upload-product-image",
            guiid: "",
            guiidAttachment: "",
            uploadedImages:[],
            uploadedAttachment:[],
            imgData:{},
            imgNameAxios:"",
            imgPath: this.$imageURL + 'product/',
            center: {},
            address: null,
            myLocationStatus: false,
            name: null,
            categoryId: null,
            price: null,
            description: null,
            videoLink: null,
            creatorID: null,
            requestStatus: null,
            categoryList: [],
            image_file_length: 0,
            attachment_file_length: 0,
            featuredListing: "Not Required",
            featuredPricingList: [],
            featuredPricingId: ''
        }
    },
    async mounted() {
        this.center = { lat: 40.689247, lng: -74.044502 };
        this.isLoggedin = localStorage.getItem('token');
        this.guiid = uuidv4();
        this.guiidAttachment = uuidv4();
        await this.getStreetAddressFrom(this.center.lat, this.center.lng);
        await this.getProductCategory();
        await this.getFeaturedPricing();
        //this.$emit('media',this.media);
        //this.$emit('uploadedImages',this.uploadedImages)
    },
    beforeCreate: function () {
        document.body.className =
        'page-template-default page page-id-1719 theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-6 elementor-page elementor-page-1719';

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/asset/wp-content/uploads/elementor/css/post-1719.css';
        document.head.appendChild(style);

        
    },
    methods:{
        getProductCategory: function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.products.productCategory,

            };
            this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.categoryList = response.data;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
        getFeaturedPricing: function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.listing.featuredPricing,

            };
            this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.featuredPricingList = response.data;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
        checkValidation: function(){
            if(this.name == null || this.name == '') {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter Product Name",
                });
                return false;
            }
            if(this.categoryId == null) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Select Category",
                });
                return false;
            }
            if(this.price == null || this.price == '') {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter Product Price",
                });
                return false;
            }
            
            if(this.description == null || this.description == '') {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter Product Description",
                });
                return false;
            }
            if(this.videoLink == null || this.videoLink == '') {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter Product Video Link",
                });
                return false;
            }
            if(this.image_file_length == 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Upload Product images",
                });
                return false;
            }
            return true;

        },
        saveAddListing: function() {
            this.requestStatus = "Pending";
            let data = {
                name: this.name,
                categoryId: this.categoryId,
                featuredPricingId: this.featuredPricingId,
                price: this.price,
                description: this.description,
                videoLink: this.videoLink,
                location: this.address,
                guiid: this.guiid,
                guiidAttachment: this.guiidAttachment,
                requestStatus: this.requestStatus
            }
            console.log("data : ", data);
            let config = {
                method: "POST",
                data: data,
                url: this.$serverURL + this.$api.listing.createSellProductRequest,
                headers: authHeader()

            };
            if (this.checkValidation()){
                this.$axios(config)
                .then((response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully Saved your Info",
                        });
                        this.guiid = uuidv4();
                        this.guiidAttachment = uuidv4();
                        this.$router.push("/listing");
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
            }
        
        },
        openGalleryFileExporer: function(){
            $("#images-upload").trigger('click');
        },
        openAttachmentFileExporer: function(){
            $("#attachment-upload").trigger('click');
        },
        listingGalleryUpload: async function(event){
            let files = event.target.files;
            this.image_file_length = files.length;
            for(var i=0; i < files.length; i++){
                let formData = new FormData
                formData.append('product-image', files[i])
                formData.append('imageGuiid', this.guiid)
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.listing.listingImageUpload,
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                };

                this.$swal.showLoading();
                await this.$axios(config)
                    .then((response) => {
                        if (response.status == 201) {
                            this.imgData={imgData: response.data.image, dataUrl: this.imgPath, gid:response.data.id  }; 
                            this.uploadedImages.push(this.imgData);
                            if(this.uploadedImages.length > 0)
                            {
                                $(".dz-listing-first").hide();
                                $(".fotter-listing-addmore").show();
                            }
                            else
                            {
                                $(".dz-listing-first").show();
                                $(".fotter-listing-addmore").hide();
                            }
                            this.$swal.close();
                            this.$swal.close();
                            console.log('this.uploadedImages = ', this.uploadedImages);
                        }

                        this.$swal.close();
                    })
                    .catch((error) => {
                        console.log('error = ',error);
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, Please try again."
                        });
                    });
                    
                    
            }
        },
        listingAttachmentUpload: async function(event){
            let files = event.target.files;
            this.attachment_file_length = files.length;
            for(var i=0; i < files.length; i++){
                let formData = new FormData
                formData.append('product-image', files[i])
                formData.append('imageGuiid', this.guiidAttachment)
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.listing.listingImageUpload,
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                };

                this.$swal.showLoading();
                await this.$axios(config)
                    .then((response) => {
                        if (response.status == 201) {
                            this.imgData={imgData: response.data.image, dataUrl: this.imgPath, gid:response.data.id  }; 
                            this.uploadedAttachment.push(this.imgData);
                            if(this.uploadedAttachment.length > 0)
                            {
                                $(".dz-listing-attachment-first").hide();
                                $(".fotter-listing-attachment-addmore").show();
                            }
                            else
                            {
                                $(".dz-listing-attachment-first").show();
                                $(".fotter-listing-attachment-addmore").hide();
                            }
                            this.$swal.close();
                            this.$swal.close();
                            console.log('this.uploadedAttachment = ', this.uploadedAttachment);
                        }

                        this.$swal.close();
                    })
                    .catch((error) => {
                        console.log('error = ',error);
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, Please try again."
                        });
                    });
                    
                    
            }
        },
        removeUploadedGalleryItem: async function(itemIndex,gid){
                var removeConfirm = window.confirm("Are you sure to delete this image?");
                if(removeConfirm)
                {
                    let config = {
                        method: "DELETE",
                        url: this.$serverURL + this.$api.listing.listingImageDeleteUploaded+gid,
                    };
    
                    this.$swal.showLoading();
                    await this.$axios(config)
                        .then((response) => {
                            if (response.status == 200) {
                                console.log(response.data);
                                this.uploadedImages.splice(itemIndex, 1);
                                console.log('this.uploadedImages = ', this.uploadedImages);

                                if(this.uploadedImages.length > 0)
                                {
                                    $(".dz-listing-first").hide();
                                    $(".fotter-listing-addmore").show();
                                }
                                else
                                {
                                    $(".dz-listing-first").show();
                                    $(".fotter-listing-addmore").hide();
                                }

                                this.$swal.close();
                                this.$swal.close();
                                console.log('this.uploadedImages = ', this.uploadedImages);
                            }
    
                            this.$swal.close();
                        })
                        .catch((error) => {
                            console.log('error = ',error);
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong, Please try again."
                            });
                        });
                }
                
        },
        removeUploadedAttachmentItem: async function(itemIndex,gid){
                var removeConfirm = window.confirm("Are you sure to delete this image?");
                if(removeConfirm)
                {
                    let config = {
                        method: "DELETE",
                        url: this.$serverURL + this.$api.listing.listingImageDeleteUploaded+gid,
                    };
    
                    this.$swal.showLoading();
                    await this.$axios(config)
                        .then((response) => {
                            if (response.status == 200) {
                                console.log(response.data);
                                this.uploadedAttachment.splice(itemIndex, 1);
                                console.log('this.uploadedAttachment = ', this.uploadedAttachment);

                                if(this.uploadedAttachment.length > 0)
                                {
                                    $(".dz-listing-attachment-first").hide();
                                    $(".fotter-listing-attachment-addmore").show();
                                }
                                else
                                {
                                    $(".dz-listing-attachment-first").show();
                                    $(".fotter-listing-attachment-addmore").hide();
                                }

                                this.$swal.close();
                                this.$swal.close();
                                console.log('this.uploadedAttachment = ', this.uploadedAttachment);
                            }
    
                            this.$swal.close();
                        })
                        .catch((error) => {
                            console.log('error = ',error);
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong, Please try again."
                            });
                        });
                }
                
        },
        fieldDecorator:function(fidName)
        {
            if(fidName=="listing-name")
            {
                console.log($('.'+fidName).html());
                if($('.'+fidName).children('div').children('div').children('input').val().length > 0)
                {
                    $('.'+fidName).addClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('div').children('div').fadeIn();
                }
                else
                {
                    $('.'+fidName).removeClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('div').children('.listivo-field__icon').hide();
                }
            }
            else if(fidName=="listing-video")
            {
                console.log($('.'+fidName).html());
                if($('.'+fidName).children('div').children('div:eq(1)').children('div').children('input').val().length > 0)
                {
                    $('.'+fidName).addClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('div:eq(1)').children('div').children('div').fadeIn();
                }
                else
                {
                    $('.'+fidName).removeClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('div:eq(1)').children('div').children('div').fadeOut();
                }
            }
            else if(fidName=="listing-price")
            {
                console.log($('.'+fidName).html());
                if($('.'+fidName).children('div').children('input').val().length > 0)
                {
                    $('.'+fidName).addClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('div:eq(0)').fadeOut();
                    $('.'+fidName).children('div').children('div:eq(1)').fadeIn();
                }
                else
                {
                    $('.'+fidName).removeClass('listivo-submit-field-active');
                    $('.'+fidName).children('div').children('div:eq(1)').children('.listivo-field__icon').hide();
                    $('.'+fidName).children('div').children('div:eq(0)').fadeIn();
                }
            }
            else
            {
                //listivo-submit-field-active
                // if($('.'+fidName).children('div:eq(0)').children('input').val().length > 0)
                // {
                //     $('.'+fidName).addClass('listivo-field--active');
                //     $('.'+fidName).children('div:eq(0)').children('div').fadeIn();
                // }
                // else
                // {
                //     $('.'+fidName).removeClass('listivo-field--active');
                //     $('.'+fidName).children('div:eq(0)').children('div').fadeOut();
                // }
            }
                
            
        },
        fieldDecoratorClear:function(fidName)
        {
            if(fidName=="listing-name")
            {
                $('.'+fidName).children('div').children('div').children('input').val("");
                $('.'+fidName).removeClass('listivo-submit-field-active');
                $('.'+fidName).children('div').children('div').children('div').fadeOut();
            }
            else if(fidName=="listing-video")
            {
                $('.'+fidName).children('div').children('div:eq(1)').children('div').children('input').val("");
                $('.'+fidName).removeClass('listivo-submit-field-active');
                $('.'+fidName).children('div').children('div:eq(1)').children('div').children('div').fadeOut();
            }
            else if(fidName=="listing-price")
            {
                $('.'+fidName).children('div').children('input').val("");
                $('.'+fidName).removeClass('listivo-submit-field-active');
                $('.'+fidName).children('div').children('div:eq(1)').fadeOut();
            }
            else
            {
                $('.'+fidName).children('div:eq(0)').children('input').val("");
                $('.'+fidName).removeClass('listivo-field--active');
                $('.'+fidName).children('div:eq(0)').children('div').fadeOut();
            }
                
                
            
        },
        manageCategoryDropdown:function(){
            $('.catSelect').toggleClass('listivo-select__field--active');
            $('.catSelect').children('.listivo-select__dropdown').fadeToggle();
            $('.catSelect').children('.listivo-field__icon').removeClass('listivo-field__icon--clear');
            $('.catSelect').children('.listivo-field__icon').addClass('listivo-field__icon--arrow');
        },
        manageFeaturedDropdown:function(){
            $('.featSelect').toggleClass('listivo-select__field--active');
            $('.featSelect').children('.listivo-select__dropdown').fadeToggle();
            $('.featSelect').children('.listivo-field__icon').removeClass('listivo-field__icon--clear');
            $('.featSelect').children('.listivo-field__icon').addClass('listivo-field__icon--arrow');
        },
        setCategoryChoosen:function(searchCat,catID){
            this.categoryListing = searchCat;
            this.categoryId = catID;
            $('.catSelect').addClass('listivo-select__field--active');
            $('.catSelect').children('.listivo-select__dropdown').hide();
            
            if($('.catSelect').children('.listivo-field__icon').hasClass('listivo-field__icon--arrow'))
            {
                $('.catSelect').children('.listivo-field__icon').removeClass('listivo-field__icon--arrow');
            }
            $('.catSelect').children('.listivo-field__icon').addClass('listivo-field__icon--clear');
            $('.catSelect').children('.listivo-select__dropdown').children('.listivo-select__options').children('.listivo-select__option').removeClass('listivo-select__option--active');
            console.log(catID);
        },
        setFeaturedChoosen:function(searchFeat, featId){
            this.featuredListing = searchFeat;
            this.featuredPricingId = featId;
            $('.featSelect').addClass('listivo-select__field--active');
            $('.featSelect').children('.listivo-select__dropdown').hide();
            
            if($('.featSelect').children('.listivo-field__icon').hasClass('listivo-field__icon--arrow'))
            {
                $('.featSelect').children('.listivo-field__icon').removeClass('listivo-field__icon--arrow');
            }
            $('.featSelect').children('.listivo-field__icon').addClass('listivo-field__icon--clear');
            $('.featSelect').children('.listivo-select__dropdown').children('.listivo-select__options').children('.listivo-select__option').removeClass('listivo-select__option--active');
            console.log(featId);
        },
        pickMyLocation: function() {
            this.myLocationStatus = !this.myLocationStatus;
            if(this.myLocationStatus){
                const success = (position) => {
                    const latitude  = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    if(latitude && longitude){
                        this.center = {
                            lat: latitude,
                            lng: longitude
                        }
                        
                    }
                    this.getStreetAddressFrom(this.center.lat, this.center.lng);
                };
        
                const error = (error) => {
                    console.log(error)
                };
        
                navigator.geolocation.getCurrentPosition(success, error);
            }
            else {
                this.center = { lat: 40.689247, lng: -74.044502 };
            }
            
            
        },
        updateCoordinates: function(location){
            this.center = location.latLng;
            console.log("Location is: ", location);
            this.getStreetAddressFrom(this.center.lat(), this.center.lng());
        },
        async getStreetAddressFrom(lat, long) {
            try {
               var {
                  data
               } = await this.$axios.get(
                  "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                  lat +
                  "," +
                  long +
                  "&key=AIzaSyBhk7osZH33ThTArtnwC2Yg4UAU7qFlWfA"
               );
               if (data.error_message) {
                  console.log(data.error_message)
               } else {
                  this.address = data.results[0].formatted_address;
                  console.log("Address is: ", this.address);
               }
            } catch (error) {
               console.log(error.message);
            }
         }
    }
}