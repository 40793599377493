import Loading from "vue-loading-overlay";
import Header from '../../../include/Header';
import Footer from '../../../include/Footer';
import Subscribe from "../../../include/Subscribe";
import $ from 'jquery';

export default {
    name: 'Contact us',
    title: 'Contact us',

    components: {
        Header,
        Footer,
        Loading,
        Subscribe
    },
    data() {
        return {
            contactPageCMS: {},
            imagePath: this.$imageURL + 'contact/',
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactMessage: ''

        };
    },
    beforeCreate: function() {
        document.body.className = 'page-template-default page page-id-458 theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-5228 elementor-page elementor-page-458';

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/asset/wp-content/uploads/elementor/css/post-458.css';
        document.head.appendChild(style);

        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCOj-m6E9gcG8PqQlWdBEV_-VU-f0CVWUw&libraries=places&ver=5.8.2')
        document.head.appendChild(recaptchaScript)

    },
    mounted() {
        $('html,body').animate({ scrollTop: 0 }, 'slow');
        this.getSiteSettings();
    },
    methods: {
        submitInfo: function() {
            let data = {
                contactName: this.contactName,
                contactEmail: this.contactEmail,
                contactPhone: this.contactPhone,
                contactMessage: this.contactMessage
            }
            console.log("data : ", data);
            let config = {
                method: "POST",
                data: data,
                url: this.$serverURL + this.$api.contactUs.contactUsURL,

            };
            this.$axios(config)
                .then((response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully Saved your Info",
                        });
                        this.contactName = '';
                        this.contactEmail = '';
                        this.contactPhone = '';
                        this.contactMessage = '';
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
        getSiteSettings: function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.contactUs.contactUsURL,

            };
            this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.contactPageCMS = response.data.contactPageCms;
                    }
                    this.isLoading = false;
                    $('html,body').animate({ scrollTop: 0 }, 'slow');
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        }
    }
}