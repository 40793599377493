import Header from "../../../include/Header";
import Footer from "../../../include/Footer";
import { inject, toRefs } from "vue";
export default {
    name: "Login",
    title: "Login ",
    components: {
        Header,
        Footer
    },
    setup(props) {
        const { isSignIn } = toRefs(props);
        const Vue3GoogleOauth = inject("Vue3GoogleOauth");
        const handleClickLogin = () => {};
        return {
          Vue3GoogleOauth,
          handleClickLogin,
          isSignIn,
        };
      },
    data() {
        return {
            email: null,
            password: null,
            isLogin: false,
        }
    },
    beforeCreate: function() {
        document.body.className =
            "page-template-default page page-id-1587 theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-5228 elementor-page elementor-page-1587";

        let style = document.createElement("link");
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = "asset/wp-content/uploads/elementor/css/post-1587.css";
        document.head.appendChild(style);
    },
    methods: {
        async handleClickSignIn(){
            try {
              const googleUser = await this.$gAuth.signIn();
              console.log("Google User:", googleUser);
              if (googleUser) {
                
                this.isLogin = this.$gAuth.isAuthorized;
                const idToken = googleUser.getId();
                
                await this.processGoogleLogin(idToken, googleUser);
              }
            } catch (error) {
              //on fail do something
              console.error(error);
              return null;
            }
          },
          processGoogleLogin: async function(idToken, googleUser){
                let loggedData = googleUser.getBasicProfile();
                // console.log('ID: ' + loggedData.getId());
                // console.log('Full Name: ' + loggedData.getName());
                // console.log('Given Name: ' + loggedData.getGivenName());
                // console.log('Family Name: ' + loggedData.getFamilyName());
                // console.log('Image URL: ' + loggedData.getImageUrl());
                // console.log('Email: ' + loggedData.getEmail());
                console.log(loggedData);
                let email = loggedData.getEmail();
                let fullName = loggedData.getName();
                let data = { 
                    token: idToken,
                    email: email,
                    name: fullName 
                };
                //return false;
                let config = {
                    method: "POST",
                    data: data,
                    url: this.$serverURL + this.$api.auth.googleLogin,
                };
                this.isLoading = true;
                // this.$api + "api/geek-buyback-social-login"
                await this.$axios(config).then((response) => 
                {
                    if(response.status == 201) {
                        localStorage.setItem(this.$token, response.data.token)
                        localStorage.setItem(this.$id, response.data.user.id)
                        localStorage.setItem(this.$isActive, response.data.user.isActive)
                        localStorage.setItem(this.$name, response.data.user.name)
                        localStorage.setItem(this.$phone, response.data.user.phone)
                        this.$swal.fire({
                            icon: "Success",
                            text: "Successfuly logged in!!",
                        });
                        this.$router.push('/listing')
                    }
                    else {
                        this.isLoading = false
                        this.$swal.fire({
                            icon: "error",
                            text: "Google Login Failed!"
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong! Please try again."
                    });
                });
        },
        navigate: function(path) {
            this.$router.push('/' + path);
        },
        logins(){
            alert('working');
        },
        signIn(){
            let data = {
                email: this.email,
                password: this.password,
            }
            try {
                let config = {
                    method: "POST",
                    data: data,
                    url: this.$serverURL + this.$api.auth.signIn,
                };
                this.$axios(config).then((response) => {
                    console.log("response: ", response);
                    if (response.status == 201) {
                        localStorage.setItem(this.$token, response.data.token)
                        localStorage.setItem(this.$id, response.data.user.id)
                        localStorage.setItem(this.$isActive, response.data.user.isActive)
                        localStorage.setItem(this.$name, response.data.user.name)
                        localStorage.setItem(this.$phone, response.data.user.phone)
                        this.$swal.fire({
                            icon: "Success",
                            text: "Successfuly logged in!!",
                        });
                        this.$router.push('/listing')
                    }

                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: "Please provide valid email and password!"
                    });
                });
            } catch (error) {
                console.log(error);
            }
        }
    }
};