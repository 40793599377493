<template>
  <loading :active='isLoading' :is-full-page='fullPage' :loader='loader'/>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'App',
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: 'bars',
      pageCMS: {},
      socialLink: {},
      footerSubscriber: {},
      footerMenu: {},
      newListing: [],
      featuredProduct: [],
      productRawCategories:[],
      homeTestimonyData: []
    };
  },
  async created() {
    await this.getSiteSettings();
    if (
      this.$route.path == "/login" ||
       this.$route.path == "/register"
    ) {
      if (localStorage.getItem(this.$token)) {
        this.$router.push("/listing");
      }
    }
  },
    watch: {
    $route() {
      if (
        this.$route.path == "/login" ||
        this.$route.path == "/register"
      ) {
        if (localStorage.getItem(this.$token)) {
          this.$router.push("/listing");
        }
  
      }
    },
  },
  methods: {
    getSiteSettings: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.siteSettings.siteSettings,

            };
            await this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.pageCMS = response.data.pageCMS;
                        this.socialLink = response.data.socialLink;
                        this.footerSubscriber = response.data.footerSubscriber;
                        this.footerMenu = response.data.footerMenuHeading;
                        this.newListing = response.data.newListing;
                        this.featuredProduct = response.data.featuredProduct;
                        this.productRawCategories = response.data.productRawCategories;
                        this.homeTestimonyData = response.data.homeTestimonyData;
                        localStorage.setItem("socialLink", JSON.stringify(this.socialLink));
                        localStorage.setItem("pageCMS", JSON.stringify(this.pageCMS));
                        localStorage.setItem("footerSubscriber", JSON.stringify(this.footerSubscriber));
                        localStorage.setItem("footerMenu", JSON.stringify(this.footerMenu));
                        localStorage.setItem("newListing", JSON.stringify(this.newListing));
                        localStorage.setItem("featuredCategory", JSON.stringify(this.featuredProduct));
                        localStorage.setItem("productRawCategories", JSON.stringify(this.productRawCategories));
                        localStorage.setItem("homeTestimonyData", JSON.stringify(this.homeTestimonyData));

                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
  }
}
</script>
