import Header from "../../../include/Header";
import Footer from "../../../include/Footer";
import Loading from "vue-loading-overlay";
import {authHeader} from "../../../../auth";
import Subscribe from "../../../include/Subscribe";

export default {
  name: "Our Team",
  title: "Our Team",
  components: {
    Header,
    Footer,
    Loading,
    Subscribe
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: "bars",
      teamPageCms: [],
      ceo: {},
      teamMembers: [],
      teamContacts: [],
      imagePath: this.$imageURL + "team/"
    };
  },
  mounted() {
    this.getTeamsList();
  },
  beforeCreate: function() {
        document.body.className = 'page-template-default page page-id-739 theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-5228 elementor-page elementor-page-739';

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/asset/wp-content/uploads/elementor/css/post-739.css';
        document.head.appendChild(style);
    },
  methods: {
    getTeamsList: function () {
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.teams.getTeamsList,
        headers: authHeader()
      };
      this.$axios(config)
        .then((response) => {
            console.log(response);
          if (response.status == 200) {
            this.teamPageCms = response.data.teamPageCms;
            // this.teamMembers = response.data.teamMembers;
            
            for (const key in Object.keys(response.data.teamMembers)) {
              if (response.data.teamMembers[key].memberStatus == 'CEO') {
                  this.ceo = response.data.teamMembers[key]
              }
              else{
                this.teamMembers.push({
                  id: response.data.teamMembers[key].id,
                  createdAt: response.data.teamMembers[key].createdAt,
                  name: response.data.teamMembers[key].name,
                  designation: response.data.teamMembers[key].designation,
                  photo: response.data.teamMembers[key].photo,
                  shortDetail: response.data.teamMembers[key].shortDetail,
                  phone: response.data.teamMembers[key].phone,
                  facebookLink: response.data.teamMembers[key].facebookLink,
                  twitterLink: response.data.teamMembers[key].twitterLink,
                  linkedinLink: response.data.teamMembers[key].linkedinLink,
                  instagramLink: response.data.teamMembers[key].instagramLink,
                  youtubeLink: response.data.teamMembers[key].youtubeLink,
                });
              }
              
          }
            this.teamContacts = response.data.teamContacts;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again!",
          });
        });
    },
  },
};



