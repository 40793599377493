import Loading from "vue-loading-overlay";
export default {
    components: {
        Loading,
    },
    data() {
        return {
            pageCMS: {},
            socialLink: {},
            footerMenu: {},
            category_list: [],
            footerListing: [],
            isLoggedin: false,
            footerSubscriber: {}
        };
    },
    async mounted() {
        this.isLoggedin = localStorage.getItem(this.$token) ? true : false;
        // await this.getSiteSettings();
        this.pageCMS = JSON.parse(localStorage.getItem('pageCMS'));
        this.socialLink = JSON.parse(localStorage.getItem('socialLink'));
        this.footerSubscriber = JSON.parse(localStorage.getItem('footerSubscriber'));
        this.footerMenu = JSON.parse(localStorage.getItem('footerMenu'));
        this.category_list = JSON.parse(localStorage.getItem('featuredCategory'));
        this.footerListing = JSON.parse(localStorage.getItem('newListing'));
        await this.getSiteSettings();
        // await this.getFooterListing();
        await this.featuredCatogires();
        await this.getFooterListing();
    },
    methods: {
        getSiteSettings: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.siteSettings.siteSettings,

            };
            await this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.pageCMS = response.data.pageCMS;
                        this.socialLink = response.data.socialLink;
                        this.footerMenu = response.data.footerMenuHeading;
                        this.footerSubscriber = response.data.footerSubscriber;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
        featuredCatogires: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.siteSettings.featuredCategory,

            };
            await this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.category_list = response.data;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
        getFooterListing: async function() {
            try {
                this.isLoading = true;
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.siteSettings.footerListingURL
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.isLoading = false;
                        this.footerListing = response.data;
                    }
                }).catch(error => {
                    this.isLoading = false;
                    console.log(error);
                });
            } catch (error) {
                this.isLoading = false;
                console.log(error)
            }
        }
    }
}