import { createRouter, createWebHistory } from "vue-router";


import ResetPassword from "../components/pages/loginRegister/template/ResetPassword";
import Home from "../components/pages/index/template/Home";
import About from "../components/pages/about/template/About";
import Contact from "../components/pages/contact/template/Contact";
import Product from "../components/pages/gallery/template/Product";
import ProductList from "../components/pages/gallery/template/ProductList";
import SingleProduct from "../components/pages/gallery/template/ProductDetails";
import Blog from "../components/pages/blog/template/Blog";
import SinglePost from "../components/pages/blog/template/SinglePost";
import Login from "../components/pages/loginRegister/template/Login";
import Register from "../components/pages/loginRegister/template/Register";
import ForgetPassword from "../components/pages/loginRegister/template/ForgetPassword";
import Page404 from "../components/pages/404/template/Page-404";
import OurTeam from "../components/pages/ourTeam/template/OurTeam";
import UserPage from "../components/pages/userPage/template/Userpage";
import UserProfile from "../components/pages/userPage/template/UserProfile";
import Faq from "../components/pages/faq/template/Faq";
import MapSearch from "../components/pages/mapSearch/template/Mapsearch";
import AddListing from "../components/pages/addListing/template/Addlisting";
import UpdateListing from "../components/pages/addListing/template/UpdateListing";

import Listing from "../components/pages/dashboard/template/Listings"
import CreateListing from "../components/pages/dashboard/template/AddListing"
import Profile from "../components/pages/profile/template/Profile"

import guard from "../guard";

const routes = [

    { path: "/password-token-verify/:passToken", name: "resetPasswordVerify", component: ResetPassword },

    { path: "/", name: "Home", component: Home },
    { path: "/home", name: "Home", component: Home },
    { path: "/about", name: "About", component: About },
    { path: "/contact", name: "Contact", component: Contact },
    { path: "/search", name: "search", component: Product },
    { path: "/product", name: "Product", component: Product },
    { path: "/product/list/:id", name: "ProductList", component: ProductList },
    { path: "/product/details/:id", name: "SingleProduct", component: SingleProduct },
    { path: "/blog", name: "Blog", component: Blog },
    { path: "/blog/details/:id", name: "SinglePost", component: SinglePost },
    { path: "/login", name: "Login", component: Login },
    { path: "/register", name: "Register", component: Register },
    { path: "/forget-password", name: "ForgetPassword", component: ForgetPassword },
    { path: "/:catchAll(.*)*", name: "Page404", component: Page404 },
    { path: "/our-team", name: "OurTeam", component: OurTeam  },
    { path: "/team/details/:id", name: "UserPage", component: UserPage  },
    { path: "/faq", name: "Faq", component: Faq },
    { path: "/map-search", name: "MapSearch", component: MapSearch },
    { path: "/add-listing", name: "AddListing", component: AddListing, beforeEnter: guard },
    { path: "/update-listing/:id", name: "UpdateListing", component: UpdateListing, beforeEnter: guard },
    { path: "/listing", name: "Listing", component: Listing, beforeEnter: guard },
    { path: "/listing/create", name: "CreateListing", component: CreateListing, beforeEnter: guard },
    { path: "/user/profile", name: "Profile", component: Profile },
    { path: "/user/profile/details/:id", name: "UserProfile", component: UserProfile },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
router.beforeEach((to, from, next) => {
    if (to.path == '/') {
        next('/home')
    } else {
        next()
    }
})
export default router;