<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <div data-elementor-type="wp-post" data-elementor-id="57" class="elementor elementor-57" data-elementor-settings="[]">
    <div class="elementor-section-wrap">
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-de45e04 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="de45e04" data-element_type="section">
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70803c3"
            data-id="70803c3" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <div class="elementor-element elementor-element-d6d46ed elementor-widget elementor-widget-lst_menu"
                data-id="d6d46ed" data-element_type="widget" data-widget_type="lst_menu.default">
                <div class="elementor-widget-container">
                  <Header />
                </div>
              </div>
              <div
                class="elementor-element elementor-element-7807579 elementor-widget elementor-widget-lst_template_loader"
                data-id="7807579" data-element_type="widget" data-widget_type="lst_template_loader.default">
                <div class="elementor-widget-container">
                  <div data-elementor-type="wp-post" data-elementor-id="871" class="elementor elementor-871"
                    data-elementor-settings="[]">
                    <div class="elementor-section-wrap">
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-1eefc0f1 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        :style="{backgroundImage: 'url(' + imagePath + blogPageCms.headerBackground + ')'}">
                        <div class="elementor-background-overlay"></div>
                        <div class="elementor-container elementor-column-gap-default">
                          <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7c554378"
                            data-id="7c554378" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                              <div
                                class="elementor-element elementor-element-3fb36989 elementor-widget elementor-widget-lst_blog_archive_title">
                                <div class="elementor-widget-container">
                                  <h1 class="listivo-blog-title-widget">
                                    {{ blogPageCms.headerTitle }}
                                  </h1>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-2fc2e91e elementor-widget elementor-widget-spacer">
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-59453d0f elementor-widget elementor-widget-lst_breadcrumbs">
                                <div class="elementor-widget-container">
                                  <div class="listivo-app">
                                    <div class="listivo-breadcrumbs-wrapper">
                                      <div class="listivo-breadcrumbs">
                                        <div class="listivo-breadcrumbs__single">
                                          <a class="listivo-breadcrumbs__link" href="/home" title="Home">
                                            Home
                                          </a>
                                          <span class="listivo-breadcrumbs__separator">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11"
                                              viewBox="0 0 11 11">
                                              <g>
                                                <g>
                                                  <path
                                                    d="M4.974.635c-.119.12-.119.246 0 .38l3.987 3.973H.587c-.178 0-.267.09-.267.268v.223c0 .179.089.268.267.268H8.96L4.974 9.721c-.119.133-.119.26 0 .379l.156.178c.134.119.26.119.379 0l4.7-4.732c.133-.119.133-.238 0-.357L5.508.457c-.119-.12-.245-.12-.379 0z" />
                                                </g>
                                              </g>
                                            </svg>
                                          </span>
                                        </div>
                                        <div class="listivo-breadcrumbs__last">
                                          {{ blogPageCms.headerPageName }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-1bc25b09 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="1bc25b09" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                          <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2cdb86d7"
                            data-id="2cdb86d7" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                              <div
                                class="elementor-element elementor-element-817621b elementor-widget elementor-widget-spacer"
                                data-id="817621b" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-52766413 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="52766413" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                          <div
                            class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-350b8000"
                            data-id="350b8000" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated" v-if="blogList.length >0">
                              <div
                                class="elementor-element elementor-element-4818ee2b elementor-widget-tablet__width-initial elementor-widget elementor-widget-lst_blog_archive"
                                data-id="4818ee2b" data-element_type="widget"
                                data-widget_type="lst_blog_archive.default">
                                <div class="elementor-widget-container">
                                  <div class="listivo-posts listivo-posts--archive">
                                    <div v-for="(row, index) in blogList" :key="index"
                                      class="listivo-blog-card-wrapper">
                                      <article
                                        class="listivo-blog-card post-454 post type-post status-publish format-standard has-post-thumbnail hentry category-lifestyle tag-baby-accessories tag-buy tag-community"
                                        v-if="index+1 <= blogPageCms.blogPostShowLength">
                                        <a href="javascript:void(0)" @click="$router.push({
                                            name: 'SinglePost',
                                            params: { id: row['id']}})
                                          " class="listivo-blog-card__image-static">
                                          <img v-bind:src="imagePath + row.photo" v-bind:alt="row['title']" />
                                        </a>
                                        <div class="listivo-blog-card__content">
                                          <h3 class="listivo-blog-card__title">
                                            <a href="javascript:void(0)" @click="$router.push({
                                                name: 'SinglePost',
                                                params: { id: row['id'] },
                                              })">
                                              {{ row["title"] }}
                                            </a>
                                          </h3>
                                          <div class="listivo-blog-card__meta listivo-blog-card__meta--top">
                                            <div class="listivo-blog-card__author">
                                              <a href="javascript:void(0)" @click="$router.push({
                                                name: 'SinglePost',
                                                params: { id: row['id'] },
                                              })" v-bind:title="row['postedBy']"
                                                class="listivo-blog-card__author-avatar-link">
                                                <img v-bind:src="
                                                    imagePath +
                                                    row.UploaderPhoto
                                                  " v-bind:alt="row['postedBy']" />
                                              </a>
                                              <a href="#" v-bind:title="row['postedBy']"
                                                rel="author">{{ row["postedBy"] }}</a>
                                            </div>
                                            <div class="listivo-blog-card__date">
                                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 72">
                                                <path class="cls-1"
                                                  d="M326.5,907v-7.312A1.491,1.491,0,0,0,324.812,898h-1.124A1.491,1.491,0,0,0,322,899.688V907H295v-7.312A1.491,1.491,0,0,0,293.312,898h-1.124a1.491,1.491,0,0,0-1.688,1.688V907h-6.75a6.723,6.723,0,0,0-6.75,6.75v49.5a6.723,6.723,0,0,0,6.75,6.75h49.5a6.723,6.723,0,0,0,6.75-6.75v-49.5a6.723,6.723,0,0,0-6.75-6.75H326.5Zm6.75,4.5a2.167,2.167,0,0,1,2.25,2.25v6.75h-54v-6.75a2.167,2.167,0,0,1,2.25-2.25h49.5Zm-49.5,54a2.167,2.167,0,0,1-2.25-2.25V925h54v38.25a2.167,2.167,0,0,1-2.25,2.25h-49.5Zm15.75-24.188v-5.624A1.491,1.491,0,0,0,297.812,934h-5.624a1.491,1.491,0,0,0-1.688,1.688v5.624A1.491,1.491,0,0,0,292.188,943h5.624a1.491,1.491,0,0,0,1.688-1.688h0Zm13.5,0v-5.624A1.491,1.491,0,0,0,311.312,934h-5.624A1.491,1.491,0,0,0,304,935.688v5.624A1.491,1.491,0,0,0,305.688,943h5.624A1.491,1.491,0,0,0,313,941.312h0Zm13.5,0v-5.624A1.491,1.491,0,0,0,324.812,934h-5.624a1.491,1.491,0,0,0-1.688,1.688v5.624A1.491,1.491,0,0,0,319.188,943h5.624a1.491,1.491,0,0,0,1.688-1.688h0Zm-13.5,13.5v-5.624a1.491,1.491,0,0,0-1.688-1.688h-5.624A1.491,1.491,0,0,0,304,949.188v5.624a1.491,1.491,0,0,0,1.688,1.688h5.624A1.491,1.491,0,0,0,313,954.812h0Zm-13.5,0v-5.624a1.491,1.491,0,0,0-1.688-1.688h-5.624a1.491,1.491,0,0,0-1.688,1.688v5.624a1.491,1.491,0,0,0,1.688,1.688h5.624a1.491,1.491,0,0,0,1.688-1.688h0Zm27,0v-5.624a1.491,1.491,0,0,0-1.688-1.688h-5.624a1.491,1.491,0,0,0-1.688,1.688v5.624a1.491,1.491,0,0,0,1.688,1.688h5.624a1.491,1.491,0,0,0,1.688-1.688h0Z"
                                                  transform="translate(-277 -898)" />
                                              </svg>
                                              <span>
                                                {{
                                                  $filters.formatDate(
                                                    row["createdAt"]
                                                  )
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                          <div class="listivo-blog-card__excerpt" v-html="row.shortDetail">
                                          </div>
                                          <div class="listivo-blog-card__bottom">
                                            <div class="listivo-blog-card__meta listivo-blog-card__meta--bottom">
                                              <div class="listivo-blog-card__author">
                                                <a class="listivo-post-author-image" href="#"
                                                  v-bind:title="row['postedBy']">
                                                  <img v-bind:src="
                                                      imagePath +
                                                      row.UploaderPhoto
                                                    " v-bind:title="
                                                      row['postedBy']
                                                    " />
                                                </a>
                                                <a href="#" v-bind:title="row['postedBy']"
                                                  rel="author">{{ row["postedBy"] }}</a>
                                              </div>
                                              <span class="listivo-blog-card__dot"></span>
                                              <div class="listivo-blog-card__date">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 72">
                                                  <path class="cls-1"
                                                    d="M326.5,907v-7.312A1.491,1.491,0,0,0,324.812,898h-1.124A1.491,1.491,0,0,0,322,899.688V907H295v-7.312A1.491,1.491,0,0,0,293.312,898h-1.124a1.491,1.491,0,0,0-1.688,1.688V907h-6.75a6.723,6.723,0,0,0-6.75,6.75v49.5a6.723,6.723,0,0,0,6.75,6.75h49.5a6.723,6.723,0,0,0,6.75-6.75v-49.5a6.723,6.723,0,0,0-6.75-6.75H326.5Zm6.75,4.5a2.167,2.167,0,0,1,2.25,2.25v6.75h-54v-6.75a2.167,2.167,0,0,1,2.25-2.25h49.5Zm-49.5,54a2.167,2.167,0,0,1-2.25-2.25V925h54v38.25a2.167,2.167,0,0,1-2.25,2.25h-49.5Zm15.75-24.188v-5.624A1.491,1.491,0,0,0,297.812,934h-5.624a1.491,1.491,0,0,0-1.688,1.688v5.624A1.491,1.491,0,0,0,292.188,943h5.624a1.491,1.491,0,0,0,1.688-1.688h0Zm13.5,0v-5.624A1.491,1.491,0,0,0,311.312,934h-5.624A1.491,1.491,0,0,0,304,935.688v5.624A1.491,1.491,0,0,0,305.688,943h5.624A1.491,1.491,0,0,0,313,941.312h0Zm13.5,0v-5.624A1.491,1.491,0,0,0,324.812,934h-5.624a1.491,1.491,0,0,0-1.688,1.688v5.624A1.491,1.491,0,0,0,319.188,943h5.624a1.491,1.491,0,0,0,1.688-1.688h0Zm-13.5,13.5v-5.624a1.491,1.491,0,0,0-1.688-1.688h-5.624A1.491,1.491,0,0,0,304,949.188v5.624a1.491,1.491,0,0,0,1.688,1.688h5.624A1.491,1.491,0,0,0,313,954.812h0Zm-13.5,0v-5.624a1.491,1.491,0,0,0-1.688-1.688h-5.624a1.491,1.491,0,0,0-1.688,1.688v5.624a1.491,1.491,0,0,0,1.688,1.688h5.624a1.491,1.491,0,0,0,1.688-1.688h0Zm27,0v-5.624a1.491,1.491,0,0,0-1.688-1.688h-5.624a1.491,1.491,0,0,0-1.688,1.688v5.624a1.491,1.491,0,0,0,1.688,1.688h5.624a1.491,1.491,0,0,0,1.688-1.688h0Z"
                                                    transform="translate(-277 -898)" />
                                                </svg>
                                                <span>
                                                  {{
                                                    $filters.formatDate(
                                                      row["createdAt"]
                                                    )
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                            <div class="listivo-blog-card__bottom-button">
                                              <router-link :to="{
                                                  name: 'SinglePost',
                                                  params: { id: row['id'] },
                                                }" class="listivo-button-outline listivo-button-outline--v3">
                                                Read More
                                                <span class="listivo-button-outline__icon">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11"
                                                    viewBox="0 0 9 14">
                                                    <g>
                                                      <g>
                                                        <path
                                                          d="M8.32 6.57L2.023.245c-.284-.327-.587-.327-.907 0L.742.627c-.284.29-.284.6 0 .927l5.443 5.452-5.443 5.452c-.32.327-.32.637 0 .927l.374.381c.32.328.623.328.907 0L8.32 7.442c.285-.29.285-.58 0-.872z" />
                                                      </g>
                                                    </g>
                                                  </svg>
                                                </span>
                                              </router-link>
                                            </div>
                                          </div>
                                        </div>
                                      </article>
                                    </div>
                                  </div>
                                  <div class="listivo-pagination listivo-pagination--listings"
                                    v-if="blogList.length > 0">
                                    <div class="listivo-pagination-mobile">
                                      <MobilePagination :totalPages="totalPages?totalPages:1" :perPage="limit"
                                                                  :maxVisibleButtons="5" :currentPage="currentPaginPage"
                                                                  @pagechanged="onPageChange" />
                                    </div>
                                    <div class="listivo-pagination-desktop">

                                      <pagination :totalPages="totalPages" :perPage="5" :maxVisibleButtons="5"
                                        :currentPage="currentPaginPage" @pagechanged="onPageChange" />
                                      <!-- </div> -->
                                    </div>
                                  </div>
                                </div>


                              </div>
                            </div>
                            <div class="elementor-widget-wrap elementor-element-populated" v-else>
                              <div
                                class="elementor-element elementor-element-4818ee2b elementor-widget-tablet__width-initial elementor-widget elementor-widget-lst_blog_archive"
                                data-id="4818ee2b" data-element_type="widget"
                                data-widget_type="lst_blog_archive.default">
                                <div class="elementor-widget-container">
                                  <div class="listivo-posts listivo-posts--archive">
                                    <div style="text-align:center;">
                                      <code style="text-align: center;">No data found.</code>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div
                            class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1a539364"
                            data-id="1a539364" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated" style="display: block;">
                              <div
                                class="elementor-element elementor-element-246d029c elementor-widget elementor-widget-lst_blog_keyword_search"
                                data-id="246d029c" data-element_type="widget"
                                data-widget_type="lst_blog_keyword_search.default">
                                <div class="elementor-widget-container">
                                  <form v-on:submit.prevent="getSearchedData" method="get" action="" role="search">
                                    <div class="listivo-blog-keyword-search">
                                      <input class="listivo-blog-keyword-search__input" type="text" v-model="search"
                                        name="s" placeholder="Search..." />
                                      <button class="listivo-blog-keyword-search__button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18"
                                          viewBox="0 0 17 18">
                                          <g>
                                            <g>
                                              <path fill="#fff"
                                                d="M7.636-.004c4.227 0 7.666 3.639 7.666 8.112a8.409 8.409 0 0 1-1.178 4.316l2.346 2.483a1.876 1.876 0 0 1 0 2.549 1.638 1.638 0 0 1-2.41 0l-2.345-2.482a7.322 7.322 0 0 1-4.08 1.247c-4.227 0-7.666-3.64-7.666-8.113 0-4.473 3.439-8.112 7.667-8.112zm0 14.422c3.288 0 5.963-2.83 5.963-6.31 0-3.478-2.675-6.31-5.963-6.31-3.289 0-5.964 2.832-5.964 6.31 0 3.48 2.675 6.31 5.964 6.31z" />
                                            </g>
                                          </g>
                                        </svg>
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-52c99f30 elementor-widget elementor-widget-spacer"
                                data-id="52c99f30" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-86335f6 elementor-widget elementor-widget-lst_blog_categories"
                                data-id="86335f6" data-element_type="widget"
                                data-widget_type="lst_blog_categories.default">
                                <div class="elementor-widget-container">
                                  <div class="listivo-sidebar-widget">
                                    <h3 class="listivo-sidebar-widget__label">
                                      {{
                                        blogPageCms.categoryHeading
                                      }}
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 913.000000 42.000000"
                                        preserveAspectRatio="xMidYMid meet">
                                        <g transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                          stroke="none">
                                          <path
                                            d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z">
                                          </path>
                                        </g>
                                      </svg>
                                    </h3>
                                    <div class="listivo-sidebar-widget__content">
                                      <div class="listivo-blog-categories">
                                        <a @click="getCategoryWiseBlog(row['id'])"
                                          v-for="(row, index) in blogCategories" :key="index"
                                          class="listivo-blog-categories__category-wrapper" href="#">
                                          <span class="listivo-blog-categories__category">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width="13.0pt"
                                              height="13.0pt">
                                              <path
                                                d="M 11.88 0.00 L 13.00 0.00 L 13.00 1.15 C 11.79 5.07 10.78 9.04 9.70 13.00 L 9.34 13.00 C 6.16 9.99 3.00 6.84 0.00 3.66 L 0.00 3.29 C 3.97 2.22 7.94 1.20 11.88 0.00 Z"
                                                fill="#e55c69" />
                                              <path
                                                d="M 11.13 1.87 C 10.57 4.87 9.71 7.78 8.81 10.69 C 6.67 8.50 4.51 6.33 2.31 4.19 C 5.22 3.29 8.14 2.43 11.13 1.87 Z"
                                                fill="#f2fafc" />
                                            </svg>
                                            {{ row["name"] }}
                                          </span>
                                          <!-- <span class="listivo-blog-categories__count"> 2 </span> -->
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-5d8fadc1 elementor-widget elementor-widget-spacer"
                                data-id="5d8fadc1" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-76b0834c elementor-widget elementor-widget-lst_posts_sidebar"
                                data-id="76b0834c" data-element_type="widget"
                                data-widget_type="lst_posts_sidebar.default">
                                <div class="elementor-widget-container">
                                  <div class="listivo-sidebar-widget">
                                    <h3 class="listivo-sidebar-widget__label">
                                      {{
                                        blogPageCms.popularPostHeading
                                      }}
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 913.000000 42.000000"
                                        preserveAspectRatio="xMidYMid meet">
                                        <g transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                          stroke="none">
                                          <path
                                            d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z">
                                          </path>
                                        </g>
                                      </svg>
                                    </h3>
                                    <div class="listivo-sidebar-widget__content">
                                      <div class="listivo-widget-posts">
                                        <div class="listivo-widget-posts__row" v-for="(row, index) in popularBlogs"
                                          :key="index">
                                          <div class="listivo-widget-posts__image">
                                            <router-link :to="{
                                                  name: 'SinglePost',
                                                  params: { id: row['id'] },
                                                }" :title="row.title">
                                              <img :src="$imageURL + 'blog/' + row.photo" :alt="row.title" />
                                            </router-link>
                                          </div>
                                          <div>
                                            <router-link :to="{
                                                  name: 'SinglePost',
                                                  params: { id: row['id'] },
                                                }" :title="row.title" class="listivo-widget-posts__title">
                                              {{row.title}}
                                            </router-link>
                                            <div class="listivo-widget-posts__date">
                                              {{$filters.formatDate(row.createdAt)}}
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-26a2b348 elementor-widget elementor-widget-spacer"
                                data-id="26a2b348" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-c4c4b6 elementor-widget elementor-widget-lst_tags_cloud"
                                data-id="c4c4b6" data-element_type="widget" data-widget_type="lst_tags_cloud.default">
                                <div class="elementor-widget-container">
                                  <div class="listivo-sidebar-widget">
                                    <h3 class="listivo-sidebar-widget__label">
                                      {{ blogDetailPageCmsData.tagHeading }}
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 913.000000 42.000000"
                                        preserveAspectRatio="xMidYMid meet">
                                        <g transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                          stroke="none">
                                          <path
                                            d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z">
                                          </path>
                                        </g>
                                      </svg>
                                    </h3>
                                    <div class="listivo-sidebar-widget__content">
                                      <div class="listivo-tags-cloud">
                                        <a v-for="(row, index) in blogTags" @click="getTagWiseBlog(row['name'])"
                                          :key="index" href="#" class="tag-cloud-link tag-link-167 tag-link-position-1"
                                          style="font-size: 16.4pt">{{ row["name"] }}</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-4bf44792 elementor-widget elementor-widget-spacer">
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-6ace747 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="6ace747" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                          <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-224b532b"
                            data-id="224b532b" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                              <div
                                class="elementor-element elementor-element-60d8e112 elementor-widget elementor-widget-spacer"
                                data-id="60d8e112" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  </div>
</template>

<script src="../js/blog.js"></script>
<style scoped>
.listivo-pagination-mobile__inner{
  margin-bottom: 30px;
}
</style>