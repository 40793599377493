import Loading from "vue-loading-overlay";
export default {
    components: {
        Loading,
    },
    data() {
        return {
            pageCMS: {},
            socialLink: {},
            isLoggedIn: false,
            fullname: '',
            categoryList: [],
            isHidden: false,
            isHiddens: "menu-item-has-children--open",
        };
    },
    async mounted() {
        this.isLoggedIn = localStorage.getItem(this.$token);
        this.fullname = localStorage.getItem(this.$name);
        this.pageCMS = JSON.parse(localStorage.getItem('pageCMS'));
        this.socialLink = JSON.parse(localStorage.getItem('socialLink'));
        this.categoryList = JSON.parse(localStorage.getItem('productRawCategories'));
        await this.getProductCategory();
        await this.getSiteSettings();
    },
    methods: {
        listivoMenuIconWrapper(){
            var d = document.getElementById("menuOpen");
                d.classList.add("listivo-active");
        },
        listivoMenuIconWrapperClose(){
            var d = document.getElementById("menuOpen");
                d.classList.remove("listivo-active");
        },
        hasMenu(){
            var d = document.getElementById("listivo-menu-element-menu-item-2-485");
                d.classList.add("listivo-open");
                d.classList.add("menu-item-has-children--open");
                this.isHasMenu = 'active'
        },
        hasMenuOff(){
            var d = document.getElementById("listivo-menu-element-menu-item-2-485");
                d.classList.remove("listivo-open");
                d.classList.remove("menu-item-has-children--open");
                console.log("asd");
                this.isHasMenu = 'inactive'
        },
        showHide() {
            this.isHidden = !this.isHidden;
        },
        getSiteSettings: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.siteSettings.siteSettings,

            };
            await this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.pageCMS = response.data.pageCMS;
                        this.socialLink = response.data.socialLink;
                        console.log("page cms is: ", this.pageCMS);
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
        getProductCategory: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.products.productCategory,

            };
            await this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.categoryList = response.data;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
        logout: function() {
            localStorage.removeItem("token");
            this.$router.push("/login")
        }
    }
}