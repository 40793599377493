<template>
  <div class="listivo-pagination-mobile__inner">
    <div
      class="
        listivo-pagination-mobile__arrow listivo-pagination-mobile__arrow--left
      "
      @click="onClickPreviousPage"
      :disabled="isInFirstPage"
    >
      <i class="fa fa-chevron-left"></i>
    </div>

    <!-- Visible Buttons Start -->

    <span
      v-for="page in pages"
      :key="page.name"
      class="listivo-pagination-mobile__page"
      :disabled="page.isDisabled"
      :class="page.isDisabled ? ' listivo-pagination-mobile__page--active' : ''"
      @click="onClickPage(page.name)"
    >
    </span>
    <span class="listivo-pagination-mobile__start">{{ startPage }}</span>
    <span class="listivo-pagination-mobile__middle">/</span>
    <span class="listivo-pagination-mobile__end">{{ totalPages }}</span>
  
    <!-- Visible Buttons End -->

    <div
      class="
        listivo-pagination-mobile__arrow listivo-pagination-mobile__arrow--right
      "
      type="button"
      @click="onClickNextPage"
      :disabled="isInLastPage"
    >
      <i class="fa fa-chevron-right"></i>
    </div>
  </div>
</template>

<script>
// import $ from 'jquery';
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 2,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        // return this.totalPages - this.maxVisibleButtons;
        return this.currentPage;
      }

      // When inbetween
      return this.currentPage - 1;
    },
    pages() {
      const range = [];
      console.log("this.startPage",this.startPage)
      for (
        let i = this.startPage;
        i <=
        Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++
      ) {
        if (i > 0) {
          range.push({
            name: i,
            isDisabled: i === this.currentPage,
          });
        }
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      if (this.currentPage - 1 >= 1) {
        this.$emit("pagechanged", this.currentPage - 1);
      }
    },
    onClickPage(page) {
      //this.currentPage=page;
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      if (this.currentPage + 1 <= this.totalPages) {
        this.$emit("pagechanged", this.currentPage + 1);
      }
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>

<style>
.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}

.active {
  background-color: #4aae9b;
  color: #ffffff;
}
</style>