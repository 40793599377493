<template>
  <div
    data-elementor-type="wp-post"
    data-elementor-id="57"
    class="elementor elementor-57"
    data-elementor-settings="[]"
  >
    <div class="elementor-section-wrap">
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-de45e04 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="de45e04"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70803c3"
            data-id="70803c3"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-d6d46ed elementor-widget elementor-widget-lst_menu"
                data-id="d6d46ed"
                data-element_type="widget"
                data-widget_type="lst_menu.default"
              >
                <div class="elementor-widget-container">
                  <Header />
                </div>
              </div>
              <div
                class="elementor-element elementor-element-7807579 elementor-widget elementor-widget-lst_template_loader"
                data-id="7807579"
                data-element_type="widget"
                data-widget_type="lst_template_loader.default"
              >
                <div class="elementor-widget-container">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="1587"
                    class="elementor elementor-1587"
                    data-elementor-settings="[]"
                  >
                    <div class="elementor-section-wrap">
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-714310b listivo-bg-100-auto elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="714310b"
                        data-element_type="section"
                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}"
                      >
                        <div class="elementor-container elementor-column-gap-default">
                          <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-35aead8"
                            data-id="35aead8"
                            data-element_type="column"
                          >
                            <div class="elementor-widget-wrap elementor-element-populated">
                              <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-48222fe elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="48222fe"
                                data-element_type="section"
                              >
                                <div class="elementor-container elementor-column-gap-default">
                                  <div
                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-160e398"
                                    data-id="160e398"
                                    data-element_type="column"
                                  >
                                    <div class="elementor-widget-wrap elementor-element-populated">
                                      <div
                                        class="elementor-element elementor-element-8743712 elementor-widget elementor-widget-spacer"
                                        data-id="8743712"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="elementor-spacer">
                                            <div class="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-2eec4ad elementor-widget elementor-widget-lst_login_and_register"
                                        data-id="2eec4ad"
                                        data-element_type="widget"
                                        data-widget_type="lst_login_and_register.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="listivo-app">
                                            <div class="listivo-access" style="margin: 0 auto;">
                                              <div>
                                                <div class="listivo-access__tabs">
                                                  <h3 class="listivo-access__tab">Reset Your Password</h3>
                                                </div>

                                                  <div>
                                                    <form class="listivo-access__form" method="get" action="#">
                                                      
                                                      
                                                      <div class="listivo-access__field">
                                                        <input v-if="resetFlag"
                                                          v-model="password"
                                                          type="password"
                                                          class="listivo-access__field__input"
                                                          placeholder="New Password"
                                                        />
                                                      </div>
                                                      <div class="listivo-access__field">
                                                        <input  v-if="resetFlag"
                                                          v-model="confirm_password"
                                                          type="password"
                                                          class="listivo-access__field__input"
                                                          placeholder="Repeat New Password"
                                                        />
                                                      </div>
                                                      <div class="listivo-access__main-button"  v-if="resetFlag">
                                                        <button
                                                          type="button"  v-on:click="updatePassword()"
                                                          class="listivo-primary-button listivo-primary-button--icon"
                                                        >
                                                          <span
                                                            class="listivo-primary-button__text"
                                                          >Save New Password</span>
                                                          <span
                                                            class="listivo-primary-button__icon"
                                                          >
                                                            <i class="fas fa-sign-in-alt"></i>
                                                          </span>
                                                        </button>
                                                      </div>
                                                      
                                                    </form>
                                                  </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="elementor-element elementor-element-d688b63 elementor-widget elementor-widget-spacer"
                                        data-id="d688b63"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div class="elementor-widget-container">
                                          <div class="elementor-spacer">
                                            <div class="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  </div>
</template>
<script src="../js/ResetPassword.js"></script>