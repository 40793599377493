<template>
    <div class="listivo-panel-content listivo-panel-content--no-listings">
        <div class="listivo-panel-content-heading-wrapper">
            <h2 class="listivo-panel-content-heading">
                <div>
                    You do not have any listings yet. </div>
                <div class="listivo-panel-content-heading__arrow"><svg xmlns="http://www.w3.org/2000/svg" version="1.0"
                        viewBox="0 0 346.000000 258.000000" preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,258.000000) scale(0.100000,-0.100000)" stroke="none">
                            <path
                                d="M3071 2511 c-12 -16 -21 -33 -21 -37 0 -5 -11 -31 -24 -59 -57 -117         -86 -184 -86 -198 0 -16 26 -47 38 -47 7 0 51 83 71 135 7 17 23 43 36 60 22         27 25 28 31 10 4 -11 11 -26 17 -32 14 -19 47 3 47 32 0 13 4 26 8 29 5 3 53         -17 108 -44 90 -45 100 -48 112 -33 6 9 12 20 12 24 0 9 -62 56 -92 70 -13 5         -41 21 -63 34 -63 38 -155 85 -165 85 -5 0 -18 -13 -29 -29z">
                            </path>
                            <path
                                d="M3177 2193 c-11 -11 -8 -128 4 -140 5 -5 21 -8 35 -5 21 4 24 10 24         49 0 67 -37 123 -63 96z">
                            </path>
                            <path
                                d="M3201 1876 c-18 -19 -22 -33 -19 -72 3 -47 4 -49 33 -49 30 0 30 1         33 61 4 76 -13 97 -47 60z">
                            </path>
                            <path
                                d="M3173 1578 c-5 -7 -15 -33 -22 -57 -12 -41 -11 -46 5 -58 32 -24 48         -11 58 44 6 39 5 57 -4 68 -15 18 -25 19 -37 3z">
                            </path>
                            <path
                                d="M3075 1243 c-25 -55 -25 -58 -8 -71 21 -15 43 -12 53 8 27 55 40 94         33 100 -4 4 -18 11 -30 15 -20 5 -26 -1 -48 -52z">
                            </path>
                            <path
                                d="M2960 991 c-11 -22 -24 -42 -29 -45 -4 -3 -6 -17 -3 -31 4 -21 9 -25         30 -23 19 2 32 15 49 47 27 52 29 77 7 85 -27 10 -33 7 -54 -33z">
                            </path>
                            <path
                                d="M2780 729 c-28 -36 -31 -44 -21 -66 l11 -24 28 19 c51 37 74 69 67         91 -11 35 -48 26 -85 -20z">
                            </path>
                            <path
                                d="M37 563 c-4 -3 -7 -16 -7 -28 0 -28 70 -76 100 -68 29 8 23 53 -8 69         -13 6 -29 17 -35 23 -12 12 -40 15 -50 4z">
                            </path>
                            <path
                                d="M2566 514 c-37 -33 -37 -36 -21 -54 16 -18 19 -18 43 -2 48 31 75 61         68 77 -9 26 -49 17 -90 -21z">
                            </path>
                            <path
                                d="M287 409 c-17 -10 -16 -12 3 -39 25 -35 93 -58 116 -39 20 17 18 24         -20 52 -40 31 -75 40 -99 26z">
                            </path>
                            <path
                                d="M2370 373 c-8 -3 -32 -16 -52 -30 -36 -25 -37 -26 -23 -53 l15 -29         45 26 c53 29 72 57 54 79 -12 15 -15 15 -39 7z">
                            </path>
                            <path
                                d="M557 283 c-4 -3 -7 -17 -7 -30 0 -32 82 -70 117 -54 35 16 28 33 -26         64 -49 27 -72 33 -84 20z">
                            </path>
                            <path
                                d="M2080 230 c-8 -6 -27 -14 -42 -20 -18 -6 -28 -17 -28 -30 0 -29 23         -34 83 -18 47 13 52 17 52 44 0 24 -5 29 -25 31 -14 1 -32 -2 -40 -7z">
                            </path>
                            <path
                                d="M841 167 c-6 -6 -11 -17 -11 -25 0 -17 84 -54 113 -50 31 4 35 43 5         61 -31 20 -93 28 -107 14z">
                            </path>
                            <path
                                d="M1758 128 c-27 -7 -38 -15 -38 -29 0 -27 21 -33 88 -23 52 8 57 11         57 34 0 22 -5 25 -35 27 -19 1 -52 -3 -72 -9z">
                            </path>
                            <path
                                d="M1135 105 c-3 -8 -7 -21 -9 -29 -8 -24 16 -36 70 -36 87 0 94 51 9         70 -55 13 -65 12 -70 -5z">
                            </path>
                            <path
                                d="M1457 93 c-33 -5 -45 -27 -26 -49 11 -13 25 -15 68 -11 62 7 91 26         67 46 -17 14 -69 21 -109 14z">
                            </path>
                        </g>
                    </svg></div>
                <div>
                    <router-link to="/add-listing"  class="listivo-button-outline-v6">
                        Add Listing
                        <span class="listivo-button-outline__icon"><i class="fas fa-plus"></i></span></router-link>
                </div>
            </h2>
            <!---->
            <!---->
            <!---->
        </div>
    </div>
</template>