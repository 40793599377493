import Header from '../../../include/Header';
import Footer from '../../../include/Footer';
import Carasoul from '../../index/include/Carasoul';
import { authHeader } from "../../../../auth";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import $ from 'jquery';
import YouTube from 'vue3-youtube';
import { onMounted } from "vue";
export default {
    name: 'Product',
    title: 'Product',
    data() {
        return {
            productDetail: {},
            productDetail_video_link: false,
            imagePath: this.$imageURL + 'product/',
            images: [],
            center: {lat: 0, lng: 0},
            location: null,
            userInfo: {
                phoneNumber: ''
            },
            ownerProducts: [],
            relatedProducts: [],
            memberSince: '',
            reveal: false,
            message: null,
            productId: null,
            isLoggedin: false,
            messages: [],
            attachment: [],
            currentSlide:0
            
        }
    },
    components: {
        Header,
        Footer,
        Carasoul,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        YouTube

    },
    async mounted() {
        $('html,body').animate({ scrollTop: 0 }, 'slow');
        this.isLoggedin = localStorage.getItem('token');
        this.productId = this.$route.params.id
        await this.getProductDetails(this.$route.params.id);
        if(this.isLoggedin) {
            await this.getProductMessageList();
        }
        $(".carousel__prev").css("margin-left","30px");
        $(".carousel__next").css("margin-right","30px");


        const map = new window.google.maps.Map(document.getElementById('address-map'), {
            center: {lat: this.productDetail.lat, lng: this.productDetail.lng},
            zoom: 13,
            draggable: true,
            styles: [{
                elementType: 'geometry',
                stylers: [{
                    color: '#242f3e'
                }]
            }, {
                elementType: 'labels.text.stroke',
                stylers: [{
                    color: '#242f3e'
                }]
            }, {
                elementType: 'labels.text.fill',
                stylers: [{
                    color: '#746855'
                }]
            }, {
                featureType: 'administrative.locality',
                elementType: 'labels.text.fill',
                stylers: [{
                    color: '#d59563'
                }]
            }, {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [{
                    color: '#d59563'
                }]
            }, {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [{
                    color: '#263c3f'
                }]
            }, {
                featureType: 'poi.park',
                elementType: 'labels.text.fill',
                stylers: [{
                    color: '#6b9a76'
                }]
            }, {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{
                    color: '#38414e'
                }]
            }, {
                featureType: 'road',
                elementType: 'geometry.stroke',
                stylers: [{
                    color: '#212a37'
                }]
            }, {
                featureType: 'road',
                elementType: 'labels.text.fill',
                stylers: [{
                    color: '#9ca5b3'
                }]
            }, {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [{
                    color: '#746855'
                }]
            }, {
                featureType: 'road.highway',
                elementType: 'geometry.stroke',
                stylers: [{
                    color: '#1f2835'
                }]
            }, {
                featureType: 'road.highway',
                elementType: 'labels.text.fill',
                stylers: [{
                    color: '#f3d19c'
                }]
            }, {
                featureType: 'transit',
                elementType: 'geometry',
                stylers: [{
                    color: '#2f3948'
                }]
            }, {
                featureType: 'transit.station',
                elementType: 'labels.text.fill',
                stylers: [{
                    color: '#d59563'
                }]
            }, {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [{
                    color: '#17263c'
                }]
            }, {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [{
                    color: '#515c6d'
                }]
            }, {
                featureType: 'water',
                elementType: 'labels.text.stroke',
                stylers: [{
                    color: '#17263c'
                }]
            }]
        });
        const marker = new window.google.maps.Marker({
            map: map,
            position: {lat: this.productDetail.lat, lng: this.productDetail.lng},
            draggable: false,
        });

        marker.setVisible(true);

    },
    beforeCreate: function() {
        document.body.className = 'listivo_listing-template-default single single-listivo_listing postid-1402 theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-5228';

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/asset/wp-content/uploads/elementor/css/post-374.css';
        document.head.appendChild(style);


    },
    methods: {
        getProductMessageList: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.message.getProductMessage + this.productId,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.isLoading = false;
                    if(response.data.cms) {
                        this.messages = response.data.cms;
                    }
                    
                }
            }).catch((error) => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });

        },
        onReady() {
            //this.$refs.youtube.playVideo()
        },
        youtube_parser: function(url){
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            var match = url.match(regExp);
            return (match&&match[7].length==11)? match[7] : false;
        },
        getProductDetails: async function(id) {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.products.productDetails + id
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.productDetail = response.data.productsDetail;
                    console.log(this.productDetail);
                    let getVideoID = '';
                    if(this.productDetail.videoLink != null){
                         getVideoID=this.youtube_parser(this.productDetail.videoLink);
                    }
                    this.productDetail_video_link=getVideoID;
                    this.userInfo = response.data.productsDetail.owner_data;
                    this.ownerProducts = response.data.productsDetail.owner_product;
                    this.relatedProducts = response.data.productsDetail.related_product;
                    this.images = response.data.productsDetail.photos;
                    this.location = response.data.productsDetail.location;
                    this.memberSince = this.getMonthDifference(this.$filters.userDate(this.userInfo.updatedAt), this.$filters.userDate(new Date()));
                    console.log("this.images",this.memberSince);

                    for(const key in Object.keys(response.data.productsDetail.photos)) {
                        if (response.data.productsDetail.photos[key].itemType != 'Gallery') {
                            this.attachment.push(response.data.productsDetail.photos[key]);
                        }
                        
                    }



                }
                    this.getLocationFrom(this.location);
                    this.isLoading = false;

                    $('html,body').animate({ scrollTop: 0 }, 'slow');
                    

            }).catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });

        },
        getMonthDifference(st, ed) {
            console.log("st: ", st);
            let endDate = new Date(ed);
            let startDate  = new Date(st);
            return (
              endDate.getMonth() -
              startDate.getMonth() +
              12 * (endDate.getFullYear() - startDate.getFullYear())
            );
        },
        async getLocationFrom(address) {
            try {
               var {
                  data
               } = await this.$axios.get(
                  "https://maps.googleapis.com/maps/api/geocode/json?address=" +
                  address +
                  "&key=" + this.$mapAPI
                  
               );
               if (data.error_message) {
                  console.log(data.error_message)
               } else {
                  let lat = data.results[0].geometry.location.lat;
                  let lng = data.results[0].geometry.location.lng;
                  this.center = { lat: lat, lng: lng}
                  
                  console.log("Address is: ", this.center);
               }
            } catch (error) {
               console.log(error.message);
            }
         },
         movetomap: function (e) {

            // window.location.href="#listivo-model-form__map--153";
            $('html,body').animate({ scrollTop: $(".elementor-widget-lst_listing_map").offset().top}, 'slow');
            e.preventDefault();
            e.stopPropagation(); 
     
        },
        revealPhone: function() {
            if(localStorage.getItem('token')) {
                this.reveal = !this.reveal;
            }
            else {
                this.$swal.fire({
                    icon: "error",
                    text: "To reveal number please log in or register!",
                });
            }
            
            
        },
        sendMessage: async function() {
            let data = {
                productId: this.productId,
                productMessage: this.message,
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.products.sendMessage,
                data: data,
                headers: authHeader()
            };
            if (localStorage.getItem('token')){
                await this.$axios(config)
                .then((response) => {
                    if (response.status == 201) {
                        this.message = null;
                        this.$swal.fire({
                            icon: "success",
                            text: "Your Message have been sent.",
                        });
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
            }
            else {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Log In or Register",
                });
            }
        }
    },
    setup() {

        onMounted(() => {
    
          const googleMapScript = document.createElement("SCRIPT");
          googleMapScript.setAttribute(
            "src",
            `https://maps.googleapis.com/maps/api/js?key=AIzaSyBAQTQIGNCLnH93PMerongNRnj9S84C1kA`
          );
          googleMapScript.setAttribute("defer", "");
          googleMapScript.setAttribute("async", "");
          document.head.appendChild(googleMapScript);
    
    
        });
    
      },
}
