<template>
  <div
    data-elementor-type="wp-post"
    data-elementor-id="57"
    class="elementor elementor-57"
    data-elementor-settings="[]"
  >
    <div class="elementor-section-wrap">
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-de45e04 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="de45e04"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70803c3"
            data-id="70803c3"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-d6d46ed elementor-widget elementor-widget-lst_menu"
                data-id="d6d46ed"
                data-element_type="widget"
                data-widget_type="lst_menu.default"
              >
                <div class="elementor-widget-container">
                  <Header />
                </div>
              </div>
              <div
                class="elementor-element elementor-element-7807579 elementor-widget elementor-widget-lst_template_loader"
                data-id="7807579"
                data-element_type="widget"
                data-widget_type="lst_template_loader.default"
              >
                <div class="elementor-widget-container">
                  <div
                    data-elementor-type="wp-post"
                    data-elementor-id="824"
                    class="elementor elementor-824"
                    data-elementor-settings="[]"
                  >
                    <div class="elementor-section-wrap">
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-3243b4fa elementor-hidden-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="3243b4fa"
                        data-element_type="section"
                      >
                        <div
                          class="elementor-container elementor-column-gap-default"
                        >
                          <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-60135aa9"
                            data-id="60135aa9"
                            data-element_type="column"
                          >
                            <div
                              class="elementor-widget-wrap elementor-element-populated"
                              style="margin-top: 35px;"
                            >
                              <div
                                class="elementor-element elementor-element-211de73b elementor-widget elementor-widget-spacer"
                                data-id="211de73b"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-37465450 elementor-widget elementor-widget-lst_breadcrumbs"
                                data-id="37465450"
                                data-element_type="widget"
                                data-widget_type="lst_breadcrumbs.default"
                              >
                                <div class="elementor-widget-container">
                                  <div>
                                    <div class="listivo-breadcrumbs-wrapper">
                                      <div class="listivo-breadcrumbs">
                                        <div class="listivo-breadcrumbs__single" >
                      
                                          <router-link title="Home" class="listivo-breadcrumbs__link" to="/">Home</router-link>
                                          <span class="listivo-breadcrumbs__separator"
                                            ><svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="11"
                                              height="11"
                                              viewBox="0 0 11 11"
                                            >
                                              <g>
                                                <g>
                                                  <path
                                                    d="M4.974.635c-.119.12-.119.246 0 .38l3.987 3.973H.587c-.178 0-.267.09-.267.268v.223c0 .179.089.268.267.268H8.96L4.974 9.721c-.119.133-.119.26 0 .379l.156.178c.134.119.26.119.379 0l4.7-4.732c.133-.119.133-.238 0-.357L5.508.457c-.119-.12-.245-.12-.379 0z"
                                                  ></path>
                                                </g>
                                              </g></svg></span>
                                        </div>
                                        <div class="listivo-breadcrumbs__last">
                                          <router-link title="Blog" to="/blog">Blog</router-link>
                                          <span class="listivo-breadcrumbs__separator"
                                            ><svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="11"
                                              height="11"
                                              viewBox="0 0 11 11"
                                            >
                                              <g>
                                                <g>
                                                  <path
                                                    d="M4.974.635c-.119.12-.119.246 0 .38l3.987 3.973H.587c-.178 0-.267.09-.267.268v.223c0 .179.089.268.267.268H8.96L4.974 9.721c-.119.133-.119.26 0 .379l.156.178c.134.119.26.119.379 0l4.7-4.732c.133-.119.133-.238 0-.357L5.508.457c-.119-.12-.245-.12-.379 0z"
                                                  ></path>
                                                </g>
                                              </g></svg></span>
                                        </div>
                                        <div class="listivo-breadcrumbs__single" >
                      
                                          <span>{{blogDetail.title}}</span>

                                        </div>
                                      </div>
                                      <!---->
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-3923bba7 elementor-widget elementor-widget-spacer"
                                data-id="3923bba7"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-ac8cef5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="ac8cef5"
                        data-element_type="section"
                        style="margin-top: 17px;"
                      >
                        <div
                          class="elementor-container elementor-column-gap-default"
                        >
                          <div
                            class="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-c4aee50"
                            data-id="c4aee50"
                            data-element_type="column"
                          >
                            <div
                              class="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                class="elementor-element elementor-element-353a48c elementor-widget elementor-widget-lst_blog_post"
                                data-id="353a48c"
                                data-element_type="widget"
                                data-widget_type="lst_blog_post.default"
                              >
                                <div class="elementor-widget-container">
                                  <article class="listivo-post">
                                    <div class="listivo-post-widget-image">
                                      <img
                                        v-bind:src="
                                          imagePath + blogDetail.photo
                                        "
                                        :alt="blogDetail.title"
                                      />
                                    </div>
                                    <div class="listivo-post-meta">
                                      <div
                                        class="listivo-post-meta__single listivo-post-meta__single--user"
                                      >
                                        <div class="listivo-post-author">
                                          <a
                                            class="listivo-post-author-image"
                                            href="#"
                                            :title="blogDetail.postedBy"
                                          >
                                            <img
                                              v-bind:src="
                                                imagePath +
                                                blogDetail.UploaderPhoto
                                              "
                                              :alt="blogDetail.postedBy"
                                            />
                                          </a>
                                          <div>
                                            <a
                                              href="#"
                                              :title="blogDetail.postedBy"
                                              rel="author"
                                              >{{ blogDetail.postedBy }}</a
                                            >
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="listivo-post-meta__single listivo-post-meta__single--date"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 63 72"
                                        >
                                          <path
                                            class="cls-1"
                                            d="M326.5,907v-7.312A1.491,1.491,0,0,0,324.812,898h-1.124A1.491,1.491,0,0,0,322,899.688V907H295v-7.312A1.491,1.491,0,0,0,293.312,898h-1.124a1.491,1.491,0,0,0-1.688,1.688V907h-6.75a6.723,6.723,0,0,0-6.75,6.75v49.5a6.723,6.723,0,0,0,6.75,6.75h49.5a6.723,6.723,0,0,0,6.75-6.75v-49.5a6.723,6.723,0,0,0-6.75-6.75H326.5Zm6.75,4.5a2.167,2.167,0,0,1,2.25,2.25v6.75h-54v-6.75a2.167,2.167,0,0,1,2.25-2.25h49.5Zm-49.5,54a2.167,2.167,0,0,1-2.25-2.25V925h54v38.25a2.167,2.167,0,0,1-2.25,2.25h-49.5Zm15.75-24.188v-5.624A1.491,1.491,0,0,0,297.812,934h-5.624a1.491,1.491,0,0,0-1.688,1.688v5.624A1.491,1.491,0,0,0,292.188,943h5.624a1.491,1.491,0,0,0,1.688-1.688h0Zm13.5,0v-5.624A1.491,1.491,0,0,0,311.312,934h-5.624A1.491,1.491,0,0,0,304,935.688v5.624A1.491,1.491,0,0,0,305.688,943h5.624A1.491,1.491,0,0,0,313,941.312h0Zm13.5,0v-5.624A1.491,1.491,0,0,0,324.812,934h-5.624a1.491,1.491,0,0,0-1.688,1.688v5.624A1.491,1.491,0,0,0,319.188,943h5.624a1.491,1.491,0,0,0,1.688-1.688h0Zm-13.5,13.5v-5.624a1.491,1.491,0,0,0-1.688-1.688h-5.624A1.491,1.491,0,0,0,304,949.188v5.624a1.491,1.491,0,0,0,1.688,1.688h5.624A1.491,1.491,0,0,0,313,954.812h0Zm-13.5,0v-5.624a1.491,1.491,0,0,0-1.688-1.688h-5.624a1.491,1.491,0,0,0-1.688,1.688v5.624a1.491,1.491,0,0,0,1.688,1.688h5.624a1.491,1.491,0,0,0,1.688-1.688h0Zm27,0v-5.624a1.491,1.491,0,0,0-1.688-1.688h-5.624a1.491,1.491,0,0,0-1.688,1.688v5.624a1.491,1.491,0,0,0,1.688,1.688h5.624a1.491,1.491,0,0,0,1.688-1.688h0Z"
                                            transform="translate(-277 -898)"
                                          />
                                        </svg>
                                        <span>
                                          {{
                                            $filters.formatDate(
                                              blogDetail.createdAt
                                            )
                                          }}</span
                                        >
                                      </div>
                                      <div
                                        class="listivo-post-meta__single listivo-post-meta__single--comments"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 72.031 63"
                                        >
                                          <path
                                            class="cls-1"
                                            d="M264.641,835.75q0,10.266-9.211,17.508T233.141,860.5a37.505,37.505,0,0,1-11.672-1.828l-2.11-.7-1.828,1.406a30,30,0,0,1-14.484,5.484,36.72,36.72,0,0,0,5.765-9.7l0.985-2.672-1.969-2.109a20.611,20.611,0,0,1-6.187-14.625q0-10.263,9.211-17.508T233.141,811q13.077,0,22.289,7.242T264.641,835.75Zm-67.5,0q0,9.844,7.453,17.719a31.278,31.278,0,0,1-2.6,5.133,30.017,30.017,0,0,1-2.672,3.8q-1.055,1.2-1.336,1.477a3.151,3.151,0,0,0-.562,3.586,3.082,3.082,0,0,0,3.094,2.039,33.235,33.235,0,0,0,19.546-6.469A44.635,44.635,0,0,0,233.141,865q14.9,0,25.453-8.578t10.547-20.672q0-12.093-10.547-20.672T233.141,806.5q-14.908,0-25.453,8.578T197.141,835.75h0Z"
                                            transform="translate(-197.125 -806.5)"
                                          />
                                        </svg>
                                        <span
                                          >{{
                                            blogDetail.totalComments
                                          }}
                                          Comments</span
                                        >
                                      </div>
                                      <div
                                        class="listivo-post-meta__single listivo-post-meta__single--categories"
                                      >
                                        <i class="far fa-folder-open"></i>
                                        <span
                                          ><a href="#" rel="category tag"
                                            >{{ blogDetail.categoryName }}
                                            <span v-if="blogDetail.tags > 0"
                                              >| {{ blogDetail.tags }}
                                            </span></a
                                          ></span
                                        >
                                      </div>
                                    </div>
                                    <h1 class="listivo-post-title">
                                      {{ blogDetail.title }}
                                    </h1>
                                    <div
                                      id="post-454"
                                      class="post-454 post type-post status-publish format-standard has-post-thumbnail hentry category-lifestyle tag-baby-accessories tag-buy tag-community"
                                    >
                                      <div class="listivo-post-content">
                                        <div
                                          class="listivo-post-inner"
                                          v-html="blogDetail.longDetail"
                                        ></div>
                                        <div class="listivo-post-content-end">
                                          <!-- <div class="listivo-post-tags">
                                                                 <div
                                                                    class="listivo-post-tags__inner">
                                                                    <a href="tag/baby-accessories/"
                                                                       rel="tag">Baby
                                                                    Accessories</a> <a
                                                                       href="tag/buy/"
                                                                       rel="tag">Buy</a> <a
                                                                       href="tag/community/"
                                                                       rel="tag">Community</a>
                                                                 </div>
                                                              </div>
                                                              <div class="listivo-post-social-share">
                                                                 <div class="listivo-social-share">
                                                                    <a href="https://www.facebook.com/sharer/sharer.php?u=best-holiday-gift-ideas-for-2021/"
                                                                       target="_blank"
                                                                       class="listivo-social-share__single">
                                                                    <i
                                                                       class="fab fa-facebook-f"></i>
                                                                    </a>
                                                                    <a href="https://twitter.com/share?url=best-holiday-gift-ideas-for-2021/"
                                                                       target="_blank"
                                                                       class="listivo-social-share__single">
                                                                    <i
                                                                       class="fab fa-twitter"></i>
                                                                    </a>
                                                                 </div>
                                                              </div> -->
                                        </div>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-f648a74 elementor-widget elementor-widget-lst_related_blog_posts"
                                data-id="f648a74"
                                data-element_type="widget"
                                data-widget_type="lst_related_blog_posts.default"
                                v-if="blogRelatedPosts.length > 0"
                              >
                                <div class="elementor-widget-container">
                                  <div class="listivo-related-blog-posts">
                                    <h3
                                      class="listivo-related-blog-posts__label"
                                    >
                                      {{ blogDetailPageCmsData.similarPostHeading }}
                                    </h3>
                                    <div
                                      class="listivo-related-blog-posts__list"
                                    >
                                      <div
                                        class="listivo-blog-card-v2"
                                        v-for="(row, index) in blogRelatedPosts"
                                        :key="index"
                                      >
                                        <router-link
                                          class="listivo-blog-card-v2__image"
                                          :to="{
                                            name: 'SinglePost',
                                            params: {
                                              id: row.relatedPostDetails.id,
                                            },
                                          }"
                                        >
                                          <img
                                            class="lazyloaded"
                                            :src="
                                              imagePath +
                                              row.relatedPostDetails.photo
                                            "
                                            :alt="row.title"
                                            :data-srcset="
                                              imagePath +
                                              row.relatedPostDetails.photo +
                                              ' 750w, ' +
                                              imagePath +
                                              row.relatedPostDetails.photo +
                                              ' 600w,' +
                                              imagePath +
                                              row.relatedPostDetails.photo +
                                              ' 300w'
                                            "
                                            data-sizes="auto"
                                          />
                                        </router-link>
                                        <div
                                          class="listivo-blog-card-v2__content"
                                        >
                                          <div
                                            class="listivo-blog-card-v2__top"
                                          >
                                            <div
                                              class="listivo-blog-card-v2__head"
                                            >
                                              <router-link
                                                class="listivo-blog-card-v2__label"
                                                :to="{
                                                  name: 'SinglePost',
                                                  params: {
                                                    id: row.relatedPostDetails
                                                      .id,
                                                  },
                                                }"
                                              >
                                                <h3>
                                                  {{
                                                    row.relatedPostDetails.title
                                                  }}
                                                </h3>
                                              </router-link>
                                              <div
                                                class="listivo-blog-card-v2__meta"
                                              >
                                                <a
                                                  class="listivo-blog-card-v2__value listivo-blog-card-v2__value--link"
                                                  href="user/sophia-kennith/"
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                  >
                                                    <path
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="2"
                                                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                    />
                                                  </svg>
                                                  {{
                                                    row.relatedPostDetails
                                                      .postedBy
                                                  }}
                                                </a>
                                                <span
                                                  class="listivo-blog-card-v2__value"
                                                >
                                                  {{
                                                    $filters.formatDate(
                                                      row.relatedPostDetails
                                                        .createdAt
                                                    )
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              class="listivo-blog-card-v2__text"
                                            >
                                              {{
                                                row.relatedPostDetails
                                                  .shortDetail
                                              }}
                                            </div>
                                          </div>
                                          <div
                                            class="listivo-blog-card-v2__button"
                                          >
                                            <router-link
                                              :to="{
                                                name: 'SinglePost',
                                                params: {
                                                  id: row.relatedPostDetails.id,
                                                },
                                              }"
                                              class="listivo-button-outline listivo-button-outline--v3"
                                            >
                                              Read More
                                              <span
                                                class="listivo-button-outline__icon"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="7"
                                                  height="11"
                                                  viewBox="0 0 9 14"
                                                >
                                                  <g>
                                                    <g>
                                                      <path
                                                        d="M8.32 6.57L2.023.245c-.284-.327-.587-.327-.907 0L.742.627c-.284.29-.284.6 0 .927l5.443 5.452-5.443 5.452c-.32.327-.32.637 0 .927l.374.381c.32.328.623.328.907 0L8.32 7.442c.285-.29.285-.58 0-.872z"
                                                      />
                                                    </g>
                                                  </g>
                                                </svg>
                                              </span>
                                            </router-link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-a942f68 elementor-widget elementor-widget-lst_blog_post_comments"
                                data-id="a942f68"
                                data-element_type="widget"
                                data-widget_type="lst_blog_post_comments.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="listivo-comments">
                                    <div id="respond" class="comment-respond">
                                      <h3
                                        id="reply-title"
                                        class="comment-reply-title"
                                      >
                                        {{
                                                blogDetailPageCmsData.commentHeading
                                              }}
                                        <small
                                          ><a
                                            rel="nofollow"
                                            id="cancel-comment-reply-link"
                                            href="#"
                                            style="display: none"
                                            >Cancel reply</a
                                          ></small
                                        >
                                      </h3>
                                      <form
                                        v-on:submit.prevent="postComment"
                                        action="wp-comments-post.php"
                                        method="post"
                                        id="commentform"
                                        class="comment-form"
                                        novalidate
                                      >
                                        <p class="comment-notes">
                                          <span id="email-notes"
                                            >{{
                                                blogDetailPageCmsData.commentSubHeading
                                              }}</span
                                          >
                                          Required fields are marked
                                          <span class="required">*</span>
                                        </p>
                                        <p class="comment-form-comment">
                                          <textarea
                                            id="comment"
                                            name="comment"
                                            v-model="comment"
                                            placeholder="Comments"
                                            aria-required="true"
                                          ></textarea>
                                        </p>
                                        <p class="comment-form-author">
                                          <input
                                            id="author"
                                            name="author"
                                            v-model="name"
                                            placeholder="Name*"
                                            type="text"
                                            size="30"
                                            aria-required="true"
                                          />
                                        </p>
                                        <p class="comment-form-email">
                                          <input
                                            id="email"
                                            name="email"
                                            v-model="email"
                                            placeholder="E-mail*"
                                            type="text"
                                            size="30"
                                            aria-required="true"
                                          />
                                        </p>
                                        <p class="comment-form-url">
                                          <input
                                            id="url"
                                            name="url"
                                            v-model="website"
                                            placeholder="Website"
                                            type="text"
                                            size="30"
                                          />
                                        </p>
                                        <!-- <p class="comment-form-cookies-consent">
                                          <input
                                            id="wp-comment-cookies-consent"
                                            name="wp-comment-cookies-consent"
                                            type="checkbox"
                                            value="yes"
                                          />
                                          <label
                                            for="wp-comment-cookies-consent"
                                            >Save my name, email, and website in
                                            this browser for the next time I
                                            comment.</label
                                          >
                                        </p> -->
                                        <p class="form-submit">
                                          <button
                                            id="submit"
                                            class="listivo-primary-button listivo-primary-button--icon"
                                          >
                                            <span
                                              class="listivo-primary-button__text"
                                              >{{
                                                blogDetailPageCmsData.commentButtonText
                                              }}
                                              </span
                                            ><span
                                              class="listivo-primary-button__icon"
                                              ><i class="fas fa-comment"></i
                                            ></span>
                                          </button>
                                          <input
                                            type="hidden"
                                            name="comment_post_ID"
                                            value="3666"
                                            id="comment_post_ID"
                                          />
                                          <input
                                            type="hidden"
                                            name="comment_parent"
                                            id="comment_parent"
                                            value="0"
                                          />
                                        </p>
                                      </form>
                                    </div>
                                    <h4
                                      id="listivo-comments"
                                      class="listivo-comments__heading-top-count"
                                    >
                                      <span
                                        class="listivo-comments__heading-top-count__title"
                                      >
                                        Comments
                                      </span>
                                      <span
                                        class="listivo-comments__heading-top-count__count"
                                      >
                                        <span>(</span
                                        ><span>{{ blogComments.length }}</span
                                        ><span>)</span>
                                      </span>
                                    </h4>
                                    <div
                                      v-for="(row, index) in blogComments"
                                      :key="index"
                                      class="comment byuser comment-author-george-brown odd alt thread-odd thread-alt depth-1 listivo-comment"
                                      id="comment-21"
                                    >
                                      <div class="listivo-comment__content">
                                        <div class="listivo-comment__avatar">
                                          <img
                                            :alt="row.name"
                                            src="/img/user.webp"
                                            srcset="/img/user.webp 2x"
                                            class="avatar avatar-85 photo"
                                            height="85"
                                            width="85"
                                            loading="lazy"
                                          />
                                        </div>
                                        <div class="listivo-comment__bg">
                                          <div class="listivo-comment__top">
                                            <h4
                                              class="listivo-comment__user-name"
                                            >
                                              <a href="#">{{ row.name }}</a>
                                            </h4>
                                            <div class="listivo-comment__date">
                                              {{
                                                $filters.formatDate(
                                                  row.createdAt
                                                )
                                              }}
                                            </div>
                                            <!-- <div class="listivo-comment__reply">
                                                  <a rel="nofollow" class="comment-reply-link" @click="deleteComment(row.id)" href="javascript:void(0)" data-respondelement="respond" data-replyto="Reply to " aria-label="Reply to">
                                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"/></svg>
                                                      <span>Delete</span>
                                                  </a>
                                                </div> -->
                                          </div>
                                          <div class="listivo-comment__text">
                                            <p v-html="row.comment"></p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-92d0c1f"
                            data-id="92d0c1f"
                            data-element_type="column"
                          >
                            <div
                              class="elementor-widget-wrap elementor-element-populated"
                              style="display: block;"
                            >
                              <div
                                class="elementor-element elementor-element-740b59a elementor-widget elementor-widget-lst_blog_keyword_search"
                                data-id="740b59a"
                                data-element_type="widget"
                                data-widget_type="lst_blog_keyword_search.default"
                              >
                                <div class="elementor-widget-container">
                                  <form method="get" action="" role="search">
                                    <div class="listivo-blog-keyword-search">
                                      <input
                                        class="listivo-blog-keyword-search__input"
                                        type="text"
                                        v-model="search"
                                        name="s"
                                        placeholder="Search..."
                                      />
                                      <button
                                        @click="searchBlog"
                                        class="listivo-blog-keyword-search__button"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="17"
                                          height="18"
                                          viewBox="0 0 17 18"
                                        >
                                          <g>
                                            <g>
                                              <path
                                                fill="#fff"
                                                d="M7.636-.004c4.227 0 7.666 3.639 7.666 8.112a8.409 8.409 0 0 1-1.178 4.316l2.346 2.483a1.876 1.876 0 0 1 0 2.549 1.638 1.638 0 0 1-2.41 0l-2.345-2.482a7.322 7.322 0 0 1-4.08 1.247c-4.227 0-7.666-3.64-7.666-8.113 0-4.473 3.439-8.112 7.667-8.112zm0 14.422c3.288 0 5.963-2.83 5.963-6.31 0-3.478-2.675-6.31-5.963-6.31-3.289 0-5.964 2.832-5.964 6.31 0 3.48 2.675 6.31 5.964 6.31z"
                                              />
                                            </g>
                                          </g>
                                        </svg>
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-915e27a elementor-widget elementor-widget-spacer"
                                data-id="915e27a"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-76637f2 elementor-widget elementor-widget-lst_blog_categories"
                                data-id="76637f2"
                                data-element_type="widget"
                                data-widget_type="lst_blog_categories.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="listivo-sidebar-widget">
                                    <h3 class="listivo-sidebar-widget__label">
                                      {{
                                        blogDetailPageCmsData.categoryHeading
                                      }}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 913.000000 42.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                      >
                                        <g
                                          transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                          stroke="none"
                                        >
                                          <path
                                            d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z"
                                          ></path>
                                        </g>
                                      </svg>
                                    </h3>
                                    <div
                                      class="listivo-sidebar-widget__content"
                                    >
                                      <div class="listivo-blog-categories">
                                        <a
                                          @click="categorySearch(row.id)"
                                          v-for="(row, index) in blogCategories"
                                          :key="index"
                                          class="listivo-blog-categories__category-wrapper"
                                          href="#"
                                        >
                                          <span
                                            class="listivo-blog-categories__category"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 13 13"
                                              width="13.0pt"
                                              height="13.0pt"
                                            >
                                              <path
                                                d="M 11.88 0.00 L 13.00 0.00 L 13.00 1.15 C 11.79 5.07 10.78 9.04 9.70 13.00 L 9.34 13.00 C 6.16 9.99 3.00 6.84 0.00 3.66 L 0.00 3.29 C 3.97 2.22 7.94 1.20 11.88 0.00 Z"
                                                fill="#e55c69"
                                              />
                                              <path
                                                d="M 11.13 1.87 C 10.57 4.87 9.71 7.78 8.81 10.69 C 6.67 8.50 4.51 6.33 2.31 4.19 C 5.22 3.29 8.14 2.43 11.13 1.87 Z"
                                                fill="#f2fafc"
                                              />
                                            </svg>
                                            {{ row["name"] }}
                                          </span>
                                          <!-- <span
                                            class="listivo-blog-categories__count"
                                          >
                                            2
                                          </span> -->
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-f8f0ef7 elementor-widget elementor-widget-spacer"
                                data-id="f8f0ef7"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-7ff95ce elementor-widget elementor-widget-lst_posts_sidebar"
                                data-id="7ff95ce"
                                data-element_type="widget"
                                data-widget_type="lst_posts_sidebar.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="listivo-sidebar-widget">
                                    <h3 class="listivo-sidebar-widget__label">
                                      {{
                                        blogDetailPageCmsData.popularPostHeading
                                      }}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 913.000000 42.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                      >
                                        <g
                                          transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                          stroke="none"
                                        >
                                          <path
                                            d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z"
                                          ></path>
                                        </g>
                                      </svg>
                                    </h3>
                                    <div
                                      class="listivo-sidebar-widget__content"
                                    >
                                      <div class="listivo-widget-posts">
                                        <div
                                          class="listivo-widget-posts__row"
                                          v-for="(row, index) in popularBlogs"
                                          :key="index"
                                        >
                                          <div
                                            class="listivo-widget-posts__image"
                                          >
                                            <router-link
                                              :to="{
                                                name: 'SinglePost',
                                                params: { id: row['id'] },
                                              }"
                                              :title="row.title"
                                            >
                                              <img
                                                :src="
                                                  $imageURL +
                                                  'blog/' +
                                                  row.photo
                                                "
                                                :alt="row.title"
                                              />
                                            </router-link>
                                          </div>
                                          <div>
                                            <router-link
                                              :to="{
                                                name: 'SinglePost',
                                                params: { id: row['id'] },
                                              }"
                                              :title="row.title"
                                              class="listivo-widget-posts__title"
                                            >
                                              {{ row.title }}
                                            </router-link>
                                            <div
                                              class="listivo-widget-posts__date"
                                            >
                                              {{
                                                $filters.formatDate(
                                                  row.createdAt
                                                )
                                              }}
                                            </div>
                                          </div>
                                        </div>
                                        <!-- <div class="listivo-widget-posts__row">
                                          <div
                                            class="listivo-widget-posts__image"
                                          >
                                            <a
                                              href="find-the-best-deals-onlinefind-only-best-affordable-deals/"
                                              title="Find Only Best &#038; Affordable Deals"
                                            >
                                              <img
                                                v-bind:src="'/asset/wp-content/uploads/2021/09/popular-post-3.jpg'"
                                                alt="Find Only Best &#038; Affordable Deals"
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <a
                                              href="find-the-best-deals-onlinefind-only-best-affordable-deals/"
                                              title="Find Only Best &#038; Affordable Deals"
                                              class="listivo-widget-posts__title"
                                            >
                                              Find Only Best &#038; Affordable
                                              Deals
                                            </a>
                                            <div
                                              class="listivo-widget-posts__date"
                                            >
                                              June 5, 2021
                                            </div>
                                          </div>
                                        </div> -->
                                        <!-- <div class="listivo-widget-posts__row">
                                          <div
                                            class="listivo-widget-posts__image"
                                          >
                                            <a
                                              href="the-25-cheapest-places-to-travel/"
                                              title="The 25 Cheapest Places To Travel"
                                            >
                                              <img
                                                v-bind:src="'/asset/wp-content/uploads/2021/06/blog-4-150x150.jpg'"
                                                alt="The 25 Cheapest Places To Travel"
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <a
                                              href="the-25-cheapest-places-to-travel/"
                                              title="The 25 Cheapest Places To Travel"
                                              class="listivo-widget-posts__title"
                                            >
                                              The 25 Cheapest Places To Travel
                                            </a>
                                            <div
                                              class="listivo-widget-posts__date"
                                            >
                                              June 5, 2021
                                            </div>
                                          </div>
                                        </div> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-a099f64 elementor-widget elementor-widget-spacer"
                                data-id="a099f64"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-4928e92 elementor-widget elementor-widget-lst_tags_cloud"
                                data-id="4928e92"
                                data-element_type="widget"
                                data-widget_type="lst_tags_cloud.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="listivo-sidebar-widget">
                                    <h3 class="listivo-sidebar-widget__label">
                                      {{ blogDetailPageCmsData.tagHeading }}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 913.000000 42.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                      >
                                        <g
                                          transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                          stroke="none"
                                        >
                                          <path
                                            d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z"
                                          ></path>
                                        </g>
                                      </svg>
                                    </h3>
                                    <div
                                      class="listivo-sidebar-widget__content"
                                    >
                                      <div class="listivo-tags-cloud">
                                        <a
                                          @click="tagSearch(row.name)"
                                          v-for="(row, index) in blogTags"
                                          :key="index"
                                          href="#"
                                          class="tag-cloud-link tag-link-167 tag-link-position-1"
                                          style="font-size: 16.4pt"
                                          :aria-label="row['name']"
                                          >{{ row["name"] }}</a
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-ecf52f8 elementor-widget elementor-widget-spacer"
                                data-id="ecf52f8"
                                data-element_type="widget"
                                data-widget_type="spacer.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="elementor-spacer">
                                    <div class="elementor-spacer-inner"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  </div>
</template>
<script src="../js/blogDetail.js"></script>
