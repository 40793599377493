import Header from "../../../include/Header";
import Footer from "../../../include/Footer";
import { authHeader } from "../../../../auth";
import Subscribe from "../../../include/Subscribe";
import $ from 'jquery';

export default {
    name: 'Faq',
    title: 'Faq',
    components: {
        Header,
        Footer,
        Subscribe
    },
    mounted() {},
    beforeCreate: function() {
        document.body.className = 'page-template-default page page-id-604 theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-6 elementor-page elementor-page-604';

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/asset/wp-content/uploads/elementor/css/post-604.css';
        document.head.appendChild(style);

        //'elementor-heading-title'
    },
    data() {
        return {
            faqPageCMS: {},
            faqCategories: {},
            arrow_toggle: false,
            detail_arrow: 'fas fa-arrow-left',
            display: 'display: none;',
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactMessage: '',
            imagePath: this.$imageURL + "faq/",
        }
    },
    created() {
        this.getFaqPageCms();
        //this.getFaqCategories();
        //this.getFaqContent();
    },
    methods: {
        getFaqPageCms: async function() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.faq.faqs,
                };
                await this.$axios(config).then((response) => {

                    if (response.status == 200) {
                        this.faqPageCMS = response.data.faqPageCMS;
                        this.faqCategories = response.data.faqCategories;
                        console.log('Faq = ', this.faqCategories);
                    } else {
                        console.log('Faq Failed = ', this.faqCategories);
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error)
            }
        },
        getFaqCategories: async function() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.faq.getFaqCategory,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.faqCategoryList = response.data
                    }
                    console.log(this.faqCategoryList);
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error)
            }
        },
        getFaqContent: async function() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.faq.getFaqContent,
                    //headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.faqContent = response.data
                    }
                    console.log(this.faqContent);
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error)
            }
        },
        showDetailsFaq: function(event) {
            console.log(event.currentTarget.id);

            //this.detail_arrow = '';
            //this.display = 'overflow:hidden;'
            //this.arrow_toggle = true;
            $("#" + event.currentTarget.id).children('div').children('i').toggleClass('fas fa-arrow-left');
            $("#" + event.currentTarget.id).children('div').children('i').toggleClass('fas fa-arrow-down');
            $("#" + event.currentTarget.id).parent().children('div:eq(1)').toggle();


        },
        submitInfo: function() {
            let data = {
                contactName: this.contactName,
                contactEmail: this.contactEmail,
                contactPhone: this.contactPhone,
                contactMessage: this.contactMessage
            }
            console.log("data : ", data);
            let config = {
                method: "POST",
                data: data,
                url: this.$serverURL + this.$api.contactUs.contactUsURL,

            };
            this.$axios(config)
                .then((response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully Saved your Info",
                        });
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
    }
}