import Header from "../../../include/Header";
import Footer from "../../../include/Footer";
export default {
  name: "Forgot Password",
  title: "Forgot Password ",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      email: null,
      current_password: "",
      password: "",
      confirm_password: "",
      isActive: 0,
    };
  },
  beforeCreate: function () {
    document.body.className =
      "page-template-default page page-id-1587 theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-5228 elementor-page elementor-page-1587";

    let style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href = "asset/wp-content/uploads/elementor/css/post-1587.css";
    document.head.appendChild(style);
  },
  methods: {
    async forgetPassword() {
      let data = {
        email: this.email,
      };
      try {
        let config = {
          method: "POST",
          data: data,
          url: this.$serverURL + this.$api.auth.forgotPassword,
        };
        await this.$axios(config)
          .then((response) => {
            if (response.status == 201) {
              this.isActive = 1;

              this.$swal.fire({
                  icon: "success",
                  text: "Your password reset link sent to your email!",
              });
              this.$router.push('/login');
            }
          })
          .catch((error) => {
            console.log(error);
            this.$swal.fire({
                icon: "error",
                text: "Your email address does not match!",
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
    updatePassword() {
      this.isLoading = false;
      if (this.password == "") {
        this.$swal.fire({
          icon: "error",
          text: "Please enter your password!",
        });
      } else if (this.confirm_password == "") {
        this.isLoading = false;
        this.$swal.fire({
          icon: "error",
          text: "Please enter your confirm password!",
        });
      } else if (this.password != this.confirm_password) {
        this.isLoading = false;
        this.$swal.fire({
          icon: "error",
          text: "Password & Confirm Password does not match!",
        });
      } else {
        if (this.password.length < 6) {
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            text: "You have to enter at least 6 digit!",
          });
        } else {
          this.isLoading = false;
          let data = {
            email: this.email,
          };
          try {
            let config = {
              method: "POST",
              data: data,
              url: this.$serverURL + this.$api.auth.updatePassword,
            };
            this.$axios(config)
              .then((response) => {
                if (response.status == 201) {
                  this.isActive = 1;

                  // this.$swal.fire({
                  //     icon: "success",
                  //     text: "Your password reset link sent to your email!",
                  // });
                  // this.$router.push('/login');
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
  },
};
