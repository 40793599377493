import Header from "../../../include/Header";
import Footer from "../../../include/Footer";
import testimonials from "../../index/include/Testimonials";
import YouTube from 'vue3-youtube'
import Subscribe from "../../../include/Subscribe";
import $ from 'jquery';
export default {
    name: 'About us',
    title: 'About us',
    components: {
        Header,
        Footer,
        testimonials,
        Subscribe,
        YouTube
    },
    data() {
        return {
            aboutPageList: [],
            imagePath: this.$imageURL + "about/",
            aboutHeaderCms: {},
            aboutContentCms: {},
            aboutExploreItem: {},
            aboutAssistBlockCms: {},
            aboutTeamCms: {videoLink:''},
            aboutTeamCms_videoLink:""
        }
    },
    created() {
        
    },
    mounted() {
        $('html,body').animate({ scrollTop: 0 }, 'slow');
        this.getAboutPageCms();
    },
    beforeCreate: function() {
        document.body.className = 'page-template-default page page-id-471 theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-5228 elementor-page elementor-page-471';

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/asset/wp-content/uploads/elementor/css/post-471.css';
        document.head.appendChild(style);
        
        // let recaptchaScript = document.createElement('script')
        // recaptchaScript.setAttribute('src', 'asset/wp-content/plugins/elementor/assets/lib/jquery-numerator/jquery-numerator.min.js')
        // document.head.appendChild(recaptchaScript)

    },
    methods: {
        getAboutPageCms: async function() {
            try {
                
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.aboutPage.aboutPageURL,
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        // console.log(response);
                        this.aboutPageList = response.data;
                        this.aboutHeaderCms = response.data.aboutHeaderCms;
                        this.aboutContentCms = response.data.aboutContentCms;
                        this.aboutExploreItem = response.data.aboutExploreItem;
                        this.aboutAssistBlockCms = response.data.aboutAssistBlockCms;
                        this.aboutTeamCms = response.data.aboutTeamCms;

                        // var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                        // var match = this.aboutTeamCms.videoLink.match(regExp);
                        // const urlIDYoutube = (match&&match[7].length==11)? match[7] : false;
                        const urlIDYoutube = this.youtube_parser(this.aboutTeamCms.videoLink);
                        this.aboutTeamCms_videoLink=urlIDYoutube;
                        console.log('this.aboutContentCms.videoLink =',this.aboutTeamCms_videoLink);
                        $('html,body').animate({ scrollTop: 0 }, 'slow');
                    }

                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error)
            }
        },
        youtube_parser: function(url){
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            var match = url.match(regExp);
            return (match&&match[7].length==11)? match[7] : false;
        },
        onReady() {
            //this.$refs.youtube.playVideo()
        },
    }
}