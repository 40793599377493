import $ from 'jquery';
import { authHeader } from "../../../../auth";
export default {
  name: 'Chat Message',
  title: 'Chat Message',
  components:{

  },
  props:['conversation', 'requesterName', 'seqID', 'prodID'],
  data (){
     return {
       text: null
     }
  },
  mounted() {
    console.log("Seq Pro: ", this.seqID, this.prodID);
  },
  beforeCreate: function() {
        
    },
    methods: {
        closeChatMessage:function(){
            $('#live-chat').fadeOut(300);
            // this.$emit('chatStatus', false);
            
            // this.chatStatus = false;
        },
        getMessageDetails: async function() {
          let config = {
            method: "GET",
            url: this.$serverURL + this.$api.message.getConversation + this.prodID + '/' + this.seqID,
            headers: authHeader()
        }
        await this.$axios(config)
            .then((response) => {
                if (response.status == 200) {
                  let conv = []
                    conv = response.data.cms;
                    this.$emit('conversationChanged', conv);
                }
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
            console.log("Conversation: ", this.conversation);
        },
        replyMessage: function() {
          let data = {
            messageSequenceID: this.seqID,
            productId: this.prodID,
            productMessage: this.text,
        };
        let config = {
            method: "POST",
            url: this.$serverURL + this.$api.message.replyMessage,
            data: data,
            headers: authHeader()
        };
        if (localStorage.getItem('token')){
            this.$axios(config)
            .then((response) => {
                if (response.status == 201) {
                  this.getMessageDetails();
                    this.text = null;
                }
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!",
                });
            });
        }
        else {
            this.$swal.fire({
                icon: "error",
                text: "Please Log In or Register",
            });
        }
    
        }

    }
}