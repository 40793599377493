<template>
  <div
    data-elementor-type="wp-post"
    data-elementor-id="57"
    class="elementor elementor-57"
    data-elementor-settings="[]"
  >
    <div class="elementor-section-wrap">
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-de45e04 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
        data-id="de45e04"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70803c3"
            data-id="70803c3"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-d6d46ed elementor-widget elementor-widget-lst_menu"
                data-id="d6d46ed"
                data-element_type="widget"
                data-widget_type="lst_menu.default"
              >
                <div class="elementor-widget-container">
                  <Header />
                </div>
              </div>
              <div
                class="elementor-element elementor-element-7807579 elementor-widget elementor-widget-lst_template_loader"
                data-id="7807579"
                data-element_type="widget"
                data-widget_type="lst_template_loader.default"
              >
                <div class="elementor-widget-container">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="1719"
                    class="elementor elementor-1719"
                    data-elementor-settings="[]"
                  >
                    <div class="elementor-section-wrap">
                      <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-167a15d elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                        data-id="167a15d"
                        data-element_type="section"
                      >
                        <div
                          class="elementor-container elementor-column-gap-default"
                        >
                          <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-34829c6"
                            data-id="34829c6"
                            data-element_type="column"
                          >
                            <div
                              class="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                class="elementor-element elementor-element-a932591 elementor-widget elementor-widget-lst_panel"
                                data-id="a932591"
                                data-element_type="widget"
                                data-widget_type="lst_panel.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="listivo-panel__header">
                                    <div class="listivo-panel__header-inner">
                                      <h1>User Profile</h1>
                                      <div
                                        class="listivo-panel__header-buttons"
                                      >
                                        <router-link
                                          :to="{ name: 'Listing' }"
                                          class="listivo-button-outline listivo-button-outline--v2"
                                        >
                                          Dashboard
                                          <span
                                            class="listivo-button-outline__icon"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="9"
                                              height="14"
                                              viewBox="0 0 9 14"
                                            >
                                              <g>
                                                <g>
                                                  <path
                                                    fill="#fff"
                                                    d="M8.32 6.57L2.023.245c-.284-.327-.587-.327-.907 0L.742.627c-.284.29-.284.6 0 .927l5.443 5.452-5.443 5.452c-.32.327-.32.637 0 .927l.374.381c.32.328.623.328.907 0L8.32 7.442c.285-.29.285-.58 0-.872z"
                                                  ></path>
                                                </g>
                                              </g>
                                            </svg>
                                          </span>
                                        </router-link>
                                        <router-link
                                          :to="{ name: 'Listing' }"
                                          class="listivo-button-outline"
                                        >
                                          Listing
                                          <span
                                            class="listivo-button-outline__icon"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="9"
                                              height="14"
                                              viewBox="0 0 9 14"
                                            >
                                              <g>
                                                <g>
                                                  <path
                                                    fill="#fff"
                                                    d="M8.32 6.57L2.023.245c-.284-.327-.587-.327-.907 0L.742.627c-.284.29-.284.6 0 .927l5.443 5.452-5.443 5.452c-.32.327-.32.637 0 .927l.374.381c.32.328.623.328.907 0L8.32 7.442c.285-.29.285-.58 0-.872z"
                                                  ></path>
                                                </g>
                                              </g>
                                            </svg>
                                          </span>
                                        </router-link>
                                        <router-link
                                          :to="{ name: 'Profile' }"
                                          class="listivo-button-outline listivo-button-outline--v2"
                                        >
                                          Profile
                                          <span
                                            class="listivo-button-outline__icon"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="9"
                                              height="14"
                                              viewBox="0 0 9 14"
                                            >
                                              <g>
                                                <g>
                                                  <path
                                                    fill="#fff"
                                                    d="M8.32 6.57L2.023.245c-.284-.327-.587-.327-.907 0L.742.627c-.284.29-.284.6 0 .927l5.443 5.452-5.443 5.452c-.32.327-.32.637 0 .927l.374.381c.32.328.623.328.907 0L8.32 7.442c.285-.29.285-.58 0-.872z"
                                                  ></path>
                                                </g>
                                              </g>
                                            </svg>
                                          </span>
                                        </router-link>
                                      </div>
                                      <div class="listivo-profile-mobile">
                                        <div class="listivo-panel__menu" style="padding-top:10px">
                                          <router-link :to="{ name: 'Listing' }" class="
                                                listivo-button-outline
                                                listivo-button-outline--v2 
                                              " style="margin-right:10px">
                                            Dashboard
                                          </router-link>
                                          <router-link :to="{ name: 'Profile' }" class="
                                                listivo-button-outline
                                                listivo-button-outline--v2 listivo-profile-mobile-active
                                              ">
                                            Profile
                                          </router-link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="listivo-panel__menu-wrapper">
                                    <div class="listivo-panel__menu">
                                      <div id="accountDetails" style="cursor: pointer"
                                        @click="accountDetails"
                                        class="listivo-panel__menu-pill listivo-panel__menu-pill--active listivo-panel__menu-pill--create"
                                      >
                                        
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                                          ></path>
                                        </svg>
                                        Account Details
                                      </div>
                                      <div id="profileImage" @click="profileImage" style="cursor: pointer"
                                        class="listivo-panel__menu-pill  listivo-panel__menu-pill--listings"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                          ></path>
                                        </svg>
                                        Profile Image
                                      </div>
                                      <div id="socialLinks" style="cursor: pointer"
                                        @click="socialLinks"
                                        class="listivo-panel__menu-pill listivo-panel__menu-pill--favorites"
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                          stroke="currentColor">
                                           <path stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2" d="M384 32C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H384zM320 96C284.7 96 256 124.7 256 160C256 162.5 256.1 164.9 256.4 167.3L174.5 212C162.8 199.7 146.3 192 128 192C92.65 192 64 220.7 64 256C64 291.3 92.65 320 128 320C146.3 320 162.8 312.3 174.5 299.1L256.4 344.7C256.1 347.1 256 349.5 256 352C256 387.3 284.7 416 320 416C355.3 416 384 387.3 384 352C384 316.7 355.3 288 320 288C304.6 288 290.5 293.4 279.4 302.5L194.1 256L279.4 209.5C290.5 218.6 304.6 224 320 224C355.3 224 384 195.3 384 160C384 124.7 355.3 96 320 96V96z"/></svg>
                                        Social Links
                                      </div>
                                      <div id="changePassword" @click="changePassword" style="cursor: pointer"
                                        class="listivo-panel__menu-pill listivo-panel__menu-pill--favorites"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                                          ></path>
                                        </svg>
                                        Change Password
                                      </div>
                                    </div>
                                  </div>
                                  <div class="listivo-panel-my-listings">
                                    <div class="listivo-listing-list-wrapper">
                                      <div class="" style="padding-top: 10px">
                                        <div v-if="accountDetailsFlag == true">
                                          <form @submit.prevent="updateProfile">
                                            <div
                                              class="listivo-settings-fields-wrapper"
                                            >
                                              <div
                                                class="listivo-settings-fields"
                                              >
                                                <div
                                                  class="listivo-settings-field listivo-settings-field--display-name"
                                                >
                                                  <label
                                                    for="listivo-name"
                                                    class="listivo-settings-field-label"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      stroke="currentColor"
                                                    >
                                                      <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="1.4"
                                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                      ></path>
                                                    </svg>
                                                    Display Name
                                                  </label>
                                                  <div>
                                                    <input
                                                      id="listivo-name"
                                                      type="text"
                                                      v-model="name"
                                                      placeholder="Enter your display name"
                                                    />
                                                  </div>
                                                </div>
                                                <div
                                                  class="listivo-settings-field listivo-settings-field--phone"
                                                >
                                                  <label
                                                    for="listivo-phone"
                                                    class="listivo-settings-field-label"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      stroke="currentColor"
                                                    >
                                                      <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="1.4"
                                                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                                      ></path>
                                                    </svg>
                                                    Phone Number
                                                  </label>
                                                  <div>
                                                    <input
                                                      id="listivo-phone"
                                                      type="tel"
                                                      v-model="phone"
                                                      placeholder="Enter your phone number"
                                                    />
                                                  </div>
                                                </div>
                                                <div
                                                  class="listivo-settings-field listivo-settings-field--description"
                                                >
                                                  <label
                                                    for="listivo-description"
                                                    class="listivo-settings-field-label"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      stroke="currentColor"
                                                    >
                                                      <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="1.4"
                                                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                      ></path>
                                                    </svg>
                                                    Profile Description
                                                  </label>
                                                  <div>
                                                    <textarea
                                                      id="listivo-description"
                                                      type="text"
                                                      v-model="description"
                                                      placeholder="Write something about yourself"
                                                    ></textarea>
                                                  </div>
                                                </div>
                                                <div
                                                  class="listivo-settings-field listivo-settings-field--map"
                                                >
                                                  <label
                                                    for="listivo-user-address"
                                                    class="listivo-settings-field-label"
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                      stroke="currentColor"
                                                    >
                                                      <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="1"
                                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                                      ></path>
                                                      <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="1"
                                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                                      ></path>
                                                    </svg>
                                                    Address
                                                  </label>
                                                  <div>
                                                    <input
                                                      id="listivo-user-address"
                                                      type="text"
                                                      v-model="address"
                                                      placeholder="Enter your address"
                                                    />
                                                  </div>
                                                  <div
                                                    class="listivo-settings-fields__button"
                                                  >
                                                    <button
                                                      class="listivo-primary-button listivo-primary-button--icon"
                                                    >
                                                      <span
                                                        class="listivo-primary-button__text"
                                                      >
                                                        Save Changes
                                                      </span>
                                                      <span
                                                        class="listivo-primary-button__icon"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 20 20"
                                                          fill="currentColor"
                                                        >
                                                          <path
                                                            fill-rule="evenodd"
                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                            clip-rule="evenodd"
                                                          ></path>
                                                        </svg>
                                                      </span>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                        <div v-if="profileImageFlag == true">
                                          <form  @submit.prevent="updateProfile">
                                            <div
                                              class="listivo-settings-fields-wrapper"
                                            >
                                              <div
                                                class="listivo-settings-fields"
                                              >
                                                <div
                                                  class="listivo-settings-field"
                                                >
                                                  <label
                                                    for="listivo-facebook"
                                                    class="listivo-settings-field-label"
                                                    >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M194.6 32H317.4C338.1 32 356.4 45.22 362.9 64.82L373.3 96H448C483.3 96 512 124.7 512 160V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V160C0 124.7 28.65 96 64 96H138.7L149.1 64.82C155.6 45.22 173.9 32 194.6 32H194.6zM256 384C309 384 352 341 352 288C352 234.1 309 192 256 192C202.1 192 160 234.1 160 288C160 341 202.1 384 256 384z"/></svg>
                                                    Profile Image
                                                  </label>
                                                  <!-- <div>
                                                    <input
                                                      id="listivo-facebook"
                                                      type="file"
                                                      ref="profileImageFile"
                                                      @change="onProfileImageChange"
                                                      placeholder=" Please upload square image. "
                                                    />
                                                  </div>
                                                  <div>
                                                    <div id="preview">
                                                      <img v-if="imageShow" :src="imageShow"/>
                                                      <img v-if="flag == true" v-bind:src="$imageURL + 'user/' + profileImageDisplay" height="200" width="200"/>
                                                    </div>
                                                  </div> -->
                                                      <div class="avatar-upload">
        <div class="avatar-edit">
            <input type='file' id="imageUpload" @change="onProfileImageChange" accept=".png, .jpg, .jpeg" />
            <label for="imageUpload"></label>
        </div>
        <div class="avatar-preview">
            <div id="imagePreview" style="background-image: url(http://i.pravatar.cc/500?img=7);" 
            v-if="flag == true" :style="{backgroundImage: 'url(' + $imageURL + 'user/' +profileImageDisplay + ')'}">
            </div>
        </div>
        <div>
        </div>
    </div>
                                                </div>



                                                
                                                  <div
                                                    class="listivo-settings-fields__button"
                                                  >
                                                    <button
                                                      class="listivo-primary-button listivo-primary-button--icon"
                                                    >
                                                      <span
                                                        class="listivo-primary-button__text"
                                                      >
                                                        Save Changes
                                                      </span>
                                                      <span
                                                        class="listivo-primary-button__icon"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 20 20"
                                                          fill="currentColor"
                                                        >
                                                          <path
                                                            fill-rule="evenodd"
                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                            clip-rule="evenodd"
                                                          ></path>
                                                        </svg>
                                                      </span>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                          </form>
                                        </div>
                                        <div v-if="socialLinksFlag == true">
                                          <form  @submit.prevent="updateProfile">
                                            <div
                                              class="listivo-settings-fields-wrapper"
                                            >
                                              <div
                                                class="listivo-settings-fields"
                                              >
                                                <div
                                                  class="listivo-settings-field"
                                                >
                                                  <label
                                                    for="listivo-facebook"
                                                    class="listivo-settings-field-label"
                                                    ><i
                                                      class="fab fa-facebook-square"
                                                    ></i>
                                                    Facebook
                                                  </label>
                                                  <div>
                                                    <input
                                                      id="listivo-facebook"
                                                      type="text"
                                                      v-model="facebookLink"
                                                      placeholder="Enter the url to your Facebook profile"
                                                    />
                                                  </div>
                                                </div>
                                                <div
                                                  class="listivo-settings-field"
                                                >
                                                  <label
                                                    for="listivo-twitter"
                                                    class="listivo-settings-field-label"
                                                    ><i
                                                      class="fab fa-twitter-square"
                                                    ></i>
                                                    Twitter
                                                  </label>
                                                  <div>
                                                    <input
                                                      id="listivo-twitter"
                                                      type="text"
                                                      v-model="twitterLink"
                                                      placeholder="Enter the url to your Twitter profile"
                                                    />
                                                  </div>
                                                </div>
                                                <div
                                                  class="listivo-settings-field"
                                                >
                                                  <label
                                                    for="listivo-instagram"
                                                    class="listivo-settings-field-label"
                                                    ><i
                                                      class="fab fa-instagram-square"
                                                    ></i>
                                                    Instagram
                                                  </label>
                                                  <div>
                                                    <input
                                                      id="listivo-instagram"
                                                      type="text"
                                                      v-model="insagramLink"
                                                      placeholder="Enter the url to your Instagram profile"
                                                    />
                                                  </div>
                                                </div>
                                                <div
                                                  class="listivo-settings-field"
                                                >
                                                  <label
                                                    for="listivo-youtube"
                                                    class="listivo-settings-field-label"
                                                    ><i
                                                      class="fab fa-youtube-square"
                                                    ></i>
                                                    YouTube
                                                  </label>
                                                  <div>
                                                    <input
                                                      id="listivo-youtube"
                                                      type="text"
                                                      v-model="youtubeLink"
                                                      placeholder="Enter the url to your YouTube profile"
                                                    />
                                                  </div>
                                                </div>
                                                <div
                                                  class="listivo-settings-field"
                                                >
                                                  <label
                                                    for="listivo-linkedin"
                                                    class="listivo-settings-field-label"
                                                    ><i
                                                      class="fab fa-linkedin"
                                                    ></i>
                                                    LinkedIn
                                                  </label>
                                                  <div>
                                                    <input
                                                      id="listivo-linkedin"
                                                      type="text"
                                                      v-model="linkedinLink"
                                                      placeholder="Enter the url to your LinkedIn profile"
                                                    />
                                                  </div>
                                                  <div
                                                    class="listivo-settings-fields__button"
                                                  >
                                                    <button
                                                      class="listivo-primary-button listivo-primary-button--icon"
                                                    >
                                                      <span
                                                        class="listivo-primary-button__text"
                                                      >
                                                        Save Changes
                                                      </span>
                                                      <span
                                                        class="listivo-primary-button__icon"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 20 20"
                                                          fill="currentColor"
                                                        >
                                                          <path
                                                            fill-rule="evenodd"
                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                            clip-rule="evenodd"
                                                          ></path>
                                                        </svg>
                                                      </span>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                        <div v-if="changePasswordFlag == true">
                                          <form v-on:submit.prevent="updatePassword">
                                            <div
                                              class="listivo-settings-fields-wrapper"
                                            >
                                              <div
                                                class="listivo-settings-fields"
                                              >
                                                <div
                                                  class="listivo-settings-field"
                                                >
                                                  <label
                                                    for="listivo-facebook"
                                                    class="listivo-settings-field-label"
                                                    >
                                                    Old Password
                                                  </label>
                                                  <div>
                                                    <input
                                                      id="listivo-"
                                                      type="password"
                                                      v-model="currentPassword"
                                                      placeholder="Enter your old password"
                                                    />
                                                  </div>
                                                </div>
                                                <div
                                                  class="listivo-settings-field"
                                                >
                                                  <label
                                                    for="listivo-twitter"
                                                    class="listivo-settings-field-label"
                                                    >
                                                    New Password
                                                  </label>
                                                  <div>
                                                    <input
                                                      id="listivo-twitter"
                                                      type="password"
                                                      v-model="newPassword"
                                                      placeholder="Enter your new password"
                                                    />
                                                  </div>
                                                </div>
                                                <div
                                                  class="listivo-settings-field"
                                                >
                                                  <label
                                                    for="listivo-twitter"
                                                    class="listivo-settings-field-label"
                                                    >
                                                    Confirm Password
                                                  </label>
                                                  <div>
                                                    <input
                                                      id="listivo-twitter"
                                                      type="password"
                                                      v-model="confirmPassword"
                                                      placeholder="Enter your confirm password"
                                                    />
                                                  </div>
                                                </div>
                                                  <div
                                                    class="listivo-settings-fields__button"
                                                  >
                                                    <button
                                                      class="listivo-primary-button listivo-primary-button--icon"
                                      
                                                    >
                                                      <span
                                                        class="listivo-primary-button__text"
                                                      >
                                                        Save Changes
                                                      </span>
                                                      <span
                                                        class="listivo-primary-button__icon"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 20 20"
                                                          fill="currentColor"
                                                        >
                                                          <path
                                                            fill-rule="evenodd"
                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                            clip-rule="evenodd"
                                                          ></path>
                                                        </svg>
                                                      </span>
                                                    </button>
                                                  </div>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  </div>
</template>

<style scoped>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
  #preview {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #preview img {
    max-width: 100%;
    max-height: 300px;
  }
  .avatar-upload {
  position: relative;
  max-width: 205px;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

</style>

<script src="../js/profile.js"></script>
