import Header from '../../../include/Header';
import Footer from '../../../include/Footer';
import Pagination from '../../paginationResource/Pagination.vue';
import MobilePagination from '../../paginationResource/MobilePagination.vue';
import ListingMessage from '../template/message.vue';
import NoListing from '../template/NoListing.vue';

//listivo-panel__menu-pill--active
import $ from 'jquery';
import { authHeader } from "../../../../auth";
export default {
  name: 'My Listing',
  title: 'My Listing',
  components:{
    Header,
    Footer,
    Pagination,
    MobilePagination,
    ListingMessage,
    NoListing,
  },
  data (){
     return {
            selectFieldSort: 'Newest',
            filterFlag: false,
            searchText: '',
            searchFlag: false,
            sortByText: null,
            productList: [],
            messageList: [],
            sortData: null,
            keyword: null,
            page: 1,
            limit: 9,
            totalPage: 0,
            paginationStatus: "Main",
            featured: null,
            status: null,
            imagePath: this.$imageURL + 'product/',
            messageFlag: false
     }
  },
  mounted() {
    this.getListingData();
  },
  beforeCreate: function() {
        document.body.className = 'page-template-default page page-id-1719 logged-in theme-listivo listivo-1.0.19 listivo_user woocommerce-js elementor-default elementor-kit-5228 elementor-page elementor-page-1719 e--ua-blink e--ua-chrome e--ua-webkit';

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/asset/wp-content/uploads/elementor/css/post-1719.css';
        document.head.appendChild(style);
    },
    methods: {
      getApprovedList: async function() {
        this.page = 1;
        this.messageFlag = false;
        await this.getApprovedData();
      },
      getPendingList: async function() {
        this.page = 1;
        this.messageFlag = false;
        await this.getPendingData();
      },
      getMessageList: function() {
        this.productList = [];
        this.messageFlag = true;
        //await this.getPendingData();

        $('.listivo-panel__menu').children('.listivo-panel__menu-pill').removeClass('listivo-panel__menu-pill--active');
        $('.listivo-panel__menu').children('#myMessageListing').addClass('listivo-panel__menu-pill--active');
        
      },
      getFeaturedList: async function() {
        this.page = 1;
        this.messageFlag = false;
        await this.getFeaturedData();
      },
      getSearchedList: async function() {
        this.page = 1;
        this.messageFlag = false;
        await this.getSearchedData();
      },

      getApprovedData: async function() {
        $('.listivo-panel__menu').children('.listivo-panel__menu-pill').removeClass('listivo-panel__menu-pill--active');
        $('.listivo-panel__menu').children('#myApprovedListing').addClass('listivo-panel__menu-pill--active');
        this.paginationStatus = "Approved";
        this.status = "Approved";
        let params = "?page=" + this.page + "&limit=" + this.limit + "&status=Approved";
        if(this.keyword) {
          params += "&keyword=" + this.keyword;
        }
        if(this.sortData){
          params += "&sortby=" + this.sortData;
        }
        let config = {
          method: "GET",
          url: this.$serverURL + this.$api.listing.ItemSellProductList + params,
          headers: authHeader()
        };
      
        await this.$axios(config)
            .then((response) => {
                if (response.status == 200) {
                    this.productList = response.data.productsSellRequest;
                    this.totalPage = response.data.pageCount;
                }
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
      },
      getPendingData: async function() {
        $('.listivo-panel__menu').children('.listivo-panel__menu-pill').removeClass('listivo-panel__menu-pill--active');
        $('.listivo-panel__menu').children('#myPendingListing').addClass('listivo-panel__menu-pill--active');
        this.paginationStatus = "Pending";
        this.status = "Pending";
        let params = "?page=" + this.page + "&limit=" + this.limit + "&status=Pending";
        if(this.keyword) {
          params += "&keyword=" + this.keyword;
        }
        if(this.sortData){
          params += "&sortby=" + this.sortData;
        }
        let config = {
          method: "GET",
          url: this.$serverURL + this.$api.listing.ItemSellProductList + params,
          headers: authHeader()
        };
      
        await this.$axios(config)
            .then((response) => {
                if (response.status == 200) {
                    this.productList = response.data.productsSellRequest;
                    this.totalPage = response.data.pageCount;
                }
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
      },
      getFeaturedData: async function() {
        $('.listivo-panel__menu').children('.listivo-panel__menu-pill').removeClass('listivo-panel__menu-pill--active');
        $('.listivo-panel__menu').children('#myFeaturedListing').addClass('listivo-panel__menu-pill--active');
        this.paginationStatus = "Featured";
        this.featured = "featured";
        let params = "?page=" + this.page + "&limit=" + this.limit + "&featured=1";
        if(this.keyword) {
          params += "&keyword=" + this.keyword;
        }
        if(this.sortData){
          params += "&sortby=" + this.sortData;
        }
        let config = {
          method: "GET",
          url: this.$serverURL + this.$api.listing.ItemSellProductList + params,
          headers: authHeader()
        };
      
        await this.$axios(config)
            .then((response) => {
                if (response.status == 200) {
                    this.productList = response.data.productsSellRequest;
                    this.totalPage = response.data.pageCount;
                }
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
      },
      getSearchedData: async function() {
        this.paginationStatus = "Searched";
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if(this.keyword) {
          params += "&keyword=" + this.keyword;
        }
        if(this.sortData){
          params += "&sortby=" + this.sortData;
        }
        if (this.featured){
          params += "&featured=1";
        }
        if (this.status){
          params += "&status=" + this.status;
        }
        let config = {
          method: "GET",
          url: this.$serverURL + this.$api.listing.ItemSellProductList + params,
          headers: authHeader()
        };
      
        await this.$axios(config)
            .then((response) => {
                if (response.status == 200) {
                    this.productList = response.data.productsSellRequest;
                    this.totalPage = response.data.pageCount;
                }
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
      },
      
      getListingData: async function() {
        $('.listivo-panel__menu').children('.listivo-panel__menu-pill').removeClass('listivo-panel__menu-pill--active');
        $('.listivo-panel__menu').children('#myListing').addClass('listivo-panel__menu-pill--active');
        //$("listivo-panel__menu-pill").removeClass('listivo-panel__menu-pill--active');
        //$("#myListing").removeClass('listivo-panel__menu-pill--active');
        let params = "?page=" + this.page + "&limit=" + this.limit;
        this.messageFlag = false;
        this.paginationStatus = "Main";
        let config = {
          method: "GET",
          url: this.$serverURL + this.$api.listing.ItemSellProductList + params,
          headers: authHeader()

        };
      
        await this.$axios(config)
            .then((response) => {
                if (response.status == 200) {
                    this.productList = response.data.productsSellRequest;
                    this.totalPage = response.data.pageCount;
                }
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
      },
      onPageChange(SourceDataCounterPage) {
        console.log(SourceDataCounterPage)
        this.page = SourceDataCounterPage;
        console.log(SourceDataCounterPage)
        if(this.paginationStatus == "Main"){
            this.getListingData();
        }
        else if(this.paginationStatus == "Approved"){
            this.getApprovedData();
        }
        else if(this.paginationStatus == "Featured"){
          this.getFeaturedData();
        }
        else if(this.paginationStatus == "Pending"){
          this.getPendingData();
        }
        else {
          this.getSearchedData();
        }
        
        $('.listivo-pagination-desktop__page').removeClass('listivo-pagination-desktop__page--active');
        $('.listivo-pagination-desktop__page')
        .each(function(index,row){
            if($(row).html()==SourceDataCounterPage)
            {
                $(row).addClass('listivo-pagination-desktop__page--active');
            }
        });
        $(".product-frame-loader").removeClass('listivo-loading');
    },
       manageSortDropdown:function(){
            $('.sort-select').toggleClass('listivo-select__field--active');
            $('.sort-select').children('.listivo-select__dropdown').fadeToggle();
            $('.sort-select').children('.listivo-field__icon').removeClass('listivo-field__icon--clear');
            $('.sort-select').children('.listivo-field__icon').addClass('listivo-field__icon--arrow');
        },
        setSortChoosen:function(searchCat){
            this.searchFlag = true;
            this.filterFlag = true;
            this.selectFieldSort = searchCat;
            this.sortData = searchCat.replace(/\s/g, '')
            $('.sort-select').addClass('listivo-select__field--active');
            $('.sort-select').children('.listivo-select__dropdown').hide();
            
            if($('.sort-select').children('.listivo-field__icon').hasClass('listivo-field__icon--arrow'))
            {
                $('.sort-select').children('.listivo-field__icon').removeClass('listivo-field__icon--arrow');
            }
            $('.sort-select').children('.listivo-field__icon').addClass('listivo-field__icon--clear');

            $('.sort-select').children('.listivo-select__dropdown').children('.listivo-select__options').children('.listivo-select__option').removeClass('listivo-select__option--active');
            this.getSearchedData();
            
        },
        
    }
}