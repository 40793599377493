import Header from "../../../include/Header";
import Footer from "../../../include/Footer";
import Loading from "vue-loading-overlay";
import { authHeader } from "../../../../auth";
import Pagination from '../../paginationResource/Pagination.vue';
import MobilePagination from '../../paginationResource/MobilePagination.vue';
import $ from 'jquery';


export default {
    name: "Blog",
    title: "Blog",
    components: {
        Header,
        Footer,
        Loading,
        Pagination,
        MobilePagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            blogList: [],
            blogCategories: [],
            blogTags: [],
            blogPageCms: [],
            blogDetailPageCmsData: [],
            imagePath: this.$imageURL + "blog/",
            popularBlogs: [],
            limit:10,
            catID: null,
            tagID: null,
            search: null,
            currentPaginPage:1,
            totalPages: null,
            searchFlag: false
        };
    },
    filters: {
        AddCommas: function(event) {
            event.target.value = event.target.value.replace(" ", ".");
        }
    },
    async mounted() {
        $('html,body').animate({ scrollTop: 0 }, 'slow');
        await this.getBlogList();
        await this.getPopularPosts();
        if(this.$route.query.categoryId){
            this.searchFlag = true;
            this.catID = this.$route.query.categoryId;
            this.getSearchBlogList();
        }
        else if(this.$route.query.tag){
            this.searchFlag = true;
            this.tagID = this.$route.query.tag;
            this.getSearchBlogList();
        }
        else if(this.$route.query.keyword){
            this.searchFlag = true;
            this.search = this.$route.query.keyword;
            this.getSearchBlogList();
        }
    },
    beforeCreate: function() {
        document.body.className =
            "blog theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-5228";

        let style = document.createElement("link");
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = "asset/wp-content/uploads/elementor/css/post-871.css";
        document.head.appendChild(style);
    },
    methods: {
        getSearchedData: function(){
            this.searchFlag = true;
            this.getSearchBlogList();
        },
        getCategoryWiseBlog: function(catID){
            this.catID = catID;
            this.searchFlag = true;
            this.getSearchBlogList();
        },
        getTagWiseBlog: function(tagname){
            this.tagID = tagname;
            this.searchFlag = true;
            this.getSearchBlogList();
            
        },
        getPopularPosts: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.popularBlog,
                headers: authHeader()
            };
            await this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.popularBlogs = response.data.posts;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
        getBlogList: async function() {
            let params = "?page=" + this.currentPaginPage + "&limit=" + this.limit;
            
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogList + params,
                headers: authHeader()
            };
            await this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.blogList = response.data.blogPosts.posts;
                        this.blogCategories = response.data.blogCategories;
                        this.totalPages = response.data.blogPosts.pageCount;
                        this.blogTags = response.data.blogTags;
                        this.blogPageCms = response.data.blogPageCms;
                        this.blogDetailPageCmsData = response.data.blogDetailPageCmsData;
                    }
                    this.isLoading = false;
                    $('html,body').animate({ scrollTop: 0 }, 'slow');
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
        getSearchBlogList: function(){
            let params = "?page=" + this.currentPaginPage + "&limit=" + this.limit;
            if (this.catID){
                params += "&categoryId=" + this.catID;
            }
            if (this.tagID){
                params += "&tag=" + this.tagID;
            }
            if (this.search){
                params += "&keyword=" + this.search;
            }
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.searchBlog + params,
                headers: authHeader()
            };
            this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.blogList = response.data.posts;
                        this.totalPages = response.data.pageCount;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
        onPageChange(SourceDataCounterPage) {
            console.log(SourceDataCounterPage)
            this.currentPaginPage = SourceDataCounterPage;
            console.log(SourceDataCounterPage)
            if(this.searchFlag == false){
                this.getBlogList();
            }
            else{
                this.getSearchBlogList();
            }
            
            $('.listivo-pagination-desktop__page').removeClass('listivo-pagination-desktop__page--active');
            $('.listivo-pagination-desktop__page')
            .each(function(index,row){
                if($(row).html()==SourceDataCounterPage)
                {
                    $(row).addClass('listivo-pagination-desktop__page--active');
                }
            });
            $(".product-frame-loader").removeClass('listivo-loading');
        },
    },
};