<template>
   <div data-elementor-type="wp-post" data-elementor-id="57" class="elementor elementor-57"
      data-elementor-settings="[]">
      <div class="elementor-section-wrap">
         <section
            class="elementor-section elementor-top-section elementor-element elementor-element-de45e04 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="de45e04" data-element_type="section">
            <div class="elementor-container elementor-column-gap-default">
               <div
                  class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70803c3"
                  data-id="70803c3" data-element_type="column">
                  <div class="elementor-widget-wrap elementor-element-populated">
                     <div class="elementor-element elementor-element-d6d46ed elementor-widget elementor-widget-lst_menu"
                        data-id="d6d46ed" data-element_type="widget" data-widget_type="lst_menu.default">
                        <div class="elementor-widget-container">
                           <Header />
                        </div>
                     </div>
                     <div
                        class="elementor-element elementor-element-7807579 elementor-widget elementor-widget-lst_template_loader"
                        data-id="7807579" data-element_type="widget" data-widget_type="lst_template_loader.default">
                        <div class="elementor-widget-container">
                           <div data-elementor-type="wp-post" data-elementor-id="867" class="elementor elementor-867"
                              data-elementor-settings="[]">
                              <div class="elementor-section-wrap">
                                 <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-3243b4fa elementor-hidden-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="3243b4fa" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                       <div
                                          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-60135aa9"
                                          data-id="60135aa9" data-element_type="column">
                                          <div class="elementor-widget-wrap elementor-element-populated">
                                             <div
                                                class="elementor-element elementor-element-211de73b elementor-widget elementor-widget-spacer"
                                                data-id="211de73b" data-element_type="widget"
                                                data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                   <div class="elementor-spacer">
                                                      <div class="elementor-spacer-inner"></div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div
                                                class="elementor-element elementor-element-37465450 elementor-widget elementor-widget-lst_breadcrumbs"
                                                data-id="37465450" data-element_type="widget"
                                                data-widget_type="lst_breadcrumbs.default">
                                                <div class="elementor-widget-container">
                                                   <div>
                                                      <div class="listivo-breadcrumbs-wrapper">
                                                         <div class="listivo-breadcrumbs">
                                                            <div class="listivo-breadcrumbs__single">
                                                               <a href="#" title="Home"
                                                                  class="listivo-breadcrumbs__link">
                                                                  Home </a>
                                                               <span class="listivo-breadcrumbs__separator">
                                                                  <svg xmlns="http://www.w3.org/2000/svg" width="11"
                                                                     height="11" viewBox="0 0 11 11">
                                                                     <g>
                                                                        <g>
                                                                           <path
                                                                              d="M4.974.635c-.119.12-.119.246 0 .38l3.987 3.973H.587c-.178 0-.267.09-.267.268v.223c0 .179.089.268.267.268H8.96L4.974 9.721c-.119.133-.119.26 0 .379l.156.178c.134.119.26.119.379 0l4.7-4.732c.133-.119.133-.238 0-.357L5.508.457c-.119-.12-.245-.12-.379 0z">
                                                                           </path>
                                                                        </g>
                                                                     </g>
                                                                  </svg>
                                                               </span>
                                                            </div>
                                                            <div class="listivo-breadcrumbs__last">
                                                               Search
                                                            </div>
                                                         </div>
                                                         <!---->
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div
                                                class="elementor-element elementor-element-3923bba7 elementor-widget elementor-widget-spacer"
                                                data-id="3923bba7" data-element_type="widget"
                                                data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                   <div class="elementor-spacer">
                                                      <div class="elementor-spacer-inner"></div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </section>
                                 <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-5b27d5e5 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="5b27d5e5" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                       <div
                                          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5a46d328"
                                          data-id="5a46d328" data-element_type="column">
                                          <div class="elementor-widget-wrap elementor-element-populated">
                                             <div
                                                class="elementor-element elementor-element-4fb0b8e6 elementor-widget elementor-widget-lst_search"
                                                data-id="4fb0b8e6" data-element_type="widget"
                                                data-widget_type="lst_search.default">
                                                <div class="elementor-widget-container">
                                                   <div>
                                                      <div class="product-frame-loader">
                                                         <div class="listivo-fields">
                                                            <div class="listivo-fields-primary">
                                                               <div class="listivo-fields-primary__inner">
                                                                  <div
                                                                     class="listivo-field listivo-field--keyword search-title-product">
                                                                     <div class="listivo-relative">
                                                                        <div class="listivo-keyword-icon">
                                                                           <svg xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none" viewBox="0 0 24 24"
                                                                              stroke="currentColor" class="h-6 w-6">
                                                                              <path stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1"
                                                                                 d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9">
                                                                              </path>
                                                                           </svg>
                                                                        </div>
                                                                        <div class="listivo-select__placeholder"
                                                                           style="display:none;"></div>
                                                                        <input type="text"
                                                                           v-model="name"
                                                                           placeholder="I'm looking for..."
                                                                           @keyup="fieldDecorator('search-title-product')">
                                                                        <!---->
                                                                        <!---->
                                                                        <div style="display:none;"
                                                                           @click="fieldDecoratorClear('search-title-product')"
                                                                           class="listivo-field__icon listivo-field__icon--clear">
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div
                                                                     class="listivo-field listivo-field--location listivo-field--with-icon">
                                                                     <div class="listivo-field__location">
                                                                        <div
                                                                           class="listivo-field__location-input listivo-field__location-input--my-location">
                                                                           <input type="text"
                                                                              v-model="location"
                                                                              placeholder="Location (e.g. New York)"
                                                                              class="lst-location-153 pac-target-input"
                                                                              @keyup="fieldDecorator('listivo-field__location')"
                                                                              autocomplete="off">
                                                                           <div class="listivo-field__set-my-location" @click="pickMyLocation()">
                                                                              <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 fill="none" viewBox="0 0 24 24"
                                                                                 stroke="currentColor">
                                                                                 <path stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-width="1"
                                                                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                 </path>
                                                                                 <path stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-width="1"
                                                                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                 </path>
                                                                              </svg>
                                                                              <div
                                                                                 class="listivo-field__set-my-location-label">
                                                                                 Use my location </div>
                                                                           </div>
                                                                           <!---->
                                                                        </div>
                                                                        <!---->
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                            <div class="listivo-fields-secondary">
                                                               <!-- <div class="listivo-search-mobile-more-button"><button>
                                                                     More Filters (0)</button></div> -->
                                                               <div class="listivo-fields-secondary__inner">
                                                                  <div class="listivo-field">
                                                                     <div tabindex="0" class="listivo-select catSelect">

                                                                        <div class="listivo-select__field"
                                                                           @click="manageCategoryDropdown()">
                                                                           <!-- <div>Community</div> -->
                                                                           {{selectFieldCategory}}
                                                                        </div>
                                                                        <div class="listivo-select__dropdown"
                                                                           style="display:none;">
                                                                           <!---->
                                                                           <div class="listivo-select__options" >
                                                                              <div class="listivo-select__option" v-for="(cat, index) in categoryList" :key="index">
                                                                                 <div class="listivo-select__value"
                                                                                    @click="setCategoryChoosen(cat.name, cat.id)">
                                                                                    {{cat.name}}</div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                        <!-- <div class="col-sm-6">
                                                                           <label for="category">Category:</label>
                                                                           <Select2 v-model="myValue" :options="myOptions" :settings="{ settingOption: value, settingOption: value }" @change="myChangeEvent($event)" @select="mySelectEvent($event)" />
                                                                     </div> -->

                                                                        <div
                                                                           class="listivo-field__icon listivo-field__icon--arrow"
                                                                           @click="manageCategoryDropdown()"></div>
                                                                     </div>
                                                                  </div>
                                                                  <!---->
                                                                  <!---->
                                                                  <div class="listivo-field listivo-field--double">
                                                                     <div class="listivo-field__fields">
                                                                        <div class="listivo-field min-price">
                                                                           <div class="listivo-relative">
                                                                              <input type="text" placeholder="Min Price" v-model="minPrice"
                                                                                 @keyup="fieldDecorator('min-price')">
                                                                              <!---->
                                                                              <div style="display:none;"
                                                                                 @click="fieldDecoratorClear('min-price')"
                                                                                 class="listivo-field__icon listivo-field__icon--clear">
                                                                              </div>
                                                                           </div>

                                                                        </div>
                                                                        <div class="listivo-field max-price">
                                                                           <div class="listivo-relative">
                                                                              <input type="text" v-model="maxPrice" placeholder="Max Price"
                                                                                 @keyup="fieldDecorator('max-price')">
                                                                              <!---->
                                                                              <div style="display:none;"
                                                                                 @click="fieldDecoratorClear('max-price')"
                                                                                 class="listivo-field__icon listivo-field__icon--clear">
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <!---->
                                                                  <!---->
                                                                  <!---->
                                                                  <!---->
                                                                  <!---->
                                                                  <!---->
                                                                  <!---->
                                                                  <!---->
                                                                  <!---->
                                                                  <!---->
                                                                  <!---->
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div class="listivo-clearfix"></div>
                                                         <div class="listivo-search-mobile" style="display: none;">
                                                            <div class="listivo-search-mobile__inner">
                                                               <div>
                                                                  <h3 class="listivo-search-mobile__heading">
                                                                     Filters
                                                                  </h3>
                                                                  <div class="listivo-search-mobile__close">
                                                                     <svg xmlns="http://www.w3.org/2000/svg" width="21"
                                                                        height="19" viewBox="0 0 21 19">
                                                                        <g>
                                                                           <g>
                                                                              <path fill="#111"
                                                                                 d="M.602 18.781h2.443c.335 0 .574-.106.766-.284l6.178-6.615a.216.216 0 0 1 .336 0l6.13 6.615c.192.178.431.284.766.284h2.347c.48 0 .67-.284.383-.569L12.05 9.89a.176.176 0 0 1 0-.213l7.902-8.322c.288-.284.096-.569-.383-.569H17.03c-.336 0-.575.107-.767.285l-6.13 6.614a.215.215 0 0 1-.335 0l-6.13-6.614C3.475.893 3.235.786 2.9.786H.6c-.478 0-.67.285-.382.57l7.855 8.321a.177.177 0 0 1 0 .213L.219 18.212c-.288.285-.096.57.383.57z">
                                                                              </path>
                                                                           </g>
                                                                        </g>
                                                                     </svg>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-search-mobile__results-bar">
                                                                  <div class="listivo-results-bar__count">
                                                                     <div>
                                                                        177 Results
                                                                     </div>
                                                                     <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 913.000000 42.000000"
                                                                        preserveAspectRatio="xMidYMid meet">
                                                                        <g transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                                                           stroke="none">
                                                                           <path
                                                                              d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z">
                                                                           </path>
                                                                        </g>
                                                                     </svg>
                                                                  </div>
                                                                  <div><button class="listivo-search-mobile__clear-all">
                                                                        Clear All </button>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-field listivo-field--keyword">
                                                                  <div class="listivo-relative">
                                                                     <div class="listivo-keyword-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                           fill="none" viewBox="0 0 24 24"
                                                                           stroke="currentColor" class="h-6 w-6">
                                                                           <path stroke-linecap="round"
                                                                              stroke-linejoin="round" stroke-width="1"
                                                                              d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9">
                                                                           </path>
                                                                        </svg>
                                                                     </div>
                                                                     <div class="listivo-select__placeholder"
                                                                        style="display: none;">
                                                                     </div>
                                                                     <input type="text"
                                                                        placeholder="I'm looking for...">
                                                                     <!---->
                                                                     <!---->
                                                                  </div>
                                                               </div>
                                                               <div
                                                                  class="listivo-field listivo-field--location listivo-field--with-icon">
                                                                  <div class="listivo-field__location">
                                                                     <div
                                                                        class="listivo-field__location-input listivo-field__location-input--my-location">
                                                                        <input type="text"
                                                                           placeholder="Location (e.g. New York)"
                                                                           class="lst-location-153 pac-target-input"
                                                                           autocomplete="off">
                                                                        <div class="listivo-field__set-my-location">
                                                                           <svg xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none" viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1"
                                                                                 d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                              </path>
                                                                              <path stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1"
                                                                                 d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                              </path>
                                                                           </svg>
                                                                           <div
                                                                              class="listivo-field__set-my-location-label">
                                                                              Use my location
                                                                           </div>
                                                                        </div>
                                                                        <!---->
                                                                     </div>
                                                                     <!---->
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-field">
                                                                  <div class="listivo-field">
                                                                     <div tabindex="0" class="listivo-select">
                                                                        <div class="listivo-select__field">
                                                                           Category
                                                                        </div>
                                                                        <!---->
                                                                        <!---->
                                                                        <div
                                                                           class="listivo-field__icon listivo-field__icon--arrow">
                                                                        </div>
                                                                        <!---->
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <!---->
                                                               <!---->
                                                               <div class="listivo-field listivo-field--double">
                                                                  <div class="listivo-field__fields">
                                                                     <div class="listivo-field">
                                                                        <div class="listivo-relative">
                                                                           <input type="text" placeholder="Min Price">
                                                                           <!---->
                                                                        </div>
                                                                     </div>
                                                                     <div class="listivo-field">
                                                                        <div class="listivo-relative">
                                                                           <input type="text" placeholder="Max Price">
                                                                           <!---->
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <!---->
                                                               <!---->
                                                               <!---->
                                                               <!---->
                                                               <!---->
                                                               <!---->
                                                               <!---->
                                                               <!---->
                                                               <!---->
                                                               <!---->
                                                               <!---->
                                                               <div class="listivo-search-mobile__apply"><button
                                                                     class="listivo-primary-button listivo-primary-button--full-width listivo-primary-button--icon"><span
                                                                        class="listivo-primary-button__text">
                                                                        Apply </span> <span
                                                                        class="listivo-primary-button__icon"><i
                                                                           class="fas fa-check"></i></span></button>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div class="listivo-results-bar">
                                                            <div class="listivo-results-bar__count">
                                                               <div class="listivo-results-bar__count-inner">
                                                                  <div class="listivo-results-bar__count-big">
                                                                     {{filterFlag== true? productList.length + ' Results' : resultText}}
                                                                     <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 913.000000 42.000000"
                                                                        preserveAspectRatio="xMidYMid meet">
                                                                        <g transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                                                           stroke="none">
                                                                           <path
                                                                              d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z">
                                                                           </path>
                                                                        </g>
                                                                     </svg>
                                                                  </div>
                                                                  <h1>Classified Ads</h1>
                                                               </div>
                                                            </div>
                                                            <div class="listivo-results-bar__right">
                                                               <div class="listivo-results-bar__sort">
                                                                  <div class="listivo-results-bar__sort-label">
                                                                     Sort by:
                                                                  </div>
                                                                  <div tabindex="0" class="listivo-select sortselect">
                                                                     <div class="listivo-select__field"
                                                                        @click="manageSortDropdown()">
                                                                        <div>{{selectFieldSort}}</div>

                                                                     </div>

                                                                     <div class="listivo-select__dropdown"
                                                                        style="display:none;">
                                                                        <div class="listivo-select__options">
                                                                           <div class="listivo-select__option">
                                                                              <div class="listivo-select__value"
                                                                                 @click="setSortChoosen('Most Relevant')">
                                                                                 Most Relevant</div>
                                                                           </div>
                                                                           <div class="listivo-select__option">
                                                                              <div class="listivo-select__value"
                                                                                 @click="setSortChoosen('Date Listed: Newest')">
                                                                                 Date Listed: Newest</div>
                                                                           </div>
                                                                           <div class="listivo-select__option">
                                                                              <div class="listivo-select__value"
                                                                                 @click="setSortChoosen('Date Listed: Oldest')">
                                                                                 Date Listed: Oldest</div>
                                                                           </div>
                                                                           <div class="listivo-select__option">
                                                                              <div class="listivo-select__value"
                                                                                 @click="setSortChoosen('Price: Lowest')">
                                                                                 Price: Lowest</div>
                                                                           </div>
                                                                           <div class="listivo-select__option">
                                                                              <div class="listivo-select__value"
                                                                                 @click="setSortChoosen('Price: Highest')">
                                                                                 Price: Highest</div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <!---->
                                                                     <!-- <div class="col-sm-6">
                                                                           <Select2 v-model="myValue" :options="myOptions" :settings="{ settingOption: value, settingOption: value }" @change="myChangeEvent($event)" @select="mySelectEvent($event)" />
                                                                  </div> -->
                                                                     <div
                                                                        class="listivo-field__icon listivo-field__icon--arrow"
                                                                        @click="manageSortDropdown()"></div>
                                                                  </div>
                                                               </div>
                                                               <div class="listivo-results-bar__grids">
                                                                  <div
                                                                     class="listivo-results-bar__grid listivo-results-bar__grid--active"
                                                                     @click="viewProductoption('grid')">
                                                                     <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path stroke-linecap="round"
                                                                           stroke-linejoin="round" stroke-width="1.4"
                                                                           d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z">
                                                                        </path>
                                                                     </svg>
                                                                  </div>
                                                                  <div class="listivo-results-bar__grid"
                                                                     @click="viewProductoption('list')">
                                                                     <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path stroke-linecap="round"
                                                                           stroke-linejoin="round" stroke-width="1.4"
                                                                           d="M4 6h16M4 10h16M4 14h16M4 18h16"></path>
                                                                     </svg>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div class="listivo-listings" v-if="productList.length > 0">
                                                            <div class="listivo-cards-list-v2">
                                                               <div data-id="3804"
                                                                  class="listivo-listing-card listivo-listing-no-user listivo-listing-featured"
                                                                  v-for="(row, index) in productList" :key="index">
                                                                  <div v-if="!gridorListView" class="listivo-listing-card__inner">

                                                                     <a class="listivo-listing-card__link"
                                                                        href="javascript:void(0)" @click="$router.push({
                                                                     name: 'SingleProduct',
                                                                     params: {id: row.id}})"></a>
                                                                     <!-- <a :href="'product/details/' + row['id']" class="listivo-listing-card__link"></a>  -->
                                                                     <div>
                                                                        <div class="listivo-listing-card__top"
                                                                           v-if="row.photos.length > 0">
                                                                           <img
                                                                              :data-srcset="imagePath + row.photos[0].image + ' 720w, ' + imagePath + row.photos[0].image +  ' 360w'"
                                                                              data-sizes="auto" :alt="row.name"
                                                                              class="listivo-listing-card__preview lazyautosizes lazyloaded"
                                                                              sizes="358px"
                                                                              :srcset="imagePath + row.photos[0].image +  ' 720w, ' + imagePath + row.photos[0].image +  ' 360w'">
                                                                           <div class="listivo-listing-card__labels">
                                                                              <div class="listivo-listing-card__label" v-if="row.isFeatured">
                                                                                 Featured
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                        <div class="listivo-listing-card__top" v-else>
                                                                           <img data-srcset="../../../../assets/default.jpg 720w, ../../../../assets/default.jpg 360w"
                                                                           data-sizes="auto"
                                                                           class="listivo-listing-card__preview lazyautosizes lazyloaded"
                                                                           sizes="358px"
                                                                           srcset="../../../../assets/default.jpg 720w, ../../../../assets/default.jpg 360w"
                                                                           >

                                                                        </div>
                                                                        <div class="listivo-listing-card__main">
                                                                           <div class="listivo-listing-card__main-head">
                                                                              <h3 class="listivo-listing-card__name">
                                                                                 {{row.name}}
                                                                              </h3>
                                                                              <div
                                                                                 class="listivo-listing-card__location">
                                                                                 <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none" viewBox="0 0 24 24"
                                                                                    stroke="currentColor">
                                                                                    <path stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                    </path>
                                                                                    <path stroke-linecap="round"
                                                                                       stroke-linejoin="round"
                                                                                       stroke-width="1.4"
                                                                                       d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                    </path>
                                                                                 </svg>
                                                                                 {{row.location}}
                                                                              </div>
                                                                              <b class="listivo-listing-card__price">
                                                                                 ${{row.price}} </b>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="listivo-listing-card__footer">
                                                                        <div class="listivo-listing-card__features">
                                                                           <ul>
                                                                              <li><span>{{row.category.name}}</span>
                                                                              </li>
                                                                           </ul>
                                                                        </div>
                                                                        <button class="listivo-listing-card__favorite">
                                                                           <svg xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none" viewBox="0 0 24 24"
                                                                              stroke="currentColor">
                                                                              <path stroke-linecap="round"
                                                                                 stroke-linejoin="round"
                                                                                 stroke-width="1.4"
                                                                                 d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z">
                                                                              </path>
                                                                           </svg>
                                                                           <!---->
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                                  <div v-if="gridorListView" class="listivo-listing-card-row__inner">
                                                                     <a class="listivo-listing-card__link"
                                                                        href="javascript:void(0)" @click="$router.push({
                                                                     name: 'SingleProduct',
                                                                     params: {id: row.id}})"></a>
                                                                     <div class="listivo-listing-card-row__left">
                                                                        <div class="listivo-listing-card-row__labels">
                                                                           <div class="listivo-listing-card-row__label" v-if="row.isFeatured">
                                                                              Featured </div>
                                                                        </div> <img
                                                                           :data-srcset="imagePath + row.photos[0].image + ' 720w, '+imagePath + row.photos[0].image +' 360w'"
                                                                           data-sizes="auto" alt="Mercedes-Benz S-Class"
                                                                           class="listivo-listing-card-row__preview lazyautosizes lazyloaded"
                                                                           sizes="300px"
                                                                           :srcset="imagePath + row.photos[0].image + ' 720w, '+imagePath + row.photos[0].image + ' 360w'">
                                                                     </div>
                                                                     <div class="listivo-listing-card-row__content">
                                                                        <div>
                                                                           <div class="listivo-listing-card-row__top">
                                                                              <h3
                                                                                 class="listivo-listing-card-row__name">
                                                                                 {{row.name}} </h3>
                                                                              <div
                                                                                 class="listivo-listing-card-row__price">
                                                                                 ${{row.price}}  </div>
                                                                           </div>
                                                                           <div
                                                                              class="listivo-listing-card-row__features">
                                                                              <ul>
                                                                                 <li>{{row.category.name}}</li>
                                                                              </ul>
                                                                           </div>
                                                                           <div class="listivo-listing-card-row__text" v-html="row.description">
                                                                            </div>
                                                                        </div>
                                                                        <div class="listivo-listing-card-row__bottom">
                                                                           <div
                                                                              class="listivo-listing-card-row__location">
                                                                              <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 fill="none" viewBox="0 0 24 24"
                                                                                 stroke="currentColor">
                                                                                 <path stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-width="1.4"
                                                                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z">
                                                                                 </path>
                                                                                 <path stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-width="1.4"
                                                                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z">
                                                                                 </path>
                                                                              </svg>
                                                                              {{row.location}} </div> <button
                                                                              class="listivo-listing-card-row__favorite"><svg
                                                                                 xmlns="http://www.w3.org/2000/svg"
                                                                                 fill="none" viewBox="0 0 24 24"
                                                                                 stroke="currentColor">
                                                                                 <path stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-width="1.4"
                                                                                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z">
                                                                                 </path>
                                                                              </svg>
                                                                              <!----></button>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>

                                                            </div>
                                                         </div>
                                                         <div class="listivo-listings" style="text-align:center;" v-else>
                                                            <code>No data found.</code>
                                                         </div>
                                                         <div class="listivo-pagination listivo-pagination--listings" v-if="productList.length > 0">
                                                            <div class="listivo-pagination-mobile">
                                                                    <MobilePagination :totalPages="totalPages?totalPages:1" :perPage="limit"
                                                                  :maxVisibleButtons="5" :currentPage="currentPaginPage"
                                                                  @pagechanged="onPageChange" />
                                                                     </div>
                                                            <div class="listivo-pagination-desktop">
                                                               <pagination :totalPages="totalPages" :perPage="limit"
                                                                  :maxVisibleButtons="5" :currentPage="currentPaginPage"
                                                                  @pagechanged="onPageChange" />
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div
                                                class="elementor-element elementor-element-36398803 elementor-widget elementor-widget-spacer"
                                                data-id="36398803" data-element_type="widget"
                                                data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                   <div class="elementor-spacer">
                                                      <div class="elementor-spacer-inner"></div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </section>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <Footer />
      </div>
   </div>


</template>
<script src="../js/product.js"></script>
<style scoped>

.listivo-pagination-desktop__page .listivo-pagination-desktop__page--active {
    background: var(--e-global-color-lprimary1) !important;
    border-color: var(--e-global-color-lprimary1) !important;
    color: var(--white) !important;
}
.listivo-pagination-desktop__page {
    display: inline-block;
    width: 50px;
    text-align: center;
    float: left;
    font-size: 16px;
    line-height: 48px;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    transition: .2s ease;
    border-radius: var(--radius-rounded);
    border: 1px solid var(--border-color);
    cursor: pointer;
}
</style>