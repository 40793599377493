import Header from "../../../include/Header";
import Footer from "../../../include/Footer";
import { authHeader } from "../../../../auth";
export default {
  name: "User Page",
  title: "User Page",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: "bars",
      imagePath: this.$imageURL + "team/",
      teamsDetail: [],
      message: null,
      userId: null
    };
  },
  beforeCreate: function () {
    document.body.className =
      "archive author author-emily-rees author-3 theme-listivo listivo-1.0.19 woocommerce-no-js elementor-default elementor-kit-6";

    let style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href = "/asset/wp-content/uploads/elementor/css/post-868.css";
    document.head.appendChild(style);
  },
  created() {
    this.userId = this.$route.params.id
    this.getTeamsDetail(this.$route.params.id);
  },
  methods: {
    getTeamsDetail: function (id) {
      this.isLoading = true;
      var config = {
        method: "GET",
        url: this.$serverURL + this.$api.teams.detail + id,
      };
      this.$axios(config)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.teamsDetail = response.data;
            this.isLoading = false;
            document.title = response.data.name;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            text: "Password and confirm password don't match!",
          });
        });
    },
    sendMessage: function() {
        console.log("Send Message....");
        
        let data = {
          teamMemberId: this.userId,
          message: this.message,
        };
        console.log("data....",data.message);
        if((data.message)==null) {
          console.log("data....s",data.message);
          this.$swal.fire({
              icon: "error",
              text: "Please enter a message!",
          });
          return false;
        }
        let config = {
            method: "POST",
            url: this.$serverURL + this.$api.teams.sendMessage,
            data: data,
            headers: authHeader()
        };
        if (localStorage.getItem('token')){
            this.$axios(config)
            .then((response) => {
                if (response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully send your message",
                    });
                    this.message = null;
                }
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!",
                });
            });
        }
        else {
            this.$swal.fire({
                icon: "error",
                text: "Please Log In or Register",
            });
        }
    }
  },
};
