import Loading from "vue-loading-overlay";

export default {
    name: "Subscribe",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            email: null,
            footerSubscriber: {},
            imagePath: this.$imageURL + "site/"
        }
    },
    created() {
        this.footerSubscriber = JSON.parse(localStorage.getItem('footerSubscriber'));
        // this.getSiteSettings();
    },
    beforeCreate: function() {
        let style = document.createElement("link");
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = "/asset/wp-content/uploads/elementor/css/custom.css";
        document.head.appendChild(style);
    },
    methods: {
        subscribe: async function() {
            try {
                this.isLoading = true;
                let data = {
                    email: this.email
                };
                let config = {
                    method: "POST",
                    data: data,
                    url: this.$serverURL + this.$api.subscribe.subscribeURL
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 201) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully subscribed!"
                        });
                        this.email = "";
                    }
                }).catch(error => {
                    this.isLoading = false;
                    console.log(error);
                });
            } catch (error) {
                this.isLoading = false;
                console.log(error)
            }
        },
        getSiteSettings: function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.siteSettings.siteSettings,
            };
            this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.footerSubscriber = response.data.footerSubscriber;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
    }
}