import { createApp } from 'vue';
import App from './App.vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import GAuth  from 'vue3-google-oauth2';
import titleMixin from './mixins/titleMixin';
import router from './routes/routes';
import moment from 'moment';
import axios from 'axios';
import VueAxios from 'vue-axios';

const app = createApp(App);
app.use(VueAxios, axios);
app.config.globalProperties.$axios = axios;
app.use(VueSweetalert2);

router.onError(error => {
    if (/loading chunk \d* failed./i.test(error.message)) {
      window.location.reload()
    }
  })


app.config.globalProperties.$filters = {
    formatDate(date) {
        return moment(String(date)).format('MMMM DD, YYYY');
    },
    userDate(date) {
        return moment(String(date)).format('Y-M-D');
    },
    messageDate(date) {
        return moment(String(date)).format('MMMM DD, YYYY HH:mm A');
    },
    chatTime(date) {
        return moment(String(date)).format('HH:mm A');
    },

};
const gAuthOptions = { 
    clientId: '492547843357-hrvov8t0k9crofq8kab87b33oed6bqhe.apps.googleusercontent.com', 
    //clientId: '149155840738-sgjn3o11q8ndb3rkjcghvoj29inoihu8.apps.googleusercontent.com', 
    scope: 'profile email', prompt: 'consent', fetch_basic_profile: false };
app.use(GAuth, gAuthOptions);
/* Vue Moment */

app.config.globalProperties.$filter = {
    replace(value) {
        return value.replace(" ", "-");
    }
};

app.config.globalProperties.$api = require('./config/api.json');
app.config.globalProperties.$token = 'token';
app.config.globalProperties.$id = 'id';
app.config.globalProperties.$isActive = 'isActive';
app.config.globalProperties.$name = 'name';
app.config.globalProperties.$phone = 'phone';
//app.config.globalProperties.$mapAPI = 'AIzaSyD4cHQ7acnFASaf2my2wEpv4xVJ32n_9Sg';
app.config.globalProperties.$mapAPI = 'AIzaSyBAQTQIGNCLnH93PMerongNRnj9S84C1kA';
if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
        //Local 
    app.config.globalProperties.$hostURL = 'http://localhost:8008/#';
    app.config.globalProperties.$serverURL = 'http://192.168.5.251:3000/';
    app.config.globalProperties.$imageURL = 'http://192.168.5.251:3000/';
}
else
{
    //Server
    app.config.globalProperties.$hostURL = 'https://2ndalist.com/';
    app.config.globalProperties.$serverURL = 'https://2ndalist.com:3030/';
    app.config.globalProperties.$imageURL = 'https://2ndalist.com:3030/';
}
app.mixin(titleMixin)
app.use(router).mount('#app')