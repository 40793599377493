<template>
  <section
    class="
      elementor-section
      elementor-top-section
      elementor-element
      elementor-element-6aacd820
      elementor-section-full_width
      elementor-section-height-default elementor-section-height-default
    "
    data-id="6aacd820"
    data-element_type="section"
  >
    <div class="elementor-container elementor-column-gap-default">
      <div
        class="
          elementor-column
          elementor-col-100
          elementor-top-column
          elementor-element
          elementor-element-652a9659
        "
        data-id="652a9659"
        data-element_type="column"
      >
        <div class="elementor-widget-wrap elementor-element-populated">
          <div
            class="
              elementor-element
              elementor-element-4f837918
              elementor-widget
              elementor-widget-spacer
            "
            data-id="4f837918"
            data-element_type="widget"
            data-widget_type="spacer.default"
          >
            <div class="elementor-widget-container">
              <div class="elementor-spacer">
                <div class="elementor-spacer-inner"></div>
              </div>
            </div>
          </div>
          <div
            class="
              elementor-element elementor-element-5918dd49 elementor-widget
              elementor-widget-lst_heading
            "
            data-id="5918dd49"
            data-element_type="widget"
            data-widget_type="lst_heading.default"
          >
            <div class="elementor-widget-container">
              <div
                class="
                  listivo-heading listivo-heading--center listivo-heading--none
                "
              >
                <div class="listivo-heading__small-text">
                  <div class="listivo-heading__small-text__svg-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="1"
                      viewBox="0 0 25 1"
                    >
                      <g>
                        <g>
                          <path d="M0 0h15v1H0z" />
                        </g>
                        <g>
                          <path d="M20 0h5v1h-5z" />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div class="listivo-heading__small-text-content">
                    {{ homePageCmData.testimonial_sub_title }}
                  </div>
                  <div class="listivo-heading__small-text__svg-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="1"
                      viewBox="0 0 25 1"
                    >
                      <g>
                        <g>
                          <path d="M10 0h15v1H10z" />
                        </g>
                        <g>
                          <path d="M0 0h5v1H0z" />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="listivo-heading__main">
                  <div class="listivo-heading__text">
                    <h2>{{ homePageCmData.testimonial_heading }}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="elementor-element elementor-element-1246bc1 elementor-widget elementor-widget-spacer"
                        data-id="1246bc1" data-element_type="widget" data-widget_type="spacer.default">
                        <div class="elementor-widget-container">
                            <div class="elementor-spacer">
                                <div class="elementor-spacer-inner"></div>
                            </div>
                        </div>
                    </div> -->
          <div
            class="
              elementor-element elementor-element-3e477a63 elementor-widget
              elementor-widget-lst_testimonials
            "
            data-id="3e477a63"
            data-element_type="widget"
            data-widget_type="lst_testimonials.default"
          >
            <div
              v-if="testimonyData.length > 0"
              class="elementor-widget-container"
            >
              <carousel :settings='settings' :breakpoints='breakpoints' :wrap-around="true">
                <slide v-for="(row, index) in testimonyData" :key="index">
                  <div class="carousel__item" style="margin: 10px">
                    <div class="listivo-swiper-slide">
                      <div class="listivo-testimonial" style="margin: 15px">
                        <div class="listivo-testimonial__top">
                          <div
                            class="listivo-testimonial__image"
                            v-if="row.photo"
                          >
                            <img
                              v-bind:src="imagePath + row.photo"
                              v-bind:title="row.name"
                            />
                          </div>
                          <div class="listivo-testimonial__image" v-else>
                            <img src="/img/user.webp" />
                          </div>
                          <div class="listivo-testimonial__top-content">
                            <span
                              class="listivo-testimonial__top-content__name"
                            >
                              {{ row.name }}
                            </span>
                            <span
                              class="listivo-testimonial__top-content__title"
                            >
                              {{ row.designation }}
                            </span>
                            <div class="listivo-testimonial__stars">
                              <div
                                v-for="n in parseInt(row.rating)"
                                :key="n"
                                class="listivo-testimonial__star"
                              >
                                <i class="fas fa-star"></i>
                              </div>
                            </div>
                          </div>
                          <div class="listivo-testimonial__icon">
                            <i class="fas fa-quote-right"></i>
                          </div>
                        </div>
                        <div class="listivo-testimonial__bottom">
                          <p
                            class="listivo-testimonial__text"
                            v-html="row.feedbackDetail"
                          ></p>
                          <a
                            :href="row.socialLink"
                            target="_blank"
                            class="
                              listivo-testimonial__via
                              listivo-testimonial__via--with-icon
                            "
                          >
                            Facebook
                            <div class="listivo-testimonial__via-icon">
                              <i class="fab fa-facebook-f"></i>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </slide>

                <template #addons>
                  <pagination />
                </template>
              </carousel>
              <div class="listivo-swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
// import { authHeader } from "../../../../auth";

export default {
  name: "myCarousel",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      testimonyData: [],
      homePageCmData: [],
      imagePath: this.$imageURL + "home/",
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        autoplay:"2000",
      },
      breakpoints: {
        700: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  computed: {
    navigationNext() {
      return ">";
    },
    navigationPrev() {
      return "<";
    },
  },
  created() {
    this.testimonyData = JSON.parse(localStorage.getItem("homeTestimonyData"));
    this.homePageCmData = JSON.parse(localStorage.getItem("homePageCmData"));
    console.log("asdad",this.homePageCmData.testimonial_heading)
    // this.getHomePageData();
  },
  methods: {
    prev() {
      this.$refs.myCarousel.prev();
    },
    next() {
      this.$refs.myCarousel.next();
    },
    //   getHomePageData: async function() {
    //         try {
    //             let config = {
    //                 method: "GET",
    //                 url: this.$serverURL + this.$api.testimonial.getTestimonialData,
    //                 headers: authHeader()
    //             };
    //             await this.$axios(config).then((response) => {
    //                 if (response.status == 200) {
    //                     this.testimonyData = response.data.testimonyData
    //                 }

    //             }).catch(error => {
    //                 console.log(error);
    //             });
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }
  },
};
</script>
<style>
.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}
</style>