<template>
   <div data-elementor-type="wp-post" data-elementor-id="57" class="elementor elementor-57"
      data-elementor-settings="[]">
      <div class="elementor-section-wrap">
         <section
            class="elementor-section elementor-top-section elementor-element elementor-element-de45e04 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="de45e04" data-element_type="section">
            <div class="elementor-container elementor-column-gap-default">
               <div
                  class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-70803c3"
                  data-id="70803c3" data-element_type="column">
                  <div class="elementor-widget-wrap elementor-element-populated" >
                     <div class="elementor-element elementor-element-d6d46ed elementor-widget elementor-widget-lst_menu"
                        data-id="d6d46ed" data-element_type="widget" data-widget_type="lst_menu.default">
                        <div class="elementor-widget-container">
                           <Header />
                        </div>
                     </div>
                     <div
                        class="elementor-element elementor-element-7807579 elementor-widget elementor-widget-lst_template_loader"
                        data-id="7807579" data-element_type="widget" data-widget_type="lst_template_loader.default">
                        <div class="elementor-widget-container">
                           <div data-elementor-type="wp-page" data-elementor-id="604" class="elementor elementor-604"
                              data-elementor-settings="[]">
                              <div class="elementor-section-wrap">
                                 <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-3d193ea7 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="3d193ea7" data-element_type="section"
                                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;}"
                                    :style="{backgroundImage: 'url(' + imagePath + faqPageCMS.headerBackground + ')'}">
                                    <div class="elementor-background-overlay"></div>
                                    <div class="elementor-container elementor-column-gap-default">
                                       <div
                                          class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7179fc5f"
                                          data-id="7179fc5f" data-element_type="column">
                                          <div class="elementor-widget-wrap elementor-element-populated">
                                             <div
                                                class="elementor-element elementor-element-2b558cf9 elementor-widget elementor-widget-heading"
                                                data-id="2b558cf9" data-element_type="widget"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                   <h1 class="elementor-heading-title elementor-size-xl">
                                                      {{faqPageCMS.headerTitle}}
                                                   </h1>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div
                                          class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7973ba90"
                                          data-id="7973ba90" data-element_type="column">
                                          <div class="elementor-widget-wrap elementor-element-populated">
                                             <div
                                                class="elementor-element elementor-element-6f8fcbc9 elementor-widget elementor-widget-lst_breadcrumbs"
                                                data-id="6f8fcbc9" data-element_type="widget"
                                                data-widget_type="lst_breadcrumbs.default">
                                                <div class="elementor-widget-container">
                                                   <div class="listivo-app">

                                                      <div class="listivo-breadcrumbs-wrapper">
                                                         <div class="listivo-breadcrumbs">
                                                            <div class="listivo-breadcrumbs__single">
                                                               <a class="listivo-breadcrumbs__link" href="#"
                                                                  title="Home">
                                                                  Home </a>
                                                               <span class="listivo-breadcrumbs__separator">
                                                                  <svg xmlns="http://www.w3.org/2000/svg" width="11"
                                                                     height="11" viewBox="0 0 11 11">
                                                                     <g>
                                                                        <g>
                                                                           <path
                                                                              d="M4.974.635c-.119.12-.119.246 0 .38l3.987 3.973H.587c-.178 0-.267.09-.267.268v.223c0 .179.089.268.267.268H8.96L4.974 9.721c-.119.133-.119.26 0 .379l.156.178c.134.119.26.119.379 0l4.7-4.732c.133-.119.133-.238 0-.357L5.508.457c-.119-.12-.245-.12-.379 0z" />
                                                                        </g>
                                                                     </g>
                                                                  </svg>
                                                               </span>
                                                            </div>
                                                            <div class="listivo-breadcrumbs__last">
                                                               FAQ
                                                            </div>
                                                         </div>

                                                      </div>

                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </section>
                                 <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-aeb8f24 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="aeb8f24" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                       <div
                                          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f0158c7"
                                          data-id="f0158c7" data-element_type="column">
                                          <div class="elementor-widget-wrap elementor-element-populated">
                                             <div
                                                class="elementor-element elementor-element-67ed9bd elementor-widget elementor-widget-spacer"
                                                data-id="67ed9bd" data-element_type="widget"
                                                data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                   <div class="elementor-spacer">
                                                      <div class="elementor-spacer-inner"></div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </section>
                                 <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-38497d3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="38497d3" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                       <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-67aae9a"
                                          data-id="67aae9a" data-element_type="column">
                                          <div class="elementor-widget-wrap elementor-element-populated">
                                             
                                             <template v-for="(row, index) in faqCategories" :key="index">
                                                <div class="elementor-element elementor-element-65b45ce elementor-widget elementor-widget-heading"
                                                   data-id="65b45ce" data-element_type="widget"
                                                   data-widget_type="heading.default">
                                                   <div class="elementor-widget-container">
                                                      <h2 class="elementor-heading-title elementor-size-medium" style="color:#000;">{{row.name}}</h2>
                                                   </div>
                                                </div>
                                                <div class="elementor-element elementor-element-ff256fe elementor-widget elementor-widget-lst_accordion"
                                                   data-id="ff256fe" data-element_type="widget"
                                                   data-widget_type="lst_accordion.default">
                                                   <div class="elementor-widget-container">
                                             
                                                            <div class="listivo-accordion" v-for="(data, key) in row.faqContent" :key="key">
                                                               <div :id="'faq'+key+ row.id" class="listivo-accordion__title-bar" @click="showDetailsFaq($event)">
                                                                  <h3 class="listivo-accordion__heading">
                                                                    {{data.questionTitle}}</h3>
                                                                  <div class="listivo-accordion__arrow"><i
                                                                        :class="detail_arrow"></i></div>
                                                               </div>
                                                               <div 
                                                                  class="listivo-accordion__text listivo-accordion__text--0"
                                                                  :style="display">
                                                                  {{data.questionDetail}}
                                                               </div>
                                                            </div>
                                                            
                                                         
                                                   </div>
                                                </div>
                                                <div class="elementor-element elementor-element-b320f06 elementor-widget elementor-widget-spacer"
                                                   data-id="b320f06" data-element_type="widget"
                                                   data-widget_type="spacer.default">
                                                   <div class="elementor-widget-container">
                                                      <div class="elementor-spacer">
                                                         <div class="elementor-spacer-inner"></div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </template>


                                             
                                          </div>
                                       </div>
                                       <div
                                          class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-eb74e96 listivo-sticky" 
                                          data-id="eb74e96" data-element_type="column">
                                          <div class="elementor-widget-wrap elementor-element-populated" style="top:-100px">
                                             <section
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-906df08 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                data-id="906df08" data-element_type="section"
                                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-default">
                                                   <div
                                                      class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-a572fd2"
                                                      data-id="a572fd2" data-element_type="column">
                                                      <div class="elementor-widget-wrap elementor-element-populated">
                                                         <div
                                                            class="elementor-element elementor-element-ed6353c elementor-widget elementor-widget-heading"
                                                            data-id="ed6353c" data-element_type="widget"
                                                            data-widget_type="heading.default">
                                                            <div class="elementor-widget-container">
                                                               <h2
                                                                  class="elementor-heading-title elementor-size-default">
                                                                  {{faqPageCMS.getInTouchTitle}}
                                                               </h2>
                                                            </div>
                                                         </div>
                                                         <div
                                                            class="elementor-element elementor-element-2541c99 elementor-widget elementor-widget-lst_shape"
                                                            data-id="2541c99" data-element_type="widget"
                                                            data-widget_type="lst_shape.default">
                                                            <div class="elementor-widget-container">
                                                               <div class="listivo-shape">
                                                                  <div class="listivo-shape-line">
                                                                     <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 913.000000 42.000000"
                                                                        preserveAspectRatio="xMidYMid meet">
                                                                        <g transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
                                                                           stroke="none">
                                                                           <path
                                                                              d="M7962 404 c-11 -12 -33 -14 -100 -12 -48 1 -240 -1 -427 -5 -187 -4 -506 -10 -710 -13 -354 -5 -415 -7 -603 -19 -185 -11 -867 -23 -1392 -25 -124 -1 -360 -6 -525 -11 -385 -14 -451 -15 -1170 -23 -411 -5 -646 -12 -745 -22 -86 -9 -301 -17 -530 -20 -244 -3 -422 -10 -485 -19 -90 -13 -202 -18 -640 -30 -77 -2 -189 -11 -250 -19 -60 -9 -151 -16 -202 -16 -50 0 -103 -4 -116 -9 -33 -13 -40 -47 -21 -109 l17 -52 193 0 c123 0 194 4 194 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -15 107 -13 112 2 5 13 100 18 562 32 115 4 263 11 330 16 67 5 312 14 546 20 234 5 529 14 655 20 234 10 529 16 1255 25 637 8 931 14 1270 25 173 5 506 15 740 21 675 17 689 17 820 28 69 5 217 10 330 11 271 1 727 18 815 30 39 5 254 9 478 10 452 0 580 9 635 46 l32 22 -32 23 c-20 14 -50 24 -77 26 -26 1 -111 7 -191 13 -80 5 -187 10 -238 11 -65 0 -96 5 -106 15 -17 16 -106 19 -106 4 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 -61 10 -162 10 -133 -1 -165 -4 -176 -16z">
                                                                           </path>
                                                                        </g>
                                                                     </svg>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div
                                                            class="elementor-element elementor-element-635680c elementor-widget elementor-widget-spacer"
                                                            data-id="635680c" data-element_type="widget"
                                                            data-widget_type="spacer.default">
                                                            <div class="elementor-widget-container">
                                                               <div class="elementor-spacer">
                                                                  <div class="elementor-spacer-inner">
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div
                                                            class="elementor-element elementor-element-49fbf92 elementor-widget-tablet__width-initial elementor-widget elementor-widget-text-editor"
                                                            data-id="49fbf92" data-element_type="widget"
                                                            data-widget_type="text-editor.default">
                                                            <div class="elementor-widget-container">
                                                               <p>{{faqPageCMS.getInTouchShortDetail}}
                                                               </p>
                                                            </div>
                                                         </div>
                                                         <div
                                                            class="elementor-element elementor-element-3228c75 elementor-widget elementor-widget-spacer"
                                                            data-id="3228c75" data-element_type="widget"
                                                            data-widget_type="spacer.default">
                                                            <div class="elementor-widget-container">
                                                               <div class="elementor-spacer">
                                                                  <div class="elementor-spacer-inner">
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div
                                                            class="elementor-element elementor-element-e98ad4d listivo-checkbox-policy-white elementor-widget elementor-widget-lst_contact_form"
                                                            data-id="e98ad4d" data-element_type="widget"
                                                            data-widget_type="lst_contact_form.default">
                                                            <div class="elementor-widget-container">
                                                               <div class="listivo-contact-form">
                                                                  <div role="form" class="wpcf7" id="wpcf7-f423-o1"
                                                                     lang="en-US" dir="ltr">
                                                                     <div class="screen-reader-response">
                                                                        <p role="status" aria-live="polite"
                                                                           aria-atomic="true">
                                                                        </p>
                                                                        <ul></ul>
                                                                     </div>
                                                                     <form v-on:submit.prevent="submitInfo()" action="/faq/#wpcf7-f423-o1" method="post"
                                                                        class="wpcf7-form init" novalidate="novalidate"
                                                                        data-status="init">
                                                                        <div style="display: none;">
                                                                           <input type="hidden" name="_wpcf7"
                                                                              value="423" />
                                                                           <input type="hidden" name="_wpcf7_version"
                                                                              value="5.5.3" />
                                                                           <input type="hidden" name="_wpcf7_locale"
                                                                              value="en_US" />
                                                                           <input type="hidden" name="_wpcf7_unit_tag"
                                                                              value="wpcf7-f423-o1" />
                                                                           <input type="hidden"
                                                                              name="_wpcf7_container_post" value="0" />
                                                                           <input type="hidden"
                                                                              name="_wpcf7_posted_data_hash" value="" />
                                                                        </div>
                                                                        <input type="hidden" name="listivo-user-id"
                                                                           value=""
                                                                           class="wpcf7-form-control wpcf7-hidden" />
                                                                        <div>
                                                                           <div class="listivo-clearfix">
                                                                              <div><span
                                                                                    class="wpcf7-form-control-wrap your-name"><input
                                                                                       type="text" name="your-name"
                                                                                       v-model="contactName" size="40"
                                                                                       class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                       aria-required="true"
                                                                                       aria-invalid="false"
                                                                                       placeholder="Name *" /></span>
                                                                              </div>
                                                                              <div><span
                                                                                    class="wpcf7-form-control-wrap your-email"><input
                                                                                       type="email" name="your-email"
                                                                                       v-model="contactEmail" size="40"
                                                                                       class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                                                       aria-required="true"
                                                                                       aria-invalid="false"
                                                                                       placeholder="Email *" /></span>
                                                                              </div>
                                                                              <div><span
                                                                                    class="wpcf7-form-control-wrap your-phone"><input
                                                                                       type="tel" name="your-phone"
                                                                                       v-model="contactPhone" size="40"
                                                                                       class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel"
                                                                                       aria-invalid="false"
                                                                                       placeholder="Phone" /></span>
                                                                              </div>
                                                                              <div><span
                                                                                    class="wpcf7-form-control-wrap your-message"><textarea
                                                                                       name="your-message" cols="40"
                                                                                       v-model="contactMessage"
                                                                                       rows="10"
                                                                                       class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                                                                                       aria-required="true"
                                                                                       aria-invalid="false"
                                                                                       placeholder="Message *"></textarea></span>
                                                                              </div>
                                                                              <div class="listivo-checkbox-policy">
                                                                                 <span
                                                                                    class="wpcf7-form-control-wrap your-accept"><span
                                                                                       class="wpcf7-form-control wpcf7-acceptance"><span
                                                                                          class="wpcf7-list-item"><label><input
                                                                                                type="checkbox"
                                                                                                name="your-accept"
                                                                                                value="1"
                                                                                                aria-invalid="false" /><span
                                                                                                class="wpcf7-list-item-label">I
                                                                                                accept
                                                                                                the
                                                                                                <a href="#">privacy
                                                                                                   policy</a></span></label></span></span></span>
                                                                              </div>
                                                                           </div>
                                                                           <div><button id="submit"
                                                                                 class="wpcf7-form-control wpcf7-submit listivo-primary-button listivo-primary-button--icon"><span
                                                                                    class="listivo-primary-button__text">{{faqPageCMS.getInTouchButtonText}}</span><span
                                                                                    class="listivo-primary-button__icon"><i
                                                                                       class="fas fa-paper-plane"></i></span></button>
                                                                           </div>
                                                                        </div>
                                                                        <div class="wpcf7-response-output"
                                                                           aria-hidden="true">
                                                                        </div>
                                                                     </form>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </section>
                                          </div>
                                       </div>
                                    </div>
                                 </section>
                                 <section
                                    class="elementor-section elementor-top-section elementor-element elementor-element-1bebe07 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="1bebe07" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                       <div
                                          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8cbfe1c"
                                          data-id="8cbfe1c" data-element_type="column">
                                          <div class="elementor-widget-wrap elementor-element-populated">
                                             <div
                                                class="elementor-element elementor-element-1533450 elementor-widget elementor-widget-spacer"
                                                data-id="1533450" data-element_type="widget"
                                                data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                   <div class="elementor-spacer">
                                                      <div class="elementor-spacer-inner"></div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </section>
                                 <Subscribe></Subscribe>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <Footer />
      </div>
   </div>

</template>
<script src="../js/Faq.js"></script>
<style scoped>

</style>