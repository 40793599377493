import Header from '../../../include/Header';
import Footer from '../../../include/Footer';
import VPagination from "@hennge/vue3-pagination";
import Select2 from 'vue3-select2-component';
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Pagination from '../../paginationResource/Pagination.vue';
import MobilePagination from '../../paginationResource/MobilePagination.vue';
import $ from 'jquery';
export default {
    name: 'Product',
    title: 'Product',
    components: {
        Header,
        Footer,
        VPagination,
        Select2,
        Pagination,
        MobilePagination,
    },
    data() {
        return {
            // listivo-select__field--active
            //listivo-field__icon--clear //remove - (listivo-field__icon--arrow)
            productList: [],
            imagePath: this.$imageURL + 'product/',
            currentPaginPage: 1,
            limit: 9,
            searchmyValue: '',
            myOptions: ['op1', 'op2', 'op3'],
            totalPages: null,
            selectFieldCategory: 'Category',
            selectFieldSort: 'Date Listed: Newest',
            currentPage: 1,
            gridorListView:false,
            resultText: 'All Available Products',
            filterFlag: false,
            searchText: '',
            categories:[],
            category:{},
            name: null,
            location: null,
            catID: null,
            minPrice: null,
            maxPrice: null,
            searchFlag: false,
            sortByText: null,
            categoryList: []
        }
    },
    async mounted() {
        $('html,body').animate({ scrollTop: 0 }, 'slow');
        await this.getProductList();
        this.categoryList = JSON.parse(localStorage.getItem('productRawCategories'));
        // await this.getProductCategory();
        if(this.$route.query.search || this.$route.query.location){
            this.name = this.$route.query.search;
            this.location = this.$route.query.location;
            this.filterFlag = true;
            this.getSearchedProductList();
        }
        
        console.log("Inside Product Component");

        // $('.listivo-pagination-desktop__inner').children('div:eq(1)').addClass('listivo-pagination-desktop__page--active');
    },
    beforeCreate: function() {
        document.body.className = 'archive post-type-archive post-type-archive-listivo_listing logged-in theme-listivo listivo-1.0.19 listivo_user woocommerce-no-js elementor-default elementor-kit-5228';

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/asset/wp-content/uploads/elementor/css/post-867.css';
        document.head.appendChild(style);

    },
    methods: {
        manageCategoryDropdown:function(){
            $('.catSelect').toggleClass('listivo-select__field--active');
            $('.catSelect').children('.listivo-select__dropdown').fadeToggle();
            $('.catSelect').children('.listivo-field__icon').removeClass('listivo-field__icon--clear');
            $('.catSelect').children('.listivo-field__icon').addClass('listivo-field__icon--arrow');
        },
        setCategoryChoosen:function(searchCat,catID){
            this.searchFlag = true;
            this.filterFlag = true;
            this.selectFieldCategory=searchCat;
            this.category = {
                id: catID,
                name: searchCat
                
            };
            this.searchText = "Searching for Category " +  searchCat;
            $('.catSelect').addClass('listivo-select__field--active');
            $('.catSelect').children('.listivo-select__dropdown').hide();
            
            if($('.catSelect').children('.listivo-field__icon').hasClass('listivo-field__icon--arrow'))
            {
                $('.catSelect').children('.listivo-field__icon').removeClass('listivo-field__icon--arrow');
            }
            $('.catSelect').children('.listivo-field__icon').addClass('listivo-field__icon--clear');
            $('.catSelect').children('.listivo-select__dropdown').children('.listivo-select__options').children('.listivo-select__option').removeClass('listivo-select__option--active');
            this.getSearchedProductList();
        },
        manageSortDropdown:function(){
            $('.sortselect').toggleClass('listivo-select__field--active');
            $('.sortselect').children('.listivo-select__dropdown').fadeToggle();
            $('.sortselect').children('.listivo-field__icon').removeClass('listivo-field__icon--clear');
            $('.sortselect').children('.listivo-field__icon').addClass('listivo-field__icon--arrow');
        },
        setSortChoosen:function(searchCat){
            this.searchFlag = true;
            this.filterFlag = true;
            this.selectFieldSort = searchCat;
            this.sortByText = searchCat.replace(/\s/g, '')
            $('.sortselect').addClass('listivo-select__field--active');
            $('.sortselect').children('.listivo-select__dropdown').hide();
            
            if($('.sortselect').children('.listivo-field__icon').hasClass('listivo-field__icon--arrow'))
            {
                $('.sortselect').children('.listivo-field__icon').removeClass('listivo-field__icon--arrow');
            }
            $('.sortselect').children('.listivo-field__icon').addClass('listivo-field__icon--clear');

            $('.sortselect').children('.listivo-select__dropdown').children('.listivo-select__options').children('.listivo-select__option').removeClass('listivo-select__option--active');
            this.getSearchedProductList();
        },
        viewProductoption:function(typeView){
            $('.listivo-results-bar__grid').removeClass('listivo-results-bar__grid--active');
            if(typeView=="grid")
            {
                $(".listivo-results-bar__grids").children('div:eq(0)').addClass('listivo-results-bar__grid--active');
                $(".listivo-listings").children('div:eq(0)').removeClass('listivo-cards-list')
                $(".listivo-listings").children('div:eq(0)').addClass('listivo-cards-list-v2')

                $(".listivo-listings").children('div:eq(0)').children('div:eq(0)').addClass('listivo-listing-card')
                $(".listivo-listings").children('div:eq(0)').children('div:eq(0)').removeClass('listivo-listing-card-row')
                this.gridorListView=false;
            }
            else if(typeView=="list")
            {
                $(".listivo-results-bar__grids").children('div:eq(1)').addClass('listivo-results-bar__grid--active');

                $(".listivo-listings").children('div:eq(0)').removeClass('listivo-cards-list-v2')
                $(".listivo-listings").children('div:eq(0)').addClass('listivo-cards-list')

                $(".listivo-listings").children('div:eq(0)').children('div:eq(0)').removeClass('listivo-listing-card')
                $(".listivo-listings").children('div:eq(0)').children('div:eq(0)').addClass('listivo-listing-card-row')
                this.gridorListView=true;
            }
        },
        fieldDecorator: function(fidName)
        {
            this.searchFlag = true;
            if(fidName=="search-title-product")
            {
                if($('.'+fidName).children().children('input').val().length > 0)
                {
                    $('.'+fidName).addClass('listivo-field--active');
                    $('.'+fidName).children().children('div:eq(2)').fadeIn();
                    this.searchText = "Searching for Product Title " +  $('.'+fidName).children().children('input').val();
                    this.filterFlag = true;
                }
                else
                {
                    $('.'+fidName).removeClass('listivo-field--active');
                    $('.'+fidName).children().children('div:eq(2)').fadeOut();
                }
            }
            else
            {
                if($('.'+fidName).children('div:eq(0)').children('input').val().length > 0)
                {
                    $('.'+fidName).addClass('listivo-field--active');
                    $('.'+fidName).children('div:eq(0)').children('div').fadeIn();
                    this.searchText = "Searching for "+ fidName.charAt(0).toUpperCase() + fidName.slice(1).replace('-', ' ') + " " + $('.'+fidName).children('div:eq(0)').children('input').val()
                    this.filterFlag = true;
                }
                else
                {
                    $('.'+fidName).removeClass('listivo-field--active');
                    $('.'+fidName).children('div:eq(0)').children('div').fadeOut();
                }
            }this.getSearchedProductList();
                
            
        },
        fieldDecoratorClear:function(fidName)
        {
            if(fidName=="search-title-product")
            {
                $('.'+fidName).children().children('input').val("");
                $('.'+fidName).removeClass('listivo-field--active');
                $('.'+fidName).children().children('div:eq(2)').fadeOut();
                this.searchText = '';
                this.filterFlag = false;
            }
            else
            {
                $('.'+fidName).children('div:eq(0)').children('input').val("");
                $('.'+fidName).removeClass('listivo-field--active');
                $('.'+fidName).children('div:eq(0)').children('div').fadeOut();
                this.searchText = '';
                this.filterFlag = false;
            }
                
                
            
        },
        onPageChange(SourceDataCounterPage) {
            console.log(SourceDataCounterPage)
            this.currentPaginPage = SourceDataCounterPage;
            console.log(SourceDataCounterPage)
            if(this.searchFlag == false){
                this.getProductList();
            }
            else{
                this.getSearchedProductList();
            }
            
            $('.listivo-pagination-desktop__page').removeClass('listivo-pagination-desktop__page--active');
            $('.listivo-pagination-desktop__page')
            .each(function(index,row){
                if($(row).html()==SourceDataCounterPage)
                {
                    $(row).addClass('listivo-pagination-desktop__page--active');
                }
            });
            $(".product-frame-loader").removeClass('listivo-loading');
        },
        getSearchedProductList: async function(){
            let params = "?page=" + this.currentPaginPage + "&limit=" + this.limit;
            if (this.name){
                params += "&name=" + this.name;
            }
            if (this.location){
                params += "&location=" + this.location;
            }
            this.catID = this.category.id;
            if (this.catID){
                params += "&categoryId=" + this.catID;
            }
            if (this.minPrice){
                params += "&minPrice=" + this.minPrice;
            }
            if (this.maxPrice){
                params += "&maxPrice=" + this.maxPrice;
            }
            if(this.sortByText) {
                params += "&sortby=" + this.sortByText;
            }
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.products.searchProducts + params,

            };
            
            await this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.productList = response.data.products;
                        this.totalPages = response.data.pageCount;
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
        },
        getProductList: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.products.productList + "?page=" + this.currentPaginPage + "&limit=" + this.limit,

            };
            
            await this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.productList = response.data.products;
                        this.categories = response.data.categories;
                        this.totalPages = response.data.pageCount;
                    }
                    this.isLoading = false;

                    $('html,body').animate({ scrollTop: 0 }, 'slow');
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
        },
        getProductCategory: function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.products.productCategory,

            };
            this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        this.categoryList = response.data;
                    }
                    this.isLoading = false;

                    $('html,body').animate({ scrollTop: 0 }, 'slow');
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
        updateHandler: function() {
            console.log("Current Page: ", this.currentPaginPage)
        },
        myChangeEvent(val) {
            console.log(val);
        },
        mySelectEvent({ id, text }) {
            console.log({ id, text })
            this.getProductList();
        },
        pickMyLocation: function() {
            const success = (position) => {
                const latitude  = position.coords.latitude;
                const longitude = position.coords.longitude;
                if(latitude && longitude){
                    this.center = {
                        lat: latitude,
                        lng: longitude
                    }
                    
                }
                this.getStreetAddressFrom(this.center.lat, this.center.lng);
    
                // Do something with the position
            };
    
            const error = (error) => {
                console.log(error);
            };
    
            // This will open permission popup
            navigator.geolocation.getCurrentPosition(success, error);
            
        },
        async getStreetAddressFrom(lat, long) {
            try {
               var {
                  data
               } = await this.$axios.get(
                  "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                  lat +
                  "," +
                  long +
                  "&key=AIzaSyBhk7osZH33ThTArtnwC2Yg4UAU7qFlWfA"
               );
               if (data.error_message) {
                  console.log(data.error_message);
               } else {
                  this.location = data.results[0].formatted_address;
                  console.log("Address is: ", this.location);
                  this.getSearchedProductList();
                  this.filterFlag = true;
               }
            } catch (error) {
               console.log(error.message);
            }
         },
    },
}