<template>
    <section v-if="footerSubscriber" :style="{backgroundImage: 'url(' + imagePath + footerSubscriber.backgroundImage + ')'}"
    class="latest-updates elementor-section elementor-top-section elementor-element elementor-element-5607add0 elementor-section-content-middle listivo-subscription-elementor-section elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="5607add0" data-element_type="section">
    <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-52fd1b61"
            data-id="52fd1b61" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-6036a2c9 elementor-widget elementor-widget-spacer"
                    data-id="6036a2c9" data-element_type="widget"
                    data-widget_type="spacer.default">
                    <div class="elementor-widget-container">
                        <div class="elementor-spacer">
                            <div class="elementor-spacer-inner"></div>
                        </div>
                    </div>
                </div>
                <div class="elementor-element elementor-element-61bdca98 elementor-widget-mobile__width-initial elementor-widget__width-inherit listivo-heading-mc4wp elementor-widget-tablet__width-initial elementor-widget elementor-widget-heading"
                    data-id="61bdca98" data-element_type="widget"
                    data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                        <h2
                            class="elementor-heading-title elementor-size-large">
                            {{footerSubscriber.heading}}
                        </h2>
                    </div>
                </div>
                <div class="elementor-element elementor-element-2b40b9f7 elementor-widget elementor-widget-lst_shape"
                    data-id="2b40b9f7" data-element_type="widget"
                    data-widget_type="lst_shape.default">
                    <div class="elementor-widget-container">
                        <div class="listivo-shape">
                            <div class="listivo-shape-underline">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 364 9" width="364.0pt"
                                    height="9.0pt">
                                    <path
                                        d="M 40.01 0.75 C 76.35 0.99 112.64 1.27 148.98 1.82 C 166.98 1.74 185.00 1.93 203.00 2.01 C 223.22 2.31 243.46 2.02 263.68 2.48 C 281.79 3.26 299.91 2.62 318.00 3.57 C 328.67 3.99 339.34 3.84 349.99 4.48 C 354.44 4.64 358.78 4.44 363.14 5.56 C 362.37 6.72 361.59 7.87 360.78 9.00 L 359.90 9.00 C 351.89 8.35 344.01 8.66 336.00 8.45 C 309.34 7.82 282.66 7.38 256.00 7.01 C 208.01 6.61 159.98 6.66 112.00 5.80 C 92.00 5.77 72.00 5.01 52.00 4.97 C 40.33 4.76 28.66 4.72 17.00 4.30 C 11.09 4.02 5.76 4.73 0.00 2.87 L 0.00 2.65 C 13.13 0.90 26.78 0.94 40.01 0.75 Z"
                                        fill="#ffc14a" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="elementor-element elementor-element-2d267f76 elementor-widget elementor-widget-spacer"
                    data-id="2d267f76" data-element_type="widget"
                    data-widget_type="spacer.default">
                    <div class="elementor-widget-container">
                        <div class="elementor-spacer">
                            <div class="elementor-spacer-inner"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5c0542ef"
            data-id="5c0542ef" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-5e8aec96 elementor-widget-tablet__width-initial elementor-widget__width-initial elementor-widget elementor-widget-wp-widget-mc4wp_form_widget"
                    data-id="5e8aec96" data-element_type="widget"
                    data-widget_type="wp-widget-mc4wp_form_widget.default">
                    <div class="elementor-widget-container">

                        <form id="mc4wp-form-1"
                            class="mc4wp-form mc4wp-form-522" method="post"
                            data-id="522" data-name="Listivo Form">
                            <div class="mc4wp-form-fields">
                                <div class="listivo-subscribe">
                                    <input type="email" name="EMAIL" v-model="email" 
                                        :placeholder="footerSubscriber.emailBoxPlaceHolder"
                                        required />
                                    <button @click="subscribe"
                                        class="listivo-primary-button listivo-primary-button--hide-icon-mobile listivo-primary-button--height-65 listivo-primary-button--mobile-full-width listivo-primary-button--icon"
                                        type="button"><span
                                            class="listivo-primary-button__text">{{footerSubscriber.buttonText}}</span><span
                                            class="listivo-primary-button__icon"><i
                                                class="far fa-envelope"></i></span></button>
                                </div>
                            </div><label
                                style="display: none !important;">Leave this
                                field empty if you're human: <input
                                    type="text" name="_mc4wp_honeypot"
                                    value="" tabindex="-1"
                                    autocomplete="off" /></label><input
                                type="hidden" name="_mc4wp_timestamp"
                                value="1641665690" /><input type="hidden"
                                name="_mc4wp_form_id" value="522" /><input
                                type="hidden" name="_mc4wp_form_element_id"
                                value="mc4wp-form-1" />
                            <div class="mc4wp-response"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </section>
</template>

<script src="./js/subscribe.js"></script>